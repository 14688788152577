import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './stores/app.reducers';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import { ROUTES as ON_SPOT_ROUTES } from './on-spot-test/on-spot-test.constants';
import { getAdmissionTestRedirectionUrl } from './shared/utils/admissionTest.util';

@Injectable({
  providedIn: 'root'
})
export class AdmissionTestGuardResolver implements Resolve<boolean> {
  constructor(private store: Store<AppState>, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select('auth').pipe(
      switchMap((auth) => {
        const queryParams: any = route.queryParamMap;
        if (queryParams?.params?.authToken) {
          return of(true);
        }
        if (!auth.authenticated) {
          return of(true);
        }
        return this.store.select('userProfile').pipe(
          filter((data) => !!(data?.userData?.userId)),
          switchMap((data) => {
            if (data?.userData?.isAdmissionTestUser) {
              let redirectionUrl = getAdmissionTestRedirectionUrl(data?.userData?.admissionTestDetail);
              if (data?.userData?.onSpotRegNo) {
                redirectionUrl = `${ON_SPOT_ROUTES.ON_SPOT}/${ON_SPOT_ROUTES.DASHBOARD}`;
              }
              if (!state.url.includes(redirectionUrl) && !state.url.includes('test-ongoing') && !state.url.includes('test-results')) {
                this.router.navigate([redirectionUrl]);
                return of(false);
              }
            }
            return of(true);
          }),
          catchError(() => {
            return of(true);
          }),
          first()
        );
      }),
      catchError(() => {
        return of(true);
      }),
      first()
    );
  }
}
