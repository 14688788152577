export enum CARD_ACTIONS {
    ADD_TO_CART = 'add_to_cart',
    BUY_NOW = 'buy_now',
    ADD_TO_ACCOUNT = 'add_to_account',
    ACTIVATE_NOW = 'activate_now',
    GO_TO_CART = 'go_to_cart'
}

export enum SESSION_DROPDOWN {
   SESSION_23_24 = 'Session 2023-24',
   SESSION_21_22 = 'Session 2021-22',
   SESSION_19_20 = 'Session 2019-20'
}

export enum CLASSES {
    CLASS_9 = '9th',
    CLASS_10 = '10th',
    CLASS_11 = '11th',
    CLASS_12 = '12th',
    CLASS_12_PASS = '12th Pass'
}

export enum GOALS {
    JEE_A = 'JEE(A)',
    JEE_M = 'JEE(M)',
    NTSE = 'NTSE & Fundamentals of Engineering and Medical',
    BITSAT = 'BITSAT'
}

export enum PACKAGE_CTA_TITLE {
    ADD_TO_CART = 'Add to Cart',
    GO_TO_CART = 'Go to Cart'
}

export enum LOCATION_INFO {
    ADMIN_LEVEL_1 = 'administrative_area_level_1'
}

export enum TEST_SERIES_TYPES {
    EXTRA = 'extra',
    WINDOW = 'window',
    MILESTONE = 'milestone',
    REVISION = 'revision'
}

export enum TEST_STATUS {
    PAST = 'Past',
    UPCOMING = 'Upcoming',
    LIVE = 'Live',
    CLASS = 'class'
}