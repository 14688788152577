import { Injectable } from '@angular/core';
import { BehaviorSubject, timer, } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';

import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  public errorMessage = new BehaviorSubject<any>({});
  public updatedProfileImage = new BehaviorSubject<any>({});
  public currentStep = new BehaviorSubject<string>('1');
  public lastStep = new BehaviorSubject<string>('1');
  public schoolUpdated = new BehaviorSubject<boolean>(false);
  public isCaptchaChecked = new BehaviorSubject<boolean>(false);
  public phoneAdded = new BehaviorSubject<any>('');

  constructor(
    private httpService: HttpService,
    private alertService: AlertService
  ) { }

  getOnboardingStep(userInfo): number {
    if (this.showStep2(userInfo)) {
      if (this.showStep3(userInfo)) {
        if (this.showStep4(userInfo)) {
          if (this.showStep5(userInfo)) {
            if (this.showStep6(userInfo)) {
              this.currentStep.next('6');
              return 6;
            } else {
              this.currentStep.next('5a');
              return 5;
            }
          } else {
            this.currentStep.next('4');
            return 4;
          }
        } else {
          this.currentStep.next('3');
          return 3;
        }
      } else {
        this.currentStep.next('2a');
        return 2;
      }
    } else {
      this.currentStep.next('1');
      return 1;
    }
  }

  showStep2(userInfo): boolean {
    if (userInfo.class && userInfo.class.name) {
      return true;
    } else {
      return false;
    }
  }

  showStep3(userInfo): boolean {
    if (userInfo.goals.length > 0 || userInfo.userType === 'fiitjee') {
      return true;
    } else {
      return false;
    }
  }

  showStep4(userInfo): boolean {
    if (userInfo.school && userInfo.school.name) {
      return true;
    } else {
      return false;
    }
  }

  showStep5(userInfo): boolean {
    if (userInfo.photoUrl || (userInfo.onboardingStepsSkipped && userInfo.onboardingStepsSkipped.find(step => step.name === 'profilePicture'))) {
      return true;
    } else {
      return false;
    }
  }

  showStep6(userInfo): boolean {
    if ((userInfo?.mobileIsVerified || userInfo?.b2bMobile?.isVerified) && userInfo?.emailIsverified) {
      return true;
    } else {
      return false;
    }
  }

  getClasses(): any {
    return new Promise((resolve, reject) => {
      this.httpService.get('v4/classes', {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCareers(value): any {
    return new Promise((resolve, reject) => {
      this.httpService.get(`v4/careers?classId=${value}`, null).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getGoals(value): any {
    return new Promise((resolve, reject) => {
      const apiName = `v4/courses?courseTypeIds=${value.courseTypeIds}&sessionYear=${value.sessionYear}&classId=${value.classId}`;
      this.httpService.get(apiName, null).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCountryData(): any {
    return new Promise((resolve, reject) => {
      this.httpService.get(`v4/locations`, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getStateData(countryId): any {
    return new Promise((resolve, reject) => {
      const apiName = 'v4/locations?countryId=' + countryId;
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCityData(data): any {
    return new Promise((resolve, reject) => {
      const apiName = 'v4/locations?countryId=' + data.countryId + '&stateId=' + data.stateId;
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getSchoolData(data): any {
    const apiName = 'v4/auth/schools?cityId=' + data.cityId + '&schoolName=' + data.schoolName;
    return new Promise((resolve, reject) => {
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  skipStep(step) : any {
    const apiName = 'v4/auth/user';
    return new Promise((resolve, reject) => {
      this.httpService.put(apiName, true, step, true).subscribe((res) => {
        if (res && res.code) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }
}
