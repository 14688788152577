import { Action } from '@ngrx/store';
import { UserProfile } from '../../models/profile.model';

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';

export const VERIFY_MOBILE_PROFILE = 'VERIFY_MOBILE_PROFILE';
export const VERIFY_MOBILE_PROFILE_SUCCESS = 'VERIFY_MOBILE_PROFILE_SUCCESS';

export const VERIFY_EMAIL_PROFILE = 'VERIFY_EMAIL_PROFILE';
export const VERIFY_EMAIL_PROFILE_SUCCESS = 'VERIFY_EMAIL_PROFILE_SUCCESS';

export const ADD_NAME = 'UPDATE_NAME';
export const ADD_NAME_SUCCESS = 'ADD_NAME_SUCCESS';

export const ADD_CLASS = 'ADD_CLASS';
export const ADD_CAREER = 'ADD_CAREER';
export const ADD_GOAL = 'ADD_GOAL';
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS';
export const ADD_SCHOOL_SUCCESS = 'ADD_SCHOOL_SUCCESS';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS';

export const GET_CLASS_LIST = 'GET_CLASS_LIST';
export const GET_CLASS_LIST_SUCCESS = 'GET_CLASS_LIST_SUCCESS';

export const GET_GOAL_LIST = 'GET_GOAL_LIST';
export const GET_GOAL_LIST_SUCCESS="GET_GOAL_LIST_SUCCESS";

export const ADD_PASSWORD = 'ADD_PASSWORD';
export const ADD_PASSWORD_SUCCESS = 'ADD_PASSWORD_SUCCESS';
export const SKIP_PASSWORD = 'SKIP_PASSWORD';
export const SKIP_PASSWORD_SUCCESS = 'SKIP_PASSWORD_SUCCESS';

export const ADD_REFERRAL = 'ADD_REFERRAL';
export const ADD_REFERRAL_SUCCESS = 'ADD_REFERRAL_SUCCESS';

export const FAIL = 'FAIL';

export const UPDATE_USER_CARD_PROFILE = 'UPDATE_USER_CARD_PROFILE';
export const UPDATE_USER_CARD_PROFILE_SUCCESS = 'UPDATE_USER_CARD_PROFILE_SUCCESS';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const UPDATE_PERSONAL_INFO_SUCCESS = 'UPDATE_PERSONAL_INFO_SUCCESS';
export const ADD_DOB = 'ADD_DOB';
export const SET_USER_DETAIL = 'SET_USER_DETAIL';

export class GetUserDetail implements Action {
    readonly type = GET_USER_DETAIL;
    constructor(public payload: { 'authToken': string, 'fromScreen': string, 'tag': string }) { }
}
export class SetUserDetail implements Action {
    readonly type = SET_USER_DETAIL;
    constructor(public payload: UserProfile) { };
}
export class UpdateUserDetail implements Action {
    readonly type = UPDATE_USER_DETAIL;
    constructor(public payload: UserProfile) { }
}

export class VerifyMobileProfile implements Action {
    readonly type = VERIFY_MOBILE_PROFILE;
    constructor(public payload: {
        'countryCode': string, 'otp': number,
        'mobile': any
    }) { }
}
export class VerifyMobileProfileSuccess implements Action {
    readonly type = VERIFY_MOBILE_PROFILE_SUCCESS;
    constructor(public payload: {}) { }
}
export class VerifyEmailProfile implements Action {
    readonly type = VERIFY_EMAIL_PROFILE;
    constructor(public payload: { 'email': string, 'otp': number }) { }
}
export class VerifyEmailProfileSuccess implements Action {
    readonly type = VERIFY_EMAIL_PROFILE_SUCCESS;
    constructor(public payload: {}) { }
}
export class GetClassList implements Action {
    readonly type = GET_CLASS_LIST;
}

export class GetClassListSuccess implements Action {
    readonly type = GET_CLASS_LIST_SUCCESS;
    constructor(public payload: {}) { }
}

export class GetGoalList implements Action {
    readonly type = GET_GOAL_LIST;
    constructor(public payload: string) { }
}

export class AddName implements Action {
    readonly type = ADD_NAME;
    constructor(public payload: {}) { }
}
export class AddNameSuccess implements Action {
    readonly type = ADD_NAME_SUCCESS;
    constructor(public payload: {}) { }
}
export class AddClass implements Action {
    readonly type = ADD_CLASS;
    constructor(public payload: {}) { }
}
export class AddCareer implements Action {
    readonly type = ADD_CAREER;
    constructor(public payload: {}) { }
}
export class AddGoal implements Action {
    readonly type = ADD_GOAL;
    constructor(public payload: {}) { }
}
export class AddGoalSuccess implements Action {
    readonly type = ADD_GOAL_SUCCESS;
    constructor(public payload: {}) { }
}

export class AddSchoolSuccess implements Action {
    readonly type = ADD_SCHOOL_SUCCESS;
    constructor(public payload: {}) { }
}

export class AddSchool implements Action {
    readonly type = ADD_SCHOOL;
    constructor(public payload: {}) { }
}

export class AddProfileSuccess implements Action {
    readonly type = ADD_PROFILE_SUCCESS;
    constructor(public payload: {}) { }
}
export class AddPassword implements Action {
    readonly type = ADD_PASSWORD;
    constructor(public payload: {}) { }
}

export class AddPasswordSuccess implements Action {
    readonly type = ADD_PASSWORD_SUCCESS;
    constructor(public payload: {}) { }
}

export class SkipPassword implements Action {
    readonly type = SKIP_PASSWORD;
    constructor(public payload: {}) { }
}

export class SKipPasswordSuccess implements Action {
    readonly type = SKIP_PASSWORD_SUCCESS;
    constructor(public payload: {}) { }
}

export class AddReferral implements Action {
    readonly type = ADD_REFERRAL;
    constructor(public payload: {}) { }
}
export class AddReferralSuccess implements Action {
    readonly type = ADD_REFERRAL_SUCCESS;
    constructor(public payload: {}) { }
}

export class Fail implements Action {
    readonly type = FAIL;
}

export class UpdateUserCardProfile implements Action {
    readonly type = UPDATE_USER_CARD_PROFILE;
    constructor(public payload: {}) { }
}

export class UpdateUserCardProfileSuccess implements Action {
    readonly type = UPDATE_USER_CARD_PROFILE_SUCCESS;
    constructor(public payload: {}) { }
}

export class ChangePassword implements Action {
    readonly type = CHANGE_PASSWORD;
    constructor(public payload: {}) { }
}

export class ChangePasswordSuccess implements Action {
    readonly type = CHANGE_PASSWORD_SUCCESS;
    constructor(public payload: {}) { }
}

export class UpdatePersonalInfoSuccess implements Action {
  readonly type = UPDATE_PERSONAL_INFO_SUCCESS;
  constructor(public payload: {}) { }
}

export class AddDOB implements Action {
    readonly type = ADD_DOB;
    constructor(public payload: {}) { }
  }

export type UserProfileActionList = VerifyMobileProfileSuccess | VerifyEmailProfileSuccess | AddPassword | AddPasswordSuccess |
    AddReferral | AddReferralSuccess | AddClass | AddCareer | AddGoal | AddGoalSuccess | AddNameSuccess | AddName | GetClassList |
    GetClassListSuccess | GetGoalList | GetUserDetail | UpdateUserDetail | AddSchoolSuccess | AddSchool | AddProfileSuccess | SkipPassword |
    SKipPasswordSuccess | Fail | UpdateUserCardProfileSuccess |
    ChangePassword | ChangePasswordSuccess | UpdatePersonalInfoSuccess | AddDOB | SetUserDetail;
