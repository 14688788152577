import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class UpcomingTestsService {

  initializeFilter = new BehaviorSubject(null); // For Filter Scroll

  constructor(
    private httpService: HttpService,
    private alertService: AlertService
  ) { }

  getUpcomingTests(body): any {
    return new Promise((resolve, reject) => {
      this.httpService.get('v4/auth/upcoming-tests', true, body, true).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  fetchUserCourses(): any {
    return new Promise((resolve, reject) => {
      this.httpService.get('v4/auth/users/courses', true, null, true, false,
      ).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }
}
