import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AppState } from '../../../stores/app.reducers';
import ClevertapReact from 'clevertap-react';
import { Store } from '@ngrx/store';
import * as UserProfileAction from '../../../stores/profile/user-profile.actions';
import { AlertService } from '../../../shared/services/alert.service';
import { DetectLocationService } from '../../../shared/services/detect-location.service';
import * as LoaderAction from '../../../stores/loader/loader.actions';
import { environment } from 'src/environments/environment';

declare var $: any;
@Injectable()
export class FiitJeeService {

  public currentStep = new Subject<any>();
  public redirectTo = new Subject<any>();
  public mobilePresent : any;
  public completeProfileSteps = new Subject<any>();
  public isMobileValid = new Subject<any>();
  public saveCode: any;
  public saveOtp: any;
  public saveWac: any;
  public fromModal: boolean = false;
  public savePassword: any;
  public enteredEnrollmentNo: any;
  public mobileNumber = new Subject<any>();
  public userId: any;
  public pageForLoginHelp = new Subject<any>();
  stepsCompleted: any = [];
  public userData: any;
  public promise: Promise<any>;
  public loginVia: String;
  public disableBtnSubject = new BehaviorSubject<any>(false);
  public userEmail: string = '';
  public fromScreen: string = '';
  public mobileCountryCode: string = '';

  constructor(private http: HttpService, private store: Store<AppState>, private alert: AlertService) {
    this.store.select('userProfile').subscribe(res => {
      this.userData = res.userData;
    });

  }


  // Api result of enrollmentNumber
  // Code 200 : EnrollmentNo not exist new user
  // Code 205 : EnrollmentNo exist but neither mypat password nor verified mobile number exist
  // Code 201 : EnrollmentNo exist, mypat password not set verifed mypat mobile number set, existing user
  // Code 202 : EnrollmentNo exist, mypat password set, verified mypat mobile number not set, existing user
  // Code 210 : EnrollmentNo exist with password and verified mypat mobile number existing user

  // Total Steps present - 
  // 1. Enrollment number enter - enNoEnter

  // Function to fetch the code so as the redirection of form can be done on main page
  fetchRedirection(params) {
    const newParams = {...params};
    if (newParams.registrationNo) {
      newParams.enrollmentno = newParams.registrationNo;
      localStorage.removeItem('enrollmentNumber');
      this.loginVia = 'registrationNumber';
    } else {
      this.loginVia = 'enrollmentNumber';
      localStorage.removeItem('registrationNumber');
    }
    newParams.source = 'Glorifire';
    delete newParams['registrationNo'];
    this.enteredEnrollmentNo = newParams.enrollmentno;
    this.http.get('checkEnrollNo', false, newParams, true, false, environment.serverURLv3).subscribe((res) => {
      if (res && res.data && res.data.userId) {
        const payloadProfile = {
          'Site': {
              'fiitjee enrolment number ': this.enteredEnrollmentNo
          }
      };
      ClevertapReact.profile(payloadProfile);
        localStorage.setItem('userIdcheckEn', res.data.userId);
      } else {
        localStorage.removeItem('userIdcheckEn');
      }
      switch (res.code) {
        case 200: {
          localStorage.setItem("eventEnrollmentNofirstTime","Yes");
          this.saveCode = res.code;
          this.stepsCompleted.push(res.code);
          this.redirectTo.next(res.code);
          break;
        }
        case 205: {
          localStorage.setItem("eventEnrollmentNofirstTime","No");
          this.saveCode = res.code;
          this.stepsCompleted.push(res.code);
          this.redirectTo.next(res.code);
          break;
        }
        case 201: {
          localStorage.setItem("eventEnrollmentNofirstTime","No");
          this.saveCode = res.code;
          this.userId = res.data.userId;
          this.stepsCompleted.push(res.code);
          this.redirectTo.next(res.code);
          break;
        }
        case 202: {
          localStorage.setItem("eventEnrollmentNofirstTime","No");
          this.saveCode = res.code;
          this.userId = res.data.userId;
          this.stepsCompleted.push(res.code);
          this.redirectTo.next(res.code);
          break;
        }
        case 210: {
          localStorage.setItem("eventEnrollmentNofirstTime","No");
          this.saveCode = res.code;
          this.stepsCompleted.push(res.code);
          this.userId = res.data.userId;
          this.redirectTo.next(res.code);
          break;
        }
        default: {
          this.alert.showErrorMessage(res['message']);
        }
      }
    });
  }


  // Manually Added codes for redirection of control and check which screen to be shown and also to keep the code in sync
  // 999 : load wac screen
  // 888 : load enter otp screen
  // 777 : load fiitjee login screen
  // 666 : load mypat login screen
  // 555 : forgot password screen
  // 444 : reset password screen

  changeTheSection(type) {
    switch (type) {
      case 'wac': {
        localStorage.setItem("eventEnrollmentNo","Clicked WAC");
        this.stepsCompleted.push('wac');
        this.redirectTo.next(999);
        break;
      }
      case 'otp': {
        localStorage.setItem("eventEnrollmentNo","Clicked OTP");
        this.stepsCompleted.push('otp');
        this.redirectTo.next(888);
        break;
      }
      case 'fiitjeelogin': {
        localStorage.setItem("eventEnrollmentNo","Clicked FIITJEE Password");
        this.stepsCompleted.push('fiitjeelogin');
        this.redirectTo.next(777);
        break;
      }
      case 'mypatlogin': {
        localStorage.setItem("eventEnrollmentNo","Clicked myPAT Password");
        this.stepsCompleted.push('mypatLogin');
        this.redirectTo.next(666);
        break;
      }
      case 'forgotPassword': {
        this.stepsCompleted.push('forgotPassword');
        this.redirectTo.next(555);
        break;
      }
      case 'resetPassword': {
        this.stepsCompleted.push('resetPassword');
        this.redirectTo.next(444);
        break;
      }
    }
  }


  fetchMobile(request: any, resendOtp: boolean, fromScreen: string = this.fromScreen): void {
    this.fromScreen = fromScreen;
    this.http.put('otpPartner', false, request, true, environment.serverURLv3).subscribe((res) => {
      if (res.code === 200) {
        if (res.data.mobileMypat){
          this.mobileNumber.next(res.data.mobileMypat);
          this.mobilePresent = res.data.mobileMypat;
          if (res.data.userId){
            this.userId = res.data.userId;
          }
        }
        else if (res.data.mobile){
          this.mobileNumber.next(res.data.mobile);
          this.mobilePresent = res.data.mobile;
          if(res.data.userId){
            this.userId = res.data.userId;
          }
        }
        if (res.data.emailMypat) {
          this.userEmail = res.data.emailMypat;
        }
        if (res.data.countryCode) {
          this.mobileCountryCode = res.data.countryCode;
        }
        if (!resendOtp)
          this.changeTheSection('otp');
      }
      else {
        this.redirectTo.next(this.saveCode);
        this.stepsCompleted.pop();
        if (res?.data.hasOwnProperty('communicationVerified') && !res.data.communicationVerified) {
          this.alert.showErrorMessage(res?.data?.message);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      }
    });
  }

  callDownloadApp(payload) {
    return new Promise((resolve, reject) => {
      this.http.post('downloadApp', false, payload, true).subscribe((data) => {
        if (data && data['code'] === 200) {
          this.alert.showSuccessMessage(data['message']);
          resolve(data);
        } else {
          this.alert.showErrorMessage(data['message']);
        }
      },
        error => {
          this.store.dispatch(new LoaderAction.SetLoader(false));
          this.alert.showErrorMessage(error['message']);
        }
      );
    });
  }
}
