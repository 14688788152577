import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { BuyCoursesService } from 'src/app/home-page/cards-detail/right-selected-card/buy-courses/buy-courses.service';
import { GLiteUtilityService } from '../../services/g-lite-utility.service';
import { CARD_ACTIONS, PACKAGE_CTA_TITLE } from '../../../home-page/cards-detail/right-selected-card/buy-courses/actions.enum';
import { AlertService } from '../../services/alert.service';
import { UtilityService } from '../../services/utility.service';
import { DISPLAY_TYPE, EXAM_INFO, AVAILABLE_CLASSES, AVAILAIBLE_QUERY_PARAMS } from './courses.constant';
import { ActivatedRoute, Router } from '@angular/router';
import USER_PURCHASE_ACTION from '../../constants/user-action.constant';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CourseCardService } from 'src/app/home-page/cards-detail/right-selected-card/buy-courses/course-card/course-card.service';
declare var $:any;
const DETAILS_INFO_CONST = {
  CHAPTERS: 'chapters',
  CONCEPTS: 'concepts'
}
@Component({
  selector: 'app-courses-we-offer',
  templateUrl: './courses-we-offer.component.html',
  styleUrls: ['./courses-we-offer.component.scss']
})
export class CoursesWeOfferComponent implements OnInit, OnDestroy {
  @Input() type: any = DISPLAY_TYPE.ALL_COURSES;
  @Input() showAllCourses?: boolean;
  private activeSubscriptions = new Subscription();
  private DAYS_IN_YEAR = 365;
  private classAvailableInRoute = '';
  private goalAvailableInRoute = '';
  public selectedGoal: any = {};
  public selectedGoalIndex: number = 0;
  public selectedClass: any ={};
  public availableGoals: any[] = [];
  public availedPackages: any[] = [];
  public availableCourses: any[] = [];
  public classesList:any[] = [];
  public targetExamYear = '2025';
  public coursesLoader: boolean= true;
  public targetExamDate ={
    startDate:new Date(),
    endDate:new Date()
  }
  public coursesWeOffer: OwlOptions = {};
  public courseId:string = '';
  public isPackageLoading:boolean = false;
  public packageData = null;
  public packageTargetExams = '';
  public classifiedTests = {};
  public testCategories = [];
  public testMap = {
    'full': '',
    'part': 'partTest',
    'combined': '',
  };
  private testPriorityMap = {
    full: 5,
    part: 3,
    'half course': 4,
    chap: 7,
    chapter: 7,
    concept: 1,
    sample: 6,
    practice: 7,
    subjective: 7,
    custom: 7,
    phase: 7,
    unit: 2
  };
  public selectedCourse: any;
  public testTypeImages = {
    'part': 'partTest',
    'sample': 'sampleTest',
    'half': 'halfTest',
    'combined': 'halfTest',
    'concept': 'conceptTest',
    'full': 'fullTest',
    'main': 'fullTest',
    'unit': 'unitTest',
    'testSeries': 'preTest'
  }

  public imagesIndices = ['part', 'sample', 'half'];
  public syllabusInfo: any = {
    name: '',
    type: '',
    subjectCount: 0,
    subjectsSyllabus: []
  };
  public syllabusLevel: string = this.viewDetailsInfo.CHAPTERS;
  public userCart: any[] = [];

  constructor(
    private _buyCourses: BuyCoursesService,
    private _glite: GLiteUtilityService,
    private _alert: AlertService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private _courseCardService: CourseCardService
  ) { }

  public get cardActions(){
    return CARD_ACTIONS;
  }

  public get packageCtaTitle(){
    return PACKAGE_CTA_TITLE;
  }

  ngOnInit(): void {
    this.activeSubscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.classAvailableInRoute = params[AVAILAIBLE_QUERY_PARAMS.selected_class];
        this.goalAvailableInRoute = params[AVAILAIBLE_QUERY_PARAMS.selected_goal];
        if (this.type !== DISPLAY_TYPE.HOME_INFO) {
          this.getClasses();
        }
      })
    );
    if (this.showAllCourses) {
      this.fetchAllPackages();
    } else {
      this.activeSubscriptions.add(
        this._glite.getActiveClassId.subscribe((classInfo: any) => {
          if (classInfo.class_id) {
            this.selectedClass._id = classInfo.class_id;
            this.getGoals();
          }
        })
      );
    }
    this.userCart = JSON.parse(localStorage.getItem('userCart')) || [];
    if (this.showAllCourses) {
      this.coursesWeOffer = {
        loop: false,
        dots: false,
        margin: 50, 
        autoplay: false,
        autoWidth: false,
        center: false,
        touchDrag: false,
        mouseDrag: false,
        items: 3,
        nav: true,
        navText: [`<img src='/assets/images/new-home/course-offer-arrow.svg' alt='Slide Arrow' />`,`<img src='/assets/images/new-home/course-offer-arrow.svg' alt='Slide Arrow' />`],
        responsive:{
          0:{items: 1, touchDrag: true, mouseDrag: true},
          576:{items: 2, touchDrag: true, mouseDrag: true},
          768:{items: 2},
          992:{items: 3},
          1200:{items: 3},
        },
      };
    }
  }

  fetchAllPackages(): void {
    this.utilityService.getAllAvailablePackages().subscribe((res: any) => {
      const courses = res?.data?.packages || [];
      this.availableCourses = [...courses];
      this.availableCourses = courses.map(course => ({ ...course, cartCTATitle: PACKAGE_CTA_TITLE.ADD_TO_CART }));
      this.coursesLoader = false;
      this.targetExamYear = this.availableCourses[0]?.targetYear;
      this.targetExamDate.startDate = this.availableCourses[0]?.targetExam[0]?.examDates[0]?.startDate;
      this.targetExamDate.endDate = this.availableCourses[0]?.targetExam[0]?.examDates[0]?.endDate;
      this.renderAvailableCourses();
    },
      err => {
        throw new Error('Unable to fetch courses!');
      }
    )
  }

  fetchAllAvailedPackagesByUser() {
    this.activeSubscriptions.add(
      this._buyCourses.getAllAvailedPackages().subscribe(
        res => {
          if (res?.code === 200) {
            this.availedPackages = [...res?.data?.packages];
          }
        },
        err => {
          this._alert.showErrorMessage(err?.message);
        }
      )
    )
  }

  getGoals() {
    this.availableGoals=[];
    this.coursesLoader=true;
    this.activeSubscriptions.add(
      this._buyCourses.getGoals(this.selectedClass._id).subscribe(res => {
        this.availableGoals = res?.data || [];
        if (this.availableGoals.length) {
          const goalFromParams = this.goalAvailableInRoute && this.getGoalsFromParams();
          this.selectedGoal = goalFromParams || this.availableGoals[0];
          this.selectedGoalIndex = goalFromParams ? this.selectedGoalIndex : 0;
          this.getAvailableCourses();
        }
      },
        err => {
          throw new Error('Unable to fetch goals!');
        }
      )
    );
  }

  getGoalsFromParams() {
    const goalFromParams = this.availableGoals.filter((item: any, index: number) => {
      if (item.name.includes(this.goalAvailableInRoute)) {
        this.selectedGoalIndex = index;
        return true;
      }
    });
    return goalFromParams.length && goalFromParams[0];
  }

  selectGoal(selectedGoal: any, selectedIndex: number) {
    if (this.selectedGoalIndex !== selectedIndex) {
      this.selectedGoal = selectedGoal;
      this.selectedGoalIndex = selectedIndex;
      if(this.type !== DISPLAY_TYPE.PREP_TRACKER){
      const queryParams = { goal: this.selectedGoal.name };
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge',
        });
      } 
      this.getAvailableCourses();
    }
  }

  getAvailableCourses() {
    this.coursesLoader=true;
    this.availableCourses = [];
    this.activeSubscriptions.add(
      this._buyCourses.getCourses(this.selectedClass._id, this.selectedGoal._id).subscribe(res => {
        const courses = res?.data?.packages || [];
        this.availableCourses = [...courses];
        this.availableCourses = courses.map(course => ({ ...course, cartCTATitle: PACKAGE_CTA_TITLE.ADD_TO_CART }));
        this.coursesLoader = false;
        this.targetExamYear = this.availableCourses[0]?.targetYear;
        this.targetExamDate.startDate = this.availableCourses[0]?.targetExam[0]?.examDates[0]?.startDate;
        this.targetExamDate.endDate = this.availableCourses[0]?.targetExam[0]?.examDates[0]?.endDate;
        this.renderAvailableCourses();
      },
        err => {
          throw new Error('Unable to fetch courses!');
        })
    );
  }

  renderAvailableCourses(): void {
    this.availableCourses.forEach((item: any) => {
      item['duration_years'] = item?.duration / this.DAYS_IN_YEAR;
      item['discountedPrice'] = item?.price - (item?.price * item?.discount / 100);
    })
  }

  getClasses(): void {
    this.activeSubscriptions.add(
      this.utilityService.getClasses().subscribe((res) => {
        if(res && res.code === 200){
          this.classesList = res?.data || [];
          if (this.classesList.length) {
            this.selectedClass = this.classAvailableInRoute ? { class: this.classAvailableInRoute, _id: AVAILABLE_CLASSES[this.classAvailableInRoute] } : this.classesList[this.classesList?.length - 1];
            this.getGoals();
          }
        }else{
          this._alert.showErrorMessage(res.message);
        }
      },error=>{
        this._alert.showErrorMessage(error.message);
      })
    );
  }

  onClassChange(index){
    this.selectedClass = this.classesList[index];
    const queryParams = { class: this.selectedClass.class };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    this.getGoals();
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }

  public get examInfo(){
    return EXAM_INFO;
  } 
  public get userAction(){
    return USER_PURCHASE_ACTION;
  }
  public get DISPLAY_TYPE(){
    return DISPLAY_TYPE;
  }
  
  public get cartCourseIdList(): string[] {
    return this.userCart.map(course => course?._id);
  }

  courseRedirect(course,type){
    this.dismissModal();
    course['type']=type;
    course['class']=this.selectedClass;
    this.userCart.push(course);
    localStorage.setItem('userCart', JSON.stringify(this.userCart));
    if (type === this.userAction.BUY_NOW) {
      this.router.navigate(['/user/signup']);
    }
  }

  examRedirect(){
    const selectedGoal=this.availableGoals[this.selectedGoalIndex].name;
    let url = EXAM_INFO[selectedGoal].ROUTE_URL || '';
    if(!url && selectedGoal.toLowerCase().includes('ntse')){
      url='/ntse';
    }
    this.router.navigate([`/exam${url}`])
  }


  openDetail(index,course){
    this.courseId = course._id;
    this.selectedCourse = course;
      this.isPackageLoading = true;
      this.packageData = null;
      this.testCategories = [];
      this.classifiedTests = {};
      this.packageTargetExams = '';
      this.activeSubscriptions.add(
        this._courseCardService.getPackageDetails(course._id).subscribe(res => {
          if(res?.code === 200){
            this.isPackageLoading = false;
            this.packageData = res?.data?.packageDetails;
            this.packageTargetExams = res?.data?.packageDetails?.targetExams?.map(exam => exam?.courseName).join(', ');
            this.packageData?.tests?.forEach(test => {
              if(test?.mainTestType === 'combined'){
                test.mainTestType = 'half course';
              }
              if (this.classifiedTests[test?.mainTestType]) {
                this.classifiedTests[test?.mainTestType].listing.push(test);
              } else {
                this.classifiedTests[test?.mainTestType] = { listing: [], priority: 0 };
                this.classifiedTests[test?.mainTestType].listing = [test];
                this.classifiedTests[test?.mainTestType].priority = this.testPriorityMap[test?.mainTestType];
              }
            });
            this.testCategories = Object.keys(this.classifiedTests).sort((a, b) => {
              return this.classifiedTests[a].priority - this.classifiedTests[b].priority
            });
            setTimeout(() =>{
            $('#course_viewDetail'+course._id).modal('show');
            $(".modal-backdrop.show").hide();
          }, 0);
          }
        },
        err => {
          this.isPackageLoading = false;
          this._alert.showErrorMessage(err?.message || 'Something went wrong. Please try again later.');
        })
      )
  }
  dismissModal(){
    $('#course_viewDetail'+this.courseId).modal('hide');
  }

  showTestSyllabus(currentTest: any): void {
    this.activeSubscriptions.add(
      this._courseCardService.getTestSyllabusDetails({ testId: currentTest.testId, courseId: currentTest.goalId, packageId: currentTest.packageId, scheduleId: currentTest.scheduleId }).subscribe((res: any) => {
        if (res.code === 200) {
          const syllabus = res?.data?.syllabus;
          const testDetails = res?.data?.testDetails;
          this.syllabusInfo.name = currentTest?.displayName;
          this.syllabusInfo.type = testDetails?.displayTestType;
          this.syllabusInfo.subjectCount = syllabus?.subjects?.length;
          this.syllabusInfo.subjectsSyllabus = [...syllabus?.subjectsSyllabus];
          this.syllabusLevel= this.viewDetailsInfo.CHAPTERS;
          setTimeout(() => $('#syllabus'+this.courseId).modal('show'), 0);
        } else {
          this._alert.showErrorMessage(res.message);
        }
      }, (err: any) => {
        this._alert.showErrorMessage(err.message);
      })
    );
  }

  @ViewChildren('chapters') chaptersComponent: ElementRef<HTMLAnchorElement>[];
  @ViewChildren('conceptsCollapse') conceptsCollapseComponent: ElementRef<HTMLDivElement>[];
  @ViewChildren('subjects') subjectsComponent: ElementRef<HTMLAnchorElement>[];
  @ViewChildren('chaptersCollapse') chaptersCollapseComponent: ElementRef<HTMLDivElement>[];
  get viewDetailsInfo () {
    return DETAILS_INFO_CONST;
  }
  changeSyllabusLevel(level: string): void {
    if (this.syllabusLevel === level) {
      return;
    }
    this.syllabusLevel = level;
    this.chaptersCollapseComponent.forEach((div) => {
      div.nativeElement.classList.add('show');
    });
    this.subjectsComponent.forEach((div) => {
      div.nativeElement.setAttribute('aria-expanded', 'true');
    });
    if (level === this.viewDetailsInfo.CONCEPTS) {
      this.conceptsCollapseComponent.forEach((div) => {
        div.nativeElement.classList.add('show');
      });
      this.chaptersComponent.forEach((div) => {
        div.nativeElement.setAttribute('aria-expanded', 'true');
      });
      return;
    }
    this.conceptsCollapseComponent.forEach((div) => {
      div.nativeElement.classList.remove('show');
    });
    this.chaptersComponent.forEach((div) => {
      div.nativeElement.setAttribute('aria-expanded', 'false');
    });
  }  
}
