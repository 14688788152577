const HOME_CARDS_ROUTE = {
    myTests : 'myTests',
    testHistory: 'test-history',
    revision: 'revision',
    upcomingTest: 'upcoming-tests',
    swotBoard: 'swot',
    buyCourses: 'buy-course',  
    buildTest: 'build-test',  
    previousYearPapers: 'previous-year-papers'
};

const ALL_CARDS = 'allCards';

const HOME_ROUTE = '/home';
const BUY_COURSE = 'buyCourse';
const SEARCH_RESULT = 'searchResult';
const MY_REVISION_PLAN = 'my-revision-plan';


export {HOME_ROUTE, HOME_CARDS_ROUTE, BUY_COURSE, SEARCH_RESULT, MY_REVISION_PLAN, ALL_CARDS};