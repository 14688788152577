<!-- Done with revision modal -->
<div class="modal fade small-modal" tabindex="-1" id="doneRevisionModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="assets/images/revision/resetIcon.svg">
                <b>Done with your revision?</b>
                <p>Make sure that you’ve revised all questions before you exit!</p>
            </div>
            <!-- only show For Other Goals footer-->
            <div class="modal-footer">
                <button type="button" class="btn textButton" (click)="closePopup()">No, Wait</button>
                <button type="button" class="btn btn-primary" (click)="leave()">Yes, Exit</button>
            </div>
        </div>
    </div>
  </div>