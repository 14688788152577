import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { AlertService } from '../../../shared/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public personalInfoUpdated = new BehaviorSubject<any>(false);
  public schoolAdded = new BehaviorSubject<any>(false);
  public emailMobileAdded = new BehaviorSubject<any>({});
  public editedCourseDetails = new BehaviorSubject<any>({});
  public showMyPlanPopUp = new BehaviorSubject<boolean>(false);

  constructor(private httpService: HttpService, private alertService: AlertService) {
  }

  getClasses(): any {
    return new Promise((resolve, reject) => {
      this.httpService.get('v4/classes', {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCareers(value): any {
    return new Promise((resolve, reject) => {
      this.httpService.get(`v4/careers?classId=${value}`, null).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getGoals(value): any {
    return new Promise((resolve, reject) => {
      const apiName = `v4/courses?courseTypeIds=${value.courseTypeIds}&sessionYear=${value.sessionYear}&classId=${value.classId}`;
      this.httpService.get(apiName, null).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCountryData(): any {
    return new Promise((resolve, reject) => {
      this.httpService.get(`v4/locations`, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getStateData(countryId): any {
    return new Promise((resolve, reject) => {
      const apiName = 'v4/locations?countryId=' + countryId;
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCityData(data): any {
    return new Promise((resolve, reject) => {
      const apiName = 'v4/locations?countryId=' + data.countryId + '&stateId=' + data.stateId;
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getSchoolData(data): any {
    const apiName = 'v4/auth/schools?cityId=' + data.cityId + '&schoolName=' + data.schoolName;
    return new Promise((resolve, reject) => {
      this.httpService.get(apiName, {}).subscribe((res) => {
        if (res && res.code) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  // get permission from s3 to upload image
  getPreSignedUrl(dataRecieved, imageData): Promise<any> {
    return new Promise((resolve, reject) => {
      const apiUrl = 'v4/auth/upload-signed-url?location=schoolId';
      this.httpService.get(apiUrl, true, { key: dataRecieved.name.toString() }, false).subscribe(
        (res) => {
          if (res && res.code === 200) {
            if (res.data.url && res.data.fields) {
              // this.imageUrl = res.data.url + '/' + res.data.fields.key;
              this.uploadYourFileOnS3(res.data, imageData).then((resN) => {
                resN.imageUrl = res.data.url + '/' + res.data.fields.key;
                resolve(resN);
              });
              // if (this.imageUrl) {
              // tslint:disable-next-line: no-string-literal
              // this.schoolData.school['schoolIdUrl'] = this.imageUrl;
            }
            // this.store.dispatch(new UserProfileAction.AddSchool(this.schoolData));
          } else {
            this.alertService.showErrorMessage(res.message);
          }
        });
    });
  }

  // // upload image after permision
  uploadYourFileOnS3(data, imageData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.postUploadFile(data, imageData).subscribe((res) => {
        if (res) {
          if (res && res.status === 204) {
            resolve(res);
          }
        }
      });
    });
  }

  updateNameAndUserName(data): Promise<any> {
    const apiName = 'v4/auth/user-name';
    return new Promise((resolve, reject) => {
      this.httpService.put(apiName, true, data).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  updateUserDetails(data): Promise<any> {
    const apiName = 'v4/auth/user';
    return new Promise((resolve, reject) => {
      this.httpService.put(apiName, true, data).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }
}
