import { Component, EventEmitter, OnInit, Output } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-done-with-revision',
  templateUrl: './done-with-revision.component.html',
  styleUrls: ['./done-with-revision.component.scss']
})
export class DoneWithRevisionComponent implements OnInit {

  @Output() confirmExit = new EventEmitter<any>();
  @Output() closeRevPopup = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  closePopup(): void {
    $('#doneRevisionModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    this.closeRevPopup.emit();
  }

  leave(): void {
    this.confirmExit.emit();
  }

}
