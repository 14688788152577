import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import { UtilityService } from '../../services/utility.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';
import { HomeSearchComponent } from 'src/app/home-page/shared/home-search/home-search.component';
import { GLiteUtilityService } from '../../services/g-lite-utility.service';
import { Subscription } from 'rxjs';
import { EbooksService } from 'src/app/landing-pages/ebooks/ebooks.service';
import { HOME_ROUTE, MY_REVISION_PLAN, SEARCH_RESULT, } from 'src/app/home-page/constants/routes.constant';
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-header-after-login',
  templateUrl: './header-after-login.component.html',
  styleUrls: ['./header-after-login.component.scss']
})

export class HeaderAfterLoginComponent implements OnInit, OnDestroy {
  @ViewChild('sideBar') sideBarRef: ElementRef;
  isClassVisible = true;
  public userData: any;
  public freeTrialData: any;
  public currentUrl = '';
  public showLogoutPopup = false;
  public showChangePassword = false;
  public sidebarOverlay: boolean = false;
  isMobile = false;
  showSearchIcon = false;
  hideRightNav = false;
  clearSearch = false;
  public clickedTabDetail: string = '';
  public showRevModal: boolean = false;
  public isNcrpUser = false;
  public numberOfItemsInCart: number = 0;
  public fiitjeeFacultyHiring:boolean = false;
  private activeSubscriptions = new Subscription();
  @ViewChild(HomeSearchComponent) homeSearchComponent: HomeSearchComponent;
  public isHomeActive : boolean = true;
  constructor(private store: Store<fromApp.AppState>,
    private router: Router, public utilityService: UtilityService, private alert: AlertService, private homeService: HomeService
    , private _gLiteUtility: GLiteUtilityService,
    private _ebook: EbooksService) {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.sidebarOverlay = false;
    this.activeSubscriptions.add(
      this.store.select('userProfile').subscribe((userProfile) => {
        this.userData = userProfile.userData;
        this.isNcrpUser = this.userData.isNCRPUser;
        this.fiitjeeFacultyHiring = this.userData.fiitjeeFacultyHiring;
      })
    );
    this.activeSubscriptions.add(
      this._gLiteUtility.getCart().subscribe(res => {
        if(res){
          this.numberOfItemsInCart=res?.data?.course?.length;
          this._gLiteUtility.numberOfItemsInCart.next({ numberOfItemsInCart: this.numberOfItemsInCart});
        }
      })
    );

    this.activeSubscriptions.add(
      this._gLiteUtility.numberOfItemsInCart.subscribe((data: any) => {
        this.numberOfItemsInCart = data.numberOfItemsInCart;
      })
    )

    this.activeSubscriptions.add(
      this.utilityService.downloadEbook.subscribe((downloadEbook: boolean) => {
        if (this._ebook.selectedEbookLink && downloadEbook) {
          window.open(this._ebook.selectedEbookLink, '_blank');
          this._ebook.selectedEbookLink = '';
        }
      })
    );

    // freeTrial code
    this.getTheFreeTrialData();

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    this.currentUrl = this.router.url;
    // for hiding navbar on outer click
    $(document).click((event) => {
      const clickover = $(event.target);
      const $navbar = $('.navbar-collapse');
      const opened = $navbar.hasClass('show');
      if (opened === true && !clickover.hasClass('navbar-toggle')) {
        $navbar.collapse('hide');
      }
    });
    this.activeSubscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (
            this.currentUrl.includes(HOME_ROUTE) ||
            this.currentUrl.includes(SEARCH_RESULT) ||
            this.currentUrl.includes(MY_REVISION_PLAN)
          ) {
            this.changeHomeStatus(true);
          }
        })
    );
  }

  @HostListener('document:click', ['$event']) onClick(event) {
    let searchEle: any = document.getElementsByClassName('searchHideRes');
    if (searchEle && searchEle.length === 0) {
      this.showSearchIcon = false;
    }
  }

  onExpand(event) {
    this.hideRightNav = event;
  }

  searchClicked() {
    this.changeHomeStatus();
    this.showSearchIcon = true;
    this.homeSearchComponent?.clearSearchText();
  }

  showSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '0px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  hideSidebar(): void {
    this.sideBarRef.nativeElement.style.left = '-1000px';
    this.sidebarOverlay = !this.sidebarOverlay;
  }

  getTheFreeTrialData(): void {
    this.utilityService.fetchFreeTrialDataAPI();
    this.activeSubscriptions.add(
      this.utilityService.freeTrialDataContainer.subscribe((res) => {
        this.freeTrialData = res;
      })
    );
  }

  navigateToHome(): void {
    localStorage.removeItem('lastRequested');
    this.changeHomeStatus(true);
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'home';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
      this.clickedTabDetail = '';
      this.clearSearch = true;
      const card = JSON.parse(localStorage.getItem('openendCardDetails'))?.selectedCard;
      if (card?.type != 'help' && card?.type != 'ideasAndSuggestions') {
        //localStorage.removeItem('toExpandCard');
        //localStorage.removeItem('openendCardDetails');
      }
      if (this.router.url.includes('home') && !this.router.url.includes('/searchResult/')) {
        this.alert.updateWorkingHomePage.next({ update: 'openHome' });
      }
      localStorage.removeItem('lastRequested');
      this.router.navigate(['/home']);
    }
  }

  navigateToClasses(): void {
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'liveclasses';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
      this.clickedTabDetail = '';
      const url = (this.userData.isFiitjee ? environment.glorifireliveClasses : environment.myPatUrl) + '?authToken=' + (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) + '&loginVia=liveClasses';
      window.open(url, '_blank');
    }
  }

  navigateToCommunity(): void {
    this.changeHomeStatus();
    if (localStorage.getItem('onLastMinuteRevision') === 'true') {
      this.clickedTabDetail = 'community';
      this.showRevModal = true;
      setTimeout(() => {
        $('#doneRevisionModal').modal({
          backdrop: 'static',
          keyboard: false
        });
      }, 100);
    } else {
      // this.clickedTabDetail = '';
      // const url = (this.userData.isFiitjee ? environment.fiitjeeUrlv3 : environment.myPatUrl) +
      // '/community/view/popular?authToken=' + (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) + '&redirectUrl=' + environment.fiitjeeUrlv4;
      // window.open(url, '_blank');
      this.router.navigate(['/community/view/popular']);
    }
  }

  goToDownloadApp(): void {
    this.router.navigate(['/mobile-app-download']);
  }

  logoutConfirm(): void {
    this.changeHomeStatus();
    this.showLogoutPopup = true;
    setTimeout(() => {
      $('#logoutModal').modal({
        backdrop: 'static',
        keyboard: false
      });
    }, 0);
  }

  closePopup(): void {
    $('#logoutModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  changePassword(): void {
    this.showChangePassword = true;
    setTimeout(() => {
      $('#changePasswordModal').modal({
        backdrop: 'static',
        keyboard: false
      });
    }, 0);
  }

  closeChangePassword(): void {
    $('#changePasswordModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  closeRevisionPopup(): void {
    $('#doneRevisionModal').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }


  confirmExit(event): void {
    localStorage.removeItem('onLastMinuteRevision');
    this.showRevModal = false;
    switch (this.clickedTabDetail) {
      case 'home': {
        this.navigateToHome();
        this.closeRevisionPopup();
        break;
      }
      case 'liveclasses': {
        this.navigateToClasses();
        this.closeRevisionPopup();
        break;
      }
      case 'community': {
        this.navigateToCommunity();
        this.closeRevisionPopup();
        break;
      }
    }
  }

  closeRevPopup(): void {
    this.showRevModal = false;
  }

  autoFocusSearchField(): void {
    this.homeService.homeSearchUpdate.emit({ action: 'autoFocusSearchField' });
  }

  clearSearchResults(): void {
    this.homeService.homeSearchUpdate.emit({ action: 'clearAllSuggestions' });
  }

  changeHomeStatus(status: boolean = false){    
    this.isHomeActive= status;  
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }
}
