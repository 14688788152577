export const getAdmissionTestRedirectionUrl = (admissionTestDetail: any) => {
  const testName = admissionTestDetail?.testName.split(" ").join("_");
  let redirectionUrl = `/test/test-ongoing/others/${testName}/${admissionTestDetail?.testId}/${admissionTestDetail?.courseId}`;
  if (admissionTestDetail?.attempts?.length) {
    const latestAttempt = admissionTestDetail?.attempts[0];
    redirectionUrl += `/${latestAttempt?.attemptId}`;
    if (latestAttempt?.status === "finished") {
      redirectionUrl = `/test-results/${admissionTestDetail?.testId}/${latestAttempt?.attemptId}/${latestAttempt?.testType}`;
    }
  }
  return redirectionUrl;
};
