import * as LoaderActionList from './loader.actions';

export interface State {
    isLoader: boolean;
    pendingRequest: number;
}

const intialState: State = {
    isLoader: false,
    pendingRequest: 0
};

export function loaderReducer(state = intialState, action: LoaderActionList.LoaderActionList): any {
    switch (action.type) {
        case LoaderActionList.SET_LOADER:
            let isLoader = state.isLoader;
            let pendingRequest = state.pendingRequest;
            if (action.payload) {
                pendingRequest = pendingRequest + 1;
                isLoader = true;
            }
            else {
                pendingRequest = pendingRequest - 1;
                if (pendingRequest <= 0) {
                    pendingRequest = 0;
                    isLoader = false;
                }
            }
            return {
                ...state,
                isLoader,
                pendingRequest
            };
        default:
            return state;
    }
}
