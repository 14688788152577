import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { timer, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import * as AuthActions from '../../../stores/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { FORM_TYPE } from '../form/free-trial-form/form.constant';
import { HelperService } from '../../services/helper.service';
import { OTP_ERROR } from '../../constants/error.constants';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-otp-verify-modal',
  templateUrl: './otp-verify-modal.component.html',
  styleUrls: ['./otp-verify-modal.component.scss']
})
export class OtpVerifyModalComponent implements OnInit, OnDestroy {
  @Input() mobileInfo: any;
  @Input() displayType = FORM_TYPE.HOME_INFO;
  @Input() examSelected;
  private activeSubscription = new Subscription();
  private validValues: any = {
    validMobileNumberLength: 10,
    validOTPLength: 4
  };
  public timerData: any = {
    showTimer: false,
    secondsLeft: null
  };
  public showResendOTP: boolean = false;
  public otpVerificationForm: FormGroup = null;
  public otpError: string = '';
  public isDisabled: boolean = true;
  constructor(
    private _utility: UtilityService,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private _alert: AlertService,
    private _helper: HelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    $('#otpVerify').modal('show');
    this.otpError = '';
    this.otpVerificationForm = this.fb.group({
      mobileNumber: '',
      OTP: ''
    });
    this.activeSubscription.add(
      this._helper.handleOtpError.subscribe((data: any) => {
        if (data?.isOtpError) {
          this.otpError = OTP_ERROR.wrong_otp;
        }
      })
    );
    this.initializeTimer();
  }

  initializeTimer() {
    this.timerData.showTimer = true;
    this.activeSubscription.add(
      this.countdown.subscribe(secondsLeft => {
        this.timerData.secondsLeft = secondsLeft;
        if (this.timerData.secondsLeft === '01') {
          this.timerData.showTimer = false;
          this.showResendOTP = true;
        }
      })
    );
  }

  countdown = timer(0, 1000).pipe(
    take(30),
    map(secondsElapsed => {
      const remainingSeconds = 30 - secondsElapsed;
      return remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds.toString();
    })
  );


  handleOTP(): void {
    let OTP = this.otpVerificationForm.value.OTP;
    this.otpError = '';
    var pattern = /^[0-9]+$/;
    if (!pattern.test(OTP)) {
      OTP = OTP.substring(0, OTP.length - 1);
    } else if (OTP.length > this.validValues.validOTPLength) {
      OTP = OTP.substring(0, this.validValues.validOTPLength);
    }
    this.isDisabled = OTP.length !== this.validValues.validOTPLength;
    this.otpVerificationForm.controls['OTP'].setValue(OTP);
  }

  resendOTP(): void {
    const payload = {
      mobileNumber: this.mobileInfo.mobileNumber,
      countryCode: this.mobileInfo.selectedDialCode.dialCode
    };
    this.activeSubscription.add(
      this._utility.resendOTP(payload).subscribe((res) => {
        if (res?.code === 200) {
          this.showResendOTP = false;
          this.initializeTimer();
          this._alert.showSuccessMessage(res?.message);
        } else {
          this._alert.showErrorMessage(res?.message);
        }
      }, (err) => {
        this._alert.showErrorMessage(err?.message);
      })
    );
  }

  submitOTP(): void {
    if (!this.isDisabled) {
      this.otpError = '';
      const userMobileData = {
        mobileNumber: this.mobileInfo.mobileNumber,
        countryCode: this.mobileInfo.selectedDialCode.dialCode,
        otp: this.otpVerificationForm.value.OTP,
        source: 'web',
        deviceToken: ''
      };
      if (this.displayType === FORM_TYPE.HOME_INFO) {
        this.store.dispatch(new AuthActions.VerifyMobile(userMobileData));
      } else {
        this.getUserId(userMobileData);
      }
    }
  }

  getUserId(payload){
    this.activeSubscription.add(
      this._utility.getUserID(payload).subscribe(res=>{
        if(res && res.code === 200){
          const userId = res?.data?.profile?.userId._id;
          $('#otpVerify').modal('hide');
          this._utility.userIdFetch.next(userId);
          if(this.examSelected)
          this.router.navigate([`/college-predictor/${this.examSelected.toLowerCase()}`]);
        }else{
          this._alert.showErrorMessage(res.message);
        }
      },error=>{
        this._alert.showErrorMessage(error.message);
      })
    )
  }

  handlePopUpClose(): void {
    $('#otpVerify').modal('hide');
    this._utility.captchaStateHandler.next(true);
    this._utility.otpPopupHandler.next({ isPopUpEnabled: false });
  }

  ngOnDestroy(): void {
    this.activeSubscription.unsubscribe();
  }
}
