import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CaptchaDirective } from 'src/app/shared/directives/captcha.directive';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../../app/stores/app.reducers';
import * as AuthActions from '../../../../../app/stores/auth/auth.actions';
import { GLiteUtilityService } from 'src/app/shared/services/g-lite-utility.service';
import { countryArray } from 'src/app/shared/constants/glite.constants';
import { FORM_TYPE } from './form.constant';
import { HelperService } from 'src/app/shared/services/helper.service';
import { EbooksService } from 'src/app/landing-pages/ebooks/ebooks.service';
@Component({
  selector: 'app-free-trial-form',
  templateUrl: './free-trial-form.component.html',
  styleUrls: ['./free-trial-form.component.scss']
})
export class FreeTrialFormComponent implements OnInit {
  @Input() submitCTA: string;
  @Input() prePaperURL: string = '';
  @ViewChild(CaptchaDirective) vc: CaptchaDirective;
  private validMobileNumberLength: number = 10;
  private availableClasses: String[] = [];
  private activeSubscription = new Subscription();
  public classesDropdown: any[] = [{ class: 'Select Class', _id: '00' }];
  public freeTrialAccessForm: FormGroup = null;
  public selectedClass: any = { class: 'Select Class', _id: '00' };
  public selectedDialCode: any = countryArray[0];
  public errorMessages = {
    noName: 'Please Enter Name',
    noClass: 'Please Enter Class',
    noNumber: 'Please Enter Mobile Number',
    invalidNumber: 'Invalid Mobile Number'
  };
  public captchaInfo: any = {
    isCaptchaEnabled: false,
    showCaptcha: false,
    captchaChecked: true,
    showCaptchaError: false
  }
  public enableOtpPopup: boolean = false;
  @Input() formType = FORM_TYPE.HOME_INFO;
  constructor(
    private fb: FormBuilder,
    private _alert: AlertService,
    private utility: UtilityService,
    private store: Store<fromApp.AppState>,
    private _glite: GLiteUtilityService,
    private ebookService: EbooksService,
    private _helper: HelperService) { }

  ngOnInit(): void {
    this.checkIfCaptchaShow();
    this.freeTrialAccessForm = this.fb.group({
      userName: [''],
      userNumber: [''],
      exam:['']
    });

    this.getClasses();
    this.activeSubscription.add(
      this.utility.captchaStateHandler.subscribe((resetCaptcha: boolean) => {
        if (resetCaptcha) {
          this.resetCaptcha();
        }
      })
    );

    this.activeSubscription.add(
      this._helper.handleSignupError.subscribe((data: any) => {
        if (!data.signupError && data.isFreeTrial) {
          const mobileNumber = data?.mobileNumber || this.freeTrialAccessForm.value.userNumber;
          const selectedDialCode = (data?.code && this.getCountryData(data?.code)) ||  this.selectedDialCode;
          this.utility.otpPopupHandler.next(
            {
              isPopUpEnabled: true,
              mobileInfo: { mobileNumber, selectedDialCode}
            }
          );
        } else {
          this.resetCaptcha();
        }
      })
    )
  }

  getCountryData(code: string) {
    const selectedCountry = countryArray.filter((country: any) => {
      return country.dialCode === code;
    })
    return !!selectedCountry.length && selectedCountry[0];
  }

  get countryData() {
    return countryArray;
  }

  validateName(): void {
    const regexToTestName = /^[A-Z]/gi;
    let userName = this.freeTrialAccessForm.value.userName;
    if (!regexToTestName.test(userName)) {
      userName = userName.substring(0, userName.length - 1);
    }
    this.freeTrialAccessForm.controls['userName'].setValue(userName);
  }

  getClasses(): void {
    this.activeSubscription.add(
      this.utility.getClasses().subscribe((res) => {
        this.classesDropdown = res?.data || [];
        this.availableClasses = this.classesDropdown.map(item => item.class);
        if (this.classesDropdown.length) {
          this._glite.getActiveClassId.next({ class_id: this.classesDropdown[0]._id });
        }
      })
    );
  }

  selectClass(dataSelected: any) {
    if (this.selectedClass._id !== dataSelected._id) {
      this.selectedClass.class = dataSelected.class;
      this.selectedClass._id = dataSelected._id;
      this._glite.getActiveClassId.next({ class_id: this.selectedClass._id });
    }
  }

  selectCountryCode(selectedDialCode: any) {
    this.selectedDialCode = selectedDialCode;
  }

  getSuccess() {
    this.captchaInfo.captchaChecked = true;
    this.captchaInfo.showCaptchaError = false;
    this.handleSignup();
    if(this.prePaperURL){
      this.ebookService.selectedEbookLink = this.prePaperURL;
    }
  }

  handleSignup() {
    let captchaToken = '';
    if (this.captchaInfo.isCaptchaEnabled && this.vc) {
      captchaToken = this.vc.getResponse();
    }

    const payload = {
      name: this.freeTrialAccessForm.value.userName,
      countryCode: this.selectedDialCode.dialCode,
      mobileNumber: this.freeTrialAccessForm.value.userNumber,
      loginViaOtp: true,
      referralCode: '',
      class: {
        classId: this.selectedClass._id,
        name: this.selectedClass.class
      },
      captchaToken,
      freeTrial: true
    };
    this.store.dispatch(new AuthActions.TryNewSignup(payload));
  }

  captchaExpired() {
    if (this.vc) {
      this.vc.reset();
    }
    this.captchaInfo.captchaChecked = false;
  }

  resetCaptcha() {
    if (this.vc) {
      this.vc.reset();
    }
    this.captchaInfo.captchaChecked = false;
    this.captchaInfo.showCaptcha = false;
  }

  checkIfCaptchaShow(): void {
    this.utility.fetchCaptchaDetails().then(res => {
      if (res) {
        if (res && res.code === 200) {
          this.captchaInfo.isCaptchaEnabled = res.data.showCaptcha;
        }
      }
    });
  }

  handleMobileNumber(e: any): any {
    let mobileNumber = this.freeTrialAccessForm.value.userNumber;
    var pattern = /^[0-9]+$/;
    if (!pattern.test(mobileNumber)) {
      mobileNumber = mobileNumber.substring(0, mobileNumber.length - 1);
    } else if (mobileNumber.length > this.validMobileNumberLength) {
      mobileNumber = mobileNumber.substring(0, this.validMobileNumberLength);
    }
    this.freeTrialAccessForm.controls['userNumber'].setValue(mobileNumber);
  }

  startLearning(): void {
    let errorMessageToShow = '';
    if (!this.freeTrialAccessForm.value.userName) {
      errorMessageToShow = this.errorMessages.noName;
    } else if (!this.availableClasses.includes(this.selectedClass.class) && this.formType !==FORM_TYPE.COLLEGE_PRED) {
      errorMessageToShow = this.errorMessages.noClass;
    } else if (!this.freeTrialAccessForm.value.userNumber) {
      errorMessageToShow = this.errorMessages.noNumber;
    } else if (this.freeTrialAccessForm.value.userNumber.length < this.validMobileNumberLength) {
      errorMessageToShow = this.errorMessages.invalidNumber;
    }
    this._alert.showErrorMessage(errorMessageToShow);
    if (errorMessageToShow) {
      return;
    }
    if(this.captchaInfo.isCaptchaEnabled){
      this.captchaInfo.showCaptcha = this.captchaInfo.isCaptchaEnabled;
    }else {
      this.getSuccess();
    }
  }

  public get FORM_TYPE(){
    return FORM_TYPE;
  }

  resetForm(){
    this.freeTrialAccessForm.reset();
    this.selectedClass = { class: 'Select Class', _id: '00' };
  }
}
