import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SignupService } from 'src/app/auth/signup-login-master/shared/services/signup.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { GoalSettingService } from '../../goal-setting/services/goal-setting.service';
import * as fromApp from '../../stores/app.reducers';

declare var $: any;

@Component({
  selector: 'app-verify-passcode',
  templateUrl: './verify-passcode.component.html',
  styleUrls: ['./verify-passcode.component.scss']
})
export class VerifyPasscodeComponent implements OnInit {
  userTag = '';

  passcodeApiError = '';
  enteredPasscodeValue: any;
  userId: string;
  currentRouteSub: Subscription;
  dueDate: any;
  isLinkExpired: boolean;

  constructor(private router: Router, private currentRoute: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private goalSettingService: GoalSettingService, private alertService: AlertService,
    private signupService: SignupService) {
    this.userId = this.currentRoute.snapshot.params.userId;
  }

  ngOnInit(): void {
    localStorage.setItem('lastRequested', this.router.url);
    this.userTag = this.goalSettingService.getUserTagByUrl(this.router.url);
    this.loadScriptForOTP();
    this.store.select('proforma').subscribe((info) => {
      if (info && info.data) {
        let data = info.data;
        if (data.dueDate) {
          const currentDate = new Date();
          this.dueDate = new Date(data.dueDate);
          if (currentDate.getTime() > this.dueDate.getTime()) {
            this.alertService.showErrorMessage('This form cannot be accessed as the submission date has already passed!');
            this.isLinkExpired = true;
          }
        }
      }
    });
  }

  loadScriptForOTP(): void {
    // tslint:disable-next-line: typedef
    $('.inputs').keyup(function (e) {
      if (e.which === 8 || e.which === 46) {
        $(this).prev('.inputs').focus();
      } else {
        if (this.value.length === this.maxLength) {
          $(this).next('.inputs').focus();
        }
      }
    });
  }

  verifyPasscode(value): void {
    this.enteredPasscodeValue = value.passcode1 + value.passcode2 + value.passcode3 +
      value.passcode4 + value.passcode5 + value.passcode6;

    if (this.enteredPasscodeValue.toString().length === 6) {
      this.passcodeApiError = '';

      const userOtpData: any = {
        userId: this.userId,
        passcode: this.enteredPasscodeValue.toString(),
        tag: this.userTag,
        device: 'desktop',
        osType: 'web',
      };

      this.goalSettingService.postPasscodeLogin(userOtpData).then(res => {
        this.signupService.navigateAfterLogin(res.data, this.userTag);
        setTimeout(() => {
          if (this.userTag === 'parent') {
            this.router.navigate(['goal-setting/parent-proforma/welcome']);
          }
          if (this.userTag === 'teacher') {
            this.router.navigate(['goal-setting/teacher-proforma/analysis']);
          }
        }, 500);
      });
    } else {
      this.passcodeApiError = 'Please enter valid passcode';
    }
  }

}
