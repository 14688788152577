import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as AuthActions from '../stores/auth/auth.actions';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from '../stores/app.reducers';

@Component({
  selector: 'app-invalid-login',
  templateUrl: './invalid-login.component.html',
  styleUrls: ['./invalid-login.component.scss']
})

export class InvalidLoginComponent implements OnInit {

  public authToken = '';

  constructor(private route:ActivatedRoute, private store: Store<fromApp.AppState>) { }

  count=1;
  userData;

  ngOnInit(): void {
    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
      this.authToken=localStorage.getItem('authToken')||sessionStorage.getItem('authToken') ||this.route.snapshot.queryParamMap.get('authToken');
      if(!this.authToken){
        let setCountDown = setInterval(() => {
          this.count++;
          if(this.count >= 5){
            let isRedirect=!this.userData?.isNCRPUser;
            this.store.dispatch(new AuthActions.Logout());
            localStorage.clear();
            sessionStorage.clear();
            clearInterval(setCountDown);
          }
        }, 1000);
      }
    });
  }

}
