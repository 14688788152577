export const countryArray = [
    {
        alpha3: 'IND',
        name: 'India',
        dialCode: '+91',
        class: 'India',
        regex: '^[6-9]\\d{9}$',
        flag: 'india'
    },
    {
        alpha3: 'NPL',
        name: 'Nepal',
        dialCode: '+977',
        class: 'Nepal',
        regex: '^[1-9]\\d{9}$',
        flag: 'nepal'
    },
    {
        alpha3: 'SAU',
        name: 'Saudi Arabia',
        dialCode: '+966',
        class: 'SaudiArabia',
        regex: '^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$',
        flag: 'saudi-arabia'
    },
    {
        alpha3: 'MYS',
        name: 'Malaysia',
        dialCode: '+60',
        class: 'Malaysia',
        regex: '[1][0123456789][0-9]{5}',
        flag: 'malaysia'
    },
    {
        alpha3: 'ARE',
        name: 'United Arab Emirates',
        dialCode: '+971',
        class: 'UnitedArabEmirates',
        regex: '^(5[02468]{1})[1-9]{1}[0-9]{6}$',
        flag: 'united-arab-emirates'
    },
    {
        alpha3: 'MMR',
        name: 'Myanmar',
        dialCode: '+95',
        class: 'Myanmar',
        regex: '[9][0-9]{9}',
        flag: 'myanmar'
    },
    {
        alpha3: 'LKA',
        name: 'Sri Lanka',
        dialCode: '+94',
        class: 'SriLanka',
        regex: '[7][0-9]{6}',
        flag: 'sri-lanka'
    },
    {
        alpha3: 'OMN',
        name: 'Oman',
        dialCode: '+968',
        class: 'Oman',
        regex: '[9]\\d[0-9]{6}$',
        flag: 'oman'
    },
    {
        alpha3: 'SGP',
        name: 'Singapore',
        dialCode: '+65',
        class: 'Singapore',
        regex: '[689]\\d[0-9]{7}$',
        flag: 'singapore'
    },
    {
        alpha3: 'BHR',
        name: 'Bahrain',
        dialCode: '+973',
        class: 'Bahrain',
        regex: '(31|33|36|39|322|340|341|342|343|344|345|353|377|383|384|388|663|666|669){1}[0-9]{5}',
        flag: 'bahrain'
    },
    {
        alpha3: 'QAT',
        name: 'Qatar',
        dialCode: '+974',
        class: 'Qatar',
        regex: '[3-9]{1}[0-9]{7}$',
        flag: 'qatar'
    },
    {
        alpha3: 'ZAF',
        name: 'South Africa',
        dialCode: '+27',
        class: 'SouthAfrica',
        regex: '[0]\\d[1-9]{9}$',
        flag: 'south-africa'
    },
    {
        alpha3: 'USA',
        name: 'United States Of America',
        dialCode: '+1',
        class: 'UnitedStates',
        regex: '^([2-9]{3})[0-9]{3}[0-9]{4}$',
        flag: 'united-states-of-america'
    }
]

export const USER_ROUTES = {
    USER: 'user',
    LOGIN: 'login',
    SIGNUP: 'signup'
};

export const COURSES_CLASSES_DATA = {
    class_9th: '9th',
    class_10th: '10th',
    class_11th: '11th',
    class_12th: '12th',
    class_12th_Pass: '12th Pass',
    goal_jee_m: 'JEE(M)',
    goal_jee_a: 'JEE(A)',
    goal_ntse: 'NTSE',
    goal_bitsat: 'BITSAT',
};
