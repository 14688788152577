<!-- Header -->
<header #dropdown>
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="logo-new" [routerLink]="['/']" (click)="handleDropdownState()"><img src="/assets/images/new-home/logo-mypat2.png" alt="myPAT Logo" /></a>
      <div class="home-login-signup-wrp">
        <a class="myCart" [routerLink]="['/cart']">
          <span> 
            <img src="/assets/images/buy-courses/cart{{isCartActive?'-active':''}}.svg" alt="Cart"/>
            <em *ngIf="cartSize"><i>{{cartSize}}</i></em>
          </span>
          <span class="course"></span><b>Cart</b>
        </a>
        <a class="btn login-btn" [routerLink]="routerInfo.login">Login</a>
        <a class="btn signup-btn" [routerLink]="routerInfo.signup">Signup</a>
      </div>
      <div class="collapse navbar-collapse" id="">
        <ul class="navbar-nav mr-auto">
          <li class="has-dropdown classList" >
            <a class="d-flex header-menu" (click)="getMenuList(HEADERMENU_DATA.CLASS_LIST)">Courses <span class="menu-ico hidden-xs ml-2"><img src="/assets/images/new-home/arrow01.svg" alt="arrow-icon"/></span></a>
            <!-- courses dropdown -->
            <div class="main-nav-dropdown">
              <div class="menu-content" *ngIf="activeDropdown[HEADERMENU_DATA?.CLASS_LIST]">
                <div class="heading-menu">
                  <h2>Courses for </h2>
                  <p>Every Class</p>
              </div>
                <div class="content-container">
                  <div class="class-container">
                  <span class="class9 class-menu" (click)="getClassExamList(HEADERMENU_DATA.CLASS9)">9<sup>th</sup></span>
                  <span class="class10 class-menu" (click)="getClassExamList(HEADERMENU_DATA.CLASS10)">10<sup>th</sup></span>
                  <span class="class11 class-menu" (click)="getClassExamList(HEADERMENU_DATA.CLASS11)">11<sup>th</sup></span>
                  <span class="class12 class-menu" (click)="getClassExamList(HEADERMENU_DATA.CLASS12)">12<sup>th</sup></span>
                  <span class="class12pass class-menu" (click)="getClassExamList(HEADERMENU_DATA.CLASS12_PASS)">12<sup>th</sup><sub>pass</sub></span>
                  </div>
                <div class="examName-class9">
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_9th, goal: classAndGoalInfo.goal_ntse}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/ntse-icon.svg" alt="NTSE"/>NTSE & Fundamentals of Engineering and Medical</a>
                </div>
                <div class="examName-class10">
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_ntse}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/ntse-icon.svg" alt="NTSE"/>NTSE & Fundamentals of Engineering and Medical</a>
                  <!-- <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-main-icon.png" alt="JEE Main"/>JEE Main</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-advance-icon.svg" alt="JEE Advanced"/>JEE Advanced</a> -->
                </div>
                <div class="examName-class11">
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-main-icon.png" alt="JEE Main"/>JEE Main</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-advance-icon.svg" alt="JEE Advanced"/>JEE Advanced</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/bitsat-icon.png" alt="BITSAT"/>BITSAT</a>
                </div>
                <div class="examName-class12">
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-main-icon.png" alt="JEE Main"/>JEE Main</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-advance-icon.svg" alt="JEE Advanced"/>JEE Advanced</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/bitsat-icon.png" alt="BITSAT"/>BITSAT</a>
                </div>
                <div class="examName-class12pass">
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-main-icon.png" alt="JEE Main"/>JEE Main</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/jee-advance-icon.svg" alt="JEE Advanced"/>JEE Advanced</a>
                  <a role="button" [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()"><img src="/assets/img/s3-images/picasso/bitsat-icon.png" alt="BITSAT"/>BITSAT</a>
                </div>
                </div>
              </div>
              <div class="menu-footer-link">
                <a [routerLink]="'/all-courses'" (click)="handleDropdownState()">View all Courses <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                <circle cx="11" cy="11.5" r="11" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.2166 15.3133C7.93566 15.5772 7.92683 16.0138 8.19702 16.2884C8.33555 16.4291 8.52067 16.5 8.70597 16.5C8.88209 16.5 9.05821 16.4362 9.19515 16.3076L13.7835 11.9972C13.9218 11.8672 14 11.6876 14 11.5C14 11.3126 13.9218 11.1329 13.7835 11.0029L9.19515 6.69255C8.9142 6.42858 8.4672 6.43703 8.19702 6.71169C7.92683 6.98634 7.93566 7.42289 8.2166 7.68686L12.2757 11.5L8.2166 15.3133Z" fill="white"/>
              </svg>
            </a>
            </div>
            </div>
          </li>
          <li class="has-dropdown examList" >
            <a class="d-flex header-menu" (click)="getMenuList(HEADERMENU_DATA.EXAM_LIST)">Exams <span class="menu-ico hidden-xs ml-2"><img src="/assets/images/new-home/arrow01.svg" alt="arrow-icon"/></span></a>
              <!-- Exam dropdown -->
              <div class="main-nav-dropdown" >
                <div class="menu-content exam-menu">
                  <div class="heading-menu">
                    <h2>Accelerate your </h2>
                    <p>Learning</p>
                </div>
                  <div class="exam-content-container" *ngIf="activeDropdown[HEADERMENU_DATA?.EXAM_LIST]">
                    <a [routerLink]="['/exam/jee-main']" (click)="handleDropdownState()">
                      <img src="/assets/img/s3-images/picasso/jee-main-exam.svg" alt="JEE Main"/>
                      <b>JEE Main</b>
                    </a>
                    <a [routerLink]="['/exam/jee-advanced']" (click)="handleDropdownState()">
                      <img src="/assets/img/s3-images/picasso/jee-advance-exam.png" alt="JEE Advanced"/>
                      <b>JEE Advanced</b>
                    </a>
                    <a [routerLink]="['/exam/bitsat']" (click)="handleDropdownState()">
                      <img src="/assets/img/s3-images/picasso/bitsat-exam.png" alt="BITSAT"/>
                      <b>BITSAT</b>
                    </a>
                    <a [routerLink]="['/exam/ntse']" (click)="handleDropdownState()">
                      <img src="/assets/img/s3-images/picasso/ntse-exam.svg" alt="NTSE"/>
                      <b>NTSE</b>
                    </a>
                  </div>
                </div>
                <div class="menu-footer-link">
                  <a [routerLink]="['/competitive-exam']" (click)="handleDropdownState()">View all Exams <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                  <circle cx="11" cy="11.5" r="11" fill="#333333"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.2166 15.3133C7.93566 15.5772 7.92683 16.0138 8.19702 16.2884C8.33555 16.4291 8.52067 16.5 8.70597 16.5C8.88209 16.5 9.05821 16.4362 9.19515 16.3076L13.7835 11.9972C13.9218 11.8672 14 11.6876 14 11.5C14 11.3126 13.9218 11.1329 13.7835 11.0029L9.19515 6.69255C8.9142 6.42858 8.4672 6.43703 8.19702 6.71169C7.92683 6.98634 7.93566 7.42289 8.2166 7.68686L12.2757 11.5L8.2166 15.3133Z" fill="white"/>
                </svg>
              </a>
              </div>
              </div>
          </li>
          <li class="has-dropdown resourcesListing" >
            <a class="d-flex header-menu" (click)="getMenuList(HEADERMENU_DATA.RESOURCES_LIST)">Resources <span class="menu-ico hidden-xs ml-2"><img src="/assets/images/new-home/arrow01.svg" alt="arrow-icon"/></span></a>
            <!-- Resources dropdown -->
            <div class="main-nav-dropdown" >
              <div class="menu-content">
                <div class="heading-menu">
                  <h2>We've Got</h2>
                  <p>You Covered</p>
                </div>
                <div class="resources-container" *ngIf="activeDropdown[HEADERMENU_DATA?.RESOURCES_LIST]">
                  <div class="resources-menu-container">
                    <a class="resources-menu blog-menu" [href]="BLOG_URL" target="_blank" (click)="getResourcesList(HEADERMENU_DATA.BLOG)">Blog</a>  
                    <a class="resources-menu ebook-menu" [routerLink]="['/ebooks']" (click)="handleDropdownState()">E-Books</a>
                    <a class="resources-menu tools-menu" (click)="getResourcesList(HEADERMENU_DATA.TOOLS)">Tools</a>
                    <div class="tools-container">
                      <a [routerLink]="['/prep-tracker/jee-main']" (click)="handleDropdownState()">JEE (M) Prep Tracker</a>
                      <a [routerLink]="['/prep-tracker/ntse']" (click)="handleDropdownState()">NTSE Prep Tracker</a>
                      <a [routerLink]="['/prep-tracker/jee-advanced']" (click)="handleDropdownState()">JEE (A) Prep Tracker</a>
                      <a [routerLink]="['/prep-tracker/bitsat']" (click)="handleDropdownState()">BITSAT Prep Tracker</a>
                      <a [routerLink]="['/college-predictor/jee-advanced']" (click)="handleDropdownState()">JEE (A) College Predictor</a>
                      <a [routerLink]="['/college-predictor/jee-main']" (click)="handleDropdownState()">JEE (M) College Predictor</a>
                    </div>
                    <a class="resources-menu previous-paper-menu" (click)="getResourcesList(HEADERMENU_DATA.PREVIOUS_PAPER)">Previous Year Papers</a>
                    <!--<a class="resources-menu sample-paper-menu" (click)="getResourcesList(HEADERMENU_DATA.SAMPLE_PAPER)">Board Sample Papers</a>-->
                    <div class="previous-paper-container">
                      <a (click)="getPreviousYearPapers(EXAM_TYPES?.IIT_JEE)">JEE Main & Advanced</a>
                      <a (click)="getPreviousYearPapers(EXAM_TYPES?.NTSE)">NTSE</a>
                    </div>
                    <div class="sample-paper-container">
                      <a (click)="getPreviousYearPapers(EXAM_TYPES?.BOARDS)">All Sample Papers</a>
                      <a>CBSE 10th Science</a>
                      <a>CBSE 10th Maths</a>
                      <a>CBSE 12th Chemistry</a>
                      <a>CBSE 12th Maths</a>
                      <a>CBSE 12th Physics</a>
                    </div>
                </div>
                
                </div>
              </div>
              
            </div>
          </li>
          <li><a class="d-flex header-menu" routerLinkActive="active" routerLink="/community" [routerLinkActiveOptions]="{ exact: true }" (click)="handleDropdownState()">Community</a></li>
        </ul>
      </div>
      <div class="collapse navbar-collapse responsive-header" id="navbarSupportedContent">
        <div class="headermenu-container">
        <div class="menu-logo">
          <a [routerLink]="['/']"><img src="/assets/images/new-home/logo-mypat2.png" alt="myPAT Logo" /></a>
          <div class="cta-container">
            <a class="btn login-btn" [routerLink]="routerInfo.login">Login</a>
            <button class="btn signup-btn" (click)="navigateToSignup()">Signup</button></div>
        </div>
        <div class="header-menu-responsive">
          <ul class="dropdown">
            <li class="dropdown-submenu">
              <a class="mobile-main-menu course-menu" (click)="getmobileMainMenu(HEADERMENU_DATA.CLASS_LIST)"><img src="/assets/img/s3-images/picasso/course-icon.svg" alt="course-icon"/>Courses</a>
              <ul class="nested-dropdown mobile-class-menu">
                <li>
                  <a class="nested-dropdown-menu class9-menu" (click)="getmobileClassList(HEADERMENU_DATA.CLASS9)">class 9</a>
                    <ul class="inner-nested-dropdown class9-list">
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_9th, goal: classAndGoalInfo.goal_ntse}" (click)="handleDropdownState()">NTSE & Fundamentals of Engineering and Medical</a></li>
                    </ul>
                </li>
                <li>
                  <a class="nested-dropdown-menu class10-menu" (click)="getmobileClassList(HEADERMENU_DATA.CLASS10)">class 10</a>
                    <ul class="inner-nested-dropdown class10-list">
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_ntse}" (click)="handleDropdownState()">NTSE & Fundamentals of Engineering and Medical</a></li>
                      <!-- <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()">JEE Main</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_10th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()">JEE Advanced</a></li> -->
                    </ul>
                </li>
                <li>
                  <a class="nested-dropdown-menu class11-menu" (click)="getmobileClassList(HEADERMENU_DATA.CLASS11)">class 11</a>
                    <ul class="inner-nested-dropdown class11-list">
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()">JEE Main</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()">JEE Advanced</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_11th, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()">BITSAT</a></li>
                    </ul>
                </li>
                <li>
                  <a class="nested-dropdown-menu class12-menu" (click)="getmobileClassList(HEADERMENU_DATA.CLASS12)">class 12</a>
                    <ul class="inner-nested-dropdown class12-list">
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()">JEE Main</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()">JEE Advanced</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()">BITSAT</a></li>
                    </ul>
                </li>
                <li>
                  <a class="nested-dropdown-menu class12pass-menu" (click)="getmobileClassList(HEADERMENU_DATA.CLASS12_PASS)">class 12 Pass</a>
                    <ul class="inner-nested-dropdown class12pass-list">
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_jee_m}" (click)="handleDropdownState()">JEE Main</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_jee_a}" (click)="handleDropdownState()">JEE Advanced</a></li>
                      <li><a [routerLink]="['/all-courses']" [queryParams]="{class: classAndGoalInfo.class_12th_Pass, goal: classAndGoalInfo.goal_bitsat}" (click)="handleDropdownState()">BITSAT</a></li>
                    </ul>
                </li>
              </ul>
            </li>
            <li class="dropdown-submenu">
              <a class="mobile-main-menu exams-menu" (click)="getmobileMainMenu(HEADERMENU_DATA.EXAM_LIST)"><img src="/assets/img/s3-images/picasso/exam-menu-icon.svg" alt="exam-icon"/>MY EXAMS</a>
              <ul class="nested-dropdown mobile-exam-menu">
                <li>
                  <a [routerLink]="['/exam/jee-main']" class="nested-dropdown-menu exams-menu-list">JEE Main</a>
                  </li>
                <li>
                  <a [routerLink]="['/exam/jee-advanced']" class="nested-dropdown-menu exams-menu-list">JEE Advanced</a>
                </li>
                <li>
                  <a [routerLink]="['/exam/ntse']" class="nested-dropdown-menu exams-menu-list">NTSE</a>
                </li>
                <li>
                  <a [routerLink]="['/exam/bitsat']" class="nested-dropdown-menu exams-menu-list">BITSAT</a>
                </li>
              </ul>
            </li>
            <li class="dropdown-submenu">
              <a class="mobile-main-menu resources-menus" (click)="getmobileMainMenu(HEADERMENU_DATA.RESOURCES_LIST)"><img src="/assets/img/s3-images/picasso/resources-icon.svg" alt="course-icon"/>Resources</a>
              <ul class="nested-dropdown mobile-resources-menu">
                <li>
                  <a class="nested-dropdown-menu blog-Link" [href]="BLOG_URL" target="_blank">Blog</a>
                </li>
                <li>
                  <a [routerLink]="['/ebooks']" class="nested-dropdown-menu ebook-Link">E-Books</a>
                </li>
                <li>
                  <a class="nested-dropdown-menu tool-menu" (click)="getMobileResourcesList(HEADERMENU_DATA.TOOLS)">Tools</a>
                    <ul class="inner-nested-dropdown tools-list">
                      <li><a [routerLink]="['/prep-tracker/jee-main']">JEE (M) Prep Tracker</a></li>
                      <li><a [routerLink]="['/prep-tracker/ntse']">NTSE Prep Tracker</a></li>
                      <li><a [routerLink]="['/prep-tracker/jee-advanced']">JEE (A) Prep Tracker</a></li>
                      <li><a [routerLink]="['/prep-tracker/bitsat']">BITSAT Prep Tracker</a></li>
                      <li><a [routerLink]="['/college-predictor/jee-advanced']">JEE (A) College Predictor</a></li>
                      <li><a [routerLink]="['/college-predictor/jee-main']">JEE (M) College Predictor</a></li>
                    </ul>
                </li>
                <li>
                  <a class="nested-dropdown-menu previousPaper-menu" (click)="getMobileResourcesList(HEADERMENU_DATA.PREVIOUS_PAPER)">Previous Year Papers</a>
                    <ul class="inner-nested-dropdown previousPaper-list">
                      <li><a (click)="getPreviousYearPapers(EXAM_TYPES?.IIT_JEE)">JEE Main & Advanced</a></li>
                      <li><a (click)="getPreviousYearPapers(EXAM_TYPES?.NTSE)">NTSE</a></li>
                    </ul>
                </li>
                <!--<li>
                  <a class="nested-dropdown-menu samplePaper-menu" (click)="getMobileResourcesList(HEADERMENU_DATA.SAMPLE_PAPER)">Board Sample Paper</a>
                    <ul class="inner-nested-dropdown samplePaper-list">
                      <li><a (click)="getPreviousYearPapers(EXAM_TYPES?.BOARDS)">All Sample Papers</a></li>
                      <li><a>CBSE 10th Science</a></li>
                      <li><a>CBSE 10th Maths</a></li>
                      <li><a>CBSE 12th Chemistry</a></li>
                      <li><a>CBSE 12th Maths</a></li>
                      <li><a>CBSE 12th Physics</a></li>
                    </ul>
                </li>-->                
              </ul>
            </li>
            <li class="dropdown-submenu">
              <a class="mobile-main-menu community-menu" (click)="getmobileMainMenu(HEADERMENU_DATA.COMMUNITY)"><img src="/assets/img/s3-images/picasso/community-icon.svg" alt="community-icon"/>Community</a>
              <ul class="nested-dropdown mobile-community-menu">
                <li>
                  <a class="nested-dropdown-menu community-menu-list" routerLink="/community/view/popular">My Feed</a>
                  </li>
                <li>
                  <a class="nested-dropdown-menu community-menu-list" routerLink="/user/login">My Question</a>
                </li>
                <li>
                  <a class="nested-dropdown-menu community-menu-list" routerLink="/user/login">My Answer</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="download-btn">
          <button class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path d="M13.3536 11.171L1.9202 19.3437C1.72309 19.4845 1.50364 19.5547 1.28418 19.5547C1.27971 19.5547 1.27532 19.5539 1.27093 19.5539C1.24144 19.5536 1.21202 19.5517 1.18268 19.5488C1.16943 19.5475 1.15625 19.5457 1.14299 19.5439C1.12006 19.5407 1.0972 19.5367 1.07441 19.532C1.06614 19.5302 1.0578 19.5296 1.04954 19.5277L1.04998 19.5269C0.907528 19.4941 0.768498 19.4313 0.639298 19.3375C0.243581 19.0504 0 18.5216 0 17.9496V1.60426C0 1.03222 0.243581 0.503384 0.639298 0.216291C0.768424 0.122519 0.907454 0.0596619 1.04991 0.0267906L1.04954 0.0261383C1.05698 0.0243683 1.0645 0.0238094 1.07188 0.0223198C1.09638 0.0171051 1.12095 0.0128212 1.1456 0.00956154C1.15796 0.00788498 1.17025 0.00621033 1.18261 0.00499916C1.21158 0.00211143 1.24047 0.000436783 1.26951 -2.86102e-05C1.27443 -0.00012207 1.27927 -0.000867844 1.28418 -0.000867844C1.50364 -0.000867844 1.72309 0.0693455 1.9202 0.210146L13.3536 8.38293C13.7533 8.66872 14 9.20026 14 9.77621C14 9.77621 14 9.77649 14 9.77658C14 9.77658 14 9.77686 14 9.77696C14 9.77696 14 9.77724 14 9.77733C14 9.77733 14 9.77761 14 9.7777C14 10.3536 13.7533 10.8851 13.3536 11.171ZM8.83207 12.794L7.69526 10.9291L4.80297 15.6741L8.83207 12.794ZM12.7992 9.59565L4.80297 3.87977L9.80967 12.0936L12.7991 9.95677C12.8509 9.9198 12.8831 9.85052 12.8831 9.7763C12.883 9.70181 12.8508 9.63253 12.7992 9.59565Z" fill="white"/>
          </svg>Download App</button>
        </div>
        </div>
        <div class="mobile-overlay" (click)="closeHeaderResponsive()"></div>
      </div>
      
    </nav>
  </div>
</header>
<!-- Header -->