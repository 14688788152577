import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';

import * as fromApp from '../app.reducers';
import * as ProformaActions from './proforma.actions';
import * as LoaderAction from '../../stores/loader/loader.actions';

import { Proforma } from '../../models/proforma.model';
import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { UtilityService } from '../../shared/services/utility.service';
import { DetectLocationService } from '../../shared/services/detect-location.service';
import { forkJoin } from 'rxjs';
import { GoalSettingService } from 'src/app/goal-setting/services/goal-setting.service';

interface ApiResponse {
    code: number;
    data: any;
    message?: any;
}


@Injectable()
export class ProformaEffects {

    body;
    response;
    MSInputMethodContext;
    data;
    constructor(
        private http: HttpService,
        private action$: Actions,
        private router: Router,
        private store: Store<fromApp.AppState>,
        private alertService: AlertService,
        private utilityService: UtilityService,
        private detectLocation: DetectLocationService, private goalService: GoalSettingService) {
        this.store.select('proforma').subscribe((proforma) => {
            this.data = proforma.data;
        });
    }

    // Registering the effect of get proforma detail action
    @Effect()
    getProformaDetails = this.action$.pipe(
        ofType<ProformaActions.GetProformaDetail>(ProformaActions.GET_PROFORMA_DETAIL),
        switchMap((data) => {
            const userTag = data.payload.tag ? data.payload.tag : 'student';
            const url = 'v4/auth/goal-settings?tag=' + userTag + '&type=' + 'initial';
            return forkJoin([
                this.http.get('v4/auth/user-info', true, null, true),
                this.http.get(`${url}`, true, null, true)
            ]).pipe(
                map(([userInfoResponse, goalSettingResponse]) => {
                    let questions = [];
                    let questionstatus = '';
                    let goalSelections = [];
                    let goalStatus = '';
                    const goalSetting = goalSettingResponse.data.goalSettings[0];
                    if (goalSettingResponse.code === 200 && goalSettingResponse.data.goalSettings.length > 0) {
                        const goalSetting = goalSettingResponse.data.goalSettings[0];
                        if (goalSetting.hasOwnProperty('questions')) {
                            questions = goalSetting.questions;
                            questionstatus = goalSetting ? goalSetting.status.questions : 'pending';
                            goalStatus = goalSetting.status.goalSelections;
                            goalSelections = goalSelections;
                        }
                    } else {
                        questionstatus = goalSetting ? goalSetting.status.questions : 'pending';
                    }
                    if (userInfoResponse.code === 200) {
                        const userInfo = userInfoResponse.data;
                        let initials = '';
                        if (userInfoResponse.data.name.hasOwnProperty('firstName') && userInfoResponse.data.name.firstName.length > 0) {
                            initials = this.utilityService.getYourInitials(userInfoResponse.data.name);
                        }
                        const initialsClass = this.utilityService.getInitialsClass();
                        switch (userTag) {
                            case 'parent':
                                goalStatus = userInfo.goalSetting.parent ? userInfo.goalSetting.parent.status : 'pending';
                                break;
                            case 'teacher':
                                goalStatus = userInfo.goalSetting.teacher ? userInfo.goalSetting.teacher.status : 'pending';
                                break;
                            case 'student':
                                goalStatus = userInfo.goalSetting.student ? userInfo.goalSetting.student.status : 'pending';
                                break;
                        }
                        if (userTag == 'teacher' || userTag == 'parent') {
                            if (userTag == 'teacher') {
                                if (!goalSetting || questionstatus == 'pending') {
                                    this.router.navigate([`/goal-setting/${userTag}-proforma/analysis`]);
                                } else if ((!goalSetting || questionstatus == 'done')) {
                                    this.router.navigate([`/goal-setting/${userTag}-proforma/last-step`]);
                                }
                            }
                            else {
                                if (userTag == 'parent') {
                                    this.router.navigate([`/goal-setting/parent-proforma/welcome`]);
                                }
                            }

                        } else {
                            if (userInfo.hasOwnProperty('goalSettingRequired') && userInfo.goalSettingRequired &&
                             !userInfo.onboardingRequired) {
                                this.router.navigate([`/goal-setting/${userTag}-proforma/intro`]);
                            }
                            else {
                                if (userInfo.hasOwnProperty('goalSettingRequired') && !userInfo.goalSettingRequired) {
                                    this.router.navigate(['/home']);
                                } else {
                                    if (localStorage.getItem('lastRequested') && localStorage.getItem('lastRequested') !== '/') {
                                        this.router.navigateByUrl(localStorage.getItem('lastRequested'));
                                    } else {
                                        this.router.navigate(['/home']);
                                    }
                                }
                            }
                        }
                        const name = userInfo.name.firstName ?
                            userInfo.name.firstName + (userInfo.name.middleName ? ' ' + userInfo.name.middleName : '') +
                            (userInfo.name.lastName ? ' ' + userInfo.name.lastName : '') : '';
                        const pastAchievements = userInfo.hasOwnProperty('pastAchievements') ? userInfo.pastAchievements : [];
                        const prevEnrollmentInfo = userInfo.hasOwnProperty('prevEnrollmentInfo') ? userInfo.prevEnrollmentInfo : [];
                        const goalSettingRequired = userInfo.hasOwnProperty('goalSettingRequired') ? userInfo.goalSettingRequired : false;
                        const revisedGoalSetting = userInfo.goalSetting.hasOwnProperty('revisedGoalSetting') ? userInfo.goalSetting.revisedGoalSetting : false;
                        const studentObj = userInfo.goalSetting.hasOwnProperty('student') ? userInfo.goalSetting.student : { status: 'pending' };
                        const parentObj = userInfo.goalSetting.hasOwnProperty('parent') ? userInfo.goalSetting.parent : { status: 'partial' };
                        const teacherObj = userInfo.goalSetting.hasOwnProperty('teacher') ? userInfo.goalSetting.teacher : { status: 'partial' };
                        const program = userInfo.hasOwnProperty('program') ? userInfo.program : { code: '', name: '' };

                        const proforma = new Proforma(userInfo._id, name, userInfo.mobile, userInfo.countryCode,
                            userInfo.email, userInfo.mypatEnrollmentNo, userInfo.enrollmentNo,
                            '', userInfo.photoUrl, (userInfo.userType === 'fiitjee'),
                            this.detectLocation.isSchoolDomain(), pastAchievements, prevEnrollmentInfo,
                            studentObj, parentObj, teacherObj,
                            revisedGoalSetting, userInfo.userType, userInfo.school, goalSettingRequired,
                             userInfo.goalSetting.dueDate, userInfo.class, program, userInfo.goals, questionstatus,
                              questions, goalStatus, goalSelections, initials, initialsClass, userInfo.centres);
                        return {
                            type: ProformaActions.UPDATE_PROFORMA_DETAIL,
                            payload: proforma
                        };
                    } else {
                        return {
                            type: ProformaActions.FAIL
                        };
                    }

                }
                ),
                catchError((error) => {
                    return [
                        {
                            type: ProformaActions.FAIL
                        }
                    ];
                })
            );
        }),
    );

    @Effect({ dispatch: false })
    updateProformaStatus = this.action$.pipe(
        ofType(ProformaActions.UPDATE_PROFORMA_STATUS),
        map((action: ProformaActions.ProformaStatus) => {
            return action.payload;
        }),
    );

    @Effect({ dispatch: false })
    updateProformaQuestions = this.action$.pipe(
        ofType(ProformaActions.UPDATE_PROFORMA_QUESTIONS),
        map((action: ProformaActions.QuestionsResponses) => {
            return action.payload;
        }),
    );

    // Registering the effect of update proforma detail action
    @Effect({ dispatch: false })
    updateProformaDetail = this.action$.pipe(
        ofType(ProformaActions.UPDATE_PROFORMA_DETAIL),
        map((action: ProformaActions.UpdateProformaDetail) => {
            return action.payload;
        }),
    );

    @Effect({ dispatch: false })
    updateEnrollmentNUmbers = this.action$.pipe(
        ofType(ProformaActions.UPDATE_ENROLLMENT_DETAIL),
        map((action: ProformaActions.UpadteEnrollmentNumbers) => {
            return action.payload;
        }), switchMap(
            (payload) => {
                return this.http.put('v4/auth/user', true, payload, true).pipe(
                    map(
                        (res: ApiResponse) => {
                            if (res.code === 200) {
                                if (payload.value == 0) {
                                    this.router.navigate([`/goal-setting/student-proforma/intro`]);
                                } else if (payload.value == 1) {
                                    this.router.navigate(['/goal-setting/student-proforma/2']);
                                }
                            } else {
                                this.alertService.showErrorMessage(res.message);
                                return {
                                    type: ProformaActions.FAIL
                                };
                            }

                        }
                    ),
                    catchError((error) => {
                        return [
                            {
                                type: ProformaActions.FAIL
                            }
                        ];
                    }));
            }
        )
    )
}
