<!-- Verify OTP -->
<div class="modal fade lite-modal" tabindex="-1" id="otpVerify" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <button type="button" class="close" (click)="handlePopUpClose()"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
            <div class="modal-body">
              <form class="viewOurResultsForm" [formGroup]="otpVerificationForm">
                  <h3>Verify OTP</h3>
                  <h4>We've sent an OTP to your entered mobile number</h4>
                  <div class="formSec">
                    <div class="form-group">
                      <div class="country-mobile-container disabled">
                        <div class="country-dropDown dropdown">
                            <a class="dropdown-toggle" data-toggle="dropdown"><img src="../../../assets/images/country-flags/{{mobileInfo.selectedDialCode.flag}}.png" [alt]="mobileInfo.selectedDialCode.name" />{{mobileInfo?.selectedDialCode?.dialCode}}</a>
                        </div>
                        <input type="text" class="form-control disabled" placeholder="Enter Mobile Number" [value]="mobileInfo?.mobileNumber"/>
                      </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control otp-container" [ngClass]="{'error-input': otpError}" placeholder="Enter OTP" formControlName="OTP" (input)="handleOTP()"/>
                        <span class="error-msg" *ngIf="otpError">{{otpError}}</span>
                        <span class="timer" *ngIf="timerData.showTimer">00:{{ timerData.secondsLeft}} secs</span>
                    </div>
                  </div>
                
                <div class="verifyOTPFormBottom">
                  <div class="courseValidInfo" *ngIf="showResendOTP">Didn't Receive? <span (click)="resendOTP()">Resend OTP</span></div>
                  <button type="button" class="btn btn-primary btn-rounded btn-block" [ngClass]="{'disabled': isDisabled}" (click)="submitOTP()">Verify</button>
                </div>
              </form>
            </div>
        </div>
    </div>
  </div>
<!-- Verify OTP -->
