const ROUTES = {
    ON_SPOT: 'on-spot',
    ENROLMENT: 'enrolment',
    DASHBOARD: 'dashboard',
};

const LOGIN_FORM_FIELDS = {
    ENROLMENT_NO: 'enrolmentNo',
    ACCESS_CODE: 'accessCode',
};

const BUTTON_STATES = {
    START_TEST: 'Start Test',
    RESUME_TEST: 'Resume Test',
    VIEW_RESULT: 'View Result',
};

const ATTEMPT_STATUS = {
    FINSIHED: 'finished',
}

const INTERNET_NOT_AVAILABLE_ERROR = 'Please check your internet connection';

export {
    ROUTES,
    LOGIN_FORM_FIELDS,
    BUTTON_STATES,
    ATTEMPT_STATUS,
    INTERNET_NOT_AVAILABLE_ERROR,
}