import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from '../shared/services/utility.service';
import { Store } from '@ngrx/store';
import { AppState } from '../stores/app.reducers';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-application-survey',
  templateUrl: './application-survey.component.html',
  styleUrls: ['./application-survey.component.scss']
})
export class ApplicationSurveyComponent implements OnInit, OnDestroy {

  @Input() modalNumber?: any;
  @Input() examPopupData?: any;
  @Input() numberOfPopups?: any;

  private popUpFields = ['applicationNumber', 'rollNumber', 'score', 'mobile', 'email', 'crlRank'];
  private validExtension = [".png", ".jpg", ".jpeg"];
  public isImageRequired = false;
  public isImageRequiredJeeA = false;
  public isJEEA = false;
  private isStatusChanged = false;
  public isRadioButtonVisible = false;
  public applicationModalData = {
    popUpName: '',
    applicationNumber: false,
    rollNumber: false,
    score: false,
    applicationNumberPlaceholder: '',
    rollNumberPlaceholder: '',
    scorePlaceholder: '',
    applicationNumberErrMsg: '',
    rollNumberErrMsg: '',
    scoreErrMsg: '',
    applicationNumberError: false,
    rollNumberError: false,
    scoreError: false,
    mobile: false,
    mobilePlaceholder: '',
    mobileErrMsg: '',
    mobileError: false,
    email: false,
    emailPlaceholder: '',
    emailErrMsg: '',
    emailError: false,
    admitCardImage: false,
    scoreCardImage: false,
    scoreCardImageErrMsg: '',
    admitCardImageErrMsg: '',
    admitCardImageError: false,
    scoreCardImageError: false,
    showCancelButton: true,
    testName: '',
    uploadImageError: false,
    imageURL: '',
    iconURL: '',
    examCleared: false,
    examClearedErrMsg: '',
    examClearedError: false,
    crlRank: false,
    crlRankPlaceholder: '',
    crlRankError: false,
    crlRankErrMsg: '',
    imageURLJeeA: '',
    uploadImageErrorJeeA: false
  };
  public imageData = {
    data: {},
    files: {}
  }
  public applicationSurvey!: FormGroup;
  private activeSubscription = new Subscription();
  isRestrictAccess = false;
  constructor(
    private httpService: HttpClient,
    private utilityService: UtilityService,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.applicationSurvey = new FormGroup({
      'applicationNumber': new FormControl('', Validators.required),
      'rollNumber': new FormControl('', Validators.required),
      'score': new FormControl('', Validators.required),
      'mobile': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'crlRank': new FormControl('', Validators.required)
    });
    this.utilityService.restrictAccessPopUp.subscribe(res => this.isRestrictAccess = res);
    setTimeout(() => {
      if (!this.isRestrictAccess)
        $(`#applicationNumber0`).modal('show');
      this.initializePopUp();
    }, 1500);
  }

  initializePopUp() {
    this.applicationModalData.showCancelButton = !this.examPopupData.isCancelDisable;
    this.applicationModalData.iconURL = this.examPopupData.icon;
    this.applicationModalData.testName = this.examPopupData.course.name;
    this.applicationModalData.popUpName = this.examPopupData.course.popupName;

    this.isJEEA = this.examPopupData.course.name.includes('JEE(A)');

    for (let field of this.examPopupData.fields) {
      if (field.key === 'examCleared') {
        this.isRadioButtonVisible = true;
        this.initializeCutoffInfo(field);
      } else if (this.applicationModalData.hasOwnProperty(field.key)) {
        this.applicationModalData[field.key] = true;
        this.applicationModalData[field.key + 'Placeholder'] = field.placeholder;
        this.applicationModalData[field.key + 'ErrMsg'] = field.errorMessage;
        this.isImageRequired = this.isImageRequired || (field.type === 'file');
        this.isImageRequiredJeeA = (this.isJEEA && this.applicationModalData.scoreCardImage);
      }
    }

    this.isImageRequired = (this.isJEEA && !this.applicationModalData.admitCardImage) ? false : this.isImageRequired;

    for (let item of this.popUpFields) {
      if (!this.applicationModalData[item]) {
        this.applicationSurvey.removeControl(item);
      }
    }
  }

  closeModal(): void {
    $(`#applicationNumber${this.modalNumber}`).modal('hide');
    if (this.numberOfPopups > this.modalNumber + 1) {
      $(`#applicationNumber${this.modalNumber + 1}`).modal('show');
    }
  }

  initializeCutoffInfo(item: any) {
    this.applicationModalData.examClearedErrMsg = item.errorMessage;
    this.applicationModalData.examCleared = item.options[0].isSelected;
    for (let el of item.options[0].fields) {
      this.applicationModalData[el.key] = true;
      this.applicationModalData[el.key + 'Placeholder'] = el.placeholder;
      this.applicationModalData[el.key + 'ErrMsg'] = el.errorMessage;
    }
  }

  submitForm(): void {
    this.isJEEA ? this.submitJEEAForm() : this.submitJEEMForm();
  }

  submitJEEAForm(): void {
    this.applicationModalData.applicationNumberError = false;
    this.applicationModalData.rollNumberError = false;
    this.applicationModalData.scoreError = false;
    this.applicationModalData.scoreCardImageError = false;
    this.applicationModalData.admitCardImageError = false;
    this.applicationModalData.crlRankError = false;
    this.applicationModalData.emailError = false;
    const isAdmitCardError = this.isImageRequired && !this.applicationModalData.imageURL;
    const isResultError = this.isImageRequiredJeeA && !this.applicationModalData.imageURLJeeA;
    const isRadioButtonError = this.isRadioButtonVisible && !this.isStatusChanged;

    if (this.applicationSurvey.invalid || isAdmitCardError || isResultError || isRadioButtonError) {
      this.applicationModalData.examClearedError = !this.isStatusChanged;

      Object.keys(this.applicationSurvey.value).forEach((key: any) => {
        if (!this.applicationSurvey.value[key]) {
          this.applicationModalData[key + 'Error'] = true;
        }
      });
      if (isAdmitCardError) {
        this.applicationModalData.admitCardImageError = this.applicationModalData.admitCardImage;
      }
      if (isResultError) {
        this.applicationModalData.scoreCardImageError = this.applicationModalData.scoreCardImage;
      }
      return;
    }

    const payload = {
      courseId: this.examPopupData.course.id,
      attemptMonth: this.examPopupData.attemptMonth
    };

    if (this.isRadioButtonVisible) {
      payload['examCleared'] = this.applicationModalData.examCleared;
    }

    if (this.applicationModalData.applicationNumber) {
      payload['applicationNumber'] = this.applicationSurvey.value.applicationNumber;
    }

    if (this.applicationModalData.score) {
      payload['score'] = this.applicationSurvey.value.score;
    }

    if (this.applicationModalData.rollNumber) {
      payload['rollNumber'] = this.applicationSurvey.value.rollNumber;
    }

    if (this.applicationModalData.mobile) {
      payload['mobile'] = this.applicationSurvey.value.mobile;
    }

    if (this.applicationModalData.email) {
      payload['email'] = this.applicationSurvey.value.email;
    }

    if (this.applicationModalData.crlRank) {
      payload['crlRank'] = this.applicationSurvey.value.crlRank;
    }

    if (this.applicationModalData.scoreCardImage) {
      payload['scoreCardImage'] = this.applicationModalData.imageURLJeeA;
    }
    if (this.applicationModalData.admitCardImage) {
      payload['admitCardImage'] = this.applicationModalData.imageURL;
    }

    this.activeSubscription.add(
      this.utilityService.submitApplicationSurvey(payload).subscribe((data) => {
        if (data.code === 200) {
          $(`#applicationNumber${this.modalNumber}`).modal('hide');
          if (this.numberOfPopups > this.modalNumber + 1) {
            $(`#applicationNumber${this.modalNumber + 1}`).modal('show');
          }
        }
      })
    );
  }

  submitJEEMForm(): void {
    this.applicationModalData.applicationNumberError = false;
    this.applicationModalData.rollNumberError = false;
    this.applicationModalData.scoreError = false;
    this.applicationModalData.scoreCardImageError = false;
    this.applicationModalData.admitCardImageError = false;

    if (this.applicationSurvey.invalid || (this.isImageRequired && !this.applicationModalData.imageURL)) {
      Object.keys(this.applicationSurvey.value).forEach((key: any) => {
        if (!this.applicationSurvey.value[key]) {
          this.applicationModalData[key + 'Error'] = true;
        }
        if (this.isImageRequired && !this.applicationModalData.imageURL) {
          this.applicationModalData.scoreCardImageError = this.applicationModalData.scoreCardImage;
          this.applicationModalData.admitCardImageError = this.applicationModalData.admitCardImage;
        }
      });
      return;
    }

    const payload = {
      courseId: this.examPopupData.course.id,
      attemptMonth: this.examPopupData.attemptMonth,
    };

    if (this.applicationModalData.applicationNumber) {
      payload['applicationNumber'] = this.applicationSurvey.value.applicationNumber;
    }

    if (this.applicationModalData.score) {
      payload['score'] = this.applicationSurvey.value.score;
    }

    if (this.applicationModalData.rollNumber) {
      payload['rollNumber'] = this.applicationSurvey.value.rollNumber;
    }

    if (this.applicationModalData.scoreCardImage) {
      payload['scoreCardImage'] = this.applicationModalData.imageURL;
    } else {
      payload['admitCardImage'] = this.applicationModalData.imageURL;
    }

    this.activeSubscription.add(
      this.utilityService.submitApplicationSurvey(payload).subscribe((data) => {
        $(`#applicationNumber${this.modalNumber}`).modal('hide');
        if (this.numberOfPopups > this.modalNumber + 1) {
          $(`#applicationNumber${this.modalNumber + 1}`).modal('show');
        }
      })
    );
  }

  handleMobileInput() {
    let mobile = this.applicationSurvey.value.mobile.toString();
    if (mobile.length > 10) {
      mobile = mobile.substring(0, 10);
      this.applicationSurvey.controls['mobile'].setValue(mobile);
    }
  }

  handleSpecialCharacters(e: any, controlName: any) {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9]+/g, '');
    this.applicationSurvey.controls[controlName].setValue(value);
  }

  handleApplicationNumber() {
    this.applicationModalData.applicationNumberError = !!(!this.applicationSurvey.value.applicationNumber);
  }

  handleRollNumber() {
    this.applicationModalData.rollNumberError = !!(!this.applicationSurvey.value.rollNumber);
  }

  handleScore() {
    this.applicationModalData.scoreError = !!(!this.applicationSurvey.value.score);
  }

  handleMobileNumber() {
    this.applicationModalData.mobileError = !!(!this.applicationSurvey.value.mobile);
  }

  handleEmailId() {
    this.applicationModalData.emailError = !!(!this.applicationSurvey.value.email);
  }

  handleCrlRank() {
    this.applicationModalData.crlRankError = !!(!this.applicationSurvey.value.crlRank);
  }

  changeStatus(status: boolean) {
    this.isStatusChanged = true;
    this.applicationModalData.crlRankError = false;
    this.applicationModalData.scoreError = false;
    this.applicationModalData.examCleared = status;
    this.applicationModalData.examClearedError = false;

    if (!status) {
      this.applicationSurvey.removeControl('crlRank');
      this.applicationSurvey.removeControl('score');
    } else {
      this.applicationSurvey.addControl('crlRank', new FormControl('', Validators.required));
      this.applicationSurvey.addControl('score', new FormControl('', Validators.required));
    }
  }

  removeImage(isJeeAImage?: boolean) {
    !isJeeAImage ? this.applicationModalData.imageURL = '' : this.applicationModalData.imageURLJeeA = '';
  }

  getFileExtension(files) {
    const names = files[0].name.split('.');
    const extension = names[names.length - 1].trim().toLowerCase();
    return '.' + extension;
  }

  processImage(e: any, type?: string): void {
    if (!type) {
      type = this.applicationModalData.scoreCardImage && !this.isJEEA ? 'scoreCardImage' : 'admitCardImage';
    }
    const files = e.target.files;
    const extension = this.getFileExtension(files);
    this.applicationModalData.uploadImageError = false;
    this.applicationModalData.scoreCardImageError = false;
    this.applicationModalData.admitCardImageError = false;

    if (!this.validExtension.includes(extension)) {
      this.applicationModalData.uploadImageError = true;
      return;
    }

    const params = {
      key: 'popups',
      type: type,
      fileExtension: extension
    };

    this.activeSubscription.add(
      this.utilityService.getImageSignedUrl(params).subscribe((data: any) => {
        if (data.data.url && data.data.fields) {
          this.imageData.data = data.data;
          this.imageData.files = files[0];
          this.uploadImageOnS3(data.data, { files: files[0] }, type);
        }
      })
    );
  }

  uploadJeeAResult(e: any) {
    const files = e.target.files;
    const extension = this.getFileExtension(files);
    const type = 'scoreCardImage';
    this.applicationModalData.uploadImageErrorJeeA = false;
    this.applicationModalData.scoreCardImageError = false;

    if (!this.validExtension.includes(extension)) {
      this.applicationModalData.uploadImageErrorJeeA = true;
      return;
    }

    const params = {
      key: 'popups',
      type,
      fileExtension: extension
    };

    this.activeSubscription.add(
      this.utilityService.getImageSignedUrl(params).subscribe((data: any) => {
        if (data.data.url && data.data.fields) {
          this.imageData.data = data.data;
          this.imageData.files = files[0];
          this.uploadImageOnS3(data.data, { files: files[0] }, type);
        }
      })
    );
  }

  uploadImageOnS3(data: any, params: any, type: string) {
    const formData: FormData = new FormData();
    for (let key of Object.keys(data.fields)) {
      formData.append(key, data.fields[key]);
    }
    formData.append('file', params.files, params.files.name);
    const key = data.fields.key;
    const url = data.url;

    const headers = new HttpHeaders().set('Accept', 'application/json');
    this.activeSubscription.add(
      this.httpService.post(data.url, formData, { headers }).subscribe((data: any) => {
        const finalUrl = url + '/' + key;
        if (!this.isJEEA) {
          this.applicationModalData.imageURL = finalUrl;
        } else {
          (type === 'scoreCardImage') ? this.applicationModalData.imageURLJeeA = finalUrl : this.applicationModalData.imageURL = finalUrl;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.activeSubscription.unsubscribe();
  }
}

