const EXAM_TYPE = {
    IIT_JEE: 'iit-jee',
    NTSE: 'ntse',
    BOARDS: 'boards'
}
const EXAM_DATA = {
    'iit-jee' : {
        EXAM: 'JEE Main & Advanced',
        STUNDENT_COUNT: '9032 myPATians',
        STUDENT_QUALIFIED: 'Above 90%ile (JEE Main 19)',
        QUESTIONS_SOLVED: '6.5 Million',
        ASPIRANTS_REGISTERED: '200000',
    },
    ntse : {
        EXAM: 'NTSE',
        STUNDENT_COUNT: '926 Students Qualified',
        STUDENT_QUALIFIED: 'Qualified NTSE Stage 1',
        QUESTIONS_SOLVED: '6.5 Million',
        ASPIRANTS_REGISTERED: '200000',
    },
    boards : {
        QUESTIONS_SOLVED: '6.5 Million',
        ASPIRANTS_REGISTERED: '200000',
    }
}

export {EXAM_TYPE, EXAM_DATA };