import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-report-abuse',
  templateUrl: './report-abuse.component.html',
  styleUrls: ['../../../new-community/new-community.component.scss']
})
export class ReportAbuseComponent implements OnInit {
  @Input() modalId: string;
  @Output() reportQuestionEvent = new EventEmitter();
  reportType: number
  reportTypeValue: string;


  constructor() {
    this.reportType = 0
  }

  ngOnInit() {

  }


  reportTypeClick(str) {
    if (str == "Spam") {
      this.reportType = 1;
      this.reportTypeValue = "Spam";
    } else if (str == "Abusive / Personal Attack") {
      this.reportType = 2;
      this.reportTypeValue = "Abusive / Personal Attack";
    } else if (str == "Insincere") {
      this.reportType = 3;
      this.reportTypeValue = "Insincere";
    } else if (str == "Poorly Written") {
      this.reportType = 4;
      this.reportTypeValue = "Poorly Written";
    } else {
      this.reportType = 5;
      this.reportTypeValue = "Incorrect Topics";
    }

  }

  reportQuestion() {
    this.reportQuestionEvent.emit(this.reportTypeValue)
    this.reportTypeValue = ''
    $(`#${this.modalId}`).modal('hide')

  }

}