import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertSeconds'
})
export class ConvertSecondsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var timeVal: number;
    let timeStr = '';
    if (value > 3600) {
      timeVal = Math.floor(value / 3600);
      timeVal = Math.round(timeVal);
      value = value % 3600;
      timeStr = timeStr + timeVal + 'h ';
    }
    if (value > 60) {
      timeVal = Math.floor(value / 60);
      timeVal = Math.round(timeVal);
      value = value % 60;
      timeStr = timeStr + timeVal + 'm ';
    }
    if (value < 60) {
      timeVal = value;
      timeVal = Math.round(timeVal);
      timeStr = timeStr + timeVal + 's ';
    }
    return timeStr;
  }

}
