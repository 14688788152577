<!-- success message -->
<div class="toster successmsg alert" [ngClass]="{'msgSubtitle': alertMessageSuccessSubtitle}" id="success-alert" role="alert" *ngIf="alertMessageSuccess">
    <div class="toster-inner">
        <div class="icon">
            <img  onerror="this.style.display='none'" src="/assets/images/success.svg">
        </div>
        <div class="toster-content">
            <b>{{alertMessageSuccess}}</b>
            <p *ngIf="alertMessageSuccessSubtitle" class="ml-0">{{alertMessageSuccessSubtitle}}</p>
        </div>
        <div class="close">
            <a href="javascript:void(0)" (click)="hideMessage()">&times;</a>
        </div>
    </div>
</div>
<!-- Danger message -->
<div class="toster errormsg alert" role="alert" id="error-alert" *ngIf="alertMessageError">
    <div class="toster-inner">
        <div class="icon">
            <img onerror="this.style.display='none'" src="/assets/images/cross.svg">
        </div>
        <div class="toster-content">
            <b>{{alertMessageError}}</b>
            <p *ngIf="alertMessageErrorSubtitle" class="ml-0">{{alertMessageErrorSubtitle}}</p>
        </div>
        <div class="close">
            <a href="javascript:void(0)" (click)="hideMessage()">&times;</a>
        </div>
    </div>
</div>
<!-- Warning message -->
<div class="toster warningmsg alert" role="alert" id="warning-alert" *ngIf="alertMessageWarning">
    <div class="toster-inner">
        <div class="icon">
            <img  onerror="this.style.display='none'" src="/assets/images/warning.svg">
        </div>
        <div class="toster-content">
            <b>{{alertMessageWarning}}</b>
        </div>
        <div class="close">
            <a href="javascript:void(0)" (click)="hideMessage()">&times;</a>
        </div>
    </div>
</div>

<!-- Primary message -->
<div class="toster primarymsg alert" role="alert" id="customBtn-alert" *ngIf="alertMessageCustom && alertMessageCustom.title">
    <div class="toster-inner">
        <div class="icon">
            <img  onerror="this.style.display='none'" src="/assets/images/primary-status-icon.svg">
        </div>
        <div class="toster-content">
            <b>{{alertMessageCustom.title}}</b>
            <p>Question removed from Revision Notebook</p>
        </div>
        <div class="close">
            <!-- <a href="javascript:void(0)" (click)="hideMessage()">&times;</a> -->
            <span (click)="undoRevision()">{{alertMessageCustom.btn}}</span>
        </div>
    </div>
</div>

<!-- All custom message -->
<div class="toster {{dynamicMessageClass}} alert" role="alert" id="dynamic-custom-alert" *ngIf="dynamicCustomMessageAlert">
    <div class="toster-inner">
        <div class="icon">
            <img onerror="this.style.display='none'" src="{{dynamicMessageIcon}}">
        </div>
        <div class="toster-content">
            <b>{{dynamicCustomMessageAlert}}</b>
            <!-- <p>There was a problem with your request</p> -->
        </div>
        <div class="close">
            <a href="javascript:void(0)" (click)="hideMessage()">&times;</a>
        </div>
    </div>
</div>