import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../stores/app.reducers';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-side-filter-list',
  templateUrl: './side-filter-list.component.html',
  styleUrls: ['./side-filter-list.component.css']
})
export class SideFilterListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filterDetails: any;
  @Input() filterType: any;
  @Input() clearingData: Boolean;
  @Output() filterChange = new EventEmitter<any>();
  @Output() closeFilters = new EventEmitter<any>();
  public filterDataToShow: any = [];
  public isMobile = false;
  private activeSubscriptions: Subscription = new Subscription();
  public userData: any; 

  constructor(
    private store: Store<fromApp.AppState>,
  ) {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    $('#kaptureChatWrapper').addClass('hide-chat');
    this.activeSubscriptions.add(
      this.store.select('userProfile').subscribe((userProfile) => {
        this.userData = userProfile.userData;
      })
    );
    this.filterDataToShow = [];
    for (let i = 0; i < this.filterDetails.length; i++) {
      const filterKeys = Object.keys(this.filterDetails[i]);
      const filterObj = {
        filterKey: filterKeys[0],
        filterValues: this.filterDetails[i][filterKeys[0]],
        type: filterKeys[0] === 'Time Taken' ? 'single' : this.filterType
      };
      for (let i = 0; i < filterObj.filterValues.length; i++) {
        filterObj.filterValues[i].isChecked = false;
        filterObj.filterValues[i].isApplied = false;
      }
      this.filterDataToShow.push(filterObj);
    }
  }

  ngOnChanges() {
  }

  onChangeMultiSelect(event) {
    if (this.isMobile) {
      return;
    }
    this.filterChange.emit(event);
  }

  onChangeSingleSelect(event) {
    if (this.isMobile) {
      return;
    }
    this.filterChange.emit(event);
  }


  apply() {
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        if (filter.isChecked) {
          filter.isApplied = true;
        }
        this.filterChange.emit({
          isChecked: filter.isChecked,
          filterKey: category.filterKey,
          filterValue: filter
        });
      });
    });
    $('#kaptureChatWrapper').removeClass('hide-chat');
    this.closeFilters.emit();
  }

  closeFilter() {
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        filter.isChecked = filter.isApplied;
      });
    });
    $('#kaptureChatWrapper').removeClass('hide-chat');
    this.closeFilters.emit();
  }

  resetFilters() {
    this.filterDataToShow.forEach((category: any) => {
      category.filterValues.forEach((filter: any) => {
        filter.isChecked = false;
        filter.isApplied = false;
      });
    });
    for (let i = 0; i < this.filterDataToShow.length; i++) {
      this.filterDataToShow[i].isChecked = false;
      for (let j = 0; j < this.filterDataToShow[i].filterValues.length; j++) {
        this.filterChange.emit({ isChecked: false, filterValue: this.filterDataToShow[i].filterValues[j], filterKey: this.filterDataToShow[i].filterKey });
      }
    }
  }

  ngOnDestroy() {
    this.activeSubscriptions.unsubscribe();
  }
}
