<div class="dropdown notification-list">
  <button class="btn btn-notification dropdown-toggle" type="button" id="notificationMenuButton" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false" (click)="fetchAllNotification()">
    <img [src]="'/assets/images/blue-bell.svg' " />
    <span *ngIf="countToDisplay > 0" class="badge badge-red">{{countToDisplay}}</span>
  </button>
  <div class="dropdown-menu" aria-labelledby="notificationMenuButton">
    <div class="allread" *ngIf="!loader" (click)="markAllNotificationRead()">
      <b>Notification</b><button class="btn btn-allread">Mark all as read</button>
    </div>
    <ul class="user-notifications" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">
      <li class="nodata-noti" *ngIf="notificationList.length==0 && !loader">
        <span>No notifications to show</span>
      </li>
      <span *ngIf="notificationList.length>0 && !loader">
        <li *ngFor=" let notification of notificationList" [ngClass]="notification.isViewed?'':'unread'">
        <span>
          <span  *ngIf="newNotificationsActions.indexOf(notification.action)>-1; else elseBlock" class="lefticon">
            <img appLazyLoad [src]="getIconImage(notification)" alt="">
          </span>
        </span>
        <ng-template #elseBlock>
        <span >
        <span *ngIf="notification.action==='community-answer-vote'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='purchaseCongratulations'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_course_added.svg" alt="">
        </span>
        <span *ngIf="notification.action==='community-question-vote'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='community-answer'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='community-mark-best-answer'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='welcome'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_welcome1.svg" alt="">
        </span>
        <span *ngIf="notification.action==='challengee-notification'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_challenge.png" alt="">
        </span>
        <span *ngIf="notification.action==='challenger-notification'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_challenge.png" alt="">
        </span>
        <span *ngIf="notification.action==='questionDeleted'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='questionApproved'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='answerDeleted'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='userBlockedForQuestion'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='userBlockedForAnswer'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='answerApproved'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='userUnblocked'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/notif_random.png" alt="">
        </span>
        <span *ngIf="notification.action==='resultGenerated' || notification.action === 'resultRegeneration'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/Group 4264.svg" />
        </span>
        <span *ngIf="notification.action==='openCalendar'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/taskNotification.svg" />
        </span>
        <span *ngIf="notification.type==='custom'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/fiitjee-icon.svg" />
        </span>
        <span *ngIf="notification.type==='testDeleted'" class="lefticon">
          <img appLazyLoad src="/assets/images/notification/new/delete-assignment.svg" alt="Delete Assignment">
        </span>
       </span> 
        </ng-template>
        <a class="" href="javascript:void(0);" (click)="setNotification(notification)">
          <b><p [innerHtml]="notification['title'] || notification['title']" *ngIf="notification.type==='custom'" ></p></b>
          <p [innerHtml]="notification['htmlBody'] || notification['body']"></p>
         <span class="date-time-status"
            *ngIf="notification.timePassed">
            <span>{{notification.timePassed}}</span>
            <!-- <span *ngIf="notification.timePassed==1">{{notification.timePassed}}</span> -->
          </span>
          <!-- <span class="date-time-status" *ngIf="getAnswerIcoCondition(notification)">
            
            <span *ngIf="notification.timePassed==0">Just Now</span>
            <span *ngIf="notification.timePassed>1">{{notification.timePassed}} days ago</span>
            <span *ngIf="notification.timePassed==1">{{notification.timePassed}} day ago</span>
          </span>
          <span class="date-time-status"
            *ngIf="notification.action==='purchaseCongratulations' || notification.action==='welcome' || notification.action==='assignmentAssigned' || notification.action==='resultGenerated'">
            <span *ngIf="notification.timePassed==0">Just Now</span>
            <span *ngIf="notification.timePassed>1">{{notification.timePassed}} days ago</span>
            <span *ngIf="notification.timePassed==1">{{notification.timePassed}} day ago</span>
          </span>
          <span class="date-time-status"
            *ngIf="notification.action==='challengee-notification' || notification.action==='challenger-notification'">
            
            <span *ngIf="notification.timePassed==0">Just Now</span>
            <span *ngIf="notification.timePassed>1">{{notification.timePassed}} days ago</span>
            <span *ngIf="notification.timePassed==1">{{notification.timePassed}} day ago</span>
          </span>
          <span class="date-time-status"
          *ngIf="notification.action==='openTestDetails' || notification.action==='openCalendar'">
          
          <span *ngIf="notification.timePassed==0">Just Now</span>
          <span *ngIf="notification.timePassed>1">{{notification.timePassed}} days ago</span>
          <span *ngIf="notification.timePassed==1">{{notification.timePassed}} day ago</span>
        </span> -->
        </a>
        </li>
      </span>
      <app-loader *ngIf="loader" [pageFrom]="'notification'"></app-loader>
    </ul>
    
  </div>
</div>
<!-- <app-create-task *ngIf="taskNotification"></app-create-task> -->