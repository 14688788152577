export const EBOOKS = [
    {
        name: 'Ultimate Guide to JEE Main Exam',
        examType: 'JEE_MAIN',
        bookId: 'guide-jee-main'
    },
    {
        name: 'Tips To Crack BITSAT By Toppers',
        examType: 'BITSAT',
        bookId: 'bitsat-topper'
    },
    {
        name: 'Top 5 Preparation Tips for NTSE',
        examType: 'NTSE',
        bookId: 'ntseTip'
    },
    {
        name: 'One-Month Mantra To Crack JEE Main 2024',
        examType: 'JEE_MAIN',
        bookId: 'mantra-jee-main'
    }
]

export  const TAG_CLICKED ={
    ELEMENT: 'A',
    CLASS:'ebooks',
};
