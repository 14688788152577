import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewChild, ElementRef, SimpleChanges, Inject } from '@angular/core';

import { Options } from 'ng5-slider';

declare const $: any;

import { HttpService } from 'src/app/shared/services/http.service';
import { AssignedTestsService } from '../../services/assigned-tests.service';
import { UpcomingTestsService } from '../../services/upcoming-tests.service';
import { UtilityService } from './../../../new-community/shared/utility.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-test-filters',
  templateUrl: './test-filters.component.html',
  styleUrls: ['./test-filters.component.scss']
})

export class TestFiltersComponent implements OnInit {

  @Input() filters: any;
  @Input() cardName: string;
  @Output() appliedFilters = new EventEmitter();

  selectedFilters: any = [];
  filtersList = [];
  resetButtonClicked = false;
  defaultFilters: any = []

  public syllabusSearchQuery: string = '';
  public isSyllabusLoading: boolean = false;
  public syllabus: any[] = [];
  public selectedSyllabus: any[] = [];
  public isSyllabusSearchFocussed: boolean = false;

  @ViewChild("syllabusSearchRef") syllabusSearchRef: ElementRef;
  @ViewChild("syllabusDropdownRef") syllabusDropdownRef: ElementRef;

  public sliderValue: number = 1;
  public sliderOptions: Options = {
    showTicks: true,
    showTicksValues: false,
    stepsArray: []
  };
  public projectedRankRange = {
    min: '',
    max: '',
  };

  constructor(
    private utilityService: UtilityService,
    private httpService: HttpService,
    private assignedTestService: AssignedTestsService,
    private upcomingTestService: UpcomingTestsService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    if (this.cardName === 'testHistory') {
      document.getElementById('scrollDiv').classList.add('filterScrollTestHistory')
    } else {
      document.getElementById('scrollDiv').classList.add('filterScroll')
    }
    this.filtersList = JSON.parse(JSON.stringify(this.filters));
    this.loadSliderInfo();
    if (this.cardName === 'upcomingTest') {
      this.selectedFilters = [];
      this.defaultFilters = [];
      this.defaultFilters = JSON.parse(localStorage.getItem('upcomingTestAppliedFilters'));
      this.filtersList.forEach(filter => {
        if (filter.values.filter(obj => obj.isSelected === true).length > 0) {
          this.selectedFilters.push(filter);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filtersList = JSON.parse(JSON.stringify(changes.filters.currentValue));
    this.selectedFilters.forEach((filter) => {
      if (filter.key === 'questionDifficulty'){
        let i = 0;
        this.filtersList.forEach(list => {
          if (list.key === 'questionDifficulty'){            
            this.filtersList[i] = filter;
          }
          i++;
        });
      }
    })
    if (this.cardName === 'upcomingTest') {
      this.selectedFilters = [];
      this.defaultFilters = [];
      this.defaultFilters = JSON.parse(localStorage.getItem('upcomingTestAppliedFilters'));
      this.filtersList.forEach(filter => {
        if (filter.values.filter(obj => obj.isSelected === true).length > 0) {
          this.selectedFilters.push(filter);
        }
        if (filter.type === 'date'){
          for (let i = 0; i < filter.values.length; i++) {
            if (!filter.values[i].isSelected){
              filter.values[i].value = '';
            }
          }
        }
      });
    }
  }

  loadSliderInfo() {
    let sheet = document.createElement('style'),
      $rangeInput = $('.range input'),
      prefs = ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track'];
    document.body.appendChild(sheet);
    let getTrackStyle = function (el) {
      let curVal = el.value,
        val = (curVal - 1) * 16.666666667,
        style = '';
      // Set active label
      $('.range-labels li').removeClass('active selected');

      let curLabel = $('.range-labels').find('li:nth-child(' + curVal + ')');

      curLabel.addClass('active selected');
      curLabel.prevAll().addClass('selected');

      // Change background gradient
      for (let i = 0; i < prefs.length; i++) {
        style += '.range {background: linear-gradient(to right, #37adbf 0%, #37adbf ' + val + '%, #fff ' + val + '%, #fff 100%)}';
        style += '.range input::-' + prefs[i] + '{background: linear-gradient(to right, #37adbf 0%, #37adbf ' + val + '%, #b2b2b2 ' + val + '%, #b2b2b2 100%)}';
      }
      return style;
    }
    $rangeInput.on('input', function () {
      sheet.textContent = getTrackStyle(this);
    });
    // Change input value on label click
    $('.range-labels li').on('click', function () {
      let index = $(this).index();

      $rangeInput.val(index + 1).trigger('input');

    });
  }

  handleFilterClose() {
    // For Filter Scroll
    this.cardName === 'assignedTest' ? this.assignedTestService.initializeFilter.next(true) : this.cardName === 'upcomingTest' ? this.upcomingTestService.initializeFilter.next(true) : null;
    this.callJqueryForFilterClose();
   //this.appliedFilters.emit(null);
    this.filtersList = JSON.parse(JSON.stringify(this.filters));
    this.selectedFilters = [];
    this.filtersList.forEach(filter => {
      if (filter.values.filter(obj => obj.isSelected === true).length > 0) {
        this.selectedFilters.push(filter);
      }
    });
  }

  callJqueryForFilterClose() {
    this.document.querySelector('.filterContainer')?.classList.remove('animateFilter');
    this.elementRef.nativeElement.querySelector('.overlayFilter').style.display = 'none';
    this.document.querySelector('body').style.overflow = '';
    this.document.querySelector('body').style.height = '';
    this.ngOnDestroy();
  }

  checkSelectedFilters() {
    let flag = false;
    this.selectedFilters.forEach(filter => {
      const x = this.defaultFilters.find(obj => obj.key === filter.key);
      filter.values.forEach(value => {
        const defaultValue = x.values.find(obj => obj.key === value.key);
        if ((defaultValue.isSelected !== value.isSelected) || defaultValue.value !== value.value) {
          flag = true;
        }
      });
    });
    if (flag) {
      return false;
    } else {
      return true;
    }
  }

  applyCheckedFilter(filter, data) {
    this.resetButtonClicked = false;
    filter.values.map((a) => {
      if (a.displayName === data.displayName) {
        a.isSelected = !a.isSelected;
      }
    });
    if (this.selectedFilters.length > 0) {
      let flag = false;
      this.selectedFilters.forEach(filterData => {
        if (filterData.key === filter.key) {
          flag = true;
          filterData.values = filter.values;
        }
      });
      if (!flag) {
        this.selectedFilters.push(filter);
      }
    } else {
      this.selectedFilters.push(filter);
    }
    if (this.cardName === 'testHistory' && filter.key === 'goal') {
      this.computeRankSliderOptions();
    }
  }

  getSelectedFilterInfo(data): boolean {
    let selectedObjs = data.values.filter(obj => obj.isSelected === true);
    if (selectedObjs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  dateInfo(data) {
    this.resetButtonClicked = false;
    if (!data.values[0].value || !data.values[1].value || !data.values[0].isSelected || !data.values[1].isSelected){  //condition to remove the date from selectedfilterobj
      let i = 0;
      this.selectedFilters.forEach(filterData => {
        if (filterData.key === data.key) {
          this.selectedFilters.splice(i, 1);
        }
        i++;
      });
      return;
    }
  
    if (this.selectedFilters.length > 0 && (data.values[0].value < data.values[1].value)) {
      let flag = false;
      this.selectedFilters.forEach(filterData => {
        if (filterData.key === data.key) {
          flag = true;
          filterData.values = data.values;
          filterData.isSelected = data.isSelected;
        }
      });
      if (!flag) {
        this.selectedFilters.push(data);
      }
    } else {
      this.selectedFilters.push(data);
    }
  }

  applyFilters() {
    if (this.resetButtonClicked) {
      localStorage.setItem('upcomingTestAppliedFilters', localStorage.getItem('upcomingTestDefaultFilter'));
      this.resetButtonClicked = false;
    }
    // For Filter Scroll
    this.cardName === 'assignedTest' ? this.assignedTestService.initializeFilter.next(true) : this.cardName === 'upcomingTest' ? this.upcomingTestService.initializeFilter.next(true) : null;
    this.callJqueryForFilterClose();
    this.appliedFilters.emit(this.selectedFilters);
  }

  resetSelection() {
    this.selectedFilters = [];
    this.filtersList = JSON.parse(JSON.stringify(this.filters.slice()));
  }

  resetFilters() {
    this.resetButtonClicked = true;
    if (this.cardName !== 'upcomingTest') {
      this.selectedFilters = [];
      this.filtersList.forEach(filter => {
        if (filter.type === 'checkbox' || filter.type === 'date') {
          filter.values.forEach(filterData => {
            filterData['isSelected'] = false;
          });
        }

        if (filter.type === 'date') {
          filter.values.forEach(filterData => {
            delete filterData.singleDate;
            filterData.value = '';
          });
        }

        // Reset Performance and question difficulty
        if (filter.key === 'performance' || filter.key === 'questionDifficulty') {
          filter.values.forEach(filterData => {
            filterData['isSelected'] = false;
            filterData['value'] = 0;
          });
        }
      });
      this.syllabusSearchQuery = '';
      this.selectedSyllabus = [];
    } else {
      this.selectedFilters = [];
      this.filtersList = JSON.parse(localStorage.getItem('upcomingTestDefaultFilter'));
      this.filtersList.forEach(filter => {
        if (filter.values.filter(obj => obj.isSelected === true).length > 0) {
          this.selectedFilters.push(filter);
        }
      });
    }
  }

  public handleSyllabusSearchEvent = this.utilityService.debounce(() => {
    this.isSyllabusLoading = true;
    this.fetchSyllabus();
  }, 350);

  public fetchSyllabus() {
    if (this.syllabusSearchQuery.length < 3) {
      this.syllabus = [];
      this.isSyllabusLoading = false;
      return;
    }

    const types = ['concept', 'chapter'];
    this.httpService.get(
      `v4/auth/syllabus?types=${types.join(',')}&searchQuery=${this.syllabusSearchQuery}`,
      true,
    ).subscribe(res => {
      if (res.code === 200) {
        this.syllabus = res.data.syllabusData;
      }
      this.isSyllabusLoading = false;
    });
  }

  public capitalizeFirstLetter(str: string) {
    const result = `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
    return result;
  }

  public setSyllabusSearchFocusMode(mode: boolean) {
    setTimeout(() => this.isSyllabusSearchFocussed = mode, 30);
  }

  public handleSyllabusAdd(syllabus) {
    const exists = this.selectedSyllabus.find((item) => item.id === syllabus.id);
    if (!exists) {
      this.selectedSyllabus.push(syllabus);
    }
    this.setSyllabusSearchFocusMode(false);
    this.applySyllabusFilter();
  }

  public handleSyllabusRemove(index) {
    this.selectedSyllabus = this.selectedSyllabus.filter((_, i) => i !== index);
    this.applySyllabusFilter();
  }

  private applySyllabusFilter() {
    this.selectedFilters = this.selectedFilters.filter((item: any) => item.key !== 'searchby');
    const payload = {
      key: "searchby",
      type: "textbox",
      displayName: "Search",
      isLocked: false,
      values: this.selectedSyllabus.map(({ id, name, type }: any) => ({
        key: id,
        displayName: name,
        searchBy: type,
        isSelected: true,
      })),
    }

    if (payload.values.length > 0) {
      this.selectedFilters = [payload, ...this.selectedFilters];
    }
  }

  public get selectedGoals() {
    const filter = this.selectedFilters.find((filter: any) => filter.key === 'goal');
    if (!filter) return [];

    return filter.values.filter((value: any) => value.isSelected === true);
  }

  public computeRankSliderOptions() {
    if (this.selectedGoals.length === 0) return;

    if (this.selectedGoals.length !== 1) {
      this.sliderOptions = {
        showTicks: true,
        showTicksValues: false,
        stepsArray: [],
      };
      return;
    }
    const goal = this.selectedGoals[0];
    const goalKey = goal.key;
    const projectedRank = this.filtersList.find((filter: any) => filter.key === 'rank');
    const rankObj = projectedRank?.values?.find((value: any) => value?.key === goalKey);
    const options: any = rankObj?.options;
    this.sliderOptions = {
      showTicks: true,
      showTicksValues: false,
      stepsArray: options?.map((option: any, index) => ({
        value: index + 1,
        legend: option?.displayName,
      })),
    };
    this.projectedRankRange.min = options?.[0]?.displayName;
    this.projectedRankRange.max = options?.slice(-1)?.[0]?.displayName;
  }

  public range(start: number, end: number, step: number): number[] {
    const arr = [];
    for (let i = start; i <= end; i += step) arr.push(i);
    return arr;
  }

  public handlePerformanceSelection(allPerformances, index) {
    allPerformances[index].selectedOption = {
      value: 0,
      key: allPerformances[index].options[0].key,
    };
    this.computePerformanceFilter(allPerformances);
  }

  public handlePerformanceKeyChange(event, allPerformances, index) {
    allPerformances[index].selectedOption = {
      value: 0,
      key: event.target.value,
    };
    this.computePerformanceFilter(allPerformances);
  }

  public handlePerformanceValueChange(allPerformances) {
    this.computePerformanceFilter(allPerformances);
  }

  public computePerformanceFilter(allPerformances) {
    const performanceFilter = this.filtersList.find((filter: any) => filter.key === 'performance');

    const performanceValues = allPerformances.map(({ key, displayName, options, selectedOption, isSelected }: any) => ({
      key,
      displayName,
      isSelected,
      options: !isSelected
        ? options :
        options.map((item: any) => ({
          ...item,
          isSelected: item.key === selectedOption.key,
          value: item.key === selectedOption.key ? selectedOption.value.toString() : item.value.toString(),
        })),
    }));

    const finalPerformanceFilter = {
      ...performanceFilter,
      values: performanceValues,
    }

    this.selectedFilters = this.selectedFilters.filter((filter: any) => filter.key !== 'performance');
    this.selectedFilters.push(finalPerformanceFilter);
  }

  public handleSliderChange(event) {
    const legends: any = document.getElementsByClassName("ng5-slider-tick-legend");
    for (let i = 0; i < legends.length; i++) {
      if (i === event - 1 && i !== 0 && i !== legends.length - 1) {
        legends[i].style.opacity = '1';
      } else {
        legends[i].style.opacity = '0';
      }
    }
  }

  public handleSyllabusClear() {
    this.selectedSyllabus = [];
    this.applySyllabusFilter();
  }

  public computeDifficultyFilter(allDifficultyFilters) {
    const difficultyFilter = this.filtersList.find((filter: any) => filter.key === 'questionDifficulty');

    const difficultyValues = allDifficultyFilters.map(({ key, displayName, isSelected, value }: any) => ({
      key,
      displayName,
      isSelected,
      value: `${value}`,
    }));

    const finalDifficultyFilter = {
      ...difficultyFilter,
      values: difficultyValues,
    }

    this.selectedFilters = this.selectedFilters.filter((filter: any) => filter.key !== 'questionDifficulty');
    this.selectedFilters.push(finalDifficultyFilter);
  }

  public isDifficultyDisabled(difficultyOptions, index) {
    if (difficultyOptions[index].isSelected) return false;

    const selectedOptions = difficultyOptions.filter((option: any) => option.isSelected === true)
      .map((option: any) => parseInt(option.value));
    const sum = selectedOptions.reduce((a: number, b: number) => a + b, 0);
    if (sum >= 100) return true;
    return false;
  }

  public getMaxDifficultyValue(difficultyOptions, index) {
    const selectedOptions = difficultyOptions.filter((option: any, i) => option.isSelected && i !== index)
      .map((option: any) => parseInt(option.value));
    const sum = selectedOptions.reduce((a: number, b: number) => a + b, 0);

    const remaining = 100 - sum;
    return remaining;
  }

  public handleDifficultyValueChange(event, allDifficultyFilters, index) {

    const max = this.getMaxDifficultyValue(allDifficultyFilters, index);
    if (parseInt(event.target.value) > max) {
      allDifficultyFilters[index].value = 0;
    }
    this.computeDifficultyFilter(allDifficultyFilters);
  }

  public clearSyllabusSearchQuery() {
    this.syllabusSearchQuery = '';
    this.handleSyllabusSearchEvent();
  }

  @HostListener('document:mousedown', ['$event'])
  dismissSyllabusDropdown(event): void {
    if (this.cardName !== 'testHistory') return;
    const c1 = !this.syllabusSearchRef.nativeElement.contains(event.target);
    const c2 = this.syllabusDropdownRef && !this.syllabusDropdownRef.nativeElement.contains(event.target)
    if (!c2) return;
    if (c1) this.setSyllabusSearchFocusMode(false);
  }

  ngOnDestroy() {
    this.resetButtonClicked = false;
  }
}
