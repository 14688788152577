import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-api-failed-for-cards',
  templateUrl: './api-failed-for-cards.component.html',
  styleUrls: ['./api-failed-for-cards.component.scss']
})
export class ApiFailedForCardsComponent implements OnInit {

  @Output() reloadApiError = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  reloadState(): void {
    this.reloadApiError.emit();
  }

}
