import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, merge } from 'rxjs';
import { LazyLoadFile, LazyLoadFileImpl } from '../shared.module';


@Injectable({
  providedIn: 'root'
})
export class LazyLoadingService {

  loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  lazyLoad(lazyLoadFiles: LazyLoadFile[]): Observable<any> {
    return merge(
      lazyLoadFiles.map(file => {
        if (file.type === 'stylesheet') {
          return this.loadStyle(file.url)
        }
        else if (file.type === 'script') {
          return this.loadScript(file.url);
        }
      })
    );
  }

  loadScript(url: string): Observable<void> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    // script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);
    return this.loadedLibraries[url].asObservable();
  }

  loadStyle(url: string): Observable<void> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this.loadedLibraries[url].asObservable();
  }
}