import * as ProformaActionList from './proforma.actions';
import { Proforma } from '../../models/proforma.model';

export interface State {
  data: Proforma;
}


const intialState: State = {
  data: new Proforma()
};

export function proformaReducer(
  state = intialState,
  action: ProformaActionList.ProformaActionList
): State {
  switch (action.type) {
    case ProformaActionList.UPDATE_PROFORMA_DETAIL:
      return {
        ...state,
        data: action.payload
      };
    case ProformaActionList.STUDENT_PROFORMA_STATUS:
      return {
        ...state,
        data: action.payload
      };
    case ProformaActionList.UPDATE_PROFORMA_STATUS:
      let payloadData = { ...state.data };

      if (action.payload['studentStatus']) {
        payloadData.student = {
          status: action.payload['studentStatus']
        };
      }

      if (action.payload['teacherStatus']) {
        payloadData.teacher = {
          ...state.data.teacher,
          status: action.payload['teacherStatus']
        };
      }
      if (action.payload['parentStatus']) {
        payloadData.parent = {
          ...state.data.parent,
          status: action.payload['parentStatus']
        };
      }
      return {
        ...state,
        data: payloadData
      };
      case ProformaActionList.UPDATE_PROFORMA_QUESTIONS:
        let questionPayload= {...state.data}
        questionPayload.questions = action.payload['questions']
        questionPayload.questionsStatus = action.payload['status']
        return {
          ...state,
          data: questionPayload
        }
    case ProformaActionList.UPDATE_PARENT_PROFORMA_STATUS:
      let parentPayload = { ...state.data }
      parentPayload.parent.status = action.payload['status'];
      parentPayload.parent.email = action.payload['email'];
      return {
        ...state,
        data: parentPayload
      }
    case ProformaActionList.UPDATE_TEACHER_PROFORMA_STATUS:
      let teacherPayload = { ...state.data }
      teacherPayload.teacher.status = action.payload['status'];
      teacherPayload.teacher.mobile = action.payload['mobile'];
      teacherPayload.teacher.email = action.payload['email'];
      return {
        ...state,
        data: teacherPayload
      }
    default:
      return {
        ...state
      };
  }
}
