<!-- BEGIN # MODAL LOGIN -->

<div class="modal fade casual-attempt-modal" id="warn-serious" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog custmofferpopup">
      <div class="modal-content bor-reuds ">
        <button type="button" (click)="resumeTest()" class="close" data-dismiss="modal"><img src="/assets/images/dark-close-ic.svg" alt="Close Icon"></button>
        <div class="modal-body custmofferpopup-pdzero">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img appLazyLoad src="/assets/images/glite-icon/casual-attempt-icon.svg" alt="Casual Attempt" *ngIf="pageLoaded==='solution'||pageLoaded==='test'" class="warning_img">
                  <h3 class="warning_header" *ngIf="pageLoaded==='test'">Casual Attempt!</h3>
                  <h3 class="warning_header" *ngIf="pageLoaded==='solution'">{{dataRecieved.warningData.heading}}</h3>
                  <p class="warning_msg" *ngIf="pageLoaded==='test'">You have attempted {{dataRecieved.totalQuestions}} questions in {{dataRecieved.totalTimeTaken | convertSeconds}} of time.
                    If you submit this test competitive analysis will not be done</p>
                    <p class="warning_msg" *ngIf="pageLoaded==='solution'">{{dataRecieved.warningData.text}}</p>
                </div>
                <div class="col-md-12 text-center d-flex justify-content-end" *ngIf="pageLoaded==='test'">
                  <a class="btn back_dashboard" (click)="submitTest()">Submit</a>
                  <button type="button" class="btn btn-primary submit-btn resume-btn" (click)="resumeTest()">Resume</button>
                </div>
                <div class="col-md-12 text-center d-flex justify-content-end" *ngIf="pageLoaded==='solution'">
                  <a class="btn back_dashboard" (click)="submitTest()">Continue to solution</a>
                  <button type="button" class="btn btn-primary submit-btn" (click)="closePopup()">BACK TO OVERVIEW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Offers popup end -->