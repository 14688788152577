import { Injectable } from '@angular/core';
import ClevertapReact from 'clevertap-react';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class CleverTapService {

  public hostDomain = window.location.host;
  public sourceUrl = window.location.href;

  constructor() { }

  formSubmission(data?): void {
    const payload = {
      'Source Url': window.location.href,
    };
    if (data) {
      payload['form submission intent'] = data.intent;
    }
    ClevertapReact.event('Form Submission', payload);
  }

  eventSignUp(data?): void {
    if (data) {
      const payload = {
        'sign up Intent': data.intent,
        'from referral': data.fromReferral ? data.fromReferral : 'No',
        'source url': window.location.href
      };
      ClevertapReact.event('Sign Up', payload);
    }
  }

  eventPageViewed(pageCategory): void {
    const payload = {};
    if (localStorage.getItem('isMobile')) {
      const urls = window.location.href.split('/');
      if (urls[4]) {
        payload['Page Name'] = urls[4].toString();
      }
      payload['Device Type'] = 'Android';
    } else {
      payload['page category'] = pageCategory;
      payload['source url'] = window.location.href;
    }
    ClevertapReact.event('page viewed', payload);
  }

  pushWindowProfile(dataReceived): void {
    const payloadProfile = {
        Site: {
            Phone: dataReceived.countryCode + '' + dataReceived.mobile,
            'referrer code' : dataReceived.referralCode ? dataReceived.referralCode : '',
            Class: dataReceived.className,
        }
    };
    // use for onuserlogin push not profile push
    window.clevertap.onUserLogin.push(payloadProfile);
  }
}

