export const LOGIN_ERROR = {
    wrong_mobile: 'This mobile no is not registered with us',
    wrong_password: 'Passwords does not match!',
    wrong_email: 'This email is not registered with us',
    field_required: 'This field is required',
};

export const OTP_ERROR = {
    wrong_otp: 'OTP entered is invalid'
};

export const LOGIN_ERROR_KEYWORDS = {
    user_name: 'username',
    password: 'password',
    mobile: 'mobile',
    email: 'email'
};
 
export const CART_WARNING = {
    MESSAGE: 'Updating Cart!' 
};