import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn:'root'
})
export class CourseCardService {

  constructor(private _http: HttpService) { }

  getPackageDetails(pid){
    return this._http.get(`api/v3/open/package/${pid}/details`, false, null, true, false, environment.serverURLv3);
  }

  getTestSyllabusDetails (payload: any) {
    let url=`v4/tests/${payload.testId}/details?courseId=${payload.courseId}&packageId=${payload.packageId}`
    if(payload.testId === '0'.repeat(24) && payload.scheduleId){
      url += `&scheduleID=${payload.scheduleId}`
    }
    return this._http.get(url, false, {}, true, false, environment.serverURLv4);
  }
}
