<div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ isPasswordSet ? 'Change Password' : 'Set Password' }}
        </h5>
        <button (click)="closeModalEvent()" type="button" class="close">
          <span aria-hidden="true">
            <img src="../../../../assets/images/cross-icon-modal.svg" />
          </span>
        </button>
      </div>
      <div class="modal-body">
        <p class="setPasswordTitle" *ngIf="!isPasswordSet">
          You have not set your password yet. Create a password to easily login, without OTPs or via social login.
        </p>
        <form class="change-password-form">
          <div *ngIf="!recoverPasswordViaOtp && isPasswordSet" class="form-group">
            <input [(ngModel)]="oldPassword" #oldPasswordFc="ngModel" [type]="displayOldPassword ? 'text' : 'password'"
              minlength="6" maxlength="20" name="oldPassword" placeholder="Enter Current Password" />
            <div (click)="togglePasswordDisplay(0)" class="eye-icon">
              <img *ngIf="!displayOldPassword" src="../../../../../assets/images/clarity_eye-hide-line.svg" />
              <img *ngIf="displayOldPassword" src="../../../../../assets/images/clarity_eye-line.svg" />
            </div>
            <div class="forget-password">
              <button (click)="forgotPassword()" type="button" class="btn">Forgot Password?</button>
            </div>
          </div>
          <div *ngIf="recoverPasswordViaOtp && isPasswordSet" class="form-group">
            <input [(ngModel)]="userMobileOtp" (input)="numberOnlyValidator($event)" type="text" minlength="4"
              maxlength="4" name="otp" placeholder="Enter OTP" />
            <div *ngIf="countDownTimer$ && (countDownTimer$ | async) !== '00'" class="enter-otp">
              00:{{countDownTimer$ | async}}
            </div>
            <div *ngIf="countDownTimer$ && (countDownTimer$ | async) == '00'" (click)="resendOtp()" class="resend-otp">
              Send OTP again
            </div>
            <p class="enterotp-text">
              Please enter the 4-digit OTP sent to your registered mobile/email to reset your password.
            </p>
          </div>
          <div class="form-group">
            <input [(ngModel)]="newPassword" (ngModelChange)="onPasswordChange($event)" #newPasswordFc="ngModel"
              [type]="displayNewPassword ? 'text' : 'password'" minlength="6" maxlength="20" name="password"
              placeholder="Enter a password" />
            <div (click)="togglePasswordDisplay(1)" class="eye-icon">
              <img *ngIf="!displayNewPassword" src="../../../../../assets/images/clarity_eye-hide-line.svg" />
              <img *ngIf="displayNewPassword" src="../../../../../assets/images/clarity_eye-line.svg" />
            </div>
          </div>
          <div class="form-group">
            <input [(ngModel)]="confirmPassword" #confirmPasswordFc="ngModel"
              [type]="displayConfirmPassword ? 'text' : 'password'" maxlength="20" name="confirmPassword"
              placeholder="Confirm password" />
            <div (click)="togglePasswordDisplay(2)" class="eye-icon">
              <img *ngIf="!displayConfirmPassword" src="../../../../../assets/images/clarity_eye-hide-line.svg" />
              <img *ngIf="displayConfirmPassword" src="../../../../../assets/images/clarity_eye-line.svg" />
            </div>
            <p *ngIf="confirmPassword && newPassword !== confirmPassword" class="weak-text">Password does not match!</p>
          </div>
          <div>
            <div *ngIf="(newPasswordFc.touched || newPasswordFc.dirty) && newPasswordFc.invalid" class="error">
              Password should be atleast 6 characters.
            </div>
          </div>
          <div class="password-strength">
            <span [ngClass]="getWeak()"></span>
            <span [ngClass]="getAverage()"></span>
            <span [ngClass]="getGood()"></span>
            <span [ngClass]="getStrong()"></span>
          </div>
          <p *ngIf="strength === 1" class="weak-text">Weak Password</p>
          <p *ngIf="strength === 2" class="good-text">Average Password</p>
          <p *ngIf="strength === 3" class="average-text">Good Password</p>
          <p *ngIf="strength === 4" class="strong-text">Strong Password</p>
          <div class="strength-helper-text">
            <ul>
              <li
                [ngClass]="newPassword.length > 0 ? newPassword.length > 10 ? 'strong-text' : 'weak-text' : 'default-text'">
                A strong password typically contains 10-12 characters
              </li>
              <li [ngClass]="newPassword.length > 0 ? strength != 4 ? 'weak-text' : 'strong-text' : 'default-text'">
                Use a combination of atleast 1 lower case, 1 upper case, 1
                number and 1 special character
              </li>
              <li
                [ngClass]="newPassword.length > 0 ? newPassword === 'password' || newPassword === 'Password' ? 'weak-text' : 'strong-text' : 'default-text'">
                Do not keep your username or words like “password” as your
                password
              </li>
            </ul>
          </div>

          <div class="captchadiv">
            <div class="captcha-container" *ngIf="captchaShow && forgotPasswordClicked">
              <div class="captcha" appCaptcha [config]="'qq'" [lang]="'en'" (captchaExpired)="captchaExpired()"
                (captchaResponse)="captchaValidationSuccess()"></div>
            </div>
          </div>
          <div class="error" *ngIf="showCaptchaError && !captchaChecked">
            <span class="captcha-error">Please select captcha</span>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button *ngIf="isPasswordSet" [disabled]="newPasswordFc.invalid || changePasswordBtnValidator()"
          (click)="changePassword()" type="button" class="btn btn-close">
          Change Password
        </button>

        <button *ngIf="!isPasswordSet"
          [disabled]="newPassword === '' || newPasswordFc.invalid || confirmPassword !== newPassword"
          (click)="setPassword()" type="button" class="btn btn-close">
          Set Password
        </button>
      </div>
    </div>
  </div>
</div>