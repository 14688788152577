import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, timer, Subscription } from 'rxjs';
import { map, scan, take } from 'rxjs/operators';

import * as fromApp from 'src/app/stores/app.reducers';
import * as AuthActions from 'src/app/stores/auth/auth.actions';

import { HttpService } from '../../services/http.service';
import { AlertService } from './../../services/alert.service';
import { UtilityService } from '../../services/utility.service';
import { CaptchaDirective } from 'src/app/shared/directives/captcha.directive';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {

  @ViewChild(CaptchaDirective) vc: CaptchaDirective;
  @Output() closeChangePass = new EventEmitter<any>();

  userData: any;
  isPasswordSet: boolean = true;
  enrollmentNumber: string;

  oldPassword = '';
  newPassword = '';
  confirmPassword = '';

  displayOldPassword: boolean = false;
  displayNewPassword: boolean = false;
  displayConfirmPassword: boolean = false;

  strength = 0;

  userMobileOtp = '';
  forgotPasswordClicked = false;
  recoverPasswordViaOtp: boolean = false;

  captchaChecked: boolean = false;
  captchaShow: boolean = false;
  showCaptchaError: boolean = false;

  countDownTimer$: Observable<any> = null;
  counterLimit: number = 30;

  private validators: any = {
    min: false,
    max: false,
    digits: false,
    lower: false,
    upper: false,
    nonWords: false
  };

  private responseResolverSubscription$: Subscription;

  constructor(
    private httpService: HttpService,
    private utility: UtilityService,
    private alertService: AlertService,
    private store: Store<fromApp.AppState>,
  ) { }

  ngOnInit(): void {
    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
      this.isPasswordSet = this.userData.isPasswordSet;
      this.enrollmentNumber = this.userData.enrollmentNumber;
    });
    this.initResponseResolver();
  }

  public togglePasswordDisplay(field: FieldTypes) {
    switch (field) {
      case FieldTypes.OLD_PASSWORD:
        this.displayOldPassword = !this.displayOldPassword;
        break;
      case FieldTypes.NEW_PASSSWORD:
        this.displayNewPassword = !this.displayNewPassword;
        break;
      case FieldTypes.CONFIRM_PASSWORD:
        this.displayConfirmPassword = !this.displayConfirmPassword;
        break;
    }
  }

  public changePassword() {
    let payload = {
      password: this.newPassword,
      confirmPassword: this.confirmPassword
    };
    if (this.recoverPasswordViaOtp) {
      this.verifyOTP();
    } else {
      payload['oldPassword'] = this.oldPassword;
      this.httpService.put('v4/auth/users/password', true, payload).subscribe((res) => {
        if (res && res.code === 200) {
          this.alertService.showSuccessMessage('Password Changed Successfully.');
          this.closeModalEvent();
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    }
  }

  setPassword(): void {
    const payload = {
      password: this.newPassword,
      confirmPassword: this.confirmPassword,
    };
    this.httpService.post('v4/auth/reset-password', true, payload).subscribe((res) => {
      if (res && res.code === 200) {
        this.alertService.showSuccessMessage('Password Set Successfully.');
        this.isPasswordSet = true;
        this.closeModalEvent();
      } else {
        this.alertService.showErrorMessage(res.message);
      }
    });
  }

  public forgotPassword() {
    this.forgotPasswordClicked = true;
    this.checkIfCaptchaShow();
    this.initCountDownTimer();
  }

  public sendOTP() {
    let token;
    if (this.captchaShow && this.vc) {
      token = this.vc.getResponse();
    }
    const body = {
      captchaToken: token
    };
    this.httpService.post('v4/auth/forgot-password', true, body).subscribe((res) => {
      if (res && res.code === 200) {
        this.forgotPasswordClicked = false;
        this.recoverPasswordViaOtp = true;
      }
    });
  }

  verifyOTP(): void {
    const body = {
      device: 'desktop',
      osType: 'web',
      otp: this.userMobileOtp
    };
    this.httpService.post('v4/auth/verify-forgot-password', true, body).subscribe((res) => {
      if (res && res.code === 200) {
        localStorage.setItem('authToken', res.data.authToken);
        sessionStorage.setItem('authToken', res.data.authToken);
        new AuthActions.SetToken(res.data.authToken);
        new AuthActions.SetAuthentication();
        this.setPassword();
      } else {
        this.alertService.showErrorMessage(res.message);
      }
    });
  }

  public captchaExpired(): void {
    if (this.vc) {
      this.vc.reset();
    }
    this.captchaChecked = false;
  }

  public captchaValidationSuccess(): void {
    this.captchaChecked = true;
    this.showCaptchaError = false;
    this.recoverPasswordViaOtp = true;
    this.initCountDownTimer();
    this.sendOTP();
  }

  public checkIfCaptchaShow(): void {
    this.utility.fetchCaptchaDetails().then(res => {
      if (res) {
        if (res && res.code === 200) {
          this.captchaShow = res.data.showCaptcha;
        }
        if (!this.captchaShow) {
          this.recoverPasswordViaOtp = true;
          this.sendOTP();
        }
      }
    });
  }

  private initCountDownTimer() {
    this.countDownTimer$ = timer(0, 1000).pipe(
      scan(acc => --acc, this.counterLimit),
      map(acc => acc < 10 ? (`0${acc}`) : acc),
      take(this.counterLimit)
    );
  }

  public resendOtp() {
    this.checkIfCaptchaShow();
    this.forgotPasswordClicked = true;
  }

  public changePasswordBtnValidator() {
    let condition = this.newPassword === '' || this.confirmPassword !== this.newPassword;
    if (this.recoverPasswordViaOtp) {
      condition = condition || this.userMobileOtp.length !== 4;
    } else {
      condition = condition || this.oldPassword === '' || (this.forgotPasswordClicked && !this.captchaChecked);
    }
    return condition;
  }

  private onPasswordChangeSuccess() {
    this.alertService.showSuccessMessage('Your password has been successfully changed!');
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.userMobileOtp = '';
    this.recoverPasswordViaOtp = false;
    this.isPasswordSet = true;
  }

  private onPasswordChangeFailure() {
    if (this.recoverPasswordViaOtp) {
      this.alertService.showErrorMessage('Invalid OTP');
      this.userMobileOtp = '';
    } else {
      this.alertService.showErrorMessage('Incorrect old password. Please retry.');
      this.oldPassword = '';
    }
  }

  private initResponseResolver() {
    this.responseResolverSubscription$ = this.utility.changePasswordResolver.subscribe(res => {
      switch (res.type) {
        case 'change-password':
          if (res.success) this.onPasswordChangeSuccess();
          else this.onPasswordChangeFailure();
          break;
      }
    });
  }

  public numberOnlyValidator(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      this.userMobileOtp = event.target.value;
    }
  }

  public closeModalEvent() {
    this.captchaExpired();
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.userMobileOtp = '';
    this.strength = 0;
    this.recoverPasswordViaOtp = false;
    this.countDownTimer$ = null;
    this.closeChangePass.emit();
  }

  onPasswordChange(password): void {
    const c = this.getColor(
      this.measureStrength(password)
    );
    this.strength = c;
  }

  public measureStrength(pass: string): number {
    let score = 0;
    // award every unique letter until 5 repetitions
    const letters = {};
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
      score += letters[pass[i]];
    }
    // bonus points for mixing it up
    const variations = {
      min: pass.length >= 6,
      max: pass.length >= 10,
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    };
    let variationCount = 0;
    // tslint:disable-next-line: forin
    for (const check in variations) {
      variationCount += variations[check] ? 1 : 0;
      this.validators[check] = variations[check];
    }
    // tslint:disable-next-line: no-string-literal
    if (!variations['min']) {
      variationCount = 0;
    }
    return variationCount;
  }

  private getColor(score: number): number {
    let idx = 0;
    if (score === 6) {
      idx = 4;
    } else if (score >= 4) {
      idx = 3;
    } else if (score === 3) {
      idx = 2;
    } else if (score === 2) {
      idx = 1;
    }
    return idx;
  }

  getWeak(): any {
    const colorBar = { default: false, weak: false, good: false, average: false, strong: false };
    switch (this.strength) {
      case 0:
        colorBar.default = true;
        break;
      case 1:
        colorBar.weak = true;
        break;
      case 2:
        colorBar.average = true;
        break;
      case 3:
        colorBar.good = true;
        break;
      case 4:
        colorBar.strong = true;
        break;
      default:
        colorBar.default = true;
    }
    return colorBar;
  }

  getAverage(): any {
    const colorBar = { default: false, weak: false, good: false, average: false, strong: false };
    switch (this.strength) {
      case 0:
        colorBar.default = true;
        break;
      case 2:
        colorBar.average = true;
        break;
      case 3:
        colorBar.good = true;
        break;
      case 4:
        colorBar.strong = true;
        break;
      default:
        colorBar.default = true;
    }
    return colorBar;
  }

  getGood(): any {
    const colorBar = { default: false, weak: false, good: false, average: false, strong: false };
    switch (this.strength) {
      case 0:
        colorBar.default = true;
        break;
      case 3:
        colorBar.good = true;
        break;
      case 4:
        colorBar.strong = true;
        break;
      default:
        colorBar.default = true;
    }
    return colorBar;
  }

  getStrong(): any {
    const colorBar = { default: false, weak: false, good: false, average: false, strong: false };
    switch (this.strength) {
      case 0:
        colorBar.default = true;
        break;
      case 4:
        colorBar.strong = true;
        break;
      default:
        colorBar.default = true;
    }
    return colorBar;
  }

  ngOnDestroy(): void {
    try {
      this.responseResolverSubscription$.unsubscribe();
    } catch (error) {
      // Do nothing
    }
  }
}

enum FieldTypes {
  OLD_PASSWORD, NEW_PASSSWORD, CONFIRM_PASSWORD
}
