import * as AuthActionList from './auth.actions';

export interface State {
    token: string;
    authenticated: boolean;
    isSignedUp: boolean;
    isMobile: boolean;
}
const intialState: State = {
    token: sessionStorage.getItem('authToken') ? sessionStorage.getItem('authToken') : localStorage.getItem('authToken'),
    authenticated: localStorage.getItem('authToken') || sessionStorage.getItem('authToken') ? true : false,
    isSignedUp: localStorage.getItem('authToken') || sessionStorage.getItem('authToken') ? true : false,
    isMobile: false,
};

export function authReducer(state = intialState, action: AuthActionList.AuthActionList): State{
    switch (action.type) {

        case AuthActionList.SET_AUTHTENTICATION:
        return {
            ...state,
            authenticated: true
        };
        case AuthActionList.SIGNIN:
        return {
            ...state,
            authenticated: true
        };
        case AuthActionList.SIGNUP:
        return {
            ...state,
            isSignedUp: true
        };

        case AuthActionList.LOGOUT_SUCCESS:
            return {
                ...state,
                authenticated: false,
                token: ''
        };
        case AuthActionList.SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case AuthActionList.SET_DEVICE_USED:
            return {
                ...state,
                isMobile: action.payload === 'mobile' ? true : false
            };
        default:
            return state;
    }
}