<div class="syllabus-container">
    <div class="syllabus-header">
        <div>
            <h3>Your Syllabus</h3>
            <p>Test Type: <b>{{testType}}</b></p>
        </div>
        <div class="syllabus-header-right">
            <div class="button-left" [ngClass]="{'active-button': syllabusLevel === 'concepts'}"
                (click)="changeSyllabusLevel('concepts')">
                Concepts
            </div>
            <div class="button-right" [ngClass]="{'active-button': syllabusLevel === 'chapters'}"
                (click)="changeSyllabusLevel('chapters')">
                Chapters
            </div>
        </div>
    </div>
    <ng-container *ngFor="let subject of testDetails?.syllabus?.subjectsSyllabus;let i=index">
        <a [ngStyle]="{'background': subject.backgroundColor}" #subjects class="subject" data-toggle="collapse" href="#collapse-{{i}}" aria-expanded="true">
            <div class="subject-content">
                <img [src]="subject?.icon?.web" [alt]="subject.name" />
                <div class="subject-name">
                    <b>{{subject.name}}</b>
                </div>
            </div>
            <div class="subject-content">
                <p><span>{{subject.chapters.length}}</span> Chapter{{subject.chapters.length === 1 ? '': 's'}}</p>
                <span class="divider"></span>
                <p><span>{{subject.conceptCount}}</span> Concept{{subject.conceptCount === 1 ? '': 's'}}</p>
            </div>
        </a>
        <div #chaptersCollapse id="collapse-{{i}}" class="collapse show">
            <div class="collapse-card">
                <div [ngStyle]="{'cursor': syllabusLevel === 'chapters' ? 'pointer': ''}" class="chapter" *ngFor="let chapter of subject.chapters;let j=index">
                    <a #chapters [attr.data-toggle]="syllabusLevel === 'chapters' ? 'collapse' : ''" [attr.data-target]="'#collapse-chapter-'+i+'-'+j">
                        <div class="chapter-content">
                            <img src="/assets/images/myPlan/add-icon.svg" alt="plus" *ngIf="syllabusLevel === 'chapters'" />
                            <p>
                                {{j+1}}. {{chapter.name}}<span>({{chapter.concepts.length}})</span>
                            </p>
                        </div>
                        <div #conceptsCollapse id="collapse-chapter-{{i}}-{{j}}" class="collapse concept-container">
                            <ol [ngClass]="(syllabusLevel === 'chapters') ? 'concept-container_chapter' : 'concept-container_concept'">
                                <li class="concept" *ngFor="let concept of chapter.concepts;let k=index">
                                    <p>
                                        {{j+1}}.{{k+1}}
                                    </p>
                                    <p class="conceptName">
                                        {{concept.name}}
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>