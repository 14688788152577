<div class="dateRangeFilter">
    <div class="inputPosition">
        <input angular-mydatepicker [(ngModel)]="fromDateValue" [options]="myDatePickerOptions" id="dateFrom"
            #fromDate="angular-mydatepicker" placeholder="DD/MM/YYYY" (dateChanged)="onDateChanged($event, 'from')" (keypress)="onKeyPress($event)" (keyup)="onkeyUp($event)"/>
        <span (click)="fromDate.toggleCalendar()" class="calendarIcon fromdate">
            <img src="../../../../assets/images/calendarIcon.svg">
        </span>
    </div>
    <div class="to">to</div>
    <div class="inputPosition1">
        <input angular-mydatepicker [(ngModel)]="toDateValue" [options]="myDatePickerEndDateOptions" [disabled]="!(fromDateEntered || fromDateValue)" id="dateTo"
            #toDate="angular-mydatepicker" placeholder="DD/MM/YYYY" (dateChanged)="onDateChanged($event, 'to')" (keypress)="onKeyPress($event)" (keyup)="onkeyUp($event)"/>
        <span (click)="toDate.toggleCalendar()" class="calendarIcon todate">
            <img src="../../../../assets/images/calendarIcon.svg">
        </span>
    </div>
</div>
<p class="error-text" *ngIf="((fromDateValue && toDateValue && compareDates()) || displayErrorMsg)">
    Please enter a valid date range
</p>
<p class="error-text" *ngIf="(fromDateValue && toDateValue === undefined)">
    Please enter the end date
</p>
<p class="error-text" *ngIf="(fromDateValue === undefined && toDateValue)">
    Please enter the start date
</p>
