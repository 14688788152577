import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAngularMyDpOptions, IMyDate, IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {

  @Input() filter;
  @Input() showValue;
  @Output() dateInfo = new EventEmitter();

  todayDate = new Date();
  fromDateValue: IMyDateModel = undefined;
  toDateValue: IMyDateModel = undefined;
  dateInit: IMyDate = {
    day: 0,
    month: 0,
    year: 0
  }
  fromDateEntered = false;
  toDateEntered = false;
  displayErrorMsg = false;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    disableSince: {...this.dateInit}
  };

  public myDatePickerEndDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    disableSince: {...this.dateInit},
    disableUntil: {...this.dateInit},
  };

  constructor(
    private datePipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.filter.values.forEach(value => {
      if (!value.hasOwnProperty('singleDate')) {
        if (value.value) {
          const dateString = value.value.split('T').join(' ').split('.')[0] + ' +0530';
          const dateValue = new Date(dateString);
          value['singleDate'] = {
            date: {
              year: dateValue.getFullYear(),
              month: dateValue.getMonth() + 1,
              day: dateValue.getDate()
            },
            formatted: this.datePipe.transform(dateValue,'dd/MM/yyyy'),
            epoc: dateValue.getTime(),
            jsDate: dateValue
          };
        }
      }
    });
    if (this.filter.values.find((obj) => obj.singleDate) !== undefined) {
      const date = new Date(this.filter.values[0].value);
      this.fromDateValue = {
        isRange: false,
        dateRange: null,
        singleDate: {
          date: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          },
          formatted: this.datePipe.transform(date,'DD/MM/YYYY'),
          epoc: date.getTime(),
          jsDate: date
        }
      };
      this.toDateValue = {
        isRange: false,
        dateRange: null,
        singleDate: this.filter.values[1].singleDate
      };
    }
  }

  ngOnChanges() {
    if (this.showValue) {
      setTimeout(() => {
        this.fromDateValue = undefined;
        this.fromDateEntered = false;
      }, 10);
      this.toDateValue = undefined;
      this.toDateEntered = false;
      this.displayErrorMsg = false;
      this.resetDisabledDates();
    }
  }

  resetDisabledDates() {
    this.myDatePickerOptions.disableSince.day = 0;
    this.myDatePickerOptions.disableSince.month = 0;
    this.myDatePickerOptions.disableSince.year = 0;
  }

  onDateChanged(dateValue: IMyDateModel, type) {
    switch (type) {
      case 'from':
        this.fromDateValue = dateValue;
        break;
      case 'to':
        this.toDateValue = dateValue;
    }

    if ((this.fromDateValue === null || this.toDateValue === null)) {
      this.displayErrorMsg = true;
    } else {
      this.displayErrorMsg = false;
    }
    
    const today = dateValue.singleDate.jsDate;
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const dt =
    yesterday.getFullYear() +
      '-' +
      (yesterday.getMonth() < 10
        ? '0' + yesterday.getMonth()
        : yesterday.getMonth()) +
      '-' +
      (yesterday.getDate() < 10
        ? '0' + yesterday.getDate()
        : yesterday.getDate());
    if (type === 'from') {
      const disablefromDate = dt.split('-');
      this.myDatePickerEndDateOptions.disableUntil.day = parseInt(disablefromDate[2]);
      this.myDatePickerEndDateOptions.disableUntil.month = parseInt(disablefromDate[1]) + 1;
      this.myDatePickerEndDateOptions.disableUntil.year = parseInt(disablefromDate[0]);
    } else {
      const disablefromDate = dt.split('-');
      this.myDatePickerOptions.disableSince.day = parseInt(disablefromDate[2]) + 2;
      this.myDatePickerOptions.disableSince.month = parseInt(disablefromDate[1]) + 1;
      this.myDatePickerOptions.disableSince.year = parseInt(disablefromDate[0]);
    }

    if (this.fromDateValue && this.toDateValue && this.fromDateValue.singleDate.jsDate <= this.toDateValue.singleDate.jsDate) {
      let dateTemp = new Date(this.fromDateValue.singleDate.jsDate)
      dateTemp.setDate(dateTemp.getDate()-1)
      this.filter.values[0].value = this.datePipe.transform(dateTemp,'yyyy-MM-dd') + 'T18:30:00.000Z';
      this.filter.values[1].value = this.datePipe.transform(this.toDateValue.singleDate.jsDate,'yyyy-MM-dd') + 'T18:29:59.000Z';
      this.filter.values[0].isSelected = true;
      this.filter.values[1].isSelected = true;
    }
    this.dateInfo.emit(this.filter);
  }

  compareDates() {
    if (this.fromDateValue && this.toDateValue) {
      if (this.fromDateValue.singleDate.jsDate > this.toDateValue.singleDate.jsDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onkeyUp(event) {
    const fromDateStr = (document.getElementById('dateFrom') as HTMLInputElement).value;
    const toDateStr = (document.getElementById('dateTo') as HTMLInputElement).value;

    if (fromDateStr === ''){
      this.fromDateValue = undefined;
      this.myDatePickerEndDateOptions.disableSince.day = 0;
      this.myDatePickerEndDateOptions.disableSince.month = 0;
      this.myDatePickerEndDateOptions.disableSince.year = 0;
      this.myDatePickerEndDateOptions.disableUntil.day = 0;
      this.myDatePickerEndDateOptions.disableUntil.month = 0;
      this.myDatePickerEndDateOptions.disableUntil.year = 0;
    }
    if (toDateStr === ''){
      this.toDateValue = undefined;
      this.resetDisabledDates();
    }
    
    if ((this.fromDateValue == null || this.toDateValue == null) && !(fromDateStr === '' || toDateStr === '')) {
      this.displayErrorMsg = true;
    } else {
      this.displayErrorMsg = false;
    }
    
    if (this.fromDateValue){
      let dateTemp = new Date(this.fromDateValue?.singleDate.jsDate)
      dateTemp.setDate(dateTemp.getDate()-1)

      this.filter.values[0].value = this.datePipe.transform(dateTemp,'yyyy-MM-dd') + 'T18:30:00.000Z';
      this.filter.values[0].isSelected = true;
    } else {
      this.filter.values[0].isSelected = false;
    }
    if (this.toDateValue){
      this.filter.values[1].value = this.datePipe.transform(this.toDateValue?.singleDate.jsDate,'yyyy-MM-dd') + 'T18:29:59.000Z';
      this.filter.values[1].isSelected = true;
    } else {
      this.filter.values[1].isSelected = false;
    }
    this.dateInfo.emit(this.filter);
  }

  onKeyPress(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 & '/'
    if (charCode < 47 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
