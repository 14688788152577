import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-testimonial-home',
  templateUrl: './testimonial-home.component.html',
  styleUrls: ['./testimonial-home.component.scss']
})
export class TestimonialHomeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    // Testimonials students
    $('.home-testimonial').owlCarousel({
      nav: true,
      loop: false,
      touchDrag : false,
      mouseDrag: false,
      items:1,
      navText: ["<img src='/assets/images/new-home/nextIcon.svg' />","<img src='/assets/images/new-home/nextIcon.svg' />"],
      responsiveClass: true,
    });
  }

}
