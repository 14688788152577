<div class="underDevelopement">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="logo d-block d-sm-none">
                    <img src="/assets/images/logo-blue.svg" alt="mYPAT">
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center flex-column-reverse flex-md-row ipad">
            <div class="col-sm-6">
                <div class="content">
                    <div class="logo d-none d-sm-block">
                        <img src="/assets/images/logo-blue.svg" alt="mYPAT">
                    </div>
                    <h2 class="heading">We are Working on it.</h2>
                    <p class="sub-heading">App is under development</p>
                    <div class="text-left">
                        <a href="javascript:void(0);" class="btn btn-primary submit-btn" (click)="logout()">Okay, Got it</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="connectionImg">
                    <img src="/assets/images/under-development.svg" alt="404">
                </div>
            </div>
        </div>
    </div>
</div>
