<div class="modal fade bs-example-modal-lg myplan-popup" data-backdrop="static" data-keyboard="false" id='myplan-popup'>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <img src="/assets/img/myplanicon.svg" />
        <h2>Go to myPlan</h2>
        <p>Please go to myPlan to start your training</p>
        <button class="btn btn-primary" (click)="dismissModal()">Got it</button>
      </div>
    </div>
  </div>
</div>