import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public updateWorkingOnHomePage = new BehaviorSubject<any>({});
  constructor(private http: HttpService, private alert: AlertService) { }

  getNotificationNumber(): any {
    return new Promise((resolve, reject) => {
      return this.http.get('getNotificationCount', true, null, false, false, environment.serverURLv3).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getAllNotificationFunc(page): any {
    return new Promise((resolve, reject) => {
      const params = { page };
      return this.http.get('getNotifications', true, params, false, false, environment.serverURLv3).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  markAllNotificationRead(notificationIds): any {
    return new Promise((resolve, reject) => {
      return this.http.post('markAllNotificationRead', true, { notificationIds }, false, false,
       false, environment.serverURLv3).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  setNotificationToRead(id): any {
    return new Promise((resolve, reject) => {
      const params = {
        notificationId: String
      };
      params.notificationId = id;
      return this.http.post('setNotificationStatus', true, params, false, false, false, environment.serverURLv3).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }
}
