import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-free-trial-expiry',
  templateUrl: './free-trial-expiry.component.html',
  styleUrls: ['./free-trial-expiry.component.scss']
})
export class FreeTrialExpiryComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToBuyCourses(): void {
    this.router.navigate(['home/buy-course']);
  }

}
