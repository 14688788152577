<div class="modal fade lite-modal" tabindex="-1" id="ebookDownload" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
          <button type="button" class="close" (click)="handlePopUpClose()"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
          <div class="modal-body pb-40">
            <form class="viewOurResultsForm" [formGroup]="ebookDownloadForm">
                <h3 class="text-center mt-0">{{popUpHeading}}</h3>
                <div class="form-group">
                  <label>Full Name</label>
                  <div class="inputGroup">
                      <input type="text" [ngClass]="{'error-input': errorInfo.name_error}" class="form-control" placeholder="Enter your name" formControlName="name" (input)="handleNameValue()"/>
                      <img class="user-img" src="/assets/images/email.svg" alt="User"/>
                  </div>
                  <span class="error-msg" *ngIf="errorInfo.name_error">{{errorInfo.no_name}}</span>
                </div>
                <div class="form-group">
                  <label>Class</label>
                  <div #dropdown class="dropdown classDropdown" [ngClass]="{'error-input': errorInfo.class_error}">
                      <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="handleDropdownState()">
                          {{selectedClass.class}}
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" role="button" *ngFor="let class of classesDropdown" (click)="selectClass(class)">{{class.class}}</a>
                      </div>
                  </div>
                  <span class="error-msg" *ngIf="errorInfo.class_error">{{errorInfo.no_class}}</span>
                </div>
                <div class="form-group">
                  <label>Mobile No.</label>
                  <div class="inputGroup country-dropDown" [ngClass]="{'error-input': errorInfo.mobile_error}" #mobileContainer>
                    <div class="select-countrycode-dropdown" *ngIf="ebookDownloadForm.value.mobile" (click)="addNewClass()">
                      <div class="countrycode-dropdown-toggle" data-toggle="dropdown">
                          <img src="/assets/images/country-flags/{{selectedDialCode.flag}}.png" [alt]="selectedDialCode.name"/>{{selectedDialCode.dialCode}}
                          <img src="/assets/images/new-home/gray_drop.png">
                      </div>
                      <ul class="dropdown-menu" role="menu">
                          <li class="dropdown-item" *ngFor="let country of countryData" (click)="selectCountryCode(country)"><img src="../../../assets/images/country-flags/{{country.flag}}.png" [alt]="country.name">{{country.dialCode}}</li>
                      </ul>
                  </div>
                      <input type="text" placeholder="Enter Mobile No." class="form-control pl-3" formControlName="mobile" (input)="handleMobileNumber($event)"/>
                  </div>
                  <span class="error-msg" *ngIf="errorInfo.mobile_error">{{errorInfo.invalid_number}}</span>
                </div>
                <div class="captcha-container" *ngIf="captchaInfo.showCaptcha">
                  <div class="captcha" appCaptcha [config]="'qq'" [lang]="'en'" (captchaExpired)="captchaExpired()"
                      (captchaResponse)="getSuccess()"></div>
                </div>
                <div class="error" *ngIf="captchaInfo.showCaptchaError && !captchaInfo.captchaChecked"><span class="captcha-error">Please select captcha</span></div>  
              <div class="verifyOTPFormBottom">
                <button type="button" class="btn btn-primary btn-rounded btn-block" [ngClass]="{'disabled': !ebookDownloadForm?.value?.name || !ebookDownloadForm?.value?.mobile || ebookDownloadForm?.value?.mobile?.length !== validMobileNumberLength || selectedClass._id === '00'}" (click)="enableCaptcha()">{{CTATitle}}</button>
              </div>
            </form>
          </div>
      </div>
  </div>
</div>

<!-- Verify OTP -->
<app-otp-verify-modal *ngIf="enableVerifyOTPPopup" [mobileInfo]="{mobileNumber: ebookDownloadForm.value.mobile, selectedDialCode: selectedDialCode}"></app-otp-verify-modal>
<!-- Verify OTP -->