import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auto-proctor-dialog',
  templateUrl: './auto-proctor-dialog.component.html',
  styleUrls: ['./auto-proctor-dialog.component.scss']
})
export class AutoProctorDialogComponent implements OnInit {

  @Input() dataPassed: any;
  @Input() proctoredGeneratedKey: any;
  @Output() closeProctorModal = new EventEmitter<any>();
  @Output() checkProctorTestResume = new EventEmitter<any>();
  @Output() startYourTest = new EventEmitter<any>();
  public proctor3CheckBox: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  closeProctor(num): void {
    if (num === 3) {
      if (this.proctor3CheckBox) {
        this.closeProctorModal.emit(num);  
      }
    } else {
      this.closeProctorModal.emit(num);
    }
  }

  checkBoxCheck(event): void {
  }

  checkForResume(): void {
    this.checkProctorTestResume.emit();
  }

  startTestClick(): void {
    this.startYourTest.emit();
  }

}
