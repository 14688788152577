<!-- info popup -->
<div class="modal fade lite-modal" id="infoPopup" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
        <div class="modal-body">
          <!-- imageName: trickyQuestion, toughQuestion, attemptPercent, averageTime, scoringQuestion, trickyQuestion -->
          <div class="headSec">
            <img src={{imgSrc}} alt="{{heading}}">
            <h3>{{heading}}</h3>
            <p class="smallText">{{text}}</p>
          </div>
        </div>
        <div class="footer">
          <button type="button" class="btn btn-primary btn-rounded btn-block" data-dismiss="modal">Got it</button>
        </div>
      </div>
    </div>
  </div>