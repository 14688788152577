const HEADERMENU = {
    CLASS_LIST: 'classList',
    EXAM_LIST: 'examList',
    RESOURCES_LIST: 'resourcesList',
    CLASS9: 'class9',
    CLASS10: 'class10',
    CLASS11: 'class11',
    CLASS12: 'class12',
    CLASS12_PASS: 'class12pass',
    TOOLS: 'tools',
    PREVIOUS_PAPER: 'previousPaper',
    SAMPLE_PAPER: 'samplePaper',
    BLOG: 'blog',
    EBOOKS: 'ebooks',
    COMMUNITY: 'community'
}

export default HEADERMENU;