import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[onSectionVisibility]'
})
export class DetectVisibilityDirective implements OnDestroy {
  observer:IntersectionObserver;
  isLoaded:boolean = false;
  @Output() onSectionVisibility = new EventEmitter<boolean>();
  constructor(@Inject(DOCUMENT) private readonly document:Document, private elRef: ElementRef) {
    const options = {
      // root: this.document,
      rootMargin: '0px',
      threshold: 1.0
    }
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      entries?.every(entry => {
        if(entry.isIntersecting && !this.isLoaded){
          this.isLoaded = true;
          this.onSectionVisibility.emit(true);
          return false;
        }
        return true;
      })
    };
    this.observer = new IntersectionObserver(intersectionCallback, options);
    const target = this.elRef?.nativeElement;
    target && this.observer?.observe(target);
  }
  ngOnDestroy(): void {
    this.observer?.disconnect?.();
  }
}