export class UserProfile {
    userId: string;
    name: string;
    userName: string;
    isPasswordSet: any;
    userMobile: string;
    countryCode: string;
    email: string;
    dateOfBirth: string;
    userGoals: any[];
    userClass: any;
    userCareers: any[];
    userLocation: {
        longitute: string;
        latitute: string;
        address: {
            pin: string;
            country: string;
            state: string;
            city: string;
            secondLine: string;
            firstLine: string;
        };
    };
    partner: {
        partnerInstitute: string,
        isPartner: any,
        isVerified: boolean,
        userName: string,
        partnerGoal: any[];
    };
    enrollmentNumber: string;
    webAccessCode: string;
    school: {
        country: any;
        state: any;
        city: any;
        id: string;
        name: string;
        schoolIdUrl: string;
    };
    photoUrlThumb: string;
    photoUrl: string;
    isFollowing: any;
    isFollowed: any;
    packageBought: any[];
    goalDDCount: any;
    tourStatus: any;
    referralCode: any;
    parentAccessToken: any;
    blockData: {
        isBlocked: string;
        blockReason: string;
        endTime: string;
        startTime: string;
    };
    isStepUser: boolean;
    refferedByCode: string;
    orgCentre: any;
    isFiitjee: any;
    isSchool: any;
    mobileIsVerified: boolean;
    emailIsverified: boolean;
    followerCount: number;
    followingCount: number;
    initials: string;
    initialsClass: string;
    userCentres: any;
    fiitjeeEnrollmentNumber: any;
    isNCRPUser: boolean;
    featurePermissions: any;
    isTeacher: boolean;
    isAdmissionTestUser: boolean;
    admissionTestDetail: any;
    hasActivePackage:boolean;
    onSpotRegNo: string;
    fiitjeeFacultyHiring: boolean | string;
    constructor(
        userId?, name?, userName?, userMobile?,countryCode?, email?,dateOfBirth?, userGoals?, userClass?, userCareers?,
        userLocation?, partner?, enrollmentNumber?, webAccessCode?,
        school?, photoUrlThumb?, photoUrl?, isFollowing?, isFollowed?, packageBought?,
        goalDDCount?, isPasswordSet?, tourStatus?, referralCode?, parentAccessToken?,
        remarkData?, refferedByCode?, orgCentre?, isFiitjee?, isSchool?, mobileIsVerified?, emailIsverified?,
        followerCount?, followingCount?, initials?, initialsClass?, userCentres?, fiitjeeEnrollmentNumber?,
        isNCRPUser?, featurePermissions?, isTeacher?, isAdmissionTestUser?, admissionTestDetail?, hasActivePackage?,
        onSpotRegNo?,
        fiitjeeFacultyHiring?
        ) {

        this.userId = userId || '';
        this.name = name || '';
        this.userName = userName || '';
        this.userMobile = userMobile || '';
        this.countryCode = countryCode || '';
        this.email = email || '';
        this.dateOfBirth=dateOfBirth || '';
        this.userGoals = userGoals || [];
        this.userClass = userClass || {};
        this.userCareers = userCareers || [];
        this.isPasswordSet = isPasswordSet || false;
        this.userLocation = userLocation || {};
        this.partner = partner || {};
        this.enrollmentNumber = enrollmentNumber || '';
        this.webAccessCode = webAccessCode || '';
        this.isFollowing = isFollowing || false;
        this.isFollowed = isFollowed || false;
        this.school = school || {};
        this.photoUrlThumb = photoUrlThumb || '';
        this.photoUrl = photoUrl || '';
        this.packageBought = packageBought || [];
        this.goalDDCount = goalDDCount || 0;
        this.tourStatus = tourStatus || { assignment: 'pending', dashboard: 'pending', myTest: 'pending' };
        this.referralCode = referralCode || '';
        this.parentAccessToken = parentAccessToken || '';
        this.isStepUser = false;
        this.blockData = remarkData || {};
        this.refferedByCode = refferedByCode || '';
        this.orgCentre = orgCentre || null;
        this.isFiitjee = isFiitjee || false;
        this.isSchool = isSchool || false;
        this.mobileIsVerified = mobileIsVerified || false;
        this.emailIsverified = emailIsverified || false;
        this.followerCount = followerCount || 0;
        this.followingCount = followingCount || 0;
        this.initials = initials || '';
        this.initialsClass = initialsClass || '';
        this.userCentres = userCentres || null;
        this.fiitjeeEnrollmentNumber = fiitjeeEnrollmentNumber || '';
        this.isNCRPUser = isNCRPUser || false;
        this.featurePermissions = featurePermissions || {};
        this.isTeacher = isTeacher || false;
        this.isAdmissionTestUser = isAdmissionTestUser || false;
        this.admissionTestDetail = admissionTestDetail || {};
        this.hasActivePackage = hasActivePackage || false;
        this.onSpotRegNo = onSpotRegNo || '';
        this.fiitjeeFacultyHiring = !!fiitjeeFacultyHiring;
    }
}

