<!-- <li class="list-group-item">{{filters.filterKey}}</li> -->
<h4 class="filterHeading">{{filters && filters.filterKey}}</h4>
<div class="card filterCard">  
  <ul>
    <li class="list-group-item" *ngFor="let key of filters.filterValues">
      <label class="container">{{key.key}}
        <input type="checkbox" name="cBoxes" [(ngModel)]="key.isChecked" (change)="change($event.target.checked,filters && filters.filterKey,key)">
        <span class="checkmark"></span>
      </label>
    </li>
  </ul>
</div>