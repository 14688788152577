import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SolutionService } from 'src/app/test-results-analytics/services/solution.service';
import { AlertService } from '../../services/alert.service';
import { UtilityService } from '../../services/utility.service';
declare var $:  any;

@Component({
  selector: 'app-tag-creator-shared',
  templateUrl: './tag-creator-shared.component.html',
  styleUrls: ['./tag-creator-shared.component.scss']
})
export class TagCreatorSharedComponent implements OnInit {
  @ViewChild('closeTagModal') closeTagModal: ElementRef;
    @Input('tagName') tagName: string;
    @Input("tagChecker") tagChecker: boolean;
    @Input('selectedTId') selectedTId: string;
    @Input('revisionRemove') revisionRemove: boolean;
    @Input('tagMarkType') tagMarkType: string;
    @Input() questiontypeDetails:any
    @Input() darkMode: boolean = false;
    @Output() saveTagEvent = new EventEmitter<any>();
    public tagArray = [];
    public selectedTagId: string = "";
    public isCreateTagModal: boolean = false;
    public isEditTagModal: boolean = false;
    public isTagModal: boolean = false;
    public isDeleteModal: boolean = false;
    public newTagName: string = "";
    public tagNameLength: number = 15;
    public selectedTagObj: any = {};
    public isRemoveTagModal: boolean = false;
    public isQuestionTagCreated: boolean = false;
    public oldTagName: string;
    public showShimmerBlock:boolean = true
    public colorArray = [
        {
            colorCode: "#F4D42E",
            selected: false
        },
        {
            colorCode: "#448C30",
            selected: false
        },
        {
            colorCode: "#691F91",
            selected: false
        },
        {
            colorCode: "#0029FA",
            selected: false
        }
    ]
    deleteQuestionCount: number = 0;
    public dataForEditTag: any;

    constructor(private solution: SolutionService, private alertService: AlertService,
        private utility: UtilityService) { }

    ngOnInit() {
        if (this.revisionRemove) {
            this.showRemoveModal();
        } else {
            this.questionTagHandler('initial');
        }

    }

    ngOnChanges() {
        if (this.revisionRemove) {
            this.showRemoveModal();
        } else {
            this.questionTagHandler('initial');
        }

    }

    updateAndSaveSelectedTagData(el) {
        this.selectedTagObj = {};
        this.selectedTagObj.name = el.name;
        this.selectedTagObj.colorCode = el.colorCode;
        this.selectedTagObj.borderCode = el.colorCode;
        this.selectedTagObj.tagId = el._id;
        this.selectedTagObj.save = true;
        this.selectedTagObj['tagType'] = el.tagType;
        this.saveTagEvent.emit(this.selectedTagObj);
    }

    public tagSaveHandler(str): void {
        let selectedTagId;
        this.tagArray.map(el => {
            if (el['selected'] === true && str !== 'close') {
                selectedTagId = el._id;
                this.updateAndSaveSelectedTagData(el);
            }
        });
        if (selectedTagId || (str === 'close')) {
            this.closeTagModal.nativeElement.click();
        } else {
            this.alertService.showErrorMessage('Please select a tag!');
        }
    }

    public showRemoveModal(): void {
         
        this.isRemoveTagModal = true;
        this.isDeleteModal = false;
        this.isCreateTagModal = false;
        this.isTagModal = false;
        this.isEditTagModal = false;
        this.tagArray.map(el => {
            el['selected'] = false    
            
        });
    }

    public questionTagHandler(str): void {
        this.solution.getQuestionTags()
            .subscribe(
                res => {
                    this.isTagModal = true;
                    this.isRemoveTagModal = false;
                    this.isEditTagModal = false;
                    this.isCreateTagModal = false;
                    this.isDeleteModal = false;
                    this.selectedTagId = "";
                    this.tagArray = res.data.tags;
                    this.tagArray = this.tagArray.map(el => {
                        el['selected'] = false;
                        return el;
                    });
                    if(this.tagArray){
                        this.showShimmerBlock = false
                    }
                    if (this.tagName !== 'Save' || str === 'creatednew' || str === 'updated') {
                        this.selectedTagId = this.selectedTId;
                        this.tagArray.map(el => {
                            if (this.selectedTId === el._id) {
                                el['selected'] = !el['selected'];
                            } else {
                                el['selected'] = false;
                            }
                        });
                    }
                }
            )
    }

    public filterSelected(event, tagId: string): void {
        if (event.target.checked) {
            this.selectedTagId = tagId;
        } else {
            this.selectedTagId = "";
        }
        this.tagArray.map(el => {
            if (tagId === el._id) {
                el['selected'] = !el['selected'];
            } else {
                el['selected'] = false;
            }
        });
    }

    public borderExpression(color: string, id: string): string {
        if (this.selectedTagId === id) {
            return `1px solid ${color}`;
        } else {
            return '';
        }
    }

    public saveQuestionTag(): void {
        let selectedName: string;
        let selectedColor: string;
        this.tagArray.map(el => {
            if (el['selected'] === true) {
                selectedName = el.name;
                selectedColor = el.colorCode;
            }
        });
        let postBody = {
            "name": selectedName,
            "colorCode": selectedColor
        }
        if (selectedName && selectedColor) {
            this.solution.saveQuestionTag(postBody)
                .subscribe(
                    res => {
                        if (res.code === 200) {
                            this.closeTagModal.nativeElement.click();
                        } else {
                            this.alertService.showErrorMessage(res.message);
                        }
                    },
                    err => {
                        console.error('error in saving custom tag', err);
                    }
                );
        }
    }


    public borderColorHandler(colorCode: string): string {
        let selectedColor: string;
        this.colorArray.forEach(el => {
            if (el.selected) {
                selectedColor = el.colorCode;
            }
        });
        if (selectedColor === colorCode) {
            return `1px solid ${colorCode}`;
        } else {
            return '';
        }
    }

    public colorSelectorHandler(event, colorCode): void {
        this.colorArray = this.colorArray.map(el => {
            if (el.colorCode === colorCode) {
                el.selected = !el.selected;
            } else {
                el.selected = false;
            }
            return el;
        })
    }

    public tagNameChangeHandler(event = null, editName = null): void {
        if (editName) {
            if (editName.length <= 15) {
                this.newTagName = editName;
                this.tagNameLength = 15 - this.newTagName.length;
            }
        } else {
            if (event.target.value.length <= 15) {
                this.newTagName = event.target.value;
                this.tagNameLength = 15 - this.newTagName.length;
            }
        }
    }

    public createModalHandler(): void {
        this.isCreateTagModal = true;
        this.isTagModal = false;
        this.isEditTagModal = false;
        this.isDeleteModal = false;
        this.newTagName = "";
        this.oldTagName = '';
        this.tagNameLength = 15;
        this.selectedTagId = "";
        this.colorArray.map(el => {
            el.selected = false;
            return el;
        });
    }

    public backToTagPage(isDeleteModal, isRemoveTagModal): void {
        if (isDeleteModal) {
            this.isCreateTagModal = false;
            this.isEditTagModal = false;
            this.isDeleteModal = false;
            this.isTagModal = true;
            this.questionTagHandler('somecond');
        } else if (isRemoveTagModal) {
            this.closeTagModal.nativeElement.click();
        } else {
            this.isCreateTagModal = false;
            this.isEditTagModal = false;
            this.isDeleteModal = false;
            this.isTagModal = true;
            this.questionTagHandler('somecond');
        }
    }

    public createNewTagHandler(create: boolean, edit: boolean): void {
        let selectedColor = null;
        this.colorArray.forEach(el => {
            if (el.selected) {
                selectedColor = el.colorCode;
            }
        });
        if ((this.newTagName || this.oldTagName) && selectedColor) {
            const postBody = {
                'colorCode': selectedColor
            };
            if (this.newTagName !== this.oldTagName) {
                postBody['name'] = this.newTagName;
            }
            if (create) {
                this.solution.saveQuestionTag(postBody)
                    .subscribe(
                        res => {
                            if (res.code === 200) {
                                this.selectedTId = res.data.tag._id;
                                this.questionTagHandler('creatednew');
                            } else {
                                this.alertService.showErrorMessage(res.message);
                            }
                        },
                        err => {
                            console.error('error in saving tag information', err);
                        }
                    );
            }
            if (edit) {
                this.dataForEditTag.borderCode = selectedColor;
                this.dataForEditTag.name = this.newTagName;
                this.dataForEditTag.colorCode = selectedColor;
                this.solution.updateTagInformation(this.selectedTagId, postBody)
                    .subscribe(
                        res => {
                            if (res.code === 200) {
                                this.selectedTId = this.selectedTagId;
                                this.updateAndSaveSelectedTagData(this.dataForEditTag);
                                this.questionTagHandler('updated');
                            } else {
                                this.alertService.showErrorMessage(res.message);
                            }
                        },
                        err => {
                            console.error('error in updating tag information', err);
                        }
                    );
            }
        }
    }

    public isTagChecked(tagAction: string): string {
        let selectedTag: boolean = false;
        let selectedRemove: boolean = false;
        this.tagArray.map(el => {
            if (el['selected'] === true) {
                selectedTag = true;
            }
        });
        this.tagArray.map(el => {
            if (this.selectedTId === el._id && el.selected) {
                selectedRemove = true;
            }
        });
        if (tagAction === 'ok') {
            if (selectedTag) {
                return 'btn-blue-default'
            } else {
                return 'btn-disabled-default'
            }
        }
        if (tagAction === 'remove') {
            if (selectedRemove) {
                return 'btn-transparent-default'
            } else {
                return 'no-display'
            }
        }
    }

    public isFormFilled(): string {
        let selectedColor = null;
        this.colorArray.forEach(el => {
            if (el.selected) {
                selectedColor = el.colorCode;
            }
        });
        if (this.newTagName.length > 0 && selectedColor) {
            return 'btn-blue-default'
        } else {
            return 'btn-disabled-default'
        }
    }

    public openDeleteModal(tagId: any): void {
        this.tagName = tagId.name
        this.isDeleteModal = true;
        this.selectedTagId = tagId._id;
        this.isCreateTagModal = false;
        this.isTagModal = false;
        this.isEditTagModal = false;
        this.solution.getDeleteQuestionCount(this.selectedTagId)
            .subscribe(
                res => {
                    this.deleteQuestionCount = res?.data?.revision?.count;
                }
            )
    }

    public openEditModal(tagData): void {
        this.isDeleteModal = false;
        this.isCreateTagModal = false;
        this.isTagModal = false;
        this.isEditTagModal = true;
        this.dataForEditTag = tagData;
        this.selectedTagId = tagData._id;

        this.solution.getOneQuestionTag(this.selectedTagId)
            .subscribe(
                response => {
                    let tagResponse = response.data.tag;
                    this.colorArray = this.colorArray.map(el => {
                        if (el.colorCode === tagResponse.colorCode) {
                            el.selected = true;
                            this.selectedTagId = tagResponse._id;
                            this.oldTagName = tagResponse.name;
                            this.tagNameChangeHandler(null, this.oldTagName);
                        } else {
                            el.selected = false;
                        }
                        return el;
                    });
                },
                error => {
                    console.error('error from getting one tag information', error);
                }
            )
    }

    public deleteTagId(deleteTag: boolean, removeTag: boolean): void {
        if (deleteTag) {
            this.solution.deleteCustomTag(this.selectedTagId)
                .subscribe(
                    response => {
                        if (response.code === 200) {
                            if (this.deleteQuestionCount > 0) {
                                this.selectedTagObj = {
                                    colorCode: "#FFFFFF",
                                    borderCode: '#999999',
                                    name: 'Save',
                                    save: false,
                                    previousTagId: this.selectedTagId || '',
                                };
                                this.utility.emptyDataRevision.next('empty');
                                setTimeout(() => {
                                   // this.alertService.selectTagObject.next(this.selectedTagObj)
                                    this.saveTagEvent.emit(this.selectedTagObj);
                                    this.closeTagModal.nativeElement.click();
                                }, 100);
                            } else if (this.deleteQuestionCount === 0) {
                                this.questionTagHandler('delete');
                            }
                        }
                    },
                    error => {
                        console.error('error deleting custom tag', error);
                    }
                )
        }
        if (removeTag) {
            this.selectedTagObj = {
                colorCode: "#FFFFFF",
                borderCode: '#999999',
                name: 'Save',
                save: false
            }
           this.utility.emptyDataRevision.next('empty');
           setTimeout(() => {
              this.isRemoveTagModal = false
               this.saveTagEvent.emit(this.selectedTagObj);
               this.alertService.selectTagObject.next(this.questiontypeDetails)
                this.closeTagModal.nativeElement.click();
            }, 100);
        }
    }

    closeModal() {
        if (this.isEditTagModal || this.isCreateTagModal) {
            $('#tagModal').modal('hide')
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        } else {
            this.tagSaveHandler('close');
        }
    }
   
}
