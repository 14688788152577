import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../stores/app.reducers';
import * as AuthActions from '../../../stores/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-under-development',
  templateUrl: './under-development.component.html',
  styleUrls: ['./under-development.component.scss']
})
export class UnderDevelopmentComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(new AuthActions.Logout())
  }
}
