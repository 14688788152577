import { AfterViewInit, Directive, ElementRef, EventEmitter, forwardRef, Injectable, Injector, Input, NgZone, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

declare const grecaptcha: any;

interface Response {
  success: any;
}

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

// export const RECAPTCHA_URL = new InjectionToken('RECAPTCHA_URL');

@Injectable()
export class ReCaptchaAsyncValidator {

  constructor(private http: HttpClient) { }

  validateToken(token: string): any {
    return (_: AbstractControl) => {
      return this.http.get('https://www.mypat.in', { params: { token } }).pipe(
        map(res => {
          if (!res['success']) {
            return { tokenInvalid: true };
          }
          return null;
        })
      );
    };
  }
}

export interface ReCaptchaConfig {
  theme?: 'dark' | 'light';
  type?: 'audio' | 'image';
  size?: 'compact' | 'normal';
  tabindex?: number;
}

@Directive({
  selector: '[appCaptcha]',
  exportAs: 'nbRecaptcha',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaptchaDirective),
      multi: true
    },
    ReCaptchaAsyncValidator
  ]
})
export class CaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() config: ReCaptchaConfig = {};
  @Input() lang: string;
  // @Input('nbRecaptcha')

  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter();

  private control: FormControl;
  private widgetId: number;

  private onChange: (value: string) => void;
  private onTouched: (value: string) => void;
  private key: any = environment.captchaKey;


  constructor(private element: ElementRef, private ngZone: NgZone, private reCaptchaAsyncValidator: ReCaptchaAsyncValidator) {}

  ngOnInit(): void {

  }

  registerReCaptchaCallback(): void {
    const self = this;
    window.reCaptchaLoad = () => {
      const config = {
        ...self.config,
        sitekey: self.key,
        callback: self.onSuccess.bind(self),
        'expired-callback': self.onExpired.bind(self)
      };
      self.widgetId = self.render(self.element.nativeElement, config);
    };
  }

  ngAfterViewInit(): void {
    this.registerReCaptchaCallback();
    this.addScript();
  }

  /**
   *
   * @description Useful for multiple captcha
   * @param number
   */
  getId(): any {
    return this.widgetId;
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * onExpired
   */
  onExpired(): void {
    this.ngZone.run(() => {
      this.captchaExpired.emit();
      this.onChange(null);
      this.onTouched(null);
    });
  }

  /**
   *
   * @param response
   */
  onSuccess(token: string): void {
    this.ngZone.run(() => {
      // this.verifyToken(token);
      this.captchaResponse.next(token);
      this.onChange(token);
      this.onTouched(token);
    });
  }

  /**
   *
   * @param token
   */
  verifyToken(token: string): void {
    this.control.setAsyncValidators(this.reCaptchaAsyncValidator.validateToken(token))
    this.control.updateValueAndValidity();
  }

  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   * @param element
   * @param config
   * @returns {number}
   */
  private render(element: HTMLElement, config): number {
    return grecaptcha.render(element, config);
  }

  /**
   * Resets the reCAPTCHA widget.
   */
  reset(): void {
    if (grecaptcha) {
      grecaptcha.reset(this.widgetId);
    }
  }

  /**
   * Gets the response for the reCAPTCHA widget.
   * @returns {string}
   */
  getResponse(): string {
    // if( this.widgetId )
    return grecaptcha.getResponse(this.widgetId);
  }

  /**
   * Add the script
   */
  addScript(): void {
    const script = document.createElement('script');
    const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

}

