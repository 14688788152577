<section class="coursesOfferSec">
  <div class="container">
    <div *ngIf="type === DISPLAY_TYPE.HOME_INFO; else allCoursesHeadTpl">
      <h2 class="coursesOfferHeading text-center"><span>Courses</span> We Offer</h2>
      <h5 class="coursesOfferSubHeading"><strong>15+</strong> Courses crafted especially to cater your preparation needs</h5>
    </div>
    <ng-template #allCoursesHeadTpl>
      <div *ngIf="type===DISPLAY_TYPE.ALL_COURSES; else prepTrackerCoursesTpl">
        <h2 class="coursesOfferHeading text-center"><span>All Courses</span></h2>
      </div>
    </ng-template>
    <ng-template #prepTrackerCoursesTpl>
      <h2 class="coursesOfferHeading text-center">Recommended Study Material & <span>Test Series</span></h2>
      <h5 class="coursesOfferSubHeading">Benchmark yourself against more than 700000+ learners worldwide and increase your chances to crack the exam.</h5>
    </ng-template>
    <div class="coursesOfferTabsSec">
      <div class="tab-container" [ngClass]="{'tab-margin':  type === DISPLAY_TYPE.PREP_TRACKER}">
      <ul class="nav nav-tabs" *ngIf="!showAllCourses || type === DISPLAY_TYPE.PREP_TRACKER">
        <li *ngFor="let goal of availableGoals; let i = index"><a [ngClass]="{'active': i === selectedGoalIndex}"
            (click)="selectGoal(goal, i)">{{goal.name}}</a></li>
      </ul>
      <div *ngIf="!showAllCourses || type === DISPLAY_TYPE.PREP_TRACKER" class="all-classes-dropdown rank-select-class-dropdown" [ngClass]="{'d-none':type===DISPLAY_TYPE.HOME_INFO}">
        <button data-toggle="dropdown" type="button" class="dropdown-toggle classSelection">
          <span id="search_concept">Class {{selectedClass.class}}</span>
          <span class="menu-ico-2"><img src="/assets/images/new-home/gray_drop.png"></span>
        </button>
        <ul role="menu" class="dropdown-menu">
          <li *ngFor="let class of classesList;let i=index" (click)="onClassChange(i)"><span *ngIf="type===DISPLAY_TYPE.ALL_COURSES">Class&nbsp;</span><span>{{class.class}}</span>
          </li>
        </ul>
      </div>
      </div>
      <div class="examInfo_container" *ngIf="type===DISPLAY_TYPE.ALL_COURSES && availableCourses.length && availableGoals[selectedGoalIndex]?.name">
        <div class="examInfo">
          <img src="/assets/img/s3-images/picasso/{{examInfo[availableGoals[selectedGoalIndex].name].HEADER_IMAGE}}.svg"
            alt="Target Exam" />
          <div>
            <h3>{{availableGoals[selectedGoalIndex].name}} {{targetExamYear}}</h3>
            <div class="d-flex">Exam Date: <p class="pl-2" [innerHTML]="examInfo[availableGoals[selectedGoalIndex].name].EXAM_DATE"></p></div>
          </div>
        </div>
        <a class="all-courses-button" role="button" (click)="examRedirect()">Exam Information & News</a>
      </div>
      <div *ngIf="!coursesLoader; else loaderTpl">
        <div class="panel-body" [ngClass]="{'all-course-offer-list-block':type===DISPLAY_TYPE.ALL_COURSES, 'prep-tracker-offer-list':type===DISPLAY_TYPE.PREP_TRACKER}">
          <div class="tab-content">
            <div class="courses-empty-screen" *ngIf="!availableCourses.length; else courseListTpl">
              <img class="courses-empty-icon" src="/assets/images/new-home/courses-empty-screen.svg"
                alt="Courses Empty" />
              <h4>No Courses Found</h4>
            </div>
            <ng-template #courseListTpl>
              <div class="coursesOfferList" [ngClass]="{'all-courses-list':type===DISPLAY_TYPE.ALL_COURSES}">
                <ng-container *ngIf="showAllCourses else goalWisePackageTpl">
                  <owl-carousel-o [options]="coursesWeOffer">
                    <ng-template *ngFor="let course of availableCourses; let i =index" carouselSlide>
                      <div class="courses-offer-listing">
                        <img class="mostPopularIcon" src="/assets/images/new-home/most-popular.svg" alt="Most Popular" />
                        <div class="coursesOfferView">
                          <span class="courseTitle" *ngIf="course?.targetExam?.length">
                            <ng-container *ngFor="let targetExam of course?.targetExam; let l=last">
                              {{targetExam?.name}}-{{targetExam.targetYear}}<ng-container *ngIf="!l">,&nbsp;</ng-container>
                            </ng-container>
                          </span>
                          <h5 class="courseName" title="{{course?.name}}">{{course?.name}}</h5>
                          <div class="courseTimePriceView">
                            <h6 class="courseTimeline">{{course?.duration_years}} Year{{course?.duration_years > 1 ? 's' : ''}}
                              Course</h6>
                            <ng-container *ngIf="course?.price else free">
                              <ng-container *ngIf="course?.discount else coursePrice">
                                <h3 class="coursesPrice">{{'₹' + (course?.discountedPrice | number)}}*</h3>
                                <span class="discountPrice" *ngIf="course?.discount">{{'₹' + (course?.price | number)}}</span>
                              </ng-container>
                            </ng-container>
                            <ng-template #free>
                              <h3 class="coursesPrice">Free</h3>
                            </ng-template>
                            <ng-template #coursePrice>
                              <h3 class="coursesPrice">{{('₹' + (course?.price | number))}}</h3>
                            </ng-template>
                          </div>
                          <div class="courseBuyInfo">
                            <div class="courseValidInfo">Validity: {{course?.expiryDate | date}}</div>
                            <ng-container *ngIf="!cartCourseIdList.includes(course?._id); else courseAddedTpl">
                              <a role="button" class="courseBuyNowBtn" (click)="courseRedirect(course,userAction.BUY_NOW)">Buy
                                Now</a>
                              <a role="button" class="courseAddCart" (click)="courseRedirect(course,userAction.ADD_TO_CART)">Add
                                to Cart</a>
                            </ng-container>
                          </div>
                          <ul class="coursesInfoList">
                            <li *ngFor="let info of course?.testsInfo"><img src="/assets/images/new-home/courses-info-check.svg"
                                alt="check" />{{info}}</li>
                          </ul>
                          <a class="view-detail-button" (click)="openDetail(i,course)">view Details</a>
                        </div>
                      </div>
                    </ng-template>
                  </owl-carousel-o>
                </ng-container>
                <ng-template #goalWisePackageTpl>
                  <div class="courses-offer-listing-block">
                    <div class="courses-offer-listing" *ngFor="let course of availableCourses;let i=index">
                      <img class="mostPopularIcon" src="/assets/images/new-home/most-popular.svg" alt="Most Popular" />
                      <div class="coursesOfferView">
                        <span class="courseTitle" *ngIf="course?.targetExam?.length">
                          <ng-container *ngFor="let targetExam of course?.targetExam; let l=last">
                            {{targetExam?.name}}-{{targetExam.targetYear}}<ng-container *ngIf="!l">,&nbsp;</ng-container>
                          </ng-container>
                        </span>
                        <h5 class="courseName" title="{{course?.name}}">{{course?.name}}</h5>
                        <div class="courseTimePriceView">
                          <h6 class="courseTimeline">{{course?.duration_years}} Year{{course?.duration_years > 1 ? 's' : ''}}
                            Course</h6>
                          <ng-container *ngIf="course?.price else free">
                            <ng-container *ngIf="course?.discount else coursePrice">
                              <h3 class="coursesPrice">{{'₹' + (course?.discountedPrice | number)}}*</h3>
                              <span class="discountPrice" *ngIf="course?.discount">{{'₹' + (course?.price | number)}}</span>
                            </ng-container>
                          </ng-container>
                          <ng-template #free>
                            <h3 class="coursesPrice">Free</h3>
                          </ng-template>
                          <ng-template #coursePrice>
                            <h3 class="coursesPrice">{{('₹' + (course?.price | number))}}</h3>
                          </ng-template>
                        </div>
                        <div class="courseBuyInfo">
                          <div class="courseValidInfo">Validity: {{course?.expiryDate | date}}</div>
                          <ng-container *ngIf="!cartCourseIdList.includes(course?._id); else courseAddedTpl">
                            <a role="button" class="courseBuyNowBtn" (click)="courseRedirect(course,userAction.BUY_NOW)">Buy
                              Now</a>
                            <a role="button" class="courseAddCart" (click)="courseRedirect(course,userAction.ADD_TO_CART)">Add
                              to Cart</a>
                          </ng-container>
                        </div>
                        <ul class="coursesInfoList">
                          <li *ngFor="let info of course?.testsInfo"><img src="/assets/images/new-home/courses-info-check.svg"
                              alt="check" />{{info}}</li>
                        </ul>
                        <a class="view-detail-button" (click)="openDetail(i,course)">view Details</a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="allCoursesBtnView">
          <ng-container *ngIf="type===DISPLAY_TYPE.HOME_INFO; else allCourseFooter">
            <a class="viewAllCoursesCTA" [routerLink]="'/all-courses'">View All Courses</a>
            <div class="taxesView">*Inclusive of all taxes</div>
          </ng-container>
          <ng-template #allCourseFooter>
            <div class="taxesView">**Tentative Dates *Exclusive of all taxes</div>
          </ng-template>
        </div>
      </div>
      <ng-template #loaderTpl>
        <loader-lite></loader-lite>
      </ng-template>
    </div>
  </div>
</section>


<!-- View course detail -->
<div class="modal fade pr0 course_viewDetail" [id]="'course_viewDetail'+courseId" tabindex="-1" role="dialog" *ngIf="packageTargetExams">
  <div class="modal-dialog modal-full" role="document">
    <div class="modal-content">
      <div class="modal-body">
          <div class="goBack" (click)="dismissModal()">
              <img src="/assets/images/g-lite/arrow-left.svg" alt="Back"/>Back to All Courses
          </div>
          <div class="courseDetail-container">
              <!-- Course added -->
              <div class="course_added" style="display: none;">
                  <img src="/assets/images/g-lite/green-tick.svg" alt="Course Added to Account"/>Course Added to Account
              </div>
              <div class="header">
                  <div>
                      <h5>{{packageData?.name}}</h5>
                      <div>{{packageTargetExams}} | {{packageData?.tests?.length}} Total Test{{packageData?.tests?.length > 1 ? 's' : ''}}</div>
                  </div>
                  <div class="cta">
                      <ng-container >
                          <ng-container *ngTemplateOutlet="ATCtpl"></ng-container>
                      </ng-container>
                  </div>
              </div>
              <div class="courseList-container" *ngFor="let category of testCategories; let i = index;">
                  <div id="testListing{{i}}">
                      <div class="card">
                          <div class="card-header {{testTypeImages[category] || 'commonTest'}}" data-toggle="collapse" [attr.data-target]="'#' + category.split(' ').join('')">
                              <h5 class="mb-0">
                                  <button class="btn btn-link">
                                      <img src="/assets/images/g-lite/{{testTypeImages[category] || 'commonTest'}}-image.svg" alt="{{category | titlecase}} Test"/>{{classifiedTests[category].length}} {{category | titlecase}} Test{{classifiedTests[category].length > 1 ? 's' : ''}}
                                  </button>
                              </h5>
                          </div>
                          <div [attr.id]="category.split(' ').join('')" class="collapse show" data-parent="#testListing{{i}}">
                              <div class="card-body">
                                  <div class="test-card" *ngFor="let test of classifiedTests[category].listing">
                                      <div class="test-info">
                                          <h5>{{test?.displayName}}</h5>
                                          <p>
                                              <span *ngIf="test?.scheduleDate">Scheduled on: <i>{{test?.scheduleDate | date: 'dd MMMM YYYY'}}</i></span>
                                              <span>Duration: <i>{{test?.duration}} MIN</i></span>
                                          </p>
                                      </div>
                                      <div class="cta">
                                          <a role="button" data-toggle="modal" (click)="showTestSyllabus(test)">Syllabus</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- for Responsive cta added-->
      <div class="modal-footer">
          <div class="cta">
                  <ng-container *ngTemplateOutlet="ATCtpl"></ng-container>
          </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ATCtpl>
    <a role="button" class="btn btnOutline mr-16 gotoCart" (click)="courseRedirect(selectedCourse,userAction.ADD_TO_CART)"><img src="/assets/images/buy-courses/cart-active.svg" alt="cart icon">Add to Cart</a>&nbsp;
    <a role="button" class="btn buyNow" (click)="courseRedirect(selectedCourse,userAction.BUY_NOW)"><img src="/assets/images/g-lite/buy-now.svg" alt="Buy now"/>Buy Now</a>
</ng-template>


<!-- Syllabus -->
<div class="modal fade lite-modal syllabus" tabindex="-1" [id]="'syllabus' + courseId" *ngIf="syllabusInfo.type">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
          <button type="button" class="close" data-target="#course_viewDetail" data-toggle="modal" data-dismiss="modal"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
          <div class="modal-body syllabus-container">
              <h3>Your Syllabus</h3>
              <div class="syllabusScroll">
                  <div class="syllabusInfo">
                      <p><span>Syllabus for Test Paper:</span>{{syllabusInfo.name}}</p>
                      <p><span>Test Type:</span>{{syllabusInfo.type}}</p>
                  </div>
                  <div class="syllabus-header">
                      <p><b>{{syllabusInfo.subjectCount}}</b> Subjects</p>
                      <div class="syllabus-header-right">
                          <div class="button-left" [ngClass]="{'active-button': syllabusLevel === viewDetailsInfo.CONCEPTS}"
                          (click)="changeSyllabusLevel(viewDetailsInfo.CONCEPTS)"> Concepts </div>
                          <div class="button-right" [ngClass]="{'active-button': syllabusLevel === viewDetailsInfo.CHAPTERS}"
                          (click)="changeSyllabusLevel(viewDetailsInfo.CHAPTERS)"> Chapters </div>
                      </div>
                  </div>
                  <ng-container *ngFor="let subject of syllabusInfo.subjectsSyllabus;let i=index">
                      <a [ngStyle]="{'background': subject.backgroundColor}" #subjects class="subject" data-toggle="collapse" href="#collapse-{{i}}" aria-expanded="true">
                          <div class="subject-content">
                              <img [src]="subject?.icon?.web" [alt]="subject.name" />
                              <div class="subject-name">
                                  <b>{{subject.name}}</b>
                              </div>
                          </div>
                          <div class="subject-content">
                              <p><span>{{subject.chapters.length}}</span> Chapter{{subject.chapters.length > 1 ? '': 's'}}</p>
                              <span class="divider"></span>
                              <p><span>{{subject.conceptCount}}</span> Concept{{subject.conceptCount > 1 ? '': 's'}}</p>
                          </div>
                      </a>
                      <div #chaptersCollapse id="collapse-{{i}}" class="collapse show">
                          <div class="collapse-card">
                              <div [ngClass]="{'cl-cursor': syllabusLevel === viewDetailsInfo.CHAPTERS}" class="chapter" *ngFor="let chapter of subject.chapters;let j=index">
                                  <a #chapters [attr.data-toggle]="syllabusLevel === viewDetailsInfo.CHAPTERS ? 'collapse' : ''" [attr.data-target]="'#collapse-chapter-'+i+'-'+j">
                                      <div class="chapter-content">
                                          <img src="/assets/images/myPlan/add-icon.svg" alt="plus" *ngIf="syllabusLevel === viewDetailsInfo.CHAPTERS" />
                                          <p>
                                              <i class="truncate" title="{{chapter.name}}">{{j+1}}. {{chapter.name}}</i>
                                              <span>({{chapter.concepts.length}})</span>
                                          </p>
                                      </div>
                                      <div #conceptsCollapse id="collapse-chapter-{{i}}-{{j}}" class="collapse concept-container">
                                          <ol [ngClass]="(syllabusLevel === viewDetailsInfo.CHAPTERS) ? 'concept-container_chapter' : 'concept-container_concept'">
                                              <li class="concept" *ngFor="let concept of chapter.concepts;let k=index">
                                                  <p>
                                                      {{j+1}}.{{k+1}}
                                                  </p>
                                                  <p class="conceptName">
                                                      {{concept.name}}
                                                  </p>
                                              </li>
                                          </ol>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #courseAddedTpl>
  <div class="course-added">
    <img src="/assets/images/g-lite/round-tick.svg" alt="Course Added" />Course Added to Cart
  </div>
</ng-template>