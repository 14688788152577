import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader-lite',
  templateUrl: './loader-lite.component.html',
  styleUrls: ['./loader-lite.component.scss']
})
export class LoaderLiteComponent implements OnInit {

  @Input('customClass') customClass = '';

  constructor() { }

  ngOnInit(): void {
  }

}
