<!-- Extend free trial Modal -->
<div class="modal fade" id="tagModal" role="dialog" [ngClass]="{'darkMode':darkMode}">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-dialog-scrollable">
      <!-- Modal content-->
      <div class="modal-content">
        
        <div class="modal-header" *ngIf="!isDeleteModal && !isRemoveTagModal">
          <span class="title" *ngIf="isTagModal">Tag the question</span>
          <span class="title" *ngIf="isCreateTagModal">Create Tag</span>
          <span class="title" *ngIf="isEditTagModal">Edit Tag</span>
          <button type="button" class="close" (click)="closeModal()"><img src="/assets/images/revision/tagclose.svg"></button>
        </div>
        <div class="modal-body" *ngIf="isTagModal">
        <div class="tag-description" >
          <div class="tag-desc-title">Tag your questions to find them easily in your revision book using filters</div>
          <div>
            <div class="label-container" *ngFor="let tagObj of tagArray; let i = index">
              <label class="filter-card">
                <span class="tag-name text-ellipsis">{{ tagObj.name }}</span>
                <input type="checkbox" [checked]="tagObj.selected" (change)="filterSelected($event, tagObj._id)" />
                <span class="checkmark-cover" [ngStyle]="{'border': borderExpression(tagObj.colorCode, tagObj._id)}">
                  <span class="checkmark" [ngStyle]="{'background-color': tagObj.colorCode}"></span>
                </span>
              </label>
              <div class="change-tag-container" *ngIf="tagObj.tagType === 'custom'">
                <img class="d-icon" src="/assets/images/myPlan/tag-edit-icon.svg" alt="edit tag"
                  (click)="openEditModal(tagObj)">
                  <img class="mob-icon" src="/assets/images/myPlan/mob-tag-edit-icon.svg" alt="edit tag"
                  (click)="openEditModal(tagObj)">
                <img src="/assets/images/myPlan/delete-tag-icon.svg" alt="delete tag"
                  (click)="openDeleteModal(tagObj)">
              </div>
            </div>
            <div class="create-new-container">
              <div class="row" >
                <div class="col-md-6 pr-0" (click)="createModalHandler()" style="cursor: pointer;">
                  <button>
                    <img src="/assets/images/myPlan/create-tag.svg" alt="create tag" />
                  </button>
                  <span>Create New</span>
                </div>
                <div class="col-md-6">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="isTagModal">
          <div class="post-button">
            <button [ngClass]="isTagChecked('remove')" *ngIf="tagName !== 'Save'"
              (click)="showRemoveModal()">Remove</button>
            <button [ngClass]="isTagChecked('ok')" (click)="tagSaveHandler('save')" class="ma-0">Done</button>
          </div>
        </div>
  
        <div class="modal-body" *ngIf="isCreateTagModal || isEditTagModal">
          <div class="tag-description">
            <div class="tag-desc-title" *ngIf="isCreateTagModal">Create your custom tags and tag the questions to find them easily</div>
            <div class="tag-desc-title" *ngIf="isEditTagModal">Enter a new name and select a color of your choice for this
              custom tag</div>
              <div class="tag-input-form">
                <div>Type your tag name</div>
                <input type="text" placeholder="Type your new tag" [(ngModel)]="newTagName" maxlength="15"
                  (keyup)="tagNameChangeHandler($event)">
                <span class="input-limit-number">{{ tagNameLength }}</span>
              </div>
              <div class="tag-color-select">
                <div>Select color for the tag</div>
                <div class="tag-color-container">
                  <label class="filter-card" *ngFor="let colorObj of colorArray; let i = index">
                    <input type="checkbox" [checked]="colorObj.selected"
                      (change)="colorSelectorHandler($event, colorObj.colorCode)" />
                    <span class="checkmark-cover" [ngStyle]="{'border': borderColorHandler(colorObj.colorCode)}">
                      <span class="checkmark" [ngStyle]="{'background-color': colorObj.colorCode}"></span>
                    </span>
                  </label>
                </div>
              </div>
              
           
          </div>
        
        </div>
        <div class="modal-footer" *ngIf="isCreateTagModal || isEditTagModal">
          <div class="post-button">
            <button class="btn-transparent-default" (click)="backToTagPage()">Back</button>
            <button [ngClass]="isFormFilled()"
              (click)="createNewTagHandler(isCreateTagModal, isEditTagModal)" class="ma-0">Done</button>
          </div>
        </div>
        <div class="delete-header" *ngIf="isDeleteModal || isRemoveTagModal">
          <span class="title" *ngIf="isDeleteModal">Delete Tag?</span>
          <span class="title" *ngIf="isRemoveTagModal">Remove Tag?</span>
          <span class="description" *ngIf="isDeleteModal">
            Are you sure you want to delete {{tagName}} tag? This cannot be
            undone! This will also delete <span *ngIf="deleteQuestionCount>0">{{ deleteQuestionCount }}</span> other saved items in this tag.
          </span>
          <span class="description" *ngIf="isRemoveTagModal && tagMarkType === 'manual'">
            Are you sure you want to
            remove "{{ tagName }}" tag from this question.
          </span>
          <span class="description" *ngIf="isRemoveTagModal && tagMarkType === 'automated'">
            Are you sure you want to
            remove this question from your Revision Notebook.
          </span>
          <div class="confirmation">
            <button class="btn-transparent-default" (click)="deleteTagId(isDeleteModal, isRemoveTagModal)">Delete</button>
            <button class="btn-blue-default" (click)="backToTagPage(isDeleteModal, isRemoveTagModal)">Cancel</button>
          </div>
        </div>
        <div class="loader-shimmer" style="padding: 20px;" *ngIf="showShimmerBlock">
          <div class="shimmer" style="height: 50px; display: flex; margin-bottom: 20px;">
          </div>
          <div class="shimmer" style="height: 50px; display: flex; margin-bottom: 20px;">
          </div>
          <div class="shimmer" style="height: 50px; display: flex; margin-bottom: 20px;">
          </div>
          <div class="shimmer" style="height: 50px; display: flex; margin-bottom: 20px;">
          </div>
      </div>
      </div>
    </div>
    <button #closeTagModal [hidden]="true" data-dismiss="modal"></button>
  </div>