<div class="searchbox" [class.expand]="expandInput" id="searchboxfix">
  <a href="javascript:void(0)" class="backArrow" (click)="removeSearchClass()">
    <img [src]="'assets/images/myPlan/whiteBack.svg' " alt="back"/>
  </a>
  <span>
    <input
    [class.searchExpand]="expandInput"
    [class.addRadius]="(recentSearchData && recentSearchData.length > 0) || (searchSuggestions && searchSuggestions.length > 0) || (searchText)"
    autocomplete="off"
    id="searchBar"
    (click)="showRecentSearchToggle()"
    (ngModelChange)="onChangeSearchValue($event)"
    (keyup.enter)="onEnterSearch()"
    [(ngModel)]="searchText"
    type="text" placeholder="Search for concepts, chapters, and more..." #globalSearch/>
    <span class="searchIcon" [class.animateSearch]="searchText.length === 0" (click)="showRecentSearchToggle()">
      <svg id="searchIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M15.8645 14.3208H15.0515L14.7633 14.0429C15.8067 12.8326 16.3802 11.2875 16.3791 9.68954C16.3791 8.36647 15.9867 7.07312 15.2517 5.97303C14.5166 4.87294 13.4719 4.01553 12.2495 3.50921C11.0272 3.0029 9.68212 2.87042 8.38447 3.12854C7.08683 3.38666 5.89487 4.02377 4.95932 4.95932C4.02377 5.89487 3.38666 7.08683 3.12854 8.38447C2.87042 9.68212 3.0029 11.0272 3.50921 12.2495C4.01553 13.4719 4.87294 14.5166 5.97303 15.2517C7.07312 15.9867 8.36647 16.3791 9.68954 16.3791C11.3465 16.3791 12.8696 15.7719 14.0429 14.7633L14.3208 15.0515V15.8645L19.4666 21L21 19.4666L15.8645 14.3208ZM9.68954 14.3208C7.12693 14.3208 5.05832 12.2521 5.05832 9.68954C5.05832 7.12693 7.12693 5.05832 9.68954 5.05832C12.2521 5.05832 14.3208 7.12693 14.3208 9.68954C14.3208 12.2521 12.2521 14.3208 9.68954 14.3208Z" fill="white"/>
      </svg>
    </span>
    <span class="crossIcon" [class.animateCross]="searchText.length > 0" (click)="clearSearchText()">
      <a href="javascript:void(0)">
        <img [src]="'/assets/images/closeIcon.svg' "/>
      </a>
    </span>
    <ul class="searchListGlobal" *ngIf="showRecentSearch && recentSearchData && recentSearchData.length > 0">
      <div id="recentList">
        <div class="recent" id="recentSearchBar">
          <span id="recentSearchText">Recent</span>
          <a id="clearAll" href="#modalClearall" data-toggle="modal">Clear All</a>
        </div>
        <li (click)="searchItemSelected($event, searchItem)" *ngFor="let searchItem of recentSearchData">
          <div class="globalSearchListLeft">
            <div class="globalListSearchIcon mr-2"><img src="/assets/images/globalSearch/globalSearchListClock.svg" alt="Clock" /></div>
            <div class="globalListSearchText"><p>{{ searchItem?.name | titlecase }}</p></div>
          </div>
          <div class="globalSearchListRight">
            <span *ngIf="searchItem?.type" [ngClass]="searchItem?.type === 'chapter' ? 'chapter' : 'concept'">{{ searchItem?.type }}</span>
          </div>
        </li>
      </div>
    </ul>
    <ul class="searchListGlobal" *ngIf="showSearchSuggestions">
      <div id="suggestionList">    
        <div class="searchFor" (click)="searchForOnlyCommunity()">
          Search Glorifire for 
          "<span>{{searchText}}</span>"
        </div>
        <li (click)="searchItemSelected($event, searchItem)" *ngFor="let searchItem of searchSuggestions">
          <div class="globalSearchListLeft">
            <div *ngIf="searchItem.isRecentSearchItem; else searchIconTemplate" class="globalListSearchIcon mr-2"><img src="/assets/images/globalSearch/globalSearchListClock.svg" alt="Clock" /></div>
            <ng-template #searchIconTemplate>
              <div class="globalListSearchIcon mr-2"><img src="/assets/images/globalSearch/globalSearchListSearch.svg" alt="Search" /></div>
            </ng-template>
            <p class="itemName">{{ searchItem?.name | titlecase }}</p>
          </div>
          <div class="globalSearchListRight">
            <span *ngIf="searchItem?.type" [ngClass]="searchItem?.type === 'chapter' ? 'chapter' : 'concept'">{{ searchItem?.type }}</span>
          </div>
        </li>
      </div>
    </ul>
  </span>
</div>
