import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  public UpdatedData = new BehaviorSubject<any>([]);
  public UpdatedDataHelp = new BehaviorSubject<any>([])
  public status = new BehaviorSubject<any>('');
  public helpStatus = new BehaviorSubject<any>('');
  public feedbackModalClose = new BehaviorSubject<any>(false);
  isModalClosed = this.feedbackModalClose.asObservable();
  constructor(private http: HttpService, private alert: AlertService) { }

  helpReportList(issueType): any {
      return new Promise((resolve, reject) => {
        this.http.post('v4/auth/ideas-suggestions/listing',
                       true, issueType, true, false, false
        ).subscribe((res) => {
          if (res) {
            resolve(res.data);
          } else {
            this.alert.showErrorMessage(res.message);
          }
        });
      });
  }

  getHelpFeedBack(payload): any {
    return new Promise((resolve, reject) => {
      this.http.put('v4/auth/ideas-suggestions/feedback', true, payload, true,
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }


  helpIssueType(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/ideas-suggestions/categories', true, null, true, false
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  postReportAnIssue(issueData): any {
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/ideas-suggestions', true,
      issueData, true, false, false
      ).subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }
  reopenAnIssue(issueData): any {
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/support-tickets/reopen', true,
      issueData, true, false, false
      ).subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
     });
   }
  getRateUs(): any {
    return new Promise((resolve, reject) => {
      this.http.get('user/feedback', true,
        true, false, false, environment.serverURLv3
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  postRateUs(rateUs): any {
    return new Promise((resolve, reject) => {
      this.http.post('user/feedback', true, rateUs, true, false, false, environment.serverURLv3
      ).subscribe((res) => {
        if (res.code === 200) {
          this.alert.showSuccessMessage(res.message);
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  getIssueComments(issueId): any {
    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/ideas-suggestions/comments?issueId=${issueId}`, true, null, true, false
      ).subscribe((res) => {
        if (res) {
          resolve(res.data);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  postIssueComment(payload): any {
    return new Promise((resolve, reject) => {
      this.http.post('v4/auth/ideas-suggestions/comments', true, payload, true, false, false).subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }
}