import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as AuthActions from '../../../stores/auth/auth.actions';
import * as fromApp from '../../../stores/app.reducers';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.scss']
})
export class LogoutConfirmComponent implements OnInit {
  public userData;
  @Output() closeLogoutPopup = new EventEmitter<any>();
  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
    this.store.select('userProfile').subscribe((userProfile) => {
      this.userData = userProfile.userData;
    });
  }

  closeModal(): void {
    this.closeLogoutPopup.emit();
  }

  logout(): void {
    this.store.dispatch(new AuthActions.Logout());
  }

}
