import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDifference',
  pure: true
})
export class DateDifferencePipe implements PipeTransform {

  transform(providedDate: any): string {
    const date: Date = new Date(providedDate)
    const currentDate: Date = new Date();
    const d: string = this.diff(date, currentDate);
    return currentDate.getTime() - date.getDate() > 0 ? d + " ago" : "in " + d;
  }
  diff(date: Date, currentDate: Date): string {
    const d = currentDate.getFullYear() - date.getFullYear();
    if (d !== 0) {
      return d + " year" + (Math.abs(d) > 1 ? "s" : "");
    } else {
      const d = currentDate.getMonth() - date.getMonth();
      if (d !== 0) {
        return d + " month" + (Math.abs(d) > 1 ? "s" : "");
      } else {
        const d = currentDate.getDate() - date.getDate();
        if (d !== 0) {
          return d + " day" + (Math.abs(d) > 1 ? "s" : "");
        } else {
          const d = currentDate.getHours() - date.getHours();
          if (d !== 0) {
            return d + " hour" + (Math.abs(d) > 1 ? "s" : "");
          } else {
            const d = currentDate.getMinutes() - date.getMinutes();
            if (d !== 0) {
              return d + " minute" + (Math.abs(d) > 1 ? "s" : "");
            } else {
              const d = currentDate.getSeconds() - date.getSeconds();
              if (d !== 0) {
                return d + " second" + (Math.abs(d) > 1 ? "s" : "");
              } else {
                return "a few seconds";
              }
            }
          }
        }
      }
    }
  }
}
