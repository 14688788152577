<!-- Button trigger modal -->

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor1"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="violation">
          Violation 1
        </div>
        <img src="../../assets/img/s3-images/auto-proctor/oh-no.svg" class="mt30"/>
        <h2>Uh NO!!</h2>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey==='keyboard'">
          <h6>DO NOT USE</h6>
          <p>ANY KEYBOARD BUTTONS !</p>
        </div>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey!=='keyboard'">
          <h6>DO NOT MOVE</h6>
          <p>AWAY FROM TEST WINDOW !</p>
        </div>    
        <p>We always got our eyes on you! Let’s be honest while attempting the Test!!</p>
        <button type="button" (click)="closeProctor(1)" class="btn btn-primary">Ok, Got it!</button>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor2"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="violation">
          Violation 2
        </div>
        <img src="../../assets/img/s3-images/auto-proctor/not-again.svg" class="mt30" />
        <h2>Not Again!</h2>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey==='keyboard'">
          <h6>DO NOT USE</h6>
          <p>ANY KEYBOARD BUTTONS !</p>
        </div>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey!=='keyboard'">
          <h6>DO NOT MOVE</h6>
          <p>AWAY FROM TEST WINDOW !</p>
        </div>
        <p>
          We always keep a close watch on you. If we find you doing it again, you’ll be locked out of the Test!!
        </p>
        <button type="button" (click)="closeProctor(2)" class="btn btn-primary">Ok, Got it!</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor3"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../assets/img/s3-images/auto-proctor/close-icon.svg" />
        </button>
      </div> -->
      <div class="modal-body">
        <div class="violation">
          Violation 3
        </div>
        <img src="../../assets/img/s3-images/auto-proctor/locked.svg" class="mt30" width="100px"/>
        <h2>Locked</h2>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey==='keyboard'">
          <h6>DO NOT USE</h6>
          <p>ANY KEYBOARD BUTTONS !</p>
        </div>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey!=='keyboard'">
          <h6>DO NOT MOVE</h6>
          <p>AWAY FROM TEST WINDOW !</p>
        </div>
        <p>Your Test has been Locked.. We have found you attempting to malpractice even after 2 warnings... This is highly unacceptable!</p>
        <p>You can unlock this Test by accepting the below terms</p>

        <div class="form-group form-check custom-checkbox">
          <input type="checkbox" (change)="checkBoxCheck($event)" [(ngModel)]="proctor3CheckBox" name="procCheck" #procCheck="ngModel" class="" id="exampleCheck1" />
          <label class="form-check-label widthNew" for="exampleCheck1"
            >I apologise for my mistake & I promise that I will not attempt to malpractice again</label
          >
        </div>

        <button type="button" [disabled]="!proctor3CheckBox" (click)="closeProctor(3)" class="btn btn-primary">
          <img
            src="../../assets/img/s3-images/auto-proctor/unlocked-icon.svg"
          />

          Unlock
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor4"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content auto-submit">
      <!-- <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../assets/img/s3-images/auto-proctor/close-icon.svg" />
        </button>
      </div> -->
      <div class="modal-body pb0">
        <div class="violation">
          Violation {{dataPassed && dataPassed.malpracticeCount}}
        </div>
        <img src="../../assets/img/s3-images/auto-proctor/auto-submit.svg" class="mt30" width="100px"/>
        <h2>You have been locked out!</h2>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey==='keyboard'">
          <h6>DO NOT USE</h6>
          <p>ANY KEYBOARD BUTTONS !</p>
        </div>
        <div class="instructionCard" *ngIf="proctoredGeneratedKey!=='keyboard'">
          <h6>DO NOT MOVE</h6>
          <p>AWAY FROM TEST WINDOW !</p>
        </div>
        <p class="lineH30">Timer would continue as per the Test duration<br>
          To Unlock the Test, Please contact your Center AO <br>
          Click below to check the Test Status</p>
      </div>
      <div class="modal-footer">
      <button type="button" (click)="checkForResume()" class="btn btn-primary">
        Resume
      </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor5"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../assets/img/s3-images/auto-proctor/close-icon.svg" />
        </button>
      </div> -->
      <div class="modal-body pa32">
        <img src="../../assets/img/s3-images/auto-proctor/test-resumed.svg" />
        <h2>Test Resumed</h2>
        <p>You can continue with the test now</p>

        <button type="button" (click)="closeProctor(5)" class="btn btn-primary">Ok, Got it!</button>

      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade auto-proctor-container"
  id="proctor6"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img src="../../assets/img/s3-images/auto-proctor/auto-proctor-icon.svg" />
        <h2>You are being watched !!</h2>
        <p>This is a Proctored Test. Entire Test will be monitored & any malpractices shall be reported</p>
        <div class="instructionCard">
          <h6><img src="../../assets/img/s3-images/auto-proctor/noto_cross-mark.svg"> Do not </h6>
          <p>Move away from test window.</p>
          <p>Press any keyboard keys. </p>
          <p>Switch between tabs and other applications. </p>
          <p>Minimize current test window.</p>
          <p>Open new documents/file</p>
        </div>
        <button type="button" class="btn btn-primary mb0" (click)="startTestClick()">I Understand</button>

      </div>
    </div>
  </div>
</div>