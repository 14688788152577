import { Component, Input, OnInit } from '@angular/core';

interface IPageContent {
  pageName: String;
  pageMessage: String;
  pageHeading: String;
  image: String;
}

@Component({
  selector: 'app-page-lock',
  templateUrl: './page-lock.component.html',
  styleUrls: ['./page-lock.component.scss']
})

export class PageLockComponent implements OnInit {
  @Input() pageContent: IPageContent;

  constructor() { }

  ngOnInit(): void { }

}
