import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './stores/app.reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGaurd implements CanActivate {
  public userDetail;
  constructor(private router: Router, private store: Store<fromApp.AppState>) { }
  canActivate(state: ActivatedRouteSnapshot, route: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select('auth').pipe(
      map((auth) => {
        if (auth.authenticated) {
          const queryParams: any = state.queryParamMap;
          if (queryParams?.params?.authToken) {
            return false;
          }
          return true;
        } else if(localStorage.getItem('fiitjeeToken')){
          this.router.navigate(['/quiz/home']);
          return false;
        }else {
          let learnDarkMode = localStorage.getItem('isDarkmodeforid');
          let redirect = !localStorage.getItem('isNCRPUser');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem('isDarkmodeforid', learnDarkMode);
          return false;
        }
      }
      )
    );
  }
}
