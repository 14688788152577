<!-- info popup -->
<div class="modal fade" id="primaryGoalPop" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
        <div class="modal-body">
            <h3 class="mt-0 text-center">{{heading}}</h3>
            <div class="primaryGoalName">
              <span *ngFor="let goalName of primaryGoalData">{{goalName?.name}}</span>
            </div>
        </div>
      </div>
    </div>
  </div>