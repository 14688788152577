import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import * as fromApp from '../../../../stores/app.reducers';
import * as AuthActions from '../../../../stores/auth/auth.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-social-signup-login',
  templateUrl: './social-signup-login.component.html',
  styleUrls: ['./social-signup-login.component.scss']
})
export class SocialSignupLoginComponent implements OnInit {

  constructor(private authService: SocialAuthService, private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
     this.store.dispatch(new AuthActions.SocialSignup(user));
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

}
