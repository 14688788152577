import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EbooksService {
  public handleDownloadEbookPopup = new BehaviorSubject<any>(false);
  public selectedEbookLink: string = '';

  constructor(
    private _http: HttpClient
  ) { }

  fetchEbookMetaData() {
    return this._http.get(`/assets/metadata/ebooks.json`);
  }
}
