<div class="overlayFilter"></div>
<div class="filterContainer">
  <div class="titleClose">
    <b>Filters</b>
    <a aria-label="open and close filter" (click)="handleFilterClose()" href="javascript:void(0);">
      <img src="../../../../assets/images/assignTest/closeFilter.svg" />
    </a>
  </div>
  <!-- Search with chip and no result -->
  <div class="search-container" *ngIf="cardName === 'testHistory'">
    <div class="input-group">
      <input #syllabusSearchRef [(ngModel)]="syllabusSearchQuery" (keyup)="handleSyllabusSearchEvent()"
        (focus)="setSyllabusSearchFocusMode(true)" type="text" class="form-control"
        placeholder="Search Concept and Chapter Name" height="44">
      <div class="input-group-append">
        <button aria-label="search button" *ngIf="syllabusSearchQuery.length === 0" class="btn btn-secondary" type="button">
          <img src="../../../../assets/images/ei_search.svg" />
        </button>
        <button (click)="clearSyllabusSearchQuery()" *ngIf="syllabusSearchQuery.length > 0" class="btn btn-secondary" type="button">
          <img src="../../../../../assets/images/assignTest/closeSmall.svg" />
        </button>
      </div>
    </div>

    <!-- No syllabus dorpdown -->
    <div
      *ngIf="isSyllabusSearchFocussed && !isSyllabusLoading && syllabus.length === 0 && syllabusSearchQuery.length > 3"
      class="suggestionList">
      <ul>
        <li class="no-data">
          No Result Found
        </li>
      </ul>
    </div>

    <!-- Suggestion List -->
    <div *ngIf="isSyllabusSearchFocussed && !isSyllabusLoading && syllabus.length > 0" #syllabusDropdownRef
      class="suggestionList">
      <ul>
        <li *ngFor="let syllabusItem of syllabus;" (click)="handleSyllabusAdd(syllabusItem)" class="cursor">
          <span class="suggestionItem">{{syllabusItem.name}}</span>
          <span class="chipOutline chapter">{{capitalizeFirstLetter(syllabusItem.type)}}</span>
        </li>
      </ul>
    </div>
    <!-- Chips -->
    <div *ngIf="selectedSyllabus.length !== 0" class="chip-container scroll">
      <div *ngFor="let syllabusItem of selectedSyllabus; let i = index;" class="searched-chips">
        <span class="">{{syllabusItem.name}}</span>
        <a (click)="handleSyllabusRemove(i)" href="javascript:void(0);"><img src="/assets/images/closeFill.svg"></a>
      </div>
    </div>
  </div>
  <div class="text-right" *ngIf="cardName === 'testHistory' && selectedSyllabus.length > 0">
    <a (click)="handleSyllabusClear()" href="javascript:void(0)" class="clearAll">Clear All</a>
  </div>
  <!-- End Search -->
  <!-- accordion start-->
  <div id="scrollDiv" [ngClass]="{'suggestionListShown': selectedSyllabus.length !== 0 , 'extraPadding': cardName === 'testHistory'}" class="mobileScroll">
    <div class="accordion" role="group" id="accordion2" *ngFor="let filter of filtersList; let fi = index;" aria-expanded="false">
      <div class="accordion-group" *ngIf="filter.type === 'checkbox'">
        <div class="accordion-heading">
          <a class="accordion-toggle" *ngIf="filter.name" aria-expanded="true"  data-toggle="collapse" data-parent="#accordion2" href="#collapse{{fi}}">
            {{ filter.name }}
          </a>
          <!-- [attr.aria-expanded]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'true' : 'false'" -->
          <a class="accordion-toggle" aria-expanded="true"  data-toggle="collapse" data-parent="#accordion2" href="#collapse{{fi}}">
            {{ filter.displayName }}
          </a>
        </div>
        <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
        <div id="collapse{{fi}}" class="accordion-body collapse show" >
          <div class="accordion-inner">
            <ng-container *ngIf="filter.key === 'goal' else otherCheckbox" >
              <ng-container *ngFor="let filterCheck of filter.values; let i = index">

                <span class="expiredText" *ngIf="(i === 0 && filterCheck?.isExpired) || (i > 0 && (filter.values[i]?.isExpired !== filter.values[i-1]?.isExpired))">
                  Expired
                </span>
                <div class="checkList" >
                  <label class="checkbox-container" [ngClass]="{'disabledcheckbox':filterCheck.isDisabled}" >
                    <input type="checkbox" role="checkbox" [disabled]="filterCheck.isDisabled" [checked]="filterCheck.isSelected"
                      (click)="applyCheckedFilter(filter, filterCheck)">
                    <span class="checkmark"></span>
                    {{ filterCheck.displayName }}
                  </label>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #otherCheckbox>
              <div class="checkList" *ngFor="let filterCheck of filter.values">
                <label class="checkbox-container" [ngClass]="{'disabledcheckbox':filterCheck.isDisabled}" >
                  <input type="checkbox" [disabled]="filterCheck.isDisabled" [checked]="filterCheck.isSelected"
                    (click)="applyCheckedFilter(filter, filterCheck)">
                  <span class="checkmark"></span>
                  {{ filterCheck.displayName }}
                  <em *ngIf="cardName === 'upcomingTest' && filter.key === 'attemptStatus' && filterCheck.key === 'finished'">
                    (Casual)
                  </em>
                </label>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="accordion-group" *ngIf="filter.type === 'date'">
        <div class="accordion-heading">
          <!-- [attr.aria-expanded]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'true' : 'false'" -->
          <a class="accordion-toggle" data-toggle="collapse"  data-parent="#accordion2" href="#collapse{{fi}}" aria-expanded="true">
            {{ filter.displayName }}
          </a>
        </div>
        <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
        <div id="collapse{{fi}}" class="accordion-body collapse show">
          <div class="accordion-inner overFlowVisible">
            <div class="calendarView">
              <!-- Date Picker New Design -->
              <div class="fromToDate">
                <div class="input-group">
                  <app-date-filter [filter]="filter" [showValue]="resetButtonClicked" (dateInfo)="dateInfo($event)">
                  </app-date-filter>
                </div>
              </div>
              <!-- End -->
            </div>
          </div>
        </div>
      </div>
      <!-- Test difficulty -->
      <div class="accordion-group" *ngIf="filter.type === 'nestedDropdown'">
        <div class="accordion-heading">
          <!-- [attr.aria-expanded]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'true' : 'false'" -->
          <a class="accordion-toggle" aria-expanded="true" data-toggle="collapse" data-parent="#accordion2" href="#collapse{{fi}}">
            Test Performance
          </a>
        </div>

        <!-- Add -->
        <!-- Percentile Starts  -->
        <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
        <div id="collapse{{fi}}" class="accordion-body collapse show" >
          <div *ngFor="let performanceOptions of filter.values; let i = index;" class="accordion-inner scroller">
            <div class="percentage">
              <div class="checkList pb0">
                <label class="checkbox-container">
                  <input [(ngModel)]="performanceOptions.isSelected"
                    (change)="handlePerformanceSelection(filter.values, i)" type="checkbox" />
                  <span class="checkmark"></span>
                  {{performanceOptions.displayName}}
                </label>
              </div>
              <div *ngIf="performanceOptions.isSelected" class="percentageInput">
                <div class="form-group">
                  <select [(ngModel)]="performanceOptions.selectedOption.key"
                    (change)="handlePerformanceKeyChange($event, filter.values, i)" class="form-control"
                    class="form-control">
                    <option *ngFor="let dropdownOption of performanceOptions.options" [value]="dropdownOption.key">
                      {{dropdownOption.displayName}}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <select [(ngModel)]="performanceOptions.selectedOption.value"
                    (change)="handlePerformanceValueChange(filter.values)" class="form-control"
                    id="exampleFormControlSelect1">
                    <!-- Add more width -->
                    <option *ngFor="let item of range(0, 100, 10);" [value]="item">{{item}}</option>
                  </select>
                </div>
                <span>%</span>
              </div>
            </div>
            <!-- Percentile Ends -->
          </div>
        </div>
      </div>
      <!-- Question Difficulty Distribution -->
      <div class="accordion-group" *ngIf="filter.key === 'questionDifficulty'">
        <div class="accordion-heading">
          <!-- [attr.aria-expanded]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'true' : 'false'" -->
          <a class="accordion-toggle" aria-expanded="true"  data-toggle="collapse" data-parent="#accordion2" href="#collapse{{fi}}">
            Question Difficulty Distribution
          </a>
        </div>
        <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
        <div id="collapse{{fi}}" class="accordion-body collapse show">
          <div class="accordion-inner scroller">
            <div *ngFor="let difficultyOption of filter.values;let i = index;" class="percentage">
              <div class="checkList">
                <label class="checkbox-container" [ngClass]="{'disabled': isDifficultyDisabled(filter.values, i)}">
                  <input [(ngModel)]="difficultyOption.isSelected" [disabled]="isDifficultyDisabled(filter.values, i)"
                    (change)="computeDifficultyFilter(filter.values)" type="checkbox" id="difficultyOption-{{i}}">
                  <span class="checkmark"></span>
                  {{difficultyOption.displayName}}
                </label>
              </div>
              <div *ngIf="difficultyOption.isSelected" class="percentageInput">
                <div class="form-group">
                  <input [min]="0" [max]="getMaxDifficultyValue(filter.values, i)" [(ngModel)]="difficultyOption.value"
                    (change)="handleDifficultyValueChange($event, filter.values, i)" class="form-control smallInput"
                    type="number" />
                </div>
                <span>%</span>
              </div>
            </div>

            <p class="impNote">*Sum of the 3 percentages cannot exceed 100</p>
          </div>
        </div>
      </div>
      <!-- Projected Rank -->
      <div class="accordion-group" *ngIf="filter.key === 'rank'">
        <div class="accordion-heading">
          <!-- [attr.aria-expanded]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'true' : 'false'" -->
          <a class="accordion-toggle" aria-expanded="true" data-toggle="collapse" data-parent="#accordion2" href="#collapse{{fi}}">
            Projected Rank
          </a>
        </div>
        <!-- [ngClass]="(fi < 3 || getSelectedFilterInfo(filter)) ? 'show' : 'collapse'" -->
        <div id="collapse{{fi}}" class="accordion-body show">
          <!-- lock panel -->
          <div *ngIf="selectedGoals.length != 1" class="lockPanel">
            <img src="../../../../assets/images/lock-icon.svg">
            <p>Please select a single Target Exam from the filter above to enable this setting</p>
          </div>
          <!-- End -->
          <div *ngIf="selectedGoals.length === 1" class="rangeSlider">
            <ng5-slider [(value)]="sliderValue" (ngModelChange)="handleSliderChange($event)"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="sliderValue" [options]="sliderOptions">
            </ng5-slider>

            <div class="d-flex justify-content-between rangeCutOff">
              <span><img class="rangeCutOffImgLeft"
                  src="../../../../assets/images/arrowUp.svg">{{projectedRankRange.min}}</span>
              <span><img class="rangeCutOffImgRight"
                  src="../../../../assets/images/arrowUp.svg">{{projectedRankRange.max}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--Accordion ends-->
  <!-- CTA Reset and Apply-->
  <div class="filterFooter">
    <button type="button" class="btn textButton" (click)="resetFilters();">Reset</button>
    <button type="button" class="btn btn-primary" *ngIf="cardName !== 'upcomingTest'" [disabled]="selectedFilters.length === 0 && !resetButtonClicked"
      (click)="applyFilters()">Apply</button>
      <button type="button" class="btn btn-primary" *ngIf="cardName === 'upcomingTest'" [disabled]="checkSelectedFilters() && !resetButtonClicked"
      (click)="applyFilters()">Apply</button>
  </div>
</div>
