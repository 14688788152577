<!-- redeemModal modal -->
<div class="modal fade" id="redeemModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" *ngIf="currentState !== 'confirm'">
                    Redeem in Paytm Wallet
                </h5>
            </div>
            <div class="modal-body">
                <img src="/assets/images/Paytm_logo.svg" />
                <p *ngIf="currentState === 'initial'">Enter Your Paytm Mobile number below</p>
                <p *ngIf="currentState === 'otpSent'">Enter OTP sent to the mobile number below</p>
                <p *ngIf="currentState === 'otpVerified'">
                    ₹{{redeemablePoint}}* will be transferred to you Paytm account<br> linked with mobile
                    number
                </p>
                <div class="form-group" *ngIf="currentState === 'initial'">
                    <div class="flag-container">
                        <button class="country-flag" type="button">
                            <img alt="" [src]="selectedCountryData.flag" />
                            <span class="country-code">{{selectedCountryData.dialCode}} <span
                                    class="country-name"></span></span>
                        </button>
                        <input type="number" placeholder="Enter Mobile No" name="Paytm Mobile Number" maxlength="10"
                            required pattern="{{ selectedCountryData.regex }}" #mobile="ngModel" id="userMobileNumber"
                            name="mobile" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                            [(ngModel)]="mobileNumber" />
                    </div>

                    <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched) ">
                        <div *ngIf="mobile.errors.required" class="error">
                            Please enter a valid mobile number!
                        </div>
                        <div *ngIf="mobile.errors.pattern" class="error">
                            Please enter a valid mobile number!
                        </div>
                    </div>
                </div>
                <h3 *ngIf="currentState === 'otpSent'">
                    {{ selectedCountryData.dialCode }} - {{ mobileNumber }}
                    <button class="btn edit-btn" (click)="editMobile()">
                        <img src="../../../../../assets/images/edit-icon.svg" />
                    </button>
                </h3>
                <div class="otp-container" *ngIf="currentState === 'otpSent'">
                    <input type="text" maxlength="1" [(ngModel)]="otpDigit1" class="form-control inputs" required
                        id="otp1" name="otp_1" oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel
                        (change)="onEnterOTP()" autocomplete="off" />
                    <input type="text" maxlength="1" [(ngModel)]="otpDigit2" class="form-control inputs" required
                        id="otp2" name="otp_2" oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel
                        (change)="onEnterOTP()" autocomplete="off" />
                    <input type="text" maxlength="1" [(ngModel)]="otpDigit3" class="form-control inputs" required
                        id="otp3" name="otp_3" oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel
                        (change)="onEnterOTP()" autocomplete="off" />
                    <input type="text" maxlength="1" [(ngModel)]="otpDigit4" class="form-control inputs" required
                        id="otp4" name="otp_4" oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel
                        (change)="onEnterOTP()" autocomplete="off" />
                </div>
                <p class="otp-time" *ngIf="currentState === 'otpSent' && showTimer && timeValue > 0">
                    00:{{ timeValue < 10 ? "0" + this.timeValue : this.timeValue }} </p>
                        <p class="resend-otp" *ngIf="currentState === 'otpSent' && !showTimer" (click)="resendOTP()">
                            <button class="btn">Resend OTP</button>
                        </p>
            </div>
            <div class="modal-body redeem-successful" *ngIf="currentState === 'confirm'">
                <img src="../../../assets/images/redeem-successful-icon.svg">
                <h3>Redeemed Successfully</h3>
                <p> ₹{{redeemablePoint}} from your myCash is transferred<br> to your Paytm wallet</p>
            </div>
            <div class="modal-footer">
                <div class="footer">
                    <button type="button" class="btn btn-footer" data-dismiss="modal" *ngIf="currentState !== 'confirm'"
                        (click)="closeModal()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-close" *ngIf="currentState !== 'confirm'"
                        (click)="checkIfValid ? performAction() : ''" [disabled]="!checkIfValid()">
                        {{ buttonText }}
                    </button>
                    <button type="button" class="btn btn-close" data-dismiss="modal" (click)="closeModal()"
                        *ngIf="currentState === 'confirm'">
                        Done
                    </button>
                </div>
                <p>*If you don’t have a Paytm account, we’ll help you create one.</p>
            </div>
        </div>
    </div>
</div>