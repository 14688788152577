import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn:'root'
})
export class BuyCoursesService {
  constructor(private _http: HttpService) { }


  getAllAvailedPackages(){
    return this._http.get('v4/auth/user/packages', true, null, true, false, environment.serverURLv4);
  }

  getCourses(class_id, exam_id) {
    return this._http.get('packages', false, { class_id, exam_id}, true, false, environment.serverURLv3);
  }

  getGoals(class_id) {
    return this._http.get('getGoalsType', false, { class: class_id }, true, false, environment.serverURLv3);
  }

  getClasses(startingClass = 9) {
    return this._http.get('getClass', false, { startingClass }, true, false, environment.serverURLv3);
  }

  addToCart(data: any) {
    return this._http.post('addToCart', true, data, true, false, false, environment.serverURLv3);
  }
  
  addToAccount(pid: any) {
    return this._http.post('v4/auth/free-packages', true, {'packageIds': [pid]}, true, false, false, environment.serverURLv4);
  }
}
