import { Action } from '@ngrx/store';
import { Proforma } from '../../models/proforma.model';

export const GET_PROFORMA_DETAIL = 'GET_PROFORMA_DETAIL';
export const UPDATE_PROFORMA_DETAIL = 'UPDATE_PROFORMA_DETAIL';
export const FAIL = 'FAIL';
export const STUDENT_PROFORMA_STATUS = 'STUDENT_PROFORMA_STATUS';
export const UPDATE_ENROLLMENT_DETAIL = 'UPDATE_ENROLLMENT_DETAIL'
export const UPDATE_PROFORMA_STATUS = 'UPDATE_PROFORMA_STATUS';
export const UPDATE_PROFORMA_QUESTIONS = 'UPDATE_PROFORMA_QUESTIONS'
export const UPDATE_PARENT_PROFORMA_STATUS = 'UPDATE_PARENT_PROFORMA_STATUS'
export const UPDATE_TEACHER_PROFORMA_STATUS = 'UPDATE_TEACHER_PROFORMA_STATUS'
export class GetProformaDetail implements Action {
    readonly type = GET_PROFORMA_DETAIL;
    constructor(public payload: { 'authToken': string, 'fromScreen': string, 'tag': string }) { }
}

export class ProformaStatus implements Action {
    readonly type = UPDATE_PROFORMA_STATUS;
    constructor(public payload: any) { }
}

export class UpdateParentProformaStatus implements Action {
    readonly type = UPDATE_PARENT_PROFORMA_STATUS;
    constructor(public payload: any) { }
}

export class UpdateTeacherProformaStatus implements Action {
    readonly type = UPDATE_TEACHER_PROFORMA_STATUS;
    constructor(public payload: any) { }
}

export class QuestionsResponses implements Action {
    readonly type = UPDATE_PROFORMA_QUESTIONS;
    constructor(public payload: any) { }
}
export class UpdateProformaDetail implements Action {
    readonly type = UPDATE_PROFORMA_DETAIL;
    constructor(public payload: Proforma) { }
}

export class UpadteEnrollmentNumbers implements Action {
    readonly type = UPDATE_ENROLLMENT_DETAIL;
    constructor(public payload: any) { }
}
export class Fail implements Action {
    readonly type = FAIL;
}
export class StudentProformaStatus implements Action {
    readonly type = STUDENT_PROFORMA_STATUS;
    constructor(public payload: Proforma) { }
}

export type ProformaActionList = GetProformaDetail | UpdateProformaDetail | Fail | StudentProformaStatus | UpadteEnrollmentNumbers | ProformaStatus | QuestionsResponses | UpdateParentProformaStatus | UpdateTeacherProformaStatus;