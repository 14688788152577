import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-test-details-syllabus',
  templateUrl: './test-details-syllabus.component.html',
  styleUrls: ['./test-details-syllabus.component.scss'],
})
export class TestDetailsSyllabusComponent implements OnInit {
  @Input() testType: string;
  @Input() testDetails: any;
  public syllabusLevel: string = 'chapters';
  @ViewChildren('chapters') chaptersComponent: ElementRef<HTMLAnchorElement>[];
  @ViewChildren('conceptsCollapse') conceptsCollapseComponent: ElementRef<HTMLDivElement>[];
  @ViewChildren('subjects') subjectsComponent: ElementRef<HTMLAnchorElement>[];
  @ViewChildren('chaptersCollapse') chaptersCollapseComponent: ElementRef<HTMLDivElement>[];
  constructor() {}

  ngOnInit(): void {}

  changeSyllabusLevel(level: string): void {
    if (this.syllabusLevel === level) {
      return;
    }
    this.syllabusLevel = level;
    this.chaptersCollapseComponent.forEach((div) => {
      div.nativeElement.classList.add('show');
    });
    this.subjectsComponent.forEach((div) => {
      div.nativeElement.setAttribute('aria-expanded', 'true');
    });
    if (level === 'concepts') {
      this.conceptsCollapseComponent.forEach((div) => {
        div.nativeElement.classList.add('show');
      });
      this.chaptersComponent.forEach((div) => {
        div.nativeElement.setAttribute('aria-expanded', 'true');
      });
      return;
    }
    this.conceptsCollapseComponent.forEach((div) => {
      div.nativeElement.classList.remove('show');
    });
    this.chaptersComponent.forEach((div) => {
      div.nativeElement.setAttribute('aria-expanded', 'false');
    });
  }
}
