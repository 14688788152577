import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';

type Steps = {
  index: number,
  label: string,
  isSuccess: boolean,
  isActive: boolean,
  nextStepIndex: number,
  prevStepIndex: number
};

type StepWizard = {
  title: string,
  steps: Steps[]
};

@Injectable({
  providedIn: 'root'
})
export class GoalSettingService {
  public step1Complete = false;
  public currentUrl = new BehaviorSubject<any>({});
  public currentStep = new BehaviorSubject<string>('1');
  public saveStepData = new Subject();
  public changesInStep = new Subject();
  state = false;
  private questionData: any;
  private enrollmentNumbers: any;
  private pastAchievmentsData: any;
  private goals: any;
  public localData: any;
  public disableValidation:boolean = false;
  studentSteps: StepWizard;
  parentSteps: StepWizard;
  userTag: string;
  public activeStep:Steps;
  constructor(
    private http: HttpService,
    private alert: AlertService,
    private router: Router) {

    const url = this.router.url.split('/');
    this.currentUrl.next(url[url.length - 1]);
    this.activeStep = {
      index:0,
      label:'',
      isSuccess:false,
      isActive:false,
      prevStepIndex:0,
      nextStepIndex:0
    }
    this.studentSteps = this.getStudentSteps();
    this.parentSteps = this.getParentSteps();
  }

  get saveData():Observable<any> {
    return this.saveStepData.asObservable();
  }

  get isDataChangedInStep():Observable<any> {
    return this.changesInStep.asObservable();
  }
  // start stepper wizard
  getUserTagByUrl(url: string): string {
    if (url.includes('parent-proforma')) {
      this.userTag = 'parent';
    } else if (url.includes('teacher-proforma')) {
      this.userTag = 'teacher';
    } else {
      this.userTag = 'student';
    }
    return this.userTag;
  }

  getCurrentStepTitle(): string {
    const steps = this.userTag === 'parent' ? this.parentSteps.steps : this.studentSteps.steps;
    const currentStep = steps.find(x => x.isActive === true && x.isSuccess === false);
    if (currentStep) {
      return currentStep.label;
    } else {
      return '';
    }
  }

  getPrevStepIndex(userTag): number {
    const steps = userTag === 'parent' ? this.parentSteps.steps : this.studentSteps.steps;
    const currentStep = steps.find(x => x.isActive === true && x.isSuccess === false);
    if (currentStep) {
      return currentStep.prevStepIndex;
    } else {
      return 1;
    }
  }

  getNextStepIndex(userTag): number {
    if (this.router.url.split('/')[this.router.url.split('/').length - 1] === (true).toString()) {
      return parseInt(this.router.url.split('/')[this.router.url.split('/').length - 2], 0) + 1;
    } else {
      const steps = userTag === 'parent' ? this.parentSteps.steps : this.studentSteps.steps;
      const currentStep = steps.find(x => x.isActive === true && x.isSuccess === false);
      if (currentStep) {
        return currentStep.nextStepIndex;
      } else {
        return 1;
      }
    }
  }

  updateActiveStep(tag: string, index: number): void {
    const steps = tag === 'parent' ? this.parentSteps.steps : this.studentSteps.steps;

    steps.forEach(step => {
      if (step.index < index) {
        step.isActive = true;
        step.isSuccess = true;
      }
      if (step.index === index) {
        step.isActive = true;
        step.isSuccess = false;
      }
      if (step.index > index) {
        step.isActive = false;
        step.isSuccess = false;
      }
    });
    const tempActive = steps.find(x => x.isActive == true && x.isSuccess == false);
    if (tempActive) {
      this.activeStep.index = tempActive.index;
    } else {
      this.activeStep.index = 0;
    }
  }

  getStudentSteps(): any {
    const stepWizard = {
      title: `Student's Goal Setting Proforma`,
      steps: [
        {
          index: 1,
          label: 'Personal Particulars',
          isSuccess: false,
          isActive: true,
          prevStepIndex: -1,
          nextStepIndex: 2
        },
        {
          index: 2,
          label: 'Self Analysis',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 1,
          nextStepIndex: 3
        },
        {
          index: 3,
          label: 'Past Achievements',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 2,
          nextStepIndex: 4
        },
        {
          index: 4,
          label: 'Goal Selection',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 3,
          nextStepIndex: 5
        },
        {
          index: 5,
          label: 'Goal Confirmation',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 4,
          nextStepIndex: 0
        },
      ]
    };
    return stepWizard;
  }
  updateStudentStep(userInfo, questionStatus, goalStatus): void {
    this.studentSteps.steps.forEach((step) => {
      switch (step.index) {
        case 1: step.isActive = true; step.isSuccess = this.showIntro(userInfo, questionStatus); break;
        case 2: step.isActive = this.showIntro(userInfo, questionStatus); step.isSuccess = this.showStep2(userInfo, questionStatus); break;
        case 3: step.isActive = this.showStep2(userInfo, questionStatus); step.isSuccess = this.showStep3(userInfo, questionStatus, goalStatus); break;
        case 4: step.isActive = this.showStep3(userInfo, questionStatus, goalStatus); step.isSuccess = this.showStep4(userInfo, goalStatus); break;
        case 5: step.isActive = this.showStep4(userInfo, goalStatus); step.isSuccess = this.showLastStep(goalStatus); break;
      }
    });
  }

  getParentSteps(): any {
    const stepWizard = {
      title: `Parent's Goal Setting Proforma`,
      steps: [
        {
          index: 1,
          label: 'Student Particulars',
          isSuccess: false,
          isActive: true,
          prevStepIndex: -1,
          nextStepIndex: 2
        },
        {
          index: 2,
          label: 'Student Analysis',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 1,
          nextStepIndex: 3
        },
        {
          index: 3,
          label: 'Goal Setting Exercise',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 2,
          nextStepIndex: 4
        },
        {
          index: 4,
          label: 'Goal Confirmation',
          isSuccess: false,
          isActive: false,
          prevStepIndex: 3,
          nextStepIndex: 0
        },
      ]
    };
    return stepWizard;
  }

  updateParentStep(userInfo, questionStatus, goalStatus): void {
    this.parentSteps.steps.forEach((step) => {
      switch (step.index) {

        case 1: step.isActive = true; step.isSuccess = this.showIntro(userInfo, questionStatus); break;
        case 2: step.isActive = this.showIntro(userInfo, questionStatus); step.isSuccess = this.showStep2(userInfo, questionStatus); break;
        case 3: step.isActive = this.showStep2(userInfo, questionStatus); step.isSuccess = this.showStep4(userInfo, goalStatus); break;
        case 4: step.isActive = this.showStep4(userInfo, goalStatus); step.isSuccess = this.showLastStep(goalStatus); break;
      }
    });
  }
  // end stepper wizard



  //set question data on next page
  public setQuestionData = (data: any) => this.questionData = data;

  //get question data
  public getQuestionData = () => this.questionData;

  //set question data on next page
  public setPastAchievementsData = (data: any) => this.pastAchievmentsData = data;

  //get question data
  public getPastAchievementsData = () => this.pastAchievmentsData;


  //set enrollment Number
  public setEnrollmentData = (data: any) => this.enrollmentNumbers = data

  //get enrollment number
  public getEnrollmentNumber = () => this.enrollmentNumbers;


  // set goals
  public setSelectedGoals = (data: any) => this.goals = data;

  // get goals
  public getSelectedGoals = () => this.goals;

  getGoalSettingStep(userTag, userInfo, questionStatus, goalStatus): number {
    if (this.showIntro(userInfo, questionStatus)) {
      if (userTag == 'parent') {
        this.updateParentStep(userInfo, questionStatus, goalStatus);
      } else {
        this.updateStudentStep(userInfo, questionStatus, goalStatus);
      }
      const steps = userTag === 'parent' ? this.parentSteps.steps : this.studentSteps.steps;
      const successSteps = steps.filter(x => x.isActive === true && x.isSuccess === true);
      if (successSteps && successSteps.length === steps.length) {
        return 0;
      }
      const currentStep = steps.find(x => x.isActive === true && x.isSuccess === false);
      if (currentStep) {
        return currentStep.index;
      }
    } else {
      return -1;
    }
  }

  showIntro(userInfo, questionStatus) {
    if ((userInfo.prevEnrollmentInfo.length >= 0 && localStorage.getItem("goalSettingStep1")) || (userInfo.prevEnrollmentInfo.length >= 0 && questionStatus === 'done')) {
      return true;
    } else {
      return false;
    }
  }

  showStep2(userInfo, questionStatus): boolean {
    if ((userInfo.prevEnrollmentInfo.length >= 0 && questionStatus === 'done')) {
      return true;
    } else {
      return false;
    }
  }

  showStep3(userInfo, questionStatus, goalStatus): boolean {
    if (((userInfo.pastAchievements.length > 0 && questionStatus == 'done') || goalStatus == 'done')) {
      return true;
    } else {
      return false;
    }
  }

  showStep4(userInfo, goalStatus): boolean {
    if (userInfo.pastAchievements.length >=0 && (goalStatus == 'partial' || goalStatus == "done")) {
      return true;
    } else {
      return false;
    }
  }

  showLastStep(goalStatus): boolean {
    if (goalStatus == 'done') {
      return true;
    } else {
      return false;
    }
  }

  public sendProforma(payload: any) {
    const apiUrl = 'v4/auth/goal-settings/send-performa';
    return new Promise((resolve, reject) => {
      this.http.put(apiUrl, true, payload, true).subscribe(res => {
        if (res.code === 200) {
          this.alert.showSuccessMessage(res.message)
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    });
  }

  public getGoalLevels(courseId) {
    const apiUrl = `v4/auth/goal-settings/goal-level?courseId=${courseId}`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl, true, {}, true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  public setPrimaryGoal(payload: any) {
    const apiUrl = 'v4/auth/goal-settings';
    return new Promise((resolve, rejects) => {
      this.http.post(apiUrl, true, payload, true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message)
        }
      })
    });
  }

  public goalSettingForUser(payload:any) {
    const apiUrl = 'v4/auth/goal-settings';
    return new Promise((resolve, rejects) => {
      this.http.patch(apiUrl, true, payload, true).subscribe(res => {
        if (res ) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message)
        }
      })
    });
  }

  public getGoals(tag, type) {
    const apiUrl = `v4/auth/goal-settings?tag=${tag}&&type=${type}`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl, true, true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  public updateStatus(payload) {
    const apiUrl = 'v4/auth/user';
    return new Promise((resolve, reject) => {
      this.http.put(apiUrl, true, payload, true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  public getQuestions(tag: string) {
    const apiUrl = `v4/auth/goal-settings/questions/${tag}`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl, true, null, true).subscribe(res => {
        if (res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  public postQuestions(body: any): Promise<any> {
    const apiUrl = `v4/auth/goal-settings`;
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, true, body, true).subscribe(res => {
        if (res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

  public postPasscodeLogin(body: any): Promise<any> {
    const apiUrl = `v4/passcode-login`;
    const lastRequested = localStorage.getItem('lastRequested')
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, false, body, true).subscribe(res => {
        if (res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
          if (localStorage.getItem('lastRequested')) {
            this.router.navigateByUrl(localStorage.getItem('lastRequested'));
          }
        }
      },
        error => {

          if (localStorage.getItem('lastRequested')) {
            this.router.navigateByUrl(localStorage.getItem('lastRequested'));
          }
        });
    });
  }

  public getAllGoals() {
    const apiUrl = `v4/auth/goal-settings/status`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl, true, true).subscribe(res => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alert.showErrorMessage(res.message);
        }
      });
    })
  }

}
