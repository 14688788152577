import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/profile/shared/services/profile.service';
declare var $: any;

@Component({
  selector: 'app-myplan-popup',
  templateUrl: './myplan-popup.component.html',
  styleUrls: ['./myplan-popup.component.scss']
})
export class MyplanPopupComponent implements OnInit {

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.profileService.showMyPlanPopUp.subscribe((showMyPlanPopUp: boolean) => {
      const hideMyplanPopup = JSON.parse(localStorage.getItem('hideMyplanPopup') || 'false');
      if (showMyPlanPopUp && !hideMyplanPopup) {
        $(`#myplan-popup`).modal('show');
      }
    });
  }

  dismissModal(): void {
    localStorage.setItem('hideMyplanPopup', 'true');
    $(`#myplan-popup`).modal('hide');
  }
}
