export const EXAM_INFO = {
    'JEE(A)':{
        IMAGE_URL: 'jeeA',
        ROUTE_URL: '/jee-advanced',
        HEADER_IMAGE: 'jee-advance-exam',
        EXAM_DATE: 'Jun 2, 2024 **'
    },
    'JEE(M)':{
        IMAGE_URL: 'jeeM',
        ROUTE_URL: '/jee-main',
        HEADER_IMAGE: 'jee-main-exam',
        EXAM_DATE: 'Session 1: Jan 24, 2024 - Feb 1, 2024 <br/>Session 2: Apr 01, 2024 - Apr 15, 2024'
    },
    'BITSAT':{
        IMAGE_URL: 'bitsat',
        ROUTE_URL: '/bitsat',
        HEADER_IMAGE: 'bitsat-exam',
        EXAM_DATE: 'Session 1: May 21, 2024 to May 26, 2024 **<br />Session 2: Jun 18, 2024 to Jun 22, 2024 **'
    },
    'NTSE & Fundamentals of Engineering and Medical':{
        IMAGE_URL: 'ntse',
        ROUTE_URL: '/ntse',
        HEADER_IMAGE: 'ntse-exam',
        EXAM_DATE: 'Stage 1: Dec 2023 **<br/>Stage 2: Jun 2024 **'
    }
};

export const DISPLAY_TYPE={
    HOME_INFO:'home-info',
    ALL_COURSES:'all-courses',
    PREP_TRACKER:'prep-tracker'
}

export const AVAILABLE_CLASSES = {
    '9th': '5ace20299fe7b01d5806b926',
    '10th': '5ace20299fe7b01d5806b927',
    '11th': '5ace20299fe7b01d5806b928',
    '12th': '5ace20299fe7b01d5806b929',
    '12th Pass': '5b32140d37377e4cb08c74b4'
};

export const AVAILAIBLE_QUERY_PARAMS = {
    selected_class: 'class',
    selected_goal: 'goal'
};