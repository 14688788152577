import * as UserProfileActionList from './user-profile.actions';
import { UserProfile } from '../../models/profile.model';
import { act } from '@ngrx/effects';

export interface State {
  userData: UserProfile;
  isVerifyMobileRequest: boolean;
  isVerifyEmailRequest: boolean;
  profileCompleted: number;
  showProfileStripOnDashboard: boolean;
  currentStep: any;
  lastStep: any;
  selectedGoal: {};
}

const intialState: State = {
  userData: new UserProfile(),
  isVerifyMobileRequest: false,
  isVerifyEmailRequest: false,
  profileCompleted: 0,
  showProfileStripOnDashboard: true,
  currentStep: '1',
  lastStep: '2a',
  selectedGoal: localStorage.getItem('targetExam')
    ? JSON.parse(localStorage.getItem('targetExam'))
    : null
};

export function userProfileReducer(
  state = intialState,
  action: UserProfileActionList.UserProfileActionList
): State {
  switch (action.type) {
    case UserProfileActionList.UPDATE_USER_DETAIL:
      return {
        ...state,
        userData: action.payload
      };
    case UserProfileActionList.ADD_NAME_SUCCESS:
      return {
        ...state,
        currentStep: '2a',
        lastStep: '1',
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          name: action.payload['name'],
          // tslint:disable-next-line: no-string-literal
          userName: action.payload['userName']
        }
      };
    case UserProfileActionList.ADD_CLASS:
      return {
        ...state,
        currentStep: '2b',
        lastStep: '2a',
        selectedGoal: action.payload['goals'],
        userData: {
          ...state.userData,
          userClass: action.payload['userClass'],
          userCareers: action.payload['userCareers'],
          userGoals: action.payload['goals'],
        }
      };
    case UserProfileActionList.ADD_CAREER:
      return {
        ...state,
        currentStep: '2c',
        lastStep: '2b',
        selectedGoal: action.payload['goals'],
        userData: {
          ...state.userData,
          userCareers: action.payload['userCareers'],
          userGoals: action.payload['goals']
        }
      };
    case UserProfileActionList.ADD_GOAL:
      return {
        ...state,
        currentStep: '3',
        lastStep: '2c',
        // tslint:disable-next-line: no-string-literal
        selectedGoal: action.payload['goals'],
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          userGoals: action.payload['goals'],
          userClass: action.payload['userClass'],
          userCareers: action.payload['userCareers'],
        }
      };
    case UserProfileActionList.ADD_GOAL_SUCCESS:
      return {
        ...state,
        currentStep: '3',
        lastStep: '2c',
        // tslint:disable-next-line: no-string-literal
        selectedGoal: action.payload['goals'],
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          userGoals: action.payload['goals'],
          userClass: action.payload['userClass'],
          userCareers: action.payload['userCareers'],
        }
      };
    case UserProfileActionList.ADD_SCHOOL_SUCCESS:
      return {
        ...state,
        currentStep: '4',
        lastStep: '3',
        userData: {
          ...state.userData,
          school: {
            // tslint:disable-next-line: no-string-literal
            country: action.payload.hasOwnProperty('country') ? action.payload['country']
              : state.userData.school.country,
            // tslint:disable-next-line: no-string-literal
            state: action.payload.hasOwnProperty('state') ? action.payload['state']
              : state.userData.school.state,
            // tslint:disable-next-line: no-string-literal
            city: action.payload.hasOwnProperty('city') ? action.payload['city']
              : state.userData.school.city,
            // tslint:disable-next-line: no-string-literal
            id: action.payload['school']['id'] ? action.payload['school']['id'] : '',
            // tslint:disable-next-line: no-string-literal
            name: action.payload['school']['name'],
            // tslint:disable-next-line: no-string-literal
            schoolIdUrl: action.payload['school']['schoolIdUrl'] ? action.payload['school']['schoolIdUrl'] : ''
          }
        }
      };
    case UserProfileActionList.ADD_PROFILE_SUCCESS:
      return {
        ...state,
        currentStep: '5a',
        lastStep: '4',
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          photoUrl: action.payload['photoUrl']
        }
      };
    case UserProfileActionList.VERIFY_MOBILE_PROFILE_SUCCESS:
      return {
        ...state,
        currentStep: '5b',
        lastStep: '5a',
        isVerifyMobileRequest: true,
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          userMobile: action.payload['mobile'],
          // tslint:disable-next-line: no-string-literal
          countryCode: action.payload['countryCode'],
          mobileIsVerified: true
        }
      };
    case UserProfileActionList.VERIFY_EMAIL_PROFILE_SUCCESS:
      return {
        ...state,
        currentStep: '6',
        lastStep: '5b',
        isVerifyEmailRequest: true,
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          email: action.payload['email'],
          emailIsverified: true
        }
      };
    case UserProfileActionList.UPDATE_USER_CARD_PROFILE_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          photoUrl: action.payload['photoUrl']
        }
      };
    case UserProfileActionList.GET_CLASS_LIST_SUCCESS:
      return {
        ...state
      };
    case UserProfileActionList.ADD_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case UserProfileActionList.SKIP_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case UserProfileActionList.ADD_REFERRAL_SUCCESS:
      return {
        ...state
      };
    case UserProfileActionList.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case UserProfileActionList.UPDATE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          // tslint:disable-next-line: no-string-literal
          name: action.payload['newName'],
          dateOfBirth :action.payload['dateOfBirth'],
          initials: action.payload['initials'],
          userLocation: action.payload['location'],
        }
      };
      case UserProfileActionList.ADD_DOB:
        return {
          ...state,
          userData: {
            ...state.userData,
            email:action.payload['email'],
            dateOfBirth: action.payload['dateOfBirth']
          }
        };
    default:
      return state;
  }
}
