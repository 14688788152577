import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HOME_CARDS_ROUTE } from '../constants/routes.constant';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public homeSearchUpdate: EventEmitter<any> = new EventEmitter();
  public updateWorkingOnHomePage = new BehaviorSubject<any>('');
  public expandHomeCard = new BehaviorSubject<any>('');
  public showCardDetail = new BehaviorSubject<any>('');
  public filtersApplied = new BehaviorSubject<any>('');
  public isTimeManagementPopup = new BehaviorSubject<any>('')
  public selectedCardData = new BehaviorSubject<any>({});
  public tagColor = {
    'chapter': '#ff7575',
    'subject': '#609b93',
    'concept': '#9f37ae',
    'goal': '#1d7dea',
    'general': '#F8AA4B'
  }
  constructor(private http: HttpService, private alertService: AlertService, private router: Router) { }

  selectCard(card: any){
    this.selectedCardData.next(card);
    const route = `home/${HOME_CARDS_ROUTE[card.type]}`;
    localStorage.setItem('lastRequested',route);
    localStorage.setItem('lastRequestedModule', route);
    this.router.navigateByUrl(route);
  }
  
  fetchHomeCardsData(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/users/cards', true, null, true).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  fetchOngoingAttemptData(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/tests/ongoing', true, null, true, false, environment.serverURLv4).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  fetchRecentSearch(): any {
    return new Promise((resolve, reject) => {
      this.http.get('v4/auth/search/recent-searches', true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          console.log(res.data.recentSearches)
          resolve(res.data.recentSearches);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  searchSuggestions(types, query): any {
    query = encodeURIComponent(query);

    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/syllabus?searchQuery=${query}&types=${types}`, true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data.syllabusData);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getCommunityQuestion(searchQuery: string, pageNumber: number, masterId: any, type?: any) {
    searchQuery = encodeURIComponent(searchQuery)
    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/search/community/questions?searchQuery=${searchQuery}&pageNumber=${pageNumber}${masterId ? '&masterId=' + masterId : ''}${type ? '&type=' + type : ''}`, true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getSummaryCardData(id, type): any {
    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/search/summary-card?id=${id}&type=${type}`, true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  getSummaryRelatedTest(id, type, name) {
    name = encodeURIComponent(name)

    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/search/related-tests?type=${type}&syllabusId=${id}&name=${name}`, true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      })
    })
  }
  getRelatedTopicsData(id, type): any {
    return new Promise((resolve, reject) => {
      this.http.get(`v4/auth/search/related-topics?id=${id}&type=${type}`, true, null, true, false).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res.data.relatedTopics);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  clearAllSuggestion() {
    return new Promise((resolve, reject) => {
      this.http.delete(`v4/auth/search/recent-searches`, null, true).subscribe((res) => {
        if (res && res.code === 200) {
          resolve(res);
        } else {
          this.alertService.showErrorMessage(res.message);
        }
      });
    });
  }

  feedback(param) {
    let showLoader = false;
    if (param['reportType']) {
      showLoader = true;
    }
    this.http.post('community/feedback', true, param, showLoader, false, false, environment.serverURLv3).subscribe(res => console.log(res));
  }

  followQuestion(payload) {
    this.http.post('community/followQuestion', true, payload, true, false, false, environment.serverURLv3).subscribe(res => console.log(res));
  }

  //post feedback
  postFeedback(category, categoryId, reportType, types, voteType) {
    return this.http.post('community/feedback', true, { category, categoryId, reportType, types, voteType }, false, false, false, environment.serverURLv3);
  }
}
