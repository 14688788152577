import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shuffle } from 'lodash';

// internal imports
import { HttpService } from '../../shared/services/http.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../stores/app.reducers';
import { environment } from 'src/environments/environment';
declare var $: any;

@Injectable()
export class UtilityService {
  myRegex: any;
  tagColor = {
    'chapter': '#ff7575',
    'subject': '#609b93',
    'concept': '#9f37ae',
    'goal': '#1d7dea',
    'general': '#F8AA4B'
  }
  popularTagColors = ['#666666', '#1D7DEA', '#5BAED7', '#F8AA4B', '#78D2A7', '#B83B5E', '#9F37AE', '#C5D86D', '#F05D23', '#F08A5D']
  authenticated: boolean;

  constructor(private http: HttpService, private store: Store<AppState>) {
    this.store.select('auth').subscribe(auth => {
      this.authenticated = auth.authenticated;
      this.myRegex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
    });
    this.popularTagColors = shuffle(this.popularTagColors)
  }

  searchTags(param): Observable<any> {
    return this.http.get('community/searchTag', false, param, true, false, environment.serverURLv3);
  }


  searchSimilarQuestions(param): Observable<any> {
    if (this.authenticated)
      return this.http.get('community/searchQuestion', this.authenticated, param, true, false, environment.serverURLv3);
    else {
      return this.http.get('community/notAuthenticatedSearchQuestion', this.authenticated, param, true, false, environment.serverURLv3);
    }
  }

  contentManipulation(content) {
    return new Promise((resolve, reject) => {
      var $div = $('<div>').html(content);
      // modify attributes

      $div.find('a').attr('target', '_blank');
      $div.find('a').attr('rel', 'nofollow');
      $div.find('a').attr('class', 'bluecolr');
      //content = $div.html();
      var urls = [];
      var src;
      while (src = this.myRegex.exec(content)) {
        urls.push(src[1]);
      }
      var length = urls.length;
      if (urls.length > 0) {
        for (let i = 0; i < urls.length; i++) {
          this.getMeta(urls[i]).then(res => {

            ///////////////////////  condition based on width ////////////////////////
            if (res['width'] > 800 && res['width'] < 1000) {
              $div.find('img').eq(i).addClass('width-one');
            }
            else if (res['width'] < 400) {
              $div.find('img').eq(i).addClass('width-two');
            }
            else if (res['width'] > 1000) {
              $div.find('img').eq(i).addClass('width-three');
            }

            ////////////////////// condition based on height ///////////////////////
            if (res['height'] > 500) {
              $div.find('img').eq(i).addClass('height-one');
            }
            if (res['height'] < 500 && res['width'] < 400) {
              $div.find('img').eq(i).addClass('height-two');
            }
            length--;
            if (length == 0) {
              content = $div.html();
              resolve(content);
            }
          });
        }
      }
      else {
        content = $div.html();
        resolve(content);
      }
    })
  }

  getMeta(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.src = url;
    })
  }

  debounce(func, wait, immediate = false) {
    let timeout;
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

}
