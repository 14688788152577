<!-- Free Trial expired -->
<div class="modal fade lite-modal" tabindex="-1" id="trialExpired" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal"><img src="/assets/images/g-lite/popup-close.svg" alt="Close"></button>
            <div class="modal-body">
                <div class="headSec">
                    <img src="/assets/images/g-lite/trial-expired.svg" alt="Free Trial Expired!"/>
                    <h3>Free Trial Expired!</h3>
                </div>
                <p>
                  Your 14-day trial period is over. Hence you cannot access this test.
                </p>
                <p class="mt-5 pt-0">Learning, however, never stops!</p>
                <p>Buy a <a>myPAT</a> course to unlock your access to a successful learning journey!</p>
            </div>
            <div class="modal-footer single-btn">
                <button type="button" class="btn btn-primary btn-rounded btn-block" data-dismiss="modal" (click)="navigateToBuyCourses()">Buy Course</button>
            </div>
        </div>
    </div>
  </div>