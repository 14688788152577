import { Action } from '@ngrx/store';

export const START_TEST = "START_TEST";
export const START_TEST_DETAILS = "START_TEST_DETAILS";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_START = "GET_QUESTIONS_START";
export const RESUME_TEST = "RESUME_TEST";
export const RESUME_TEST_START = "RESUME_TEST_START";
export const GET_QUESTION = "GET_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_SECTION_TYPE = "UPDATE_SECTION_TYPE";
export const MARK_REVIEW = "MARK_REVIEW";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const SEND_RESPONSE = "SEND_RESPONSE";
export const SEND_RESPONSE_START = "SEND_RESPONSE_START";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const MARK_PROGRESS = "MARK_PROGRESS";
export const MARK_FOR_REVIEW = "MARK_FOR_REVIEW";
export const UNMARK_FOR_REVIEW = "UNMARK_FOR_REVIEW";
export const GET_RESULT_START = "GET_RESULT_START";
export const GET_RESULT = "GET_RESULT";
export const UPDATE_PROGRESS_CPP = 'UPDATE_PROGRESS_CPP';
export const MARK_PROGRESS_CPP = 'MARK_PROGRESS_CPP';
export const UPDATE_QUESTION_TIME = 'UPDATE_QUESTION_TIME';
export const UPDATE_PROGRESS_JEEADV = 'UPDATE_PROGRESS_JEEADV';
export const MARK_PROGRESS_JEEADV = 'MARK_PROGRESS_JEEADV';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';

export class UpdateQuestionTime implements Action {
    readonly type = UPDATE_QUESTION_TIME;
    constructor(public payload: {"timeSpent": any, "time": any}){}
}

export class StartTest implements Action {
    readonly type = START_TEST;
    constructor(public payload: {"teacherId": any, "assignmentId": any, "orgId": any, "free": any, "challengeId": any, "testId": string, "courseId": any}){}
}

export class StartTestDetail implements Action {
    readonly type = START_TEST_DETAILS;
    constructor(public payload: {"testType": any, "startTime": any, "currentQuestionId": any, "currentSectionId": any, "currentTypeId": any, "details": any, "questions": any, "progress": any, "questionIds": any, "attemptNo": any, "question_array": any, "attemptId": any, "questionsAnsweredReviewed": any, "questionsReviewed": any, "questionsNotVisited": any, "questionsVisited": any, "questionsAttempted": any, "questionsNotAttempted": any, "totalDuration": any, "minQuestions": any, "paperTitle": any,"testStartDate":any,"testEndDate":any, "serverTimeInitially":any, "questionImages":any, "sectionWiseNumbering":boolean, "showNewInstruction":boolean, "isAutoProctoring":boolean}){}
}

export class ResumeTestStart implements Action {
    readonly type = "RESUME_TEST_START";
    constructor(public payload: {"teacherId": any, "assignmentId": any, "orgId": any, "challengeId": any, "testId": any, "attemptId": any, "courseId": any}){}    
}

export class ResumeTest implements Action {
    readonly type = "RESUME_TEST";
    constructor(public payload: {}){}    
}

export class GetQuestions implements Action {
    readonly type = GET_QUESTIONS;
    constructor(public payload: {}){}
}
export class GetQuestionsStart implements Action {
    readonly type = GET_QUESTIONS_START;
    constructor(public payload: {"duration": any, "attemptId": any, "questionIds": any, "testId": any, "courseId": any}){}
}
export class UpdateSectionType implements Action {
    readonly type = UPDATE_SECTION_TYPE;
    constructor(public payload: {"currentTypeId": any, "timeSpent": any, "testId": any}){}
}
export class UpdateSection implements Action {
    readonly type = UPDATE_SECTION;
    constructor(public payload: {"type": any, "currentTypeId": any, "currentSectionId": any, "timeSpent": any, "testId": any}){}
}
export class GetQuestion implements Action {
    readonly type = GET_QUESTION;
    constructor(public payload: {"testId": string, "qId": string}){}
} 
export class UpdateQuestion implements Action {
    readonly type = UPDATE_QUESTION;
    constructor(public payload: {"testId": any, "currentQuestionId": any, "currentSectionId": any, "currentTypeId": any, "timeSpent": any}){}
}
export class MarkReview implements Action {
    readonly type = MARK_REVIEW;
    constructor(public payload: {"timeSpent"}){};
}
export class UnMarkReview implements Action {
    readonly type = UNMARK_FOR_REVIEW;
    constructor(public payload: {}){};
}
export class SendResponse implements Action {
    readonly type = SEND_RESPONSE;
    constructor(public payload: { 'timeLeft': any, 'totalSessionTime': any}) {}
}
export class SendResponseStart implements Action {
    readonly type = SEND_RESPONSE_START;
    constructor(public payload: {"optionsMatrix": any, "optionsMcq": any, "type": any, "isVisited": any, "isMarked": any, "isAnsweredMarkedForReview": any, "startTime": any, "duration": any, "testId": string, "questionId": string, "sectionId": string, "subSectionId": string, "options": any, "timePerQuestion": string, "courseId": string, "isAttempted": string, "attemptNo": number, "attemptId": string}){};
}
export class UpdateProgress implements Action {
    readonly type = UPDATE_PROGRESS;
    constructor(public payload: {"options": any, "timeSpent": any, "testId": any}){};
}
export class MarkProgress implements Action {
    readonly type = MARK_PROGRESS;
    constructor(public payload: {"options": any, "timeSpent": any, "testId": any}){};
}
export class UpdateProgressCPP implements Action {
    readonly type = UPDATE_PROGRESS_CPP;
    constructor(public payload: {"options": any, "timeSpent": any, "testId": any}){};
}
export class MarkProgressCPP implements Action {
    readonly type = MARK_PROGRESS_CPP;
    constructor(public payload: {"options": any, "timeSpent": any, "testId": any}){};
}
export class UpdateProgressJEEADV implements Action {
    readonly type = UPDATE_PROGRESS_JEEADV;
    constructor(public payload: {'options': any, 'timeSpent': any, 'testId': any}) {}
}
export class MarkProgressJEEADV implements Action {
    readonly type = MARK_PROGRESS_JEEADV;
    constructor(public payload: {'options': any, 'timeSpent': any, 'testId': any}) {}
}
export class MarkForReview implements Action {
    readonly type = MARK_FOR_REVIEW;
    constructor(public payload: {"testId": string, "qId": string, "isMarked": boolean}){};
}
export class GetResultStart implements Action {
    readonly type = GET_RESULT_START;
    constructor(public payload: {"testId": string, "attemptId": string, "totalTimeTaken": any, "courseId": string}){};
}
export class GetResult implements Action {
    readonly type = GET_RESULT;
    constructor(public payload: {}){};
}
export class ClearResponse implements Action {
    readonly type = CLEAR_RESPONSE;
    constructor(public payload: {'options': any, 'timeSpent': any, 'testId': any}) {}
}

export type TestOngoingActionList = UpdateQuestionTime|MarkProgress|GetQuestions|GetQuestion|UpdateQuestion|UpdateProgress|MarkForReview|UpdateSectionType|UpdateSection|MarkReview|GetResult|SendResponse|StartTestDetail|ResumeTest|UnMarkReview|MarkProgressCPP|UpdateProgressCPP|UpdateProgressJEEADV|MarkProgressJEEADV|ClearResponse;
