<div class="row">
  <div class="col-sm-12 d-block" [ngClass]="{'d-sm-none': !userData?.isAdmissionTestUser}">
    <div class="navheader d-md-none">
      <h2><img appLazyLoad src="/assets/img/s3-images/cross-icon-black.png" (click)="closeFilter()" class="cross_img">Setting</h2>
    </div>
  </div>
</div>
<div class="sidebar-item listView" [ngClass]="{'admission-test-filters': userData?.isAdmissionTestUser}">
  <span *ngFor="let filterData of filterDataToShow">
    <span *ngIf="filterData.filterKey">
      <span *ngIf="filterData.type==='multi'">
        <!-- <div class="card">
          <ul class="list-group list-group-flush"> -->
            <app-multiselectfilter [filters]="filterData" [clearingData]="clearingData" (onChange)="onChangeMultiSelect($event)"></app-multiselectfilter>
          <!-- </ul>
        </div> -->
      </span>
      <span *ngIf="filterData.type==='single'">
        <!-- <div class="card">
          <ul class="list-group list-group-flush"> -->
            <app-single-select-filter [filters]="filterData" [clearingData]="clearingData" (onChange)="onChangeSingleSelect($event)"></app-single-select-filter>
          <!-- </ul>
        </div> -->
      </span>
    </span>
  </span>
  <div class="col-sm-12 d-block" [ngClass]="{'d-sm-none': !userData?.isAdmissionTestUser}">
      <div class="filter_close">
        <button class="btn btn-primarytext" (click)="resetFilters()">Reset</button>
        <button class="btn btn-primary btn-lg filter-submit d-md-none" (click)="apply()">Apply</button>
      </div>
    </div>
</div>