import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExtraEdgeService {

  accessToken1: Observable<any>;
  accessToken: string;
  authenticated: boolean;
  private baseUrl = 'https://thirdpartyapi.extraaedge.com/api/SaveRequest?AuthToken=MYPATB2C-14-06-2017&Source=mypatb2c';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) { }

  extraEdgeUpdateUtmAdWords(parameters): void {
    let flag = true;
    let url = this.baseUrl;
    this.route.queryParams.subscribe((params) => {
      if (!(_.isEmpty(params))) {
        if (params.utm_source) {
          url = url + '&LeadSource=' + params.utm_source;
          url = params.utm_source === 'adwords' ? url + '&ReasonCode=squad' : url;
        } else {
          url = url + '&LeadSource=Website';
        }
        if (params.utm_term) {
          url = url + '&LeadType=' + params.utm_term;
        } else {
          url = url + '&LeadType=Online';
        }

        if (params.utm_content) {
          url = url + '&LeadName=' + params.utm_content;
        } else {
          url = url + '&LeadName=Normal Register';
        }
        url = url + parameters + '&Priority=Medium&Course=myPAT';

        this.http.get(url).subscribe((res) => { });
      } else {
        url = url + parameters + '&Priority=Medium&Course=myPAT&LeadSource=Website&LeadType=Online&LeadName=Normal Register';
        this.http.get(url).subscribe((res) => { });
      }
      flag = false;
    });
  }

  extraEdgeNormalUpdate(params): void {
    const url = this.baseUrl + params;
    this.http.get(url).subscribe((res) => {});
  }

  extraEdgeJSONUpdate(params): void {
    const jsonParams = Object.assign(params);
    let urlQueryString = Object.keys(jsonParams).map(key => `${key}=${jsonParams[key]}`).join('&');
    urlQueryString = '&' + urlQueryString;

    const url = this.baseUrl + urlQueryString;
    this.http.get(url).subscribe((res) => {});
  }

  extraEdgeJSONUpdateUtmAdwords(params: { [x: string]: any }, options: { sourceName?: string, addLeadMeta?: boolean } = {}): void {
    let flag: boolean = true;
    let url = this.baseUrl;
    // common transformation
    if (!environment.production) {
      params.Leadstatus = 'Test Lead';
    }
    const jsonParams = Object.assign(params);
    let urlQueryString = Object.keys(jsonParams).map(key => `${key}=${jsonParams[key]}`).join('&');
    urlQueryString = '&' + urlQueryString;
    if (options && options.addLeadMeta) {
      const name = options && options.sourceName ? options.sourceName : 'Normal Register';
      this.route.queryParams.subscribe((paramsN) => {
        if (!(_.isEmpty(paramsN))) {
          if (params.utm_source) {
            url = url + '&LeadSource=' + params.utm_source;
            url = params.utm_source === 'adwords' ? url + '&ReasonCode=squad' : url;
          } else {
            url = url + '&LeadSource=Website';
          }

          if (params.utm_term) {
            url = url + '&LeadType=' + params.utm_term;
          } else {
            url = url + '&LeadType=Online';
          }

          if (params.utm_content) {
            url = url + '&LeadName=' + params.utm_content;
          } else {
            url = url + '&LeadName=' + name;
          }
          url = url + urlQueryString + '&Priority=Medium&Course=myPAT';
        } else {
          url = url + urlQueryString + '&Priority=Medium&Course=myPAT&LeadSource=Website&LeadType=Online&LeadName=' + name;
        }
        flag = false;
        console.log(url);
        this.http.get(url).subscribe((res) => { });
      });
    } else {
      url = url + urlQueryString;
      console.log(url);
      this.http.get(url).subscribe((res) => { });
    }
  }

  manipulateData(dataRecieved, str): void {
    let payloadToPass;
    let campaignName;
    switch (str) {
      case 'sign-up': {
        payloadToPass = {
          MobileNumber: dataRecieved.mobile,
          Field2: dataRecieved.className
        };
        campaignName = '';
        break;
      }

      case 'verify-otp': {
        payloadToPass = {
          MobileNumber: dataRecieved.mobile,
          MobileVerified: true
        };
        campaignName = '';
        break;
      }
    }

    this.extraEdgeJSONUpdateUtmAdwords(payloadToPass, { sourceName: campaignName, addLeadMeta: true });
  }
}
