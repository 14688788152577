import { HighlightSearch } from './pipes/highlight-search.pipe';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { HttpService } from './services/http.service';
import { UtilityService } from './services/utility.service';
import { ExtraEdgeService } from './services/extra-edge.service';
import { CleverTapService } from './services/clever-tap.service';
import { NotificationService } from './services/notification.service';

import { CaptchaDirective } from './directives/captcha.directive';

import { LoaderComponent } from './components/loader/loader.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TransferToPaytmComponent } from './components/transfer-to-paytm/transfer-to-paytm.component';
import { HeaderAfterLoginComponent } from './components/header-after-login/header-after-login.component';
import { ShareReferralPopupComponent } from '../profile/my-cash-card/share-referral-popup/share-referral-popup.component';
import { ReportAbuseComponent } from './components/report-abuse/report-abuse.component';
import { ConvertSecondsPipe } from './pipes/convert-seconds.pipe';
import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SeriousAttemptWarningComponent } from './serious-attempt-warning/serious-attempt-warning.component';
import { InfoPopupComponent } from './components/info-popup/info-popup.component';
import { HomeSearchComponent } from '../home-page/shared/home-search/home-search.component';
import { TagCreatorSharedComponent } from './components/tag-creator-shared/tag-creator-shared.component';
import { DoneWithRevisionComponent } from './components/done-with-revision/done-with-revision.component';
import { PageLockComponent } from './page-lock/page-lock.component';
import { ReportAnIssueComponent } from './components/report-an-issue/report-an-issue.component';
import { ReportAnIssueIdeaComponent } from './components/report-an-issue-idea/report-an-issue-idea.component';
import { SelectedCardLoaderComponent } from '../home-page/shared/selected-card-loader/selected-card-loader.component';
import { TestDetailsSyllabusComponent } from './components/test-details-syllabus/test-details-syllabus.component';
import { ZoomDirective } from './directives/zoom.directive';
import { NotificationComponent } from './components/header-after-login/notification/notification.component';
import { ProfileDropdownComponent } from './components/header-after-login/profile-dropdown/profile-dropdown.component';
import { LogoutConfirmComponent } from './components/logout-confirm/logout-confirm.component';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { LazyLoadingService } from './services/lazy-loading.service';
import { SideFilterListComponent } from '../test-results-analytics/side-filter-list/side-filter-list.component';
import { AutoProctorDialogComponent } from '../test/shared/components/auto-proctor-dialog/auto-proctor-dialog.component';
import { MultiselectfilterComponent } from '../test-results-analytics/side-filter-list/multiselectfilter/multiselectfilter.component';
import { MathliveDirective } from './directives/mathlive.directive';
import { MyplanPopupComponent } from './components/myplan-popup/myplan-popup.component';
import { LoaderLiteComponent } from './loader-lite/loader-lite.component';
import { FreeTrialFormComponent } from './components/form/free-trial-form/free-trial-form.component';
import { CoursesWeOfferComponent } from './components/courses-we-offer/courses-we-offer.component';
import { OtpVerifyModalComponent } from './components/otp-verify-modal/otp-verify-modal.component';
import { TestimonialHomeComponent } from './components/testimonial-home/testimonial-home.component';
import { SafePipe } from './pipes/safe.pipe';
import { SanitiseHTMLPipe } from './pipes/sanitise-html.pipe';
import { DownloadEbookComponent } from '../landing-pages/ebooks/download-ebook/download-ebook.component';
import { SocialSignupLoginComponent } from '../auth/signup-login-master/shared/social-signup-login/social-signup-login.component';
import { DetectVisibilityDirective } from './directives/detect-visibility.directive';
import { PrimaryGoalPopupComponent } from './components/primary-goal-popup/primary-goal-popup.component';
import { FreeTrialExpiryComponent } from '../home-page/shared/free-trial-details/free-trial-expiry/free-trial-expiry.component';
import { TestFiltersComponent } from '../home-page/shared/test-filters/test-filters.component';
import { ApiFailedForCardsComponent } from '../home-page/shared/api-failed-for-cards/api-failed-for-cards.component';
import { LockedStateComponent } from '../home-page/shared/locked-state/locked-state.component';
import { ProfileUserInfoComponent } from './components/profile-user-info/profile-user-info.component';
import { DateFilterComponent } from '../home-page/shared/test-filters/date-filter/date-filter.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

export interface LazyLoadFile {
  url: string;
  type: 'stylesheet' | 'script';
}

export class LazyLoadFileImpl implements LazyLoadFile {
  url: string;
  type: 'stylesheet' | 'script';
  constructor(url: string, type: 'stylesheet' | 'script') {
    this.url = url;
    this.type = type;
  }
}

@NgModule({
  imports: [
    CommonModule, OwlModule, FormsModule, HttpClientModule, InfiniteScrollModule,
    RouterModule, ReactiveFormsModule, CarouselModule, AngularMyDatePickerModule
  ],

  declarations: [
    CaptchaDirective,
    ChangePasswordComponent, HeaderAfterLoginComponent, LoaderComponent,
    ShareReferralPopupComponent, TransferToPaytmComponent,
    ReportAbuseComponent, ConvertSecondsPipe, SeriousAttemptWarningComponent,
    HomeSearchComponent, PageLockComponent,
    HighlightSearch,
    NotificationComponent,
    ProfileDropdownComponent,
    LogoutConfirmComponent,
    InfoPopupComponent,
    TagCreatorSharedComponent,
    DoneWithRevisionComponent,
    ReportAnIssueComponent,
    ReportAnIssueIdeaComponent,
    SelectedCardLoaderComponent,
    SideFilterListComponent,
    AutoProctorDialogComponent,
    MultiselectfilterComponent,
    // SafeHtmlPipe
    TestDetailsSyllabusComponent,
    ZoomDirective,
    DateDifferencePipe,
    MathliveDirective,
    MyplanPopupComponent,
    LoaderLiteComponent,
    FreeTrialFormComponent,
    CoursesWeOfferComponent,
    OtpVerifyModalComponent,
    TestimonialHomeComponent,
    SafePipe,
    SanitiseHTMLPipe,
    DownloadEbookComponent,
    SocialSignupLoginComponent,
    DetectVisibilityDirective,
    FreeTrialExpiryComponent,
    TestFiltersComponent,
    ApiFailedForCardsComponent,
    LockedStateComponent,
    PrimaryGoalPopupComponent,
    ProfileUserInfoComponent,
    DateFilterComponent
  ],
  exports: [
    CaptchaDirective,
    LoaderComponent, HeaderAfterLoginComponent,
    ShareReferralPopupComponent, TransferToPaytmComponent,
    ReportAbuseComponent, ConvertSecondsPipe, SeriousAttemptWarningComponent,
    HighlightSearch,
    InfoPopupComponent, HomeSearchComponent,
    TagCreatorSharedComponent, DoneWithRevisionComponent, PageLockComponent,
    ReportAnIssueComponent, ReportAnIssueIdeaComponent,
    SelectedCardLoaderComponent, ZoomDirective,
    CommonModule, OwlModule, FormsModule, HttpClientModule, InfiniteScrollModule,
    RouterModule, ReactiveFormsModule, DateDifferencePipe,
    ChangePasswordComponent, NotificationComponent, ProfileDropdownComponent, LogoutConfirmComponent, SideFilterListComponent, AutoProctorDialogComponent, MultiselectfilterComponent, TestDetailsSyllabusComponent,
    MyplanPopupComponent, MathliveDirective,
    LoaderLiteComponent, FreeTrialFormComponent, CoursesWeOfferComponent, OtpVerifyModalComponent, TestimonialHomeComponent, SafePipe, SanitiseHTMLPipe, DownloadEbookComponent, SocialSignupLoginComponent, DetectVisibilityDirective,
    FreeTrialExpiryComponent,
    TestFiltersComponent,
    ApiFailedForCardsComponent,
    LockedStateComponent,
    PrimaryGoalPopupComponent,
  ],
  providers: [LazyLoadingService, DatePipe, HttpService, CleverTapService, ExtraEdgeService, NotificationService],
})
export class SharedModule { }
