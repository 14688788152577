<div class="goalSettingHeader">
    <div class="container">
        <div class="desktop d-none d-sm-block">
            <div class="row d-flex align-items-center">
                <div class="col-md-6 logo">
                    <img src="/assets/images/mypat_fiitjee_logo.svg" alt="logo">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="VerifyPasscodeScreen">
    <div class="verifypasscodeimg">
        <img src="../../../assets/images/passcodeIcon.svg" />
    </div>
    <h3>Passcode Required for Access</h3>
    <p>
        Please enter the 6-digit Passcode sent to you along with the link for this proforma
    </p>

    <form #passcodeForm="ngForm" (submit)="verifyPasscode(passcodeForm.value)">
        <div class="form-group" id="passcode-container">
            <input type="text" class="form-control inputs" name="passcode1" id="passcode1" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
            <input type="text" class="form-control inputs" name="passcode2" id="passcode2" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
            <input type="text" class="form-control inputs" name="passcode3" id="passcode3" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
            <input type="text" class="form-control inputs" name="passcode4" id="passcode4" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
            <input type="text" class="form-control inputs" name="passcode5" id="passcode5" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
            <input type="text" class="form-control inputs" name="passcode6" id="passcode6" maxlength="1"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')" ngModel required />
        </div>
        <div class="error" *ngIf="passcodeApiError">{{ passcodeApiError }}</div>
        <div class="proceedBtn">
        <button type="submit" class="btn btn-primary submit-btn" [disabled]="isLinkExpired">Proceed</button>
    </div>
    </form>
</div>