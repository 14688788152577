import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

interface StatusMessages {
    error: string;
    warning: string;
    success: string;
    type: number;
    custom: string;
    className?: string;
    btnName?: string;
    customBtn?: string;
    icon?: string;
    subtitle?: string;
}
@Injectable()
export class AlertService {
    public pictureURL;
    status: StatusMessages = {
        error: null,
        warning: null,
        success: null,
        type: -1,
        custom: null,
        className: '',
        btnName: null,
        customBtn: null,
        icon: null,
        subtitle: null
    };


    // Declare subject
    messageChange = new Subject<StatusMessages>();
    public updateWorkingOnHomePage = new BehaviorSubject<any>({});
    public updateWorkingHomePage = new BehaviorSubject<any>({});
    public undoRevisionQuestion = new BehaviorSubject<any>({});
    public deleteRevisionQuestion = new BehaviorSubject<any>(null);
    public updateAfterViewTask = new BehaviorSubject<any>('');
    public cardDetails = new BehaviorSubject<any>('');
    public viewTaskId = new BehaviorSubject<any>('');
    public filterId = new BehaviorSubject<any>({});
    public selectTagObject = new BehaviorSubject<any>({})
    public comparisonOf = new BehaviorSubject<any>('');
    public questionId= new BehaviorSubject<any>({});
    public issueId=new BehaviorSubject<any>('');
    public helpId=new BehaviorSubject<any>('');
    public helpStatus=new BehaviorSubject<any>('');
    public helpNotifiStatus=new BehaviorSubject<any>('');
    public IdeaStatus=new BehaviorSubject<any>('');
    public swotType = new BehaviorSubject<any>('');
    public notificationCountUpdate = new BehaviorSubject<any>(0);
    public clickedBackHome = new BehaviorSubject<any>(false);
    public question
    errorChange = new Subject<any>();
    changeEvent = new Subject<any>();
    timeOut: any;

    constructor() { }

    emptyMessage(): void {
        clearTimeout(this.timeOut);
        this.status = {
            error: null,
            warning: null,
            success: null,
            type: -1,
            custom: null,
            className: '',
        };
    }

    showErrorMessage(str): void {
        this.emptyMessage();
        if (typeof str === 'object' && str !== null) {
            this.status.error = str?.title;
            this.status.subtitle = str?.subTitle;
        } else {
            this.status.error = str;
        }
        this.status.type = 0;
        this.messageChange.next(this.status);
        this.timeOut = setTimeout(() => {
            this.status.error = null;
            this.status.type = -1;
            this.messageChange.next(this.status);
        }, 5000);
    }
    showSuccessMessage(str): void {
        this.emptyMessage();
        if (typeof str === 'object' && str !== null) {
            this.status.success = str?.title;
            this.status.subtitle = str?.subTitle;
        } else {
            this.status.success = str;
        }
        this.status.type = 1;
        this.messageChange.next(this.status);

        this.timeOut = setTimeout(() => {
            this.status.success = null;
            this.status.type = -1;
            this.messageChange.next(this.status);
        }, 5000);
    }

    showWarningMessage(str): void {
        this.emptyMessage();
        this.status.warning = str;
        this.status.type = 2;
        this.messageChange.next(this.status);
        this.timeOut = setTimeout(() => {
            this.status.warning = null;
            this.status.type = -1;
            this.messageChange.next(this.status);
        }, 5000);
    }

    throwErrorMessage(msg): void {
        this.errorChange.next(msg);
    }

    throwChangeEvent(msg): void {
        this.changeEvent.next(msg);
    }

    showCustomMessage(str, className = '', icon, type = 3): void {
        this.emptyMessage();
        this.status.custom = str;
        this.status.type = type;
        this.status.className = className;
        this.status.icon = icon;
        this.messageChange.next(this.status);
        this.timeOut = setTimeout(() => {
            this.status.warning = null;
            this.status.type = -1;
            this.messageChange.next(this.status);
        }, 6000);
    }

    showCustomMessageWithButton(str, btnName, type = 4): void {
        this.emptyMessage();
        this.status.customBtn = str;
        this.status.type = type;
        this.status.btnName = btnName;
        this.messageChange.next(this.status);
        this.timeOut = setTimeout(() => {
            this.status.warning = null;
            this.status.customBtn = null;
            this.status.btnName = null;
            this.status.type = -1;
            this.messageChange.next(this.status);
            this.deleteRevisionQuestion.next(true);
        }, 5000);
    }

    hideCustomMessageWithBtn(): void {
        this.status.warning = null;
        this.status.customBtn = null;
        this.status.btnName = null;
        this.status.type = -1;
        this.messageChange.next(this.status);
    }

    getObservable(): any {
        return this.messageChange.asObservable();
    }
}
