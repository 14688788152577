import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import * as LoaderReducer from '../../../stores/loader/loader.reducers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() pageFrom: string;
  public isloader: boolean = true;
  public loaderSub: any;
  constructor(private store: Store<fromApp.AppState>) {
    this.loaderSub = this.store.select('loader').pipe(
      map((data: any) => {
        return data.isLoader;
      })
    );
    this.loaderSub.subscribe(res => {
      this.isloader = res;
    });
  }


  ngOnInit(): void {

  }

}
