<!-- Modal -->
<div class="modal fade" id="referralModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true"><img src="../../../../assets/images/cross-icon-modal.svg" /></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 d-none d-lg-block referral-img">
          </div>
          <div class="col-lg-6 col-md-12 referral-code">
            <div class="" *ngIf="!inviteSent">
              <h3>
                Win ₹500 Each For You<br />
                & Your Friends.
              </h3>
              <p *ngIf="!showTextBox">Invite them on Glorifire with your unique referral code</p>
              <div class="input-group" *ngIf="!showTextBox">
                <input type="text" class="form-control" [value]="referralLink" readonly />
                <div class="input-group-append">
                  <button type="button" class="btn btn-copycode" (click)="copyCode()">
                    Copy Link
                  </button>
                </div>
              </div>
              <p class="copied-text" *ngIf="isLinkCopied">
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.25 0.96875H12.1578C12.0047 0.96875 11.8594 1.03906 11.7656 1.15937L5.32345 9.32031L2.23438 5.40625C2.18765 5.34692 2.12809 5.29895 2.06017 5.26593C1.99224 5.23292 1.91772 5.21572 1.8422 5.21562H0.750009C0.645321 5.21562 0.587509 5.33594 0.651571 5.41719L4.93126 10.8391C5.13126 11.0922 5.51563 11.0922 5.7172 10.8391L13.3484 1.16875C13.4125 1.08906 13.3547 0.96875 13.25 0.96875Z"
                    fill="#666666" />
                </svg>
                Copied!
              </p>
              <p class="btn-back font12" *ngIf="showTextBox" (click)="goBack()">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.426 2.42408C7.73816 2.10732 7.74796 1.58345 7.44776 1.25386C7.29384 1.08504 7.08815 1 6.88226 1C6.68657 1 6.49088 1.07655 6.33872 1.2309L1.24059 6.40333C1.08686 6.55933 1 6.77492 1 7.00002C1 7.22492 1.08686 7.44051 1.24059 7.59651L6.33872 12.7689C6.65088 13.0857 7.14756 13.0756 7.44776 12.746C7.74796 12.4164 7.73816 11.8925 7.426 11.5758L2.91592 7.00002L7.426 2.42408Z"
                    fill="#666666" stroke="#666666" stroke-width="0.3" />
                </svg>
                Back
              </p>
              <p *ngIf="showTextBox" class="font14">
                Now you can share your referral code on your friend's {{ text }} via
                text directly!
              </p>

              <div class="delete-container" *ngIf="showTextBox && text === 'mobile'">
                <div class="delete-textbox" *ngFor="let cards of mobileAdded; let i=index">
                  <div class="deletebox">

                    <input type="text" class="form-control" name="mobile" #mobile="ngModel"
                      [(ngModel)]="mobileAdded[i].number" [pattern]="mobilePattern" value=""
                      placeholder="Enter Friend’s Mobile Number" />
                    <span *ngIf="mobileAdded.length >= 2"><button class="btn-delete" data-toggle="tooltip"
                        data-placement="top" title="Delete" (click)="deleteEntry(i)">
                        <img src="/assets/images/delete.svg" />
                      </button>
                    </span>

                  </div>
                  <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">
                    <div class="error" *ngIf="mobile.errors.pattern">
                      Please enter a valid mobile number
                    </div>
                  </div>
                </div>
              </div>

              <div class="delete-container" *ngIf="showTextBox && text === 'email'">
                <div class="delete-textbox" *ngFor="let cards of emailsAdded; let i=index">
                  <div class="deletebox">
                    <input type="text" class="form-control" name="userEmail" #userEmailss="ngModel"
                      [(ngModel)]="emailsAdded[i].email" [pattern]="emailPattern" value=""
                      placeholder="Enter Friend’s Email ID" />
                    <span *ngIf="emailsAdded.length >= 2"><button class="btn-delete" data-toggle="tooltip"
                        data-placement="top" title="Delete" (click)="deleteEntry(i)">
                        <img src="/assets/images/delete.svg" /></button>
                    </span>
                  </div>
                  <div *ngIf="userEmailss.invalid && (userEmailss.dirty || userEmailss.touched)">
                    <div class="error" *ngIf="userEmailss.errors.pattern">
                      Please enter a valid Email ID
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-more" *ngIf="showTextBox">
                <button class="btn-addmore" (click)="addMoreItems()" *ngIf="showAddMore()">Add More</button>
                <button class="btn btn-invite" [disabled]="!isSendInviteEnabled()"
                  (click)="isSendInviteEnabled() ? sendInvites() : ''">Invite</button>
              </div>
              <p *ngIf="!showTextBox">or share with</p>
              <div class="share-icons" *ngIf="!showTextBox">
                <a href="https://www.facebook.com/sharer/sharer.php?u={{getEncodedURL()}}" target="_blank">
                  <img src="/assets/images/share-facebook.svg" />
                </a>
                <a href="https://twitter.com/intent/tweet?text={{getEncodedURL()}}" target="_blank">
                  <img src="/assets/images/share-twitter.svg" />
                </a>
                <a (click)="showEmailInputBox()">
                  <img src="/assets/images/share-mail.svg" />
                </a>
                <a (click)="showMobileInputBox()">
                  <img src="/assets/images/share-msg.svg" />
                </a>
              </div>

            </div>
            <!-- Invite send div -->
            <div class="msg-text" *ngIf="inviteSent && isInviteSuccessfull">
              <img src="/assets/images/smiling.svg" />
              <h3>Thanks!</h3>
              <p>Your invite has been successfully sent!</p>
              <button class="btn btn-msg" (click)="closeModal()">Ok, Got it</button>
            </div>


            <!-- Invite not send div -->
            <div class="msg-text1" *ngIf="inviteSent && !isInviteSuccessfull">
              <img src="/assets/images/sad.svg" />
              <h3>OOPS!</h3>
              <p class="text-center">Your invite could not be sent right now. <br>
                Please try again after sometime.</p>
              <button class="btn btn-msg" (click)="closeModal()">Ok, Got it</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>