import { Component, ViewChild, Input, OnInit, OnDestroy, ElementRef, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CaptchaDirective } from 'src/app/shared/directives/captcha.directive';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { countryArray } from 'src/app/shared/constants/glite.constants';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../stores/app.reducers';
import * as AuthActions from '../../../stores/auth/auth.actions';
import { EbooksService } from '../ebooks.service';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper.service';
declare var $;
@Component({
  selector: 'app-download-ebook',
  templateUrl: './download-ebook.component.html',
  styleUrls: ['./download-ebook.component.scss']
})
export class DownloadEbookComponent implements OnInit, OnDestroy {
  @ViewChild(CaptchaDirective) vc: CaptchaDirective;
  @ViewChild('mobileContainer') mobileContainer: ElementRef<HTMLInputElement>;
  @ViewChild('dropdown') dropdown: ElementRef;
  @Input() classesDropdown: any[];
  @Input() availableClasses: any[];
  @Input() isCaptchaEnabled: boolean;
  @Input() CTATitle: string;
  @Input() popUpHeading: string;
  private activeSubscription = new Subscription();
  private isDropdownOpen: boolean = false;
  public validMobileNumberLength: number = 10;
  public enableVerifyOTPPopup: boolean = false;
  public ebookDownloadForm: FormGroup = null;
  public selectedClass: any = { class: 'Select Class', _id: '00' };
  public captchaInfo: any = {
    showCaptcha: false,
    captchaChecked: true,
    showCaptchaError: false
  };
  public errorInfo: any = {
    name_error: false,
    class_error: false,
    mobile_error: false,
    no_name: 'Name should at least be of 3 characters',
    no_class: 'This field is required',
    invalid_number: 'Enter a valid number',
    validNameLength: 3,
    defaultClassId: '00'
  };
  public selectedDialCode: any = countryArray[0];

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private fb: FormBuilder,
    private utility: UtilityService,
    private _ebook: EbooksService,
    private store: Store<fromApp.AppState>,
    private _helper: HelperService
  ) { }

  ngOnInit(): void {
    this.initializePopData();

    this.activeSubscription.add(
      this.utility.captchaStateHandler.subscribe((resetCaptcha: boolean) => {
        if (resetCaptcha) {
          this.enableVerifyOTPPopup = false;
          this.resetCaptcha();
        }
      })
    );

    this.activeSubscription.add(
      this._ebook.handleDownloadEbookPopup.subscribe((showPopup: boolean) => {
        if (showPopup) {
          $('#ebookDownload').modal('show');
          this.initializePopData();
        }
      })
    );

    this.activeSubscription.add(
      this._helper.handleSignupError.subscribe((data: any) => {
        if (!data.signupError) {
          this.enableVerifyOTPPopup = true;
          this.isDropdownOpen = false;
          $('#ebookDownload').modal('hide');
        } else {
          this.resetCaptcha();
        }
      })
    )
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    if (this.isDropdownOpen && !this.dropdown.nativeElement.classList.contains('show') && this.selectedClass._id === this.errorInfo.defaultClassId) {
      this.errorInfo.class_error = true;
      this.isDropdownOpen = false;
    }
  }

  handleNameValue(): void {
    const regexToTestName = /^[A-Z]/gi;
    this.errorInfo.name_error = false;
    let userName = this.ebookDownloadForm.value.name;
    if (!regexToTestName.test(userName)) {
      userName = userName.substring(0, userName.length - 1);
    }
    this.errorInfo.name_error = (userName.length < this.errorInfo.validNameLength);
    this.ebookDownloadForm.controls['name'].setValue(userName);
  }

  initializePopData(): void {
    this.ebookDownloadForm = this.fb.group({
      name: [''],
      mobile: ['']
    });
    this.selectedClass = { class: 'Select Class', _id: '00' };
    this.errorInfo.name_error = false;
    this.errorInfo.class_error = false;
    this.errorInfo.mobile_error = false;
  }

  captchaExpired() {
    if (this.vc) {
      this.vc.reset();
    }
    this.captchaInfo.captchaChecked = false;
  }

  resetCaptcha() {
    if (this.vc) {
      this.vc.reset();
    }
    this.captchaInfo.captchaChecked = false;
    this.captchaInfo.showCaptcha = false;
  }

  get countryData() {
    return countryArray;
  }

  getSuccess() {
    this.captchaInfo.captchaChecked = true;
    this.captchaInfo.showCaptchaError = false;
    this.downloadEbook();
  }

  handleDropdownState(): void {
    this.isDropdownOpen = true;
  }

  selectClass(dataSelected: any) {
    this.errorInfo.class_error = false;
    if (this.selectedClass._id !== dataSelected._id) {
      this.selectedClass.class = dataSelected.class;
      this.selectedClass._id = dataSelected._id;
    }
  }

  selectCountryCode(selectedDialCode: any) {
    this.selectedDialCode = selectedDialCode;
  }

  handlePopUpClose(): void {
    this.resetCaptcha();
    this.isDropdownOpen = false;
    $('#ebookDownload').modal('hide');
  }

  handleMobileNumber(e: any): any {
    let mobileNumber = this.ebookDownloadForm.value.mobile;
    this.errorInfo.mobile_error = false;
    var pattern = /^[0-9]+$/;
    if (!pattern.test(mobileNumber)) {
      mobileNumber = mobileNumber.substring(0, mobileNumber.length - 1);
    } else if (mobileNumber.length > this.validMobileNumberLength) {
      mobileNumber = mobileNumber.substring(0, this.validMobileNumberLength);
    }
    this.errorInfo.mobile_error = mobileNumber.length < this.validMobileNumberLength;
    this.ebookDownloadForm.controls['mobile'].setValue(mobileNumber);
  }

  enableCaptcha() {
    this.captchaInfo.showCaptcha = this.isCaptchaEnabled;
  }

  downloadEbook(): void {
    let captchaToken = '';
    if (this.isCaptchaEnabled && this.vc) {
      captchaToken = this.vc.getResponse();
    }

    const payload = {
      name: this.ebookDownloadForm.value.name,
      countryCode: this.selectedDialCode.dialCode,
      mobileNumber: this.ebookDownloadForm.value.mobile,
      loginViaOtp: true,
      referralCode: '',
      class: {
        classId: this.selectedClass._id,
        name: this.selectedClass.class
      },
      captchaToken
    };
    $('#ebookDownload').modal('hide');
    this.isDropdownOpen = false;
    this.store.dispatch(new AuthActions.TryNewSignup(payload));
  }

  ngOnDestroy(): void {
    this.activeSubscription.unsubscribe();
  }

  addNewClass(){
    this.mobileContainer.nativeElement.classList.add('borderBottomNone')
  }
}
