<!--Report Abuse modal-->
<div [id]="modalId" class="modal fade editModal bottom" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content animated">
            <div class="modal-header">
                <h6 class="modal-title">Report Abuse</h6>
                <button type="button" class="close close-modal" data-dismiss="modal"><img src="../../../assets/img/s3-images/modalCrossIcon.svg"></button>
                
            </div>
            <div class="modal-body">
                <p>Choose one of the following:</p>
                <a class="tag-primary" [ngClass]="{'active': reportTypeValue==='Spam'}" (click)="reportTypeClick('Spam')">Spam</a>
                <a class="tag-primary" [ngClass]="{'active': reportTypeValue==='Abusive / Personal Attack'}" (click)="reportTypeClick('Abusive / Personal Attack')">Abusive/Personal
          Attack</a>
                <a class="tag-primary" [ngClass]="{'active': reportTypeValue==='Insincere'}" (click)="reportTypeClick('Insincere')">Insincere</a>
                <a class="tag-primary" [ngClass]="{'active': reportTypeValue==='Poorly Written'}" (click)="reportTypeClick('Poorly Written')">Poorly Written</a>
                <a class="tag-primary" [ngClass]="{'active': reportTypeValue==='Incorrect Topics'}" (click)="reportTypeClick('Incorrect Topics')">Incorrect Topics</a>
            </div>
            <div class="modal-footer">
                <button type="button" class="cancel-btn primary-text close-modal" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary btn-primary-border" data-dismiss="modal" (click)="reportQuestion()" [disabled]="!reportTypeValue">Submit</button>
            </div>
        </div>

    </div>
</div>