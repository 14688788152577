<!-- myGoals Simmer -->
<div class="myGoalLoader-container" *ngIf="cardName === 'myGoals'">
  <ul>
    <li>
      <div class="loader-list-heading">
        <div class="box-header"></div>
        <div class="box-header"></div>
        <div class="box-header"></div>
        <div class="box-header"></div>
      </div>
    </li>
    <li *ngFor="let item of range(2)">
      <div class="loader-list-heading">
        <div class="box-md"></div>
        <div class="box-md"></div>
        <div class="box-md"></div>
        <div class="box">
          <div class="box-sm"></div>
          <div class="box-sm"></div>
        </div>
      </div>
    </li>
  </ul>
  <div class="box-lg"></div>
</div>

<!-- assignedTest Simmer -->
<div class="assignedTestLoader-container" *ngIf="cardName === 'assignedTest' || cardName === 'ideaReport'">
  <ul>
    <li>
      <div class="loader-list-heading">
        <div class="simmerAlign">
          <div class="cardLeft">
            <div class="testCount">
              <p class="box-square"></p>
            </div>
            <div class="cardMiddle">
              <p class="box-header width353 mb5"></p>
              <p class="box-header width353 mb5"></p>
            </div>
          </div>
          <div class="actionCTA">
            <div class="box-md"></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- testHistory Simmer -->
<div class="testHistoryLoader-container" *ngIf="cardName === 'testHistory'">
  <ul>
    <li *ngFor="let item of range(10)">
      <div class="loader-list-heading">
        <p class="box-lg mb15"></p>
        <div class="simmerAlign">
          <div class="cardLeft">
            <div class="testCount">
              <p class="box-sm"></p>
              <p class="box-sm"></p>
            </div>
            <div class="cardMiddle">
              <p class="box-header mb5"></p>
              <p class="box-header mb5"></p>
            </div>
          </div>
          <div class="actionCTA">
            <div class="box-md"></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- myPlan Simmer -->
<div class="myPlanLoader-container" *ngIf="cardName === 'myPlan'">
  <ul>
    <li>
      <div class="loader-list-heading">
        <div class="box-header"></div>
        <div class="d-flex justify-content-between">
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex justify-content-end">
        <div class="box-md"></div>
        <div class="box-md"></div>
        <div class="box-md"></div>
        <div class="box-md"></div>
        <div class="box-md"></div>
      </div>
    </li>
    <li>
        <div class="d-flex justify-content-between">
            <div class="box-carousel"></div>
            <div class="box-carousel"></div>
            <div class="box-carousel"></div>
            <div class="box-carousel"></div>
        </div>
    </li>
    <li>
        <div class="box-lg"></div>
        <div class="box-lg"></div>
    </li>
    <li>
        <div class="box-lg"></div>
        <div class="box-lg"></div>
    </li>
  </ul>
</div>

<!-- swot board Simmer -->
<div class="swotboardLoader-container" *ngIf="cardName === 'swotBoard'">
  <ul>
    <li>
      <div class="d-flex justify-content-between">
        <div class="box-header"></div>
        <div class="d-flex justify-content-between">
          <div class="box-header"></div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
      <div class="graph-container d-flex justify-content-between">
        <div>
        <div class="graph-text"></div>
        <div class="graph-text"></div>
        <div class="graph-text"></div>
        <div class="graph-text"></div>
        <div class="graph-text"></div>
        <div class="graph-text"></div>
        </div>
        <div class="graphdiv">
        <div class="graph"></div>
        </div>
      </div>
      <div class="tab-container">
        <div class="tab-box"></div>
        <div class="tab-box"></div>
        <div class="tab-box"></div>
        <div class="tab-box"></div>
      </div>
    </li>
  </ul>
</div>


<!-- swot board Graph Simmer -->
<div class="swotboardlistingLoader" *ngIf="cardName === 'swotBoardaccordionlisting'">
  <ul>
    <li>
      <div class="d-flex justify-content-between">
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex justify-content-between">
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex justify-content-between">
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
    </li>
    <li>
      <div class="d-flex justify-content-between">
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
        <div>
          <div class="box-header"></div>
          <div class="box-header"></div>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- swot board Graph Simmer -->
<div class="swotboardlistingLoader" style="margin-top: -30px;" *ngIf="cardName === 'buildATest'">
  <ul>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
          <div class="box-header" style="margin: 10px 15px; width: 48%;"></div>
      </div>
    </li>
  </ul>
</div>


<!-- revision notebook Graph Simmer -->
<div class="revisionlistingLoader" *ngIf="cardName === 'revisionNoteBook'">
  <div class="carousel-shimmer">
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
  </div>
  <ul>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 108%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
  </ul>
</div>

<!-- exam temperament Graph Simmer -->
<div class="examTemperamentLoader" *ngIf="cardName === 'examTemperament'">
  <!-- <div class="carousel-shimmer">
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
    <div class="shimmer" style="height: 100px; margin-bottom: 20px;">
    </div>
  </div>
  <ul>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 108%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
    <li>
      <div class="d-flex">
          <div class="box-header" style="margin: 10px 15px; width: 100%;"></div>
      </div>
    </li>
  </ul> -->
<div class="examTemperamentContainer">
  <div class="left">
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
</div>
<div class="right">
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 20px; margin-bottom: 20px;"></div>
</div>
</div>
<div class="cardtabs">
  <div class="shimmer" style="height: 120px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 120px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 120px; margin-bottom: 20px;"></div>
  <div class="shimmer" style="height: 120px; margin-bottom: 20px;"></div>
</div>
</div>
<!-- Previous year paper -->
<div class="previousYear-container mt-4" *ngIf="cardName === 'previousYear'">
  <ul class="carousel">
    <li class="box-carousel"></li>
    <li class="box-carousel"></li>
    <li class="box-carousel"></li>
    <li class="box-carousel"></li>
  </ul>  
</div>