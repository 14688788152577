import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './stores/app.reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
  public userDetail;
  constructor(private router: Router, private store: Store<fromApp.AppState>) { }
  canActivate(state: ActivatedRouteSnapshot, route: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const fiitjeeToken = localStorage.getItem('fiitjeeToken');
    return this.store.select('auth').pipe(
      map((auth) => {
        if (!auth.authenticated && !fiitjeeToken) {
          return true;
        }else if(fiitjeeToken){
          this.router.navigate(['quiz/home']);
          return false;
        }else {
          this.router.navigate(['home']);
          return false;
        }
      }
      )
    );
  }
}
