import { Component, ElementRef, OnInit, Inject, ViewChild, HostListener, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import HEADERMENU from './headermenus.constant'
import { DOCUMENT } from '@angular/common';
import { USER_ROUTES, COURSES_CLASSES_DATA } from 'src/app/shared/constants/glite.constants';
import { EXAM_TYPE } from 'src/app/shared/constants/previous-year-papers.constant';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';
import { EbooksService } from 'src/app/landing-pages/ebooks/ebooks.service';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-header-before-login',
  templateUrl: './header-before-login.component.html',
  styleUrls: ['./header-before-login.component.scss']
})
export class HeaderBeforeLoginComponent implements OnInit {
  @ViewChild('dropdown') dropdown: ElementRef;
  constructor(
    private _router: Router, public elementRef: ElementRef<HTMLDivElement>,
    @Inject(DOCUMENT) private readonly document: Document,
    private utilityService: UtilityService,
    private _ebook: EbooksService
  ) { }
  private isDropdownOpen: boolean = false;
  private activeSubscriptions = new Subscription();
  public resourcesMenu: boolean = false;
  public examsMenu: boolean = false;
  public previousPapersMenu: boolean = false;
  public eBooksMenu: boolean = false;
  public toolsMenu: boolean = false;
  public router: any;
  public enableVerifyOTPPopup: boolean = false;
  public mobileInfo: any = {};
  public formSubmitCTA:string = 'Start Learning';
  public routerInfo: any = {
    signup: `/${USER_ROUTES.USER}/${USER_ROUTES.SIGNUP}`,
    login: `/${USER_ROUTES.USER}/${USER_ROUTES.LOGIN}`
  };
  public isCartActive: boolean = false; 

  ngOnInit(): void {
    this.activeSubscriptions.add(
      this.utilityService.downloadEbook.subscribe((downloadEbook: boolean) => {
        if (this._ebook.selectedEbookLink && downloadEbook) {
          window.open(this._ebook.selectedEbookLink, '_blank');
          this._ebook.selectedEbookLink = '';
        }
      })
    );
    this.isCartActive = (this._router.url)?.includes('cart');
    this.activeSubscriptions.add(this._router.events.subscribe((event) => {
      this.isCartActive = event['url']?.includes('cart');
    }));
  }

  public get cartSize() {
    return JSON.parse(localStorage.getItem('userCart'))?.length || 0;
  }

  get classAndGoalInfo() {
    return COURSES_CLASSES_DATA;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.dropdown.nativeElement.contains(event.target) || this.isDropdownOpen) {
      this.document.querySelector(".classList").classList.remove('active');
      this.document.querySelector(".examList").classList.remove('active');
      this.document.querySelector(".resourcesListing").classList.remove('active');
      this.isDropdownOpen = false;
      for(let dropdown in this.activeDropdown){
        this.activeDropdown[dropdown] = false;
      }
    }
  }

  handleDropdownState(): void {
    this.isDropdownOpen = true;
  }

  get EXAM_TYPES(){
    return EXAM_TYPE;
  }

  getPreviousYearPapers(examType: string){
    this.getMenuList(HEADERMENU.RESOURCES_LIST);
    this._router.navigateByUrl(`previous-year-paper-download/${examType}`);
  }

  navigateToScholarshipPage() {
    this._router.navigate(['/scholarship']);
  }
  resultPage(){
    this._router.navigate(['view-our-results']);
  }
  public activeDropdown = {
    [HEADERMENU.CLASS_LIST]: false,
    [HEADERMENU.EXAM_LIST]: false,
    [HEADERMENU.RESOURCES_LIST]: false
  }
  getMenuList(str):void{
    const classContainer = this.document.querySelector(".classList");
    const examContainer = this.document.querySelector(".examList");
    const resourcesContainer = this.document.querySelector(".resourcesListing");
    for(let dropdown in this.activeDropdown){
      this.activeDropdown[dropdown] = (dropdown === str);
    }
    switch(str){
      case HEADERMENU.CLASS_LIST:
        classContainer.classList.toggle('active');
        examContainer.classList.remove('active');
        resourcesContainer.classList.remove('active');
        break;
      case HEADERMENU.EXAM_LIST:
        examContainer.classList.toggle('active');
        classContainer.classList.remove('active');
        resourcesContainer.classList.remove('active');
        break;
      case HEADERMENU.RESOURCES_LIST:
        resourcesContainer.classList.toggle('active');
        examContainer.classList.remove('active');
        classContainer.classList.remove('active');
        break;
      default:
        return;
    }
  }

  getClassExamList(str):void{
    const class9Container = this.document.querySelector(".examName-class9");
    const class10Container = this.document.querySelector(".examName-class10");
    const class11Container = this.document.querySelector(".examName-class11");
    const class12Container = this.document.querySelector(".examName-class12");
    const class12passContainer = this.document.querySelector(".examName-class12pass");

    switch(str){
      case HEADERMENU.CLASS9:
        class9Container.classList.toggle('active');
        class10Container.classList.remove('active');
        class11Container.classList.remove('active');
        class12Container.classList.remove('active');
        class12passContainer.classList.remove('active');
      break;
      case HEADERMENU.CLASS10:
        class10Container.classList.toggle('active');
        class9Container.classList.remove('active');
        class11Container.classList.remove('active');
        class12Container.classList.remove('active');
        class12passContainer.classList.remove('active');
      break;
      case HEADERMENU.CLASS11:
        class11Container.classList.toggle('active');
        class9Container.classList.remove('active');
        class10Container.classList.remove('active');
        class12Container.classList.remove('active');
        class12passContainer.classList.remove('active');
      break;
      case HEADERMENU.CLASS12:
        class12Container.classList.toggle('active');
        class9Container.classList.remove('active');
        class10Container.classList.remove('active');
        class11Container.classList.remove('active');
        class12passContainer.classList.remove('active');
      break;
      case HEADERMENU.CLASS12_PASS:
        class12passContainer.classList.toggle('active');
        class9Container.classList.remove('active');
        class10Container.classList.remove('active');
        class11Container.classList.remove('active');
        class12Container.classList.remove('active');
      break;
      default:
        return;
    }
  }
  getResourcesList(str):void{
    const toolsContainer = this.document.querySelector(".tools-container");
    const toolsMenu = this.document.querySelector(".tools-menu");
    const previousPaperContainer = this.document.querySelector(".previous-paper-container");
    const previousPaperMenu = this.document.querySelector(".previous-paper-menu");
    // Sample Paper code for future ref.
    // const samplePaperContainer = this.document.querySelector(".sample-paper-container");
    // const samplePaperMenu = this.document.querySelector(".sample-paper-menu");
    switch(str) {
      case HEADERMENU.TOOLS:
        toolsContainer.classList.toggle('active');
        toolsMenu.classList.toggle('active');
        previousPaperContainer.classList.remove('active');
        previousPaperMenu.classList.remove('active');
        // Sample Paper code for future ref.
        // samplePaperContainer.classList.remove('active');
        // samplePaperMenu.classList.remove('active');
      break;
      case HEADERMENU.PREVIOUS_PAPER:
        previousPaperContainer.classList.toggle('active');
        previousPaperMenu.classList.toggle('active');
        toolsContainer.classList.remove('active');
        toolsMenu.classList.remove('active');
        // Sample Paper code for future ref.
        // samplePaperContainer.classList.remove('active');
        // samplePaperMenu.classList.remove('active');
      break;
      case HEADERMENU.SAMPLE_PAPER:
        // Sample Paper code for future ref.
        // samplePaperContainer.classList.toggle('active');
        // samplePaperMenu.classList.toggle('active');
        toolsContainer.classList.remove('active');
        toolsMenu.classList.remove('active');
        previousPaperContainer.classList.remove('active');
        previousPaperMenu.classList.remove('active');
      break;
      case HEADERMENU.BLOG:
        // Sample Paper code for future ref.
        // samplePaperContainer.classList.remove('active');
        // samplePaperMenu.classList.remove('active');
        toolsContainer.classList.remove('active');
        toolsMenu.classList.remove('active');
        previousPaperContainer.classList.remove('active');
        previousPaperMenu.classList.remove('active');
        this.handleDropdownState();
      break;
      case HEADERMENU.EBOOKS:
        // Sample Paper code for future ref.
        // samplePaperContainer.classList.remove('active');
        // samplePaperMenu.classList.remove('active');
        toolsContainer.classList.remove('active');
        toolsMenu.classList.remove('active');
        previousPaperContainer.classList.remove('active');
        previousPaperMenu.classList.remove('active');
      break;
      default:
        return;
    }
  }
  getmobileMainMenu(str):void{
    const mobileClassContainer = this.document.querySelector(".mobile-class-menu");
    const courseMenuContainer = this.document.querySelector(".course-menu");
    const mobileExamMenuContainer = this.document.querySelector(".mobile-exam-menu");
    const examMenuContainer = this.document.querySelector(".exams-menu");
    const mobileResourcesMenuContainer = this.document.querySelector(".mobile-resources-menu");
    const resourcesMenuContainer = this.document.querySelector(".resources-menus");
    const mobileCommunitysMenuContainer = this.document.querySelector(".mobile-community-menu");
    const communityMenuContainer = this.document.querySelector(".community-menu");
    switch(str){
      case HEADERMENU.CLASS_LIST:
        mobileClassContainer.classList.toggle('active');
        courseMenuContainer.classList.toggle('active');
      break;
      case HEADERMENU.EXAM_LIST:
        mobileExamMenuContainer.classList.toggle('active');
        examMenuContainer.classList.toggle('active');
      break;
      case HEADERMENU.RESOURCES_LIST:
        mobileResourcesMenuContainer.classList.toggle('active');
        resourcesMenuContainer.classList.toggle('active');
      break;
      case HEADERMENU.COMMUNITY:
        mobileCommunitysMenuContainer.classList.toggle('active');
        communityMenuContainer.classList.toggle('active');
      default:
        return; 
    }
  }
  getmobileClassList(str):void {
    const class9listContainer = this.document.querySelector(".class9-list");
    const class9menu = this.document.querySelector(".class9-menu");
    const class10listContainer = this.document.querySelector(".class10-list");
    const class10menu = this.document.querySelector(".class10-menu");
    const class11listContainer = this.document.querySelector(".class11-list");
    const class11menu = this.document.querySelector(".class11-menu");
    const class12listContainer = this.document.querySelector(".class12-list");
    const class12menu = this.document.querySelector(".class12-menu");
    const class12passlistContainer = this.document.querySelector(".class12pass-list");
    const class12passmenu = this.document.querySelector(".class12pass-menu");
    switch(str){
      case HEADERMENU.CLASS9:
        class9listContainer.classList.toggle('active'); 
        class9menu.classList.toggle('active'); 
      break;
      case HEADERMENU.CLASS10:
        class10listContainer.classList.toggle('active'); 
        class10menu.classList.toggle('active'); 
      break;
      case HEADERMENU.CLASS11:
        class11listContainer.classList.toggle('active'); 
        class11menu.classList.toggle('active'); 
      break;
      case HEADERMENU.CLASS12:
        class12listContainer.classList.toggle('active'); 
        class12menu.classList.toggle('active'); 
      break;
      case HEADERMENU.CLASS12_PASS:
        class12passlistContainer.classList.toggle('active'); 
        class12passmenu.classList.toggle('active'); 
      break;
      default:
        return;
    }
  }
  getMobileResourcesList(str):void {
    const toolslist = this.document.querySelector(".tools-list");
    const toolsmenu = this.document.querySelector(".tool-menu");
    const previousPaperlist = this.document.querySelector(".previousPaper-list");
    const previousPapermenu = this.document.querySelector(".previousPaper-menu");
    const samplePaperlist = this.document.querySelector(".samplePaper-list");
    const samplePapermenu = this.document.querySelector(".samplePaper-menu");
    switch(str){
      case HEADERMENU.TOOLS:
        toolslist.classList.toggle('active'); 
        toolsmenu.classList.toggle('active'); 
      break;
      case HEADERMENU.PREVIOUS_PAPER:
        previousPaperlist.classList.toggle('active'); 
        previousPapermenu.classList.toggle('active');
      break;
      case HEADERMENU.SAMPLE_PAPER:
        samplePaperlist.classList.toggle('active'); 
        samplePapermenu.classList.toggle('active');
      default:
        return;
    }
  }
  closeHeaderResponsive(){
    const headermenuList = this.document.querySelector(".responsive-header");
    headermenuList.classList.remove('show');
  }

  navigateToSignup() {
    this._router.navigate([this.routerInfo.signup]);
  }

  navigateToLogin() {
    this._router.navigate([this.routerInfo.login]);
  }

  public get HEADERMENU_DATA(){
    return HEADERMENU;
  }

  retainDropdown(target){
    this.getMenuList(target);
  }
  public get BLOG_URL(){
    return environment.blog;
  }
}
