<div [ngClass]="{'downloadForm': submitCTA === 'Download'}">
    <b *ngIf="submitCTA === 'Download'">
        Download Now
    </b>
    <form [formGroup]="freeTrialAccessForm" id="mainBanner-form" >
        <div class="mainBanner-form" *ngIf="formType === FORM_TYPE.HOME_INFO; else modelFormTpl">
            <div class="input-group name-filed">
                <input class="ng-untouched ng-pristine ng-invalid" name="userfullname" placeholder="Enter Name" formControlName="userName"
                    type="text" (input)="validateName()"/>
            </div>
            <div class="select-class-dropdown">
                <button class="dropdown-toggle classSelection" data-toggle="dropdown" type="button">
                    <span id="search_concept"> {{selectedClass.class}} </span>
                    <span class="menu-ico-2">
                        <img src="/assets/images/new-home/gray_drop.png" alt="arrow icon">
                    </span>
                </button>
                <ul class="dropdown-menu" role="menu">
                    <li *ngFor="let class of classesDropdown" (click)="selectClass(class)"><span>{{class.class}}</span></li>
                </ul>
            </div>
            <div class="input-group name-filed countrycode-fieldview">
                <div class="select-countrycode-dropdown" *ngIf="freeTrialAccessForm.value.userNumber">
                    <div class="countrycode-dropdown-toggle" data-toggle="dropdown">
                        <span><img src="../../../assets/images/country-flags/{{selectedDialCode.flag}}.png" [alt]="selectedDialCode.name"/>{{selectedDialCode.dialCode}}</span>
                        <span class="menu-ico-2"><img src="/assets/images/new-home/gray_drop.png"></span>
                    </div>
                    <ul class="dropdown-menu" role="menu">
                        <li *ngFor="let country of countryData" (click)="selectCountryCode(country)"><img src="../../../assets/images/country-flags/{{country.flag}}.png" [alt]="country.name">{{country.dialCode}}</li>
                    </ul>
                </div>
                <input class="form-control" id="enterdesk-home" name="number" placeholder="Enter Mobile Number" type="text" 
                (input)="handleMobileNumber($event)" formControlName="userNumber"/>
            </div>
            <button class="btn" type="button" (click)="startLearning()">{{submitCTA}}</button>
        </div>
        <ng-template #modelFormTpl>
            <div class="modal-form">
                <div class="input-group name-filed">
                    <label for="userName">Full Name</label>
                    <div class="input-group-icon">
                        <div class="input-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g clip-path="url(#clip0_11701_10820)">
                                <path d="M5.08333 5.01118C5.08333 6.72756 6.3375 8.02237 8 8.02237C9.6625 8.02237 10.9167 6.72756 10.9167 5.01118C10.9167 3.29481 9.6625 2 8 2C6.3375 2 5.08333 3.29481 5.08333 5.01118ZM4.66667 14H13V13.1397C13 10.7677 11.1308 8.83797 8.83333 8.83797H7.16667C4.86917 8.83797 3 10.7677 3 13.1397V14H4.66667Z" fill="#98989E"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_11701_10820">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                        </div>
                      </div>
                    <input name="userfullname" placeholder="Enter Name" class="name-textbox" formControlName="userName"
                        type="text" />
                </div>
                <div class="input-group select-class-dropdown" *ngIf="formType !== FORM_TYPE.COLLEGE_PRED">
                    <label for="class">Class</label>
                    <br>
                    <button class="dropdown-toggle btn classSelection" data-toggle="dropdown" type="button">
                        <span id="search_concept"> {{selectedClass.class}} </span>
                        <span class="menu-ico-2">
                            <img src="/assets/images/new-home/gray_drop.png" alt="arrow icon">
                        </span>
                    </button>
                    <ul class="dropdown-menu class-list" role="menu">
                        <li class="list-items" *ngFor="let class of classesDropdown" (click)="selectClass(class)"><span>{{class.class}}</span></li>
                    </ul>
                </div>
                <div class="input-group">
                    <label for="mobileNo">Mobile No.</label>
                    <br/>
                    <div class="name-filed countrycode-fieldview">
                        <div class="select-countrycode-dropdown" *ngIf="freeTrialAccessForm.value.userNumber">
                            <div class="countrycode-dropdown-toggle" data-toggle="dropdown">
                                <span><img src="/assets/images/country-flags/{{selectedDialCode.flag}}.png" [alt]="selectedDialCode.name"/>{{selectedDialCode.dialCode}}</span>
                                <span class="menu-ico-2"><img src="/assets/images/new-home/gray_drop.png" alt="arrow icon"></span>
                            </div>
                            <ul class="dropdown-menu" role="menu">
                                <li *ngFor="let country of countryData" (click)="selectCountryCode(country)"><img src="/assets/images/country-flags/{{country.flag}}.png" [alt]="country.name">{{country.dialCode}}</li>
                            </ul>
                        </div>
                        <input class="form-control" id="enterdesk-home" name="number" placeholder="Enter mobile number" type="text" 
                        (input)="handleMobileNumber($event)" formControlName="userNumber"/>
                    </div>
                </div>
                <div class="input-group name-filed" *ngIf="formType === FORM_TYPE.PREP_TRACKER">
                    <label for="userName">EXAM Rank <small>(Optional)</small></label>
                    <input  name="userExam" placeholder="Enter exam" 
                        type="text" formControlName="exam" />
                </div>

               
            </div>
        </ng-template>
        <div class="captcha-container" *ngIf="captchaInfo.showCaptcha">
            <div class="captcha" appCaptcha [config]="'qq'" [lang]="'en'" (captchaExpired)="captchaExpired()"
                (captchaResponse)="getSuccess()"></div>
        </div>
        <div class="modal-button" *ngIf="formType !== FORM_TYPE.HOME_INFO">
            <button class="btn btn-outline-primary" type="button" (click)="resetForm()" data-dismiss="modal">Cancel</button>
            <button class="btn" type="button" (click)="startLearning()">Submit</button>
        </div>
        <div class="error" *ngIf="captchaInfo.showCaptchaError && !captchaInfo.captchaChecked"><span class="captcha-error">Please select captcha</span></div>
    </form>
</div>