<!--logoutModal Popup Modal -->
<div class="modal fade lite-modal" id="logoutModal">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal"><img src="/assets/images/g-lite/popup-close.svg" alt="Close" (click)="closeModal()"></button>      
      <div class="modal-body">
        <div class="headSec mt110">
          <img src="/assets/images/g-lite/logout.svg" alt="Logout"/>
          <h3 class="text-center">Logout</h3>
          <p>Are you sure you want to logout?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-footer" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-close btn-rounded" (click)="logout()">Yes</button>
      </div>
    </div>
  </div>
</div>