<div class="modal fade bs-example-modal-lg applicationNumber" data-backdrop="static" data-keyboard="false" id='applicationNumber{{modalNumber}}'>
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <!-- <button type="button" class="close" data-dismiss="modal">×</button> -->
          <div class="fiitj-banr-headr">
            <div class="imgCenter"><img src="{{applicationModalData.iconURL}}" /></div>
            <h2>{{applicationModalData.popUpName}}</h2>
            <!--Form 1st-->
            <form [formGroup]="applicationSurvey">
              <label class="col-md-12 col-sm-12">Please provide following details about your {{applicationModalData.testName}} exam application</label>
              <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.applicationNumber">
                <input class="form-control signup-login-input" name="appnumber" formControlName="applicationNumber" placeholder="{{applicationModalData.applicationNumberPlaceholder}}" type="text" (blur)="handleApplicationNumber()" (input)="handleSpecialCharacters($event, 'applicationNumber')">              
                  <div *ngIf="applicationModalData.applicationNumberError" class="error">
                    {{applicationModalData.applicationNumberErrMsg}}
                  </div> 
              </div>
              <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.mobile">
                <input class="form-control signup-login-input" name="mobile" formControlName="mobile" placeholder="{{applicationModalData.mobilePlaceholder}}" (blur)="handleMobileNumber()" type="number" (input)="handleMobileInput()">              
                  <div *ngIf="applicationModalData.mobileError" class="error">
                    {{applicationModalData.mobileErrMsg}}
                  </div> 
              </div>
              <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.email">
                <input class="form-control signup-login-input" name="email" formControlName="email" placeholder="{{applicationModalData.emailPlaceholder}}" type="text" (blur)="handleEmailId()">              
                  <div *ngIf="applicationModalData.emailError" class="error">
                    {{applicationModalData.emailErrMsg}}
                  </div> 
              </div>
              <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.rollNumber">
                <input class="form-control signup-login-input" name="rollnumber" formControlName="rollNumber" placeholder="{{applicationModalData.rollNumberPlaceholder}}" type="text" (blur)="handleRollNumber()" (input)="handleSpecialCharacters($event, 'rollNumber')">              
                  <div *ngIf="applicationModalData.rollNumberError" class="error">
                    {{applicationModalData.rollNumberErrMsg}}
                  </div> 
              </div>
              <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.score && !isJEEA">
                <input class="form-control signup-login-input" name="ntanumber" formControlName="score" placeholder="{{applicationModalData.scorePlaceholder}}" type="number" (blur)="handleScore()">              
                  <div *ngIf="applicationModalData.scoreError" class="error">
                    {{applicationModalData.scoreErrMsg}}
                  </div> 
              </div>
              <label class="col-md-12 col-sm-12 smallText" *ngIf="applicationModalData.admitCardImage">Admit Card (Hall Ticket)</label>
              <label class="col-md-12 col-sm-12 smallText" *ngIf="applicationModalData.scoreCardImage && !isJEEA">Upload Complete Result</label>
              <div class="form-group col-md-12 col-sm-12 uploader">
                <div class="uploadBrowse" *ngIf="applicationModalData.admitCardImage && !applicationModalData.imageURL">
                  <button type="button" class="browse-button">
                    <img src="/assets/img/s3-images/uploadHallTicketIcon.svg" alt="Upload hall Ticket" />
                    <input type="file" (change)="processImage($event, 'admitCardImage')" accept="image/png, image/jpg, image/jpeg" title="Upload your file"/>
                  </button>
                </div>
  
                <div class="reuploadBrowse" *ngIf="applicationModalData.imageURL">
                  <div class="reuploadDiv">
                    <div class="cardUploaded">
                      <img src="{{applicationModalData.imageURL}}"/>
                      <a href="javascript:void();" class="closeCard"><img src="/assets/img/s3-images/closeAdmit.svg" (click)="removeImage()" /></a>
                    </div>
                    <button type="button" class="browse-button">
                      Re-Upload Hall Ticket
                      <input type="file" (change)="processImage($event)" accept="image/png, image/jpg, image/jpeg" title="Click here to re-upload"/>
                    </button>
                  </div>
                </div>
                <div class="uploadBrowse" *ngIf="applicationModalData.scoreCardImage && !applicationModalData.imageURL && !isJEEA">
                  <button type="button" class="browse-button">
                    <img src="/assets/img/s3-images/uploadResultIcon.svg" alt="Upload Result" />
                    <input type="file" (change)="processImage($event, 'scoreCardImage')" accept="image/png, image/jpg, image/jpeg" title="Upload your file" />
                  </button>
                </div>
  
                <span *ngIf="!applicationModalData.uploadImageError && !(applicationModalData.admitCardImageError || applicationModalData.scoreCardImageError) && isImageRequired">Please use .png, .jpg, .jpeg formats only</span>
                <span class="error" *ngIf="applicationModalData.uploadImageError">
                  File format not supported! Please use .png, .jpg, .jpeg formats only
                </span>
                <span class="error" *ngIf="applicationModalData.admitCardImageError || (applicationModalData.scoreCardImageError && !isJEEA)">
                  {{applicationModalData.scoreCardImageErrMsg || applicationModalData.admitCardImageErrMsg || 'Please upload an image'}} 
                </span>
              </div>
              <div class="form-group col-md-12 col-sm-12" *ngIf="isJEEA && isRadioButtonVisible">
                <div class="cutoff">
                  <input id="f-option1" name="selector" type="radio" (click)="changeStatus(true)" value="concept">
                  <label for="f-option1">Cut off Cleared</label>
                  <div class="check"></div>
                </div>
                <div class="cutoff">
                  <input id="f-option2" name="selector" type="radio" (click)="changeStatus(false)" value="concept">
                  <label for="f-option2">Cut off not Cleared</label>
                  <div class="check"></div>
                </div>
                <div class="error" *ngIf="applicationModalData.examClearedError">
                  {{applicationModalData.examClearedErrMsg}} 
                </div>
              </div>
              <ng-container *ngIf="applicationModalData.examCleared">
                <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.crlRank">
                  <input class="form-control signup-login-input" name="crlRank" formControlName="crlRank" placeholder="{{applicationModalData.crlRankPlaceholder}}" type="number" (blur)="handleCrlRank()">              
                    <div *ngIf="applicationModalData.crlRankError" class="error">
                      {{applicationModalData.crlRankErrMsg}}
                    </div> 
                </div>
                <div class="form-group col-md-12 col-sm-12" *ngIf="applicationModalData.score && isJEEA">
                  <input class="form-control signup-login-input" name="ntanumber" formControlName="score" placeholder="{{applicationModalData.scorePlaceholder}}" type="number" (blur)="handleScore()">              
                    <div *ngIf="applicationModalData.scoreError" class="error">
                      {{applicationModalData.scoreErrMsg}}
                    </div> 
                </div>
              </ng-container>
              <label class="col-md-12 col-sm-12 smallText" *ngIf="applicationModalData.scoreCardImage && isJEEA">Upload Complete Result</label>
              <div *ngIf="isJEEA" class="form-group col-md-12 col-sm-12 uploader">
                <div class="reuploadBrowse" *ngIf="applicationModalData.imageURLJeeA">
                  <div class="reuploadDiv">
                    <div class="cardUploaded">
                      <img src="{{applicationModalData.imageURLJeeA}}"/>
                      <a href="javascript:void();" class="closeCard"><img src="/assets/img/s3-images/closeAdmit.svg" (click)="removeImage(true)" /></a>
                    </div>
                    <button type="button" class="browse-button">
                      Re-Upload Hall Ticket
                      <input type="file" (change)="uploadJeeAResult($event)" accept="image/png, image/jpg, image/jpeg" title="Click here to re-upload"/>
                    </button>
                  </div>
                </div>
                
                <div class="uploadBrowse" *ngIf="applicationModalData.scoreCardImage && !applicationModalData.imageURLJeeA">
                  <button type="button" class="browse-button">
                    <img src="/assets/img/s3-images/uploadResultIcon.svg" alt="Upload Result" />
                    <input type="file" (change)="uploadJeeAResult($event)" accept="image/png, image/jpg, image/jpeg" title="Upload your file" />
                  </button>
                </div>
  
                <span *ngIf="!applicationModalData.uploadImageErrorJeeA && !applicationModalData.scoreCardImageError && isImageRequiredJeeA">Please use .png, .jpg, .jpeg formats only</span>
                <span class="error" *ngIf="applicationModalData.uploadImageErrorJeeA">
                  File format not supported! Please use .png, .jpg, .jpeg formats only 
                </span>
                <span class="error" *ngIf="applicationModalData.scoreCardImageError">
                  {{applicationModalData.scoreCardImageErrMsg || 'Please upload an image'}} 
                </span>
              </div>
              <div class="form-group col-md-12 appNoBtnCta">
                <button type="Cancel" class="btn noBg" (click)="closeModal()" *ngIf="applicationModalData.showCancelButton">Cancel</button>
                <button type="submit" class="btn btn-blue" (click)="submitForm()">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>