import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { map, catchError, switchMap, tap, mergeMap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as fromApp from '../../stores/app.reducers';
import * as UserProfileActionList from '../profile/user-profile.actions';
import * as LoaderAction from "../../stores/loader/loader.actions";
import { HttpService } from '../../shared/services/http.service';
import { AlertService } from '../../shared/services/alert.service';
import { UtilityService } from '../../shared/services/utility.service';
import { ExtraEdgeService } from '../../shared/services/extra-edge.service';
import { SignupService } from '../../auth/signup-login-master/shared/services/signup.service';
import { FiitJeeService } from '../../auth/fiit-jee-login/service/fiit-jee.service';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { DetectLocationService } from 'src/app/shared/services/detect-location.service';
import { ExtraEdgeV3Service } from 'src/app/utility/extra-edge-v3.service';
import { HelperService } from 'src/app/shared/services/helper.service';
interface ApiResponse {
    code: number;
    data: any;
    message: any;
}

interface SocialSignup {
    provider: string;
    authToken: string;
}

declare var $: any;
declare var window: any;
@Injectable()
export class AuthEffects {

    body;
    response;
    id: any;
    extraedgeCampaign: any;
    leadSource: any;
    leadType: any;
    leadName: any;
    userData: any;
    profileDataResponse: any;
    profiledataResponseFiitjee: any;
    public signuplognpassedInfo: any;
    constructor(
        private http: HttpService,
        private detectLocationService: DetectLocationService,
        private action$: Actions,
        private store: Store<fromApp.AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private utility: UtilityService,
        private signUpService: SignupService,
        private alert: AlertService,
        private extraEdge: ExtraEdgeService,
        private fiitJee: FiitJeeService,
        private extraEdgeV3: ExtraEdgeV3Service,
        private _helper: HelperService
    ) {
        this.store.select('userProfile').subscribe((userProfile) => {
            this.userData = userProfile.userData;
        });
    }

    // Registering the effect of verify mobile
    @Effect({ dispatch: false })
    verifyOtpEmail = this.action$.pipe(
        ofType<AuthActions.VerifyOtpEmail>(AuthActions.VERIFY_OTP_EMAIL),
        switchMap((authData) => this.http.post('verifyOtp', false, authData).pipe(
            map((res: ApiResponse) => {
                if (res.code === 200) {
                    localStorage.removeItem('lastRequested');
                }
            }),
            catchError((error) => {
                return [
                    {
                        type: AuthActions.FAIL
                    }
                ];
            })
        )
        ),
    )

    @Effect()
    socialSignup = this.action$.pipe(
        ofType(AuthActions.SOCIAL_SIGNUP),
        map((action: AuthActions.SocialSignup) => action.payload),
        switchMap((obj: any) => {
            const dataToSend = {
                email: obj.email,
                socialToken: obj.authToken,
                type: obj.provider.toLowerCase(),
                name: obj.name,
                uid: obj.id
            };

            return this.http.post('socialLogin', false, dataToSend, true, false, false, environment.serverURLv3).pipe(
                map((res) => {
                    if (res.code === 200) {
                        const responseRecieved = JSON.parse(JSON.stringify(res));
                        localStorage.setItem('authToken', responseRecieved.data.authToken);
                        window.location.reload();
                        return [
                            new AuthActions.SetToken(responseRecieved.data.authToken),
                            new AuthActions.SetAuthentication(),
                            new UserProfileActionList.GetUserDetail(responseRecieved.data),
                        ];
                    } else {
                        this.alert.showErrorMessage(res.message);
                        return [{ type: AuthActions.FAIL }];
                    }
                }),
                catchError((error) => {
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                })
            );
        })
    );
    

    @Effect()
    socialSignin = this.action$.pipe(
        ofType(AuthActions.SOCIAL_SIGNIN),
        map((action: AuthActions.SocialSignin) => {
            return action.payload;
        }),
        switchMap(
            (obj: SocialSignup) => {
                const dataToSend = {
                    socialToken: obj.authToken,
                    osType: 'web',
                    device: 'desktop'
                };
                const apiUrl = 'v4/login/social/' + obj.provider.toLowerCase();
                return this.http.post(apiUrl, false, dataToSend, true).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            if (res && res.data) {
                                res.data.fromScreen = 'signin';
                                localStorage.removeItem('lastRequested');
                                res.data.tag = '';
                            }
                            localStorage.setItem('authToken', res.data.authToken);
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                );
            }
        )
    )

    // Registering the effect of Signin action
    @Effect({ dispatch: false })
    processAfterSignIn = this.action$.pipe(
        ofType<AuthActions.ProcessAfterSignin>(AuthActions.PROCESS_AFTER_SIGNIN),
        tap(() => {
            this.router.navigate(['/complete-profile']);
        })
    );

    // Registering the effect of Logout action
    @Effect()
    logout = this.action$.pipe(
        ofType<AuthActions.Logout>(AuthActions.LOGOUT),
        map((action: AuthActions.Logout) => {
        }),
        switchMap(
            (authData) => {
                return this.http.post('logout', true, undefined, true, false, false, environment.serverURLv3).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200) {
                            let learnDarkMode = localStorage.getItem('isDarkmodeforid');
                            localStorage.clear();
                            localStorage.setItem('isDarkmodeforid', learnDarkMode)
                            sessionStorage.clear();
                            $('#logoutModal').modal('hide');
                            $('.modal-backdrop').remove();
                            $('body').removeClass('modal-open');
                            this.alert.showSuccessMessage(res.message);
                            let isRedirect=!this.userData?.isNCRPUser;
                            localStorage.clear();
                            localStorage.setItem('isDarkmodeforid', learnDarkMode)
                            sessionStorage.clear();
                            if(!isRedirect){
                                window.location.href = `${environment.fiitjeeUrlv4}?redirect=user-myPat`;
                            }else{
                                this.router.navigate(['/']);
                            }      
                            return [
                                {
                                    type: AuthActions.LOGOUT_SUCCESS
                                }
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    })
                );
            }
        )
    )

    @Effect()
    getProfileData = this.action$.pipe(
        ofType(AuthActions.GET_PROFILE_DATA),
        map((action: AuthActions.GetProfileData) => {
            return;
        }),
        switchMap(
            () => {
                return this.http.get('profile', true, null, false),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                    mergeMap(
                        (res: ApiResponse) => {
                            if (res.code === 200) {
                                // get profile data
                                const obj = res.data;
                                this.profileDataResponse = res.data;
                            } else {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
                        }
                    );
            }
        )
    )

    @Effect()
    FiiJeeMyPatLogin = this.action$.pipe(
        ofType(AuthActions.FIITJEE_MYPAT_LOGIN),
        map((action: AuthActions.FiiJeeMyPatLogin) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                let dataToSend = {};
                if (authData.type === 'mypat') {
                    dataToSend = {
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        source: 'web',
                        deviceToken: ''
                    };
                    if (localStorage.getItem('userIdcheckEn')) {
                        dataToSend['userId'] = localStorage.getItem('userIdcheckEn');
                    }

                }
                else {
                    dataToSend = {
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        source: 'web',
                        deviceToken: ''
                    };
                }
                return this.http.post('partnerLogin', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200 || res.code === 201) {
                            localStorage.setItem('authToken', res.data.authToken);
                            
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else if (res.code === 408) {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        } else {
                            this.alert.showErrorMessage(res.message);
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }
                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                );
            }
        )
    )

    @Effect()
    OtpLoginFiitjee = this.action$.pipe(
        ofType(AuthActions.OTP_LOGIN_FIITJEE),
        map((action: AuthActions.OtpLoginFiitjee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                const dataToSend = {
                    enrollmentno: authData.enrollmentno,
                    otp: authData.otp,
                    source: 'web',
                    deviceToken: '',
                    userId: authData.userId
                };
                if (dataToSend.otp) {
                    return this.http.post('otpPartner', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                        mergeMap((res: ApiResponse) => {
                            localStorage.setItem('authToken', res.data.authToken);
                            if (res.code === 200 || res.code === 201) {
                                sessionStorage.removeItem('otpResendCount');
                                return [
                                    new AuthActions.SetToken(res.data.authToken),
                                    new AuthActions.SetAuthentication(),
                                    new UserProfileActionList.GetUserDetail(res.data),
                                ];
                            } else if (res.code === 408) {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            } else {
                                this.alert.showErrorMessage(res.message);
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
    
                        }),
                        catchError((error) => {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }),
                    );
                } else {
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                }
            }
        )
    )

    @Effect()
    WACLoginFiitjee = this.action$.pipe(
        ofType(AuthActions.WAC_LOGIN_FIITJEE),
        map((action: AuthActions.WACLoginFiitjee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                const dataToSend = {
                    enrollmentno: authData.enrollmentno,
                    wac: authData.wac,
                    source: 'web',
                    deviceToken: '',
                };
                if (dataToSend.wac) {
                    return this.http.post('wacPartner', false, dataToSend, false, false, false, environment.serverURLv3).pipe(
                        mergeMap((res: ApiResponse) => {
                            if (res.code === 200 || res.code === 201) {
                                localStorage.setItem('authToken', res.data.authToken);
                                return [
                                    new AuthActions.SetToken(res.data.authToken),
                                    new AuthActions.SetAuthentication(),
                                    new UserProfileActionList.GetUserDetail(res.data),
                                ];
                            } else if (res.code === 408) {
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            } else {
                                this.alert.showErrorMessage(res.message);
                                return [
                                    {
                                        type: AuthActions.FAIL
                                    }
                                ];
                            }
    
                        }),
                        catchError((error) => {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }),
                    );
                } else {
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                }
            }
        )
    )

    @Effect()
    ResetPasswordFiitJee = this.action$.pipe(
        ofType(AuthActions.RESET_PASSWORD_FIITJEE),
        map((action: AuthActions.ResetPasswordFiitJee) => {
            return action.payload;
        }),
        switchMap(
            (authData) => {
                let dataToSend;

                if (this.fiitJee.saveOtp) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        otp: this.fiitJee.saveOtp,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                else if (this.fiitJee.savePassword) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        fjpassword: this.fiitJee.savePassword,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                else if (this.fiitJee.saveWac) {
                    dataToSend = {
                        userId: authData.userId,
                        password: authData.password,
                        enrollmentno: authData.enrollmentno,
                        wac: this.fiitJee.saveWac,
                        source: 'web',
                        deviceToken: ''
                    }
                }
                return this.http.put('mypatLogin', false, null, false, environment.serverURLv3).pipe(
                    mergeMap((res: ApiResponse) => {
                        if (res.code === 200 || res.code === 201) {
                            localStorage.setItem('authToken', res.data.authToken);
                            return [
                                new AuthActions.SetToken(res.data.authToken),
                                new AuthActions.SetAuthentication(),
                                new UserProfileActionList.GetUserDetail(res.data),
                            ];
                        } else if (res.code === 408) {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        } else {
                            return [
                                {
                                    type: AuthActions.FAIL
                                }
                            ];
                        }

                    }),
                    catchError((error) => {
                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }),
                );
            }
        )
    )

    @Effect()
    authNewSignup = this.action$.pipe(
        ofType(AuthActions.TRY_NEW_SIGNUP),
        map((action: AuthActions.TryNewSignup) => action.payload),
        switchMap((authData: any) => {
            let isFreeTrial = false;
            if (authData.hasOwnProperty('freeTrial')) {
                isFreeTrial = authData?.freeTrial;
                delete authData.freeTrial;
            }
            return this.http.post('v1/signup', false, authData, false, false, false, environment.serverURLv3).pipe(
                catchError((error) => {
                    return of({ type: AuthActions.FAIL });
                }),
                map((res) => {
                    const response = res;
                    if (res.code == 200) {
                        this.utility.signupData.next(authData);
                        this.utility.isNewUser.next(res);
                        this.utility.signUpSuccess.next(res);
                        this._helper.handleSignupError.next({ signupError: false, isFreeTrial, mobileNumber: res?.data?.mobile?.number, code: res?.data?.mobile?.countryCode });

                        // GTM
                        const gtmData = {
                            'event': 'mobileSignup',
                            'isFIITJEE': this.detectLocationService.isFiitjeeDomain() ? 'fiitjee' : 'non-fiitjee'
                        };
                        if (localStorage.getItem('utm_source')) {
                            gtmData['source'] = localStorage.getItem('utm_source');
                        } else if (this.utility.getGlobalAuthPopup() === true) {
                            gtmData['source'] = 'WebPopup';
                        }
                        window['dataLayer'] = window['dataLayer'] || [];
                        window['dataLayer'].push(gtmData);

                        sessionStorage.setItem('userIdClever', response.data._id);
                        localStorage.setItem('userId', response.data._id);

                        let pageStr = '';
                        if (!authData.loginViaOtp) {
                            if (this.utility.getGlobalAuthPopup() === true) {
                                pageStr = "WebPopup";
                            } else if (this.router.url == '' || this.router.url == '/') {
                                pageStr = "free trial";
                            } else if (localStorage.getItem('lastRequested') == '/counselling') {
                                pageStr = "free counselling";
                            } else if (this.router.url.includes('auth/sign-up')) {
                                pageStr = "normal register";
                            } else if (this.router.url.includes('all-india-challenge')) {
                                pageStr = 'Open Live Challenge Test';
                            } else if (this.router.url.includes('pre-jee-main')) {
                                pageStr = 'Pre JEE Main';
                            } else {
                                pageStr = this.router.url.replace(new RegExp('-', 'g'), ' ').split('/').reverse().join(' ');
                            }

                            if (this.detectLocationService.isFiitjeeDomain()) {
                                this.extraEdgeV3.extraEdgeToolUpdate(
                                    `&MobileNumber=${response['data']['mobile']['number']}&Leadstatus=Closed&ReasonCode=Existing Fiitjee student&Textb1=${response.data._id}&Field2=${authData.class?.name || ''}&FirstName=${authData.name || ''}`,
                                    pageStr
                                );
                            } else {
                                const extraEdgePayload = {
                                    'MobileNumber': response['data']['mobile']['number'],
                                    'Textb1': response.data._id
                                };
                                if (authData.class && authData.class.name) extraEdgePayload['Field2'] = authData.class.name;
                                if (authData.name) extraEdgePayload['FirstName'] = authData.name;
                                this.extraEdgeV3.extraEdgeJSONUpdate(extraEdgePayload, { sourceName: pageStr, addLeadMeta: true });
                            }
                        } else {
                            if (this.router.url.includes('free-course')) {
                                const submittedJSON = JSON.parse(localStorage.getItem('freePackageData'));
                                this.extraEdgeV3.extraEdgeToolUpdate(
                                    `&Field2=${authData.class ? authData.class.name : submittedJSON.class.name}&Target Exam=${submittedJSON.goal.name}&MobileNumber=${response['data']['mobile']['number']}&Textb1=${response.data._id}`,
                                    'Free Course'
                                );
                            } else {
                                const extraEdgePayload = {
                                    'MobileNumber': response['data']['mobile']['number'],
                                    'Textb1': response.data._id
                                };
                                if (authData.class && authData.class.name) extraEdgePayload['Field2'] = authData.class.name;
                                if (authData.name) extraEdgePayload['FirstName'] = authData.name;
                                let campaignName = "";
                                if (this.router.url == '' || this.router.url == '/') {
                                    campaignName = "free trial";
                                } else if (localStorage.getItem('lastRequested') == '/counselling') {
                                    campaignName = "free counselling";
                                } else {
                                    campaignName = this.router.url.replace(new RegExp('-', 'g'), ' ').split('/').reverse().join(' ');
                                }
                                this.extraEdgeV3.extraEdgeJSONUpdate(extraEdgePayload, { sourceName: campaignName, addLeadMeta: true });
                            }
                        }

                        return { type: AuthActions.SIGNUP };
                    } else if (res.code === 403) {
                        this._helper.handleSignupError.next({signupError: true, isFreeTrial});
                        this.alert.showErrorMessage(res.message);
                        return { type: AuthActions.FAIL };
                    } else if (res.code === 409) {
                        this.utility.downloadEbook.next(true);
                        this._helper.handleSignupError.next({signupError: true, isFreeTrial});
                        this.alert.showErrorMessage(res?.message);
                        this.router.navigate(['/user/login']);
                        return { type: AuthActions.FAIL };
                    } else {
                        this._helper.handleSignupError.next({signupError: true, isFreeTrial});
                        this.alert.throwErrorMessage(res);
                        this.alert.showErrorMessage(res.message);
                        return { type: AuthActions.FAIL };
                    }
                })
            );
        })
    );

    @Effect()
    authVerifyMobile = this.action$.pipe(
        ofType(AuthActions.VERIFY_MOBILE),
        tap((action: AuthActions.VerifyMobile) => {
            action.payload['source'] = 'web';
            action.payload['deviceToken'] = '';
        }),
        switchMap((authData) => {
            this.signuplognpassedInfo = JSON.parse(JSON.stringify(authData));
            delete authData['sourcePage'];
            return this.http.post('verifyOtpMobile', false, authData.payload, false, false, false, environment.serverURLv3).pipe(
                catchError((error) => {
                    return of({ type: AuthActions.FAIL });
                })
            );
        }),
        mergeMap((res) => {
            const { code, data, message } = res;
            if (code == 200) {
                this.profileDataResponse = data.profile;
                localStorage.setItem('authToken', data['authToken']);
                this.utility.downloadEbook.next(true);
                window['dataLayer'] = window['dataLayer'] || [];
                window['dataLayer'].push({
                    'event': 'mobileSignup',
                    'isFIITJEE': this.detectLocationService.isFiitjeeDomain() ? 'fiitjee' : 'non-fiitjee'
                });

                const extraEdgePayload = {
                    'MobileNumber': data.mobile.number,
                    'MobileVerified': true
                };

                let isPreviouslyFiitjeeUser = false;
                try {
                    isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || data.profile.partner.partnerUser;
                    isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || (data.profile.partner.partnerId != null);
                    isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || data.isFIITJEE;
                } catch (error) { }
                if (isPreviouslyFiitjeeUser) {
                    extraEdgePayload['ReasonCode'] = 'Existing Fiitjee student';
                    extraEdgePayload['Leadstatus'] = 'Closed';
                }

                this.extraEdge.extraEdgeJSONUpdate(extraEdgePayload);
                window.location.reload();
                return [
                    { type: AuthActions.SET_TOKEN, payload: data['authToken'] },
                    { type: AuthActions.SIGNIN },
                    { type: AuthActions.GET_PROFILE_DATA }
                ];
            } else {
                this._helper.handleOtpError.next({isOtpError: true});
                return [{ type: AuthActions.FAIL }];
            }
        })
    );

    @Effect()
    authSignin = this.action$.pipe(
        ofType(AuthActions.TRY_SIGNIN),
        map((action: AuthActions.TrySignin) => action.payload),
        switchMap((authData) =>
            this.http.post('login', false, authData, false, false, false, environment.serverURLv3).pipe(
                mergeMap((response) => {
                    if (response.code === 200) {
                        // gtm implementation
                        if (response.data.profile.isStepUser) {
                            localStorage.setItem('step-login', 'true');
                        }

                        if (response.data && response.data.mobile) {
                            // call extra edge
                            const extraEdgePayload = {
                                'MobileNumber': response.data.mobile.number.toString(),
                            };

                            let isPreviouslyFiitjeeUser = false;
                            try {
                                isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || response.data.profile.partner.partnerUser;
                                isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || (response.data.profile.partner.partnerId != null);
                                isPreviouslyFiitjeeUser = isPreviouslyFiitjeeUser || response.data.isFIITJEE;
                            } catch (error) { }

                            if (isPreviouslyFiitjeeUser) {
                                extraEdgePayload['ReasonCode'] = 'Existing Fiitjee student';
                                extraEdgePayload['Leadstatus'] = 'Closed';
                            }

                            this.extraEdge.extraEdgeJSONUpdate(extraEdgePayload);
                        }

                        let payloadProfile;
                        if (authData.mobileNumber == null && authData.email != null) {
                            payloadProfile = {
                                'Site': {
                                    'Identity': response.data._id,
                                    'Email': response.data.email.id
                                }
                            };
                            // use for onuserlogin push not profile push
                            window['dataLayer'] = window['dataLayer'] || [];
                            window['dataLayer'].push({
                                'event': 'emailLogin',
                                'isFIITJEE': this.detectLocationService.isFiitjeeDomain() ? 'fiitjee' : 'non-fiitjee'
                            });
                        } else if (authData.mobileNumber != null && authData.email == null) {
                            payloadProfile = {
                                'Site': {
                                    'Identity': response.data._id,
                                    'Phone': response.data.mobile.countryCode + '' + response.data.mobile.number
                                }
                            };
                            // use for onuserlogin push not profile push
                            window['dataLayer'] = window['dataLayer'] || [];
                            window['dataLayer'].push({
                                'event': 'mobileLogin',
                                'isFIITJEE': this.detectLocationService.isFiitjeeDomain() ? 'fiitjee' : 'non-fiitjee'
                            });
                        }

                        localStorage.setItem('authToken', response.data.authToken);
                        // get profile data
                        const obj = response.data.profile;
                        this.profileDataResponse = response.data.profile;
                        let user = this.utility.createUserObject(response.data);
                        user['dateOfBirth'] = obj.userId.dateOfBirth;
                        if (obj.class.classId) {
                            this.store.dispatch(new UserProfileActionList.GetGoalList(obj.class.classId));
                        }
                        localStorage.removeItem('login_Type');
                        localStorage.setItem('userId', response.data._id);
                        this.store.dispatch(new UserProfileActionList.SetUserDetail(user));
                        this.store.dispatch(new LoaderAction.SetLoader(false));
                        if (user.userGoals.length < 1 && this.router.url != '/auth/congratulation/final' && this.router.url != '/auth/congratulation/email_verified') {
                            this.router.navigate(['/user-profile-completion']);
                        }
                        // window.location.reload(); commented for MV4-10529
                        return [
                            {
                                type: AuthActions.SET_TOKEN,
                                payload: response.data.authToken
                            },
                            {
                                type: AuthActions.SIGNIN
                            }
                        ];
                    } else {
                        switch (response.code) {
                            case 400:
                                this._helper.handleLoginError.next({message: response.message});
                                break;
                            case 401:
                                this.router.navigate(['/auth/verify/email', authData.email]);
                                break;
                            case 402:
                                this.router.navigate(['/auth/verify/mobile', authData.mobileNumber]);
                                break;
                            default:
                                console.log(response);
                        }

                        this.store.dispatch(new LoaderAction.SetLoader(false));

                        return [
                            {
                                type: AuthActions.FAIL
                            }
                        ];
                    }
                }),
                catchError((error) => {
                    console.error(error);
                    this.store.dispatch(new LoaderAction.SetLoader(false));
                    return [
                        {
                            type: AuthActions.FAIL
                        }
                    ];
                })
            )
        )
    );

    callGTMevent(str): void {
        switch (str) {
            case 'verify-otp': {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'mobileSignup',
                });
            }
        }
    }
}


