import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from './auth/auth.reducers';
import * as fromProfile from './profile/user-profile.reducers';
import * as fromLoader from './loader/loader.reducers';
import * as fromProforma from './proforma/proforma.reducers';
import * as testOngoing from "./test-ongoing/test-ongoing.reducers";


export interface AppState{
    auth: fromAuth.State;
    userProfile: fromProfile.State;
    loader: fromLoader.State;
    proforma: fromProforma.State;
    testOngoing:testOngoing.State;
}

export const AppReducers: ActionReducerMap<AppState> = {
    auth: fromAuth.authReducer,
    userProfile: fromProfile.userProfileReducer,
    loader: fromLoader.loaderReducer,
    proforma: fromProforma.proformaReducer,
    testOngoing: testOngoing.testOngoingReducer
};
