import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromApp from '../stores/app.reducers';
import { Router, ActivatedRoute } from '@angular/router';
import _ from "lodash";
import { environment } from '../../environments/environment';
import { DetectLocationService } from '../shared/services/detect-location.service';
import { map, filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExtraEdgeV3Service {

  accessToken1: Observable<any>;
  accessToken: string;
  authenticated
  private baseUrl = "https://thirdpartyapi.extraaedge.com/api/SaveRequest?AuthToken=MYPATB2C-14-06-2017&Source=mypatb2c";

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private detectLocationService: DetectLocationService,
  ) { }


  isProd() {
    return environment.production;
  }

  extraEdgeUpdate(parameters) {
    let flag = true;
    let url = this.baseUrl;
    this.route.queryParams.takeWhile(() => flag).subscribe((params) => {
      if (!(_.isEmpty(params))) {
        if (params['utm_source']) {
          url = url + "&LeadSource=" + params['utm_source'];
          url = params['utm_source'] === 'adwords' ? url + '&ReasonCode=squad' : url;
        } else {
          url = url + "&LeadSource=Website";
        }

        if (params['utm_term']) {
          url = url + "&LeadType=" + params['utm_term'];
        } else {
          url = url + "&LeadType=Online";
        }

        if (params['utm_content']) {
          url = url + "&LeadName=" + params['utm_content'];
        } else {
          url = url + "&LeadName=Normal Register";
        }
        url = url + parameters + "&Priority=Medium&Course=myPAT";

        this.http.get(url).subscribe((res) => { })
      } else {
        url = url + parameters + "&Priority=Medium&Course=myPAT&LeadSource=Website&LeadType=Online&LeadName=Normal Register";
        this.http.get(url).subscribe((res) => { })
      }
      flag = false;
    });
  }

  extraEdgeNormalUpdate(params) {
    let url = this.baseUrl + params;
    this.http.get(url).subscribe((res) => { })
  }

  extraEdgeNormalJSONUpdate(params) {

    const jsonParams = Object.assign(params);
    let urlQueryString = Object.keys(jsonParams).map(key => `${key}=${jsonParams[key]}`).join('&');
    urlQueryString = '&' + urlQueryString;

    let url = this.baseUrl + urlQueryString;
    this.http.get(url).subscribe((res) => { })
  }

  extraEdgeJSONUpdate(params: { [x: string]: any }, options: { sourceName?: string, addLeadMeta?: boolean } = {}) {
    const url = this.baseUrl;
    // common transformation
    if (!this.isProd() && !params.Leadstatus)
      params.Leadstatus = 'Test Lead';
    const jsonParams = Object.assign(params);
    let urlQueryString = Object.keys(jsonParams).map(key => `${key}=${jsonParams[key]}`).join('&');
    urlQueryString = '&' + urlQueryString;

    const name = options?.sourceName ?? 'Normal Register';

    this.route.queryParams.subscribe((params) => {
      if (!(_.isEmpty(params))) {
        const leadSource = params['utm_source'] || 'Website';
        const leadType = params['utm_term'] || 'Online';
        const leadName = params['utm_content'] || name;

        let urlWithParams = `${url}&LeadSource=${leadSource}`;
        urlWithParams = leadSource === 'adwords' ? `${urlWithParams}&ReasonCode=squad` : urlWithParams;
        urlWithParams = `${urlWithParams}&LeadType=${leadType}&LeadName=${leadName}${urlQueryString}&Priority=Medium&Course=myPAT`;
        this.http.get(urlWithParams).subscribe((res) => { });
      } else {
        const urlWithParams = `${url}${urlQueryString}&Priority=Medium&Course=myPAT&LeadSource=Website&LeadType=Online&LeadName=${name}`;
        this.http.get(urlWithParams).subscribe((res) => { });
      }
    });
  }

  extraEdgeToolUpdate(parameters, name) {
    let flag = true;
    let url = this.baseUrl;
    this.route.queryParams.takeWhile(() => flag).subscribe((params) => {
      if (!(_.isEmpty(params))) {
        if (params['utm_source']) {
          url = url + "&LeadSource=" + params['utm_source'];
          url = params['utm_source'] === 'adwords' ? url + '&ReasonCode=squad' : url;
        } else {
          url = url + "&LeadSource=Website";
        }

        if (params['utm_term']) {
          url = url + "&LeadType=" + params['utm_term'];
        } else {
          url = url + "&LeadType=Online";
        }

        if (params['utm_content']) {
          url = url + "&LeadName=" + params['utm_content'];
        } else {
          url = url + "&LeadName=" + name;
        }
        url = url + parameters + "&Priority=Medium&Course=myPAT";
        this.http.get(url).subscribe((res) => { })

      } else {
        url = url + parameters + "&Priority=Medium&Course=myPAT&LeadSource=Website&LeadType=Online&LeadName=" + name;
        this.http.get(url).subscribe((res) => { })
      }
      flag = false;
    });
  }

  testInfoPushEE(testName, testType, leadContactable) {
    this.store.select('userProfile').
      pipe(
        filter(data => data != null),
        first(), // Sbscribe once
        map(data => {
          const extraEdgePayload = {
            // 'MobileNumber': data.userData.userMobile.number,
            'Textb1': data.userData.userId,
            'Remarks': `${testName}, ${testType}`,
            'LeadContactable': leadContactable,
          };
          this.extraEdgeJSONUpdate(extraEdgePayload);
        })
      ).subscribe();
  }
}


