<div class="form-group">
    <div class="social-container">
        <span class="social-login">or continue with</span>
        <a role="button" id="forGoogle" (click)="signInWithGoogle()" class="google-plus-icon google-btn">
            <img src="../../../../assets/img/s3-images/picasso/google-logo.svg" alt="Google-Login"/>
        </a>
        <a role="button" id="forFacebook" (click)="signInWithFB()" class="facebook-icon facebook-btn">
            <img src="../../../../assets/img/s3-images/picasso/facebook-logo.svg" alt="Facebook-Login"/>
        </a>
    </div>
</div>