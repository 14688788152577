<section class="testimonials-sec">
    <div class="container">
      <div class="owl-carousel owl-theme home-testimonial">
        <!-- item 1 start -->
        <div>
          <h4>Meet <span>Keshav</span></h4>
          <div class="picContent">
            <div class="studentsPic">
              <img src="/assets/images/new-home/testimonials/keshav.png" alt="keshav"/>
            </div>
            <div class="contentAIR">
              <p>I took a lot of mock tests on myPAT<sup>&reg;</sup>. In the last few weeks, I was preparing primarily on myPAT<sup>&reg;</sup>, taking a mock test daily. I felt these mock tests are really good. The question difficulty was good. After the test, a review of questions and solutions helped. The detailed analysis helped me figure out my mistakes. Once I figured out my mistakes, I worked harder to fix them and eventually eliminated the mistakes. myPAT<sup>&reg;</sup> is the perfect platform to build your exam readiness. It also helps you develop habit of sitting in front of a computer screen for three hours.</p>
              <span>
                <img src="/assets/images/new-home/testimonials/IISlogo.png" alt="IIS logo"/>
                <b>AIR 5, JEE ADVANCED 2020</b>
                <p>Science, IISC Bangalore</p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <h4>Meet <span>Chinmay</span></h4>
          <div class="picContent">
            <div class="studentsPic">
              <img src="/assets/images/new-home/testimonials/chinmay.png" alt="chinmay" />
            </div>
            <div class="contentAIR">
              <p>myPAT<sup>&reg;</sup> help me get familiarized with the online way of testing. When I started preparing in class 9, paper was OMR based. But gradually it's completely shifted to the online mode. There are a lot of topic wise tests on myPAT<sup>&reg;</sup>. I use to solve those 20 mins tests. The level of questions was good, with proper solution available. Everyday, I would take few 20 mins tests. Just before the exam, I gave a lot of mock tests on myPAT<sup>&reg;</sup> and also took the All India Series like Pre JEE Main. Also, I attempted a lot of past year JEE papers of myPAT<sup>&reg;</sup>. So, it
                help me for online paper.</p>
              <span>
                <img src="/assets/images/new-home/testimonials/IITlogo.png" alt="IIT Logo" />
                <b>AIR 31, JEE ADVANCED 2020</b>
                <p>B.TECH (Computer Science), IIT Delhi</p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <h4>Meet <span>Soumil</span></h4>
          <div class="picContent">
            <div class="studentsPic">
              <img src="/assets/images/new-home/testimonials/soumil.png" alt="saumil" />
            </div>
            <div class="contentAIR">
              <p>myPAT<sup>&reg;</sup> was helpful not only because they provided us with testing, but also because of the analysis part that came after the test. I came to know about my performance with respect to my peers and I also came to know about which topics I was especially lagging behind. So, I was able to fix any errors I had in my attempting strategy. I took a lot of tests on myPAT<sup>&reg;</sup>. With time, I was able to build a test-taking strategy that maximized my score. And of course, it helped me identify my weak topics. myPAT<sup>&reg;</sup> was really helpful towards the end of myd preparation.</p>
              <span>
                <img src="/assets/images/new-home/testimonials/IITlogo.png" alt="IIT logo"/>
                <b>AIR 33, JEE ADVANCED 2020</b>
                <p>B.TECH (Computer Science), IIT Delhi</p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <h4>Meet <span>Gurnoor</span></h4>
          <div class="picContent">
            <div class="studentsPic">
              <img src="/assets/images/new-home/testimonials/gurnoor.png" alt="saumil" />
            </div>
            <div class="contentAIR">
              <p>myPAT<sup>&reg;</sup> helped me throughout my journey. After reading or studying any concept in the class, I used to solve concept tests on myPAT<sup>&reg;</sup>. That helped me to analyse what's the depth to I've reached in that particular concept. During the last phase, I also attempted the Pre-JEE Main tests and Pre-JEE Advanced tests that helped me to enhance my performance for the final day. I indeed recommend myPAT<sup>&reg;</sup> to my juniors as it's an online portal that's very user-friendly and indeed helps you to boost your performance on the final day.</p>
              <span>
                <img src="/assets/images/new-home/testimonials/iitBombay.png" alt="IIT Bombay"/>
                <b>AIR 2, JEE ADVANCED 2019</b>
                <p>B.TECH (Computer Science), IIT Bombay</p>
              </span>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </section>