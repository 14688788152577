import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const TESTS_TO_REMAIN_LOCKED = [
  'full', 'part', 'combined', 'phase', 'unit'
];

const TEST_TYPE = {
  SAMPLE_TEST: 'sample'
};

@Injectable({
  providedIn: 'root'
})
export class GLiteUtilityService {
  public numberOfItemsInCart = new BehaviorSubject<any>(false);
  public cartItems: Array<any> = [];
  public selectedState: any = '';
  public allPackages: any = {};
  public triggerFreeTrialPopup = new Subject<any>();
  public getActiveClassId = new Subject<any>();
  public signUpData: any = {};

  constructor(private _http: HttpService) { }

  fetchStates() {
    return this._http.get('stateList', false, null, null, null, environment.serverURLv3);
  }

  getCart() {
    return this._http.get('getcart', true, {}, true, false, environment.serverURLv3);
  }

  deleteCartItem(data: any) {
    return this._http.delete(`removeCartItem?course_id=${data.course_id}`, data, true, environment.serverURLv3);
  }

  getTax(state: any) {
    return this._http.get('getTax', true, state, true, false, environment.serverURLv3);
  }

  getUserPackages() {
    return this._http.get('v4/auth/user/packages', true, {}, true, false, environment.serverURLv4);
  }

  checkIfTestIsLocked(testData: any): boolean {
    let isTestLocked = false;
    Object.keys(this.allPackages).forEach((key: any) => {
      const currentPackage = this.allPackages[key];
      if (currentPackage?.packageId === testData?.packageId) {
        if (currentPackage.purchased || testData.type === TEST_TYPE.SAMPLE_TEST) {
          isTestLocked = false;
        } else if (currentPackage?.isFreeTrialExpired || (TESTS_TO_REMAIN_LOCKED.includes(testData?.type) && currentPackage?.isFreeTrial)) {
          isTestLocked = true;
        }
      }
    });
    return isTestLocked;
  }

  getTaxInfo(payload: any){
    return this._http.get('stateTax',false,payload,true,false,environment.serverURLv3);
  }
}
