import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-share-referral-popup',
  templateUrl: './share-referral-popup.component.html',
  styleUrls: ['./share-referral-popup.component.scss']
})

export class ShareReferralPopupComponent implements OnInit {

  @Input() referralCode: string;
  @Output() closePopup = new EventEmitter<any>();

  isLinkCopied = false;
  referralLink = '';

  inviteSent = false;
  isInviteSuccessfull = false;
  showTextBox = false;
  text = '';

  public emailsAdded = [];
  public mobileAdded = [];

  public emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  public mobilePattern = '^[6-9]\\d{9}$';

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.referralLink = `https://fiitjee.glorifire.com/?referralCode=${this.referralCode}`;
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  copyCode(): void {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.referralLink);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.isLinkCopied = true;
    setTimeout(() => {
      this.isLinkCopied = false;
    }, 3000);
  }

  closeModal(): void {
    this.isLinkCopied = false;
    this.inviteSent = false;
    this.isInviteSuccessfull = false;
    this.showTextBox = false;
    this.text = '';
    this.emailsAdded = [];
    this.mobileAdded = [];
    this.closePopup.emit();
  }

  getEncodedURL(): any {
    return encodeURIComponent(this.referralLink);
  }

  showMobileInputBox(): void {
    this.text = 'mobile';
    this.showTextBox = true;
    this.emailsAdded = [];
    if (this.mobileAdded.length === 0) {
      this.mobileAdded.push({ id: 1, number: '' });
    }
  }

  showEmailInputBox(): void {
    this.text = 'email';
    this.showTextBox = true;
    this.mobileAdded = [];
    if (this.emailsAdded.length === 0) {
      this.emailsAdded.push({ id: 1, email: '' });
    }
  }

  goBack(): void {
    this.showTextBox = false;
  }

  addMoreItems(): void {
    if (this.text === 'email') {
      this.mobileAdded = [];
      if (this.emailsAdded.length < 5) {
        this.emailsAdded.push({ id: this.emailsAdded[this.emailsAdded.length - 1].id + 1, email: '' });
      }
    } else {
      this.emailsAdded = [];
      if (this.mobileAdded.length < 5) {
        this.mobileAdded.push({ id: this.mobileAdded[this.mobileAdded.length - 1].id + 1, number: '' });
      }
    }
  }

  showAddMore(): boolean {
    if (this.emailsAdded.length === 5) {
      return false;
    }
    if (this.mobileAdded.length === 5) {
      return false;
    }
    return true;
  }

  deleteEntry(i): void {
    if (this.text === 'email') {
      this.emailsAdded.splice(i, 1);
    } else {
      this.mobileAdded.splice(i, 1);
    }
  }

  isSendInviteEnabled(): boolean {
    if (this.emailsAdded.length > 0) {
      if (this.emailsAdded.findIndex(o => o.email === '') === -1) {
        if (this.emailsAdded.findIndex(o => o.email.match(this.emailPattern) === null) === -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (this.mobileAdded.length > 0) {
      if (this.mobileAdded.findIndex(o => o.number === '') === -1) {
        if (this.mobileAdded.findIndex(o => o.number.match(this.mobilePattern) === null) === -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  sendInvites(): void {
    let payload;
    if (this.emailsAdded.length > 0) {
      // const emails = [];
      payload = {
        communicationList: this.emailsAdded.map(e => e.email.toLowerCase()),
        type: 'EMAIL'
      };
    } else if (this.mobileAdded.length > 0) {
      // const mobileNumbers = ;
      payload = {
        communicationList: this.mobileAdded.map(e => e.number),
        type: 'SMS'
      };
    }
    this.httpService.post('referrals/share', true, payload, false, false, false, environment.serverURLv3).subscribe((res) => {
      this.inviteSent = true;
      if (res && res.code === 200) {
        this.isInviteSuccessfull = true;
      } else {
        this.isInviteSuccessfull = false;
      }
    })
  }
}
