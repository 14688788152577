<!-- Loader first End -->

<div class="loader-bar-wrap" *ngIf="isloader">
  <div class="load-bar">
  <div class="bar"></div>
  <div class="bar"></div>
  <div class="bar"></div>
</div>
</div>

<div class="spinner" *ngIf="pageFrom==='notification'">
  <div class="rect1"></div>
  <div class="rect2"></div>
  <div class="rect3"></div>
  <div class="rect4"></div>
  <div class="rect5"></div>
</div>