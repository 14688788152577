<div class="modal fade" tabindex="-1" role="dialog" id="reportIssueidea" >
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom reportIssue" role="document">
      <div class="modal-content"><div class="opacityDiscard"></div>
        <div class="modal-header">

            <h5 class="modal-title">Submit an Idea/Suggestion</h5>

            <button
              [ngClass]="discardChanges ? 'close closedisable':'close'"
              (click)="closePopup()"
              [disabled]="disabledCrossIcon"
            >
            </button>

        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="TypeList"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [disabled]="isLoading"
                  >
                    <p class="selectcoursetext colorDark">{{reqObj.type | titlecase}}</p>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="problemTypeList">
                    <ul class="selectMenu">
                      <li (click)="onTypeChange('idea')">Idea</li>
                      <li (click)="onTypeChange('suggestion')">Suggestion</li>
                    </ul>
                  </div>
                </div>
                 </div>

          <!--Idea Type-->
          <div class="form-group">
            <div class="dropdown">
              <button
                class="btn btn-default dropdown-toggle"
                type="button"
                id="multiselectdropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                [disabled]="isLoading"
                (click)="toggleSelectMenu()"
                [ngClass]="{'redborder': errorTextProblem}"
              >
                <p class="selectcoursetext" *ngIf="reqObj.categories.length>0"><span class="suggesstionNameList">{{getCategoriesName()}}</span><span class="typeNumber"  *ngIf="selectedType.length > 3 && !isMobile && !isTab">+{{selectedType.length - 3}}</span>
                  <span class="typeNumber"  *ngIf="selectedType.length > 2 && !isMobile && isTab">+{{selectedType.length - 2}}</span>
                  <span class="typeNumber"  *ngIf="selectedType.length > 1 && isMobile && !isTab">+{{selectedType.length - 1}}</span></p>
                <p class="selectcoursetext" *ngIf="reqObj.categories.length===0">{{defaultSuggestionType}}</p>
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu checkbox-menu allow-focus" aria-labelledby="multiselectdropdown">
              <ul *ngIf="reqObj.type != 'idea'">
                <li><label>
                  <input type="checkbox" [checked]="generalCheckBox" (change)="handleGeneralCatergory($event)">
                  General
                </label>
                </li>
                <ng-container *ngFor="let item of issueType.categories; let i=index">
                  <li *ngIf="i>0" class="borderTop">
                    {{item.name}}
                    <ul>
                      <li *ngFor="let subItem of item.subCategories">
                        <label (click)="onChangeSubCategory($event,item,subItem)"><input type="checkbox" name="subCategories"[(ngModel)]="subItem.ischecked" [checked]="subItem.ischecked">
                        {{subItem.name}}
                      </label>
                      </li>
                    </ul>
                  </li>
                </ng-container>
              </ul>
              <ul *ngIf="reqObj.type == 'idea'">
                <li *ngFor="let item of issueType.categories" (click)="onChangeProblem($event,item)">
                  <label><input type="checkbox" [checked]="item.ischecked">
                  {{item.name}}
                </label>
                </li>
              </ul>
            </div>
            </div>
            <!-- <div class="error" *ngIf="errorTextProblem">Please Select {{reqObj.type | titlecase}} Type</div> -->
          </div>
          <div class="form-group" *ngIf="reqObj.type == 'idea'">
            <textarea [(ngModel)]="reqObj.description"  [ngClass]="{'disabled': !reqObj.categories.length, 'redborder': descriptionError}" [disabled]="isLoading" (keyup)="onKeyUp()"  placeholder="Explain your Idea in detail..." rows="6"></textarea>
            <span class="error counter" [ngClass]="descriptionLength >= 40 ? 'textColorChange' : ''"><span id="current" class="coutText">{{descriptionLength}} <span *ngIf="descriptionLength == 1">character</span><span *ngIf="descriptionLength != 1">characters</span></span> (min 40 characters)</span>
          </div>
          <div class="form-group" *ngIf="reqObj.type == 'suggestion'">
            <textarea [(ngModel)]="reqObj.description" [ngClass]="{'disabled': !reqObj.categories.length, 'redborder': descriptionError}" [disabled]="isLoading" (keyup)="onKeyUp()" placeholder="Explain your Suggestion in detail…" rows="6"></textarea>
            <span class="error counter" [ngClass]="descriptionLength >= 40 ? 'textColorChange' : ''"><span id="current" class="coutText">{{descriptionLength}} <span *ngIf="descriptionLength == 1">character</span><span *ngIf="descriptionLength != 1">characters</span></span> (min 40 characters)</span>
          </div>
          <div class="form-group attachMents">
            <label>Attachments<div [ngClass]="fileTypeAccept?'error':'fileTypeUpload'" style="margin-top: 0;">only .jpg, .jpeg, .png and .mp4 files are supported</div></label>
            <div class="attachFile">
              <span class="blankData" [ngClass]="{'disabled': isLoading}">
                <label for="files" class="btn"></label>
                <input type='file' id="files" accept=".jpg,.jpeg,.png,.mp4," (change)="onSelectFile($event)" (click)="onimageClick()"  multiple style="display: none;"/>
              </span>
              <span *ngFor="let list of mydata;let index = index">
                <img class="img" *ngIf="list.type == 'image'" [src]="list.url" />
                <!-- <video *ngIf="list.type == 'video'" class="img" [src]="list.url"></video> -->
                <img class="img" *ngIf="list.type == 'video'"
                    src="../../../../../../assets/images/help/bgwith-play.png"
                    alt="Video"
                />
                <button  class="closebutton" (click)="closeThumb(index)" href="javascript:void(0);"  [disabled]="disabledCrossIcon"
                  ><img
                    src="../../../../../../assets/images/help/attachClose.svg"
                    alt="close"
                /></button>
              </span>
              <div class="blankDiv w-100">
              <p class="fileSizeText">Max. File Size: 100 MB</p> 
              </div>
            </div>
            <p class="fileSizeText error" *ngIf="fileAdded">File is already added!</p>
            <div class="error mtop" *ngIf="fileSizeError">Files greater than 100 MB cannot be added</div>
            <!-- <div class="error" *ngIf="fileTypeAccept">Only .jpg, .jpeg, .png and .mp4, files are supported</div> -->
          </div>
          <div *ngIf="discardChanges" class="discardChanges">
            <div class="txtDiscard">
              <b>Discard Changes?</b>
              <em>Changes you made will be lost</em>
            </div>
            <div class="discardCta">
              <button type="button" (click)="onDiscard()" class="btn textButton">Discard</button>
              <button type="button" (click)="cancelClick()" class="btn btn-primary">Cancel</button>
            </div>
          </div>
          <div class="submitCta">
            <label *ngIf="!this.mydata.length" class="noAttachment">No File Attached</label>
            <label class="noAttachment" *ngIf="this.mydata.length">{{this.mydata.length}} Attachment{{this.mydata.length > 1 ? 's' : ''}} added</label>
            <button type="button" [ngClass]="{'disabledClass': !checkIfDisabled()}" [disabled]="disableSubmit || isLoading" (click)="reportAnIssue()" class="btn btn-primary">
              Submit</button>
          </div>
        </div>
        <div class="uploaderprogress" *ngIf="uploadingFile">
        <div class="progress" style="height:10px">
          <div class="progress-bar" style="width:40%;height:10px"></div>
        </div>
        </div>
      </div>
    </div>
  </div>
