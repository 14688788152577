import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-selected-card-loader',
  templateUrl: './selected-card-loader.component.html',
  styleUrls: ['./selected-card-loader.component.scss']
})

export class SelectedCardLoaderComponent implements OnInit {

  @Input() cardName: string;

  constructor() { }

  ngOnInit(): void {
  }

  range(n: number) {
    const arr = [];
    for (let i = 0; i < n; i += 1) arr.push(i);
    return arr;
  }
}
