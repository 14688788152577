import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EBOOKS, TAG_CLICKED } from './ebook.constant';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer-before-login',
  templateUrl: './footer-before-login.component.html',
  styleUrls: ['./footer-before-login.component.scss']
})
export class FooterBeforeLoginComponent implements OnInit {

  constructor(private router: Router) { }
  public isMobile: boolean = false;
  public companyMenu: boolean = false;
  public resourcesMenu: boolean = false;
  public examsMenu: boolean = false;
  public previousPapersMenu: boolean = false;
  public eBooksMenu: boolean = false;
  public toolsMenu: boolean = false;
  public reachUsMenu: boolean = false;
  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
  }
  public get FOOTERURL(){
    return environment;
  }

  public get ebook(){
    return EBOOKS;
  }

  redirectToEbooks(ebook){
    this.router.navigate(['/ebooks'],{queryParams:{exam:ebook.examType}});
  }

  scrollToTop(event){
    if(event.target.nodeName === TAG_CLICKED.ELEMENT && event?.target?.classList[0] !== TAG_CLICKED.CLASS){
      setTimeout(()=>{
        window.scrollTo(0, 0);
      },100);
    }
  }

}
