import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelpService } from 'src/app/help-and-support/services/help.service';
import * as fromApp from '../../../stores/app.reducers';
import * as LoaderAction from '../../../stores/loader/loader.actions';
import { HttpService } from '../../../shared/services/http.service';
import { AlertService } from '../../services/alert.service';
import { HomeService } from 'src/app/home-page/services/home.service';

declare var $: any;
var w = (window.innerWidth);
@Component({
  selector: 'app-report-an-issue-idea',
  templateUrl: './report-an-issue-idea.component.html',
  styleUrls: ['./report-an-issue-idea.component.scss']
})
export class ReportAnIssueIdeaComponent implements OnInit {
  @Output() discardComplete = new EventEmitter<any>();
  reqObj = {
    type: 'idea',
    categories: [],
    description: '',
    attachments: []
  };
  disableSubmit = false;
  errorAndSuccess = false;
  uploadingFile: boolean = false;
  selectedProblemTypeId: any;
  public dataToPassForUserCards: any;
  public issueType = {categories: []};
  public defaultSuggestionType: string = 'Idea Type';
  public prevItem = 'idea';
  public generalCheckBox: boolean = false;
  public showSelectMenu: boolean = false;
  public isLoading: boolean = false;
  public selectedType: any;
  public disabledCrossIcon: boolean = false;
  public isMobile: boolean = false;
  public isTab: boolean = false;
  public fileAdded: boolean = false;
  descriptionError : boolean = false;
  @Output() onClosePopUp = new EventEmitter<any>();
  @Output() onSubmitSuccess = new EventEmitter<any>();
  @Output() reportAnIdeaUpdate = new EventEmitter<any>();
  constructor(private helpServices: HelpService, private http: HttpClient,
    private store: Store<fromApp.AppState>, private homePageService: HomeService,
    private httpService: HttpService, private alert: AlertService) {
    let regexForTab = new RegExp('Chrome\/[.0-9]* (?!Mobile)');
    let regexForMobile = new RegExp('Chrome/[.0-9]* Mobile');
    if (navigator.userAgent.includes('Android')) {
      if (regexForTab.test(navigator.userAgent)) {
        this.isMobile = false;
        this.isTab = true;
      } else if (regexForMobile.test(navigator.userAgent)) {
        this.isMobile = true;
        this.isTab = false;
      }
    } else if (navigator.userAgent.includes('iPhone')) {
      this.isMobile = true;
      this.isTab = false;
    } else if (navigator.userAgent.includes('iPad')) {
      this.isMobile = false;
      this.isTab = true;
    } else {
      this.isMobile = false;
      this.isTab = false;
    }
  }

  ngOnInit(): void {
    $('#reportIssueidea').modal({
      backdrop: 'static',
      keyboard: false
  })
    //multiple checkbox
    $(document).ready(function(){
     $(document).on('click', '.allow-focus', function (e) {
       e.stopPropagation();
     });
    });
    this.helpServices.helpIssueType().then((res) => {
      this.issueType = res.idea;
    });
  }

  onChangeProblem(event: any, item: any) {
    event.stopPropagation();
    if (event.target.type !== 'checkbox'){
       event.preventDefault();
    }
    
    const index = this.reqObj.categories.findIndex((cat) => cat.name === item.name);
    item.ischecked = !item.ischecked;
    if (index >= 0){
      this.reqObj.categories.splice(index, 1);
    }else{
      this.reqObj.categories.push(item);
    }
    if (this.reqObj.categories.length === 0){
      // this.disableSubmit = true;
    }
  }
  onChangeSubCategory(event, item, subItem){
    event.stopPropagation();
    if (event.target.type !== 'checkbox'){
       event.preventDefault();
    }
    this.disableSubmit = false;
    subItem.ischecked = !subItem.ischecked;
    let changeItem = this.reqObj.categories.find((cat) => cat.name === item.name);
    if (changeItem){
      changeItem = {...changeItem};
      let changeSubItem = changeItem.subCategories.find((subCat) => subCat.name === subItem.name);
      if (changeSubItem){
        changeSubItem = {...changeSubItem};
        changeItem.subCategories = changeItem.subCategories.filter((subCat) => subCat.name != subItem.name);
      }else{
        changeItem.subCategories.push(subItem);
      }
      if (changeItem.subCategories.length === 0){
        this.reqObj.categories = this.reqObj.categories.filter((c) => c.name !== item.name);
      }else{
        const index = this.reqObj.categories.findIndex((cat) => cat.name === item.name);
        this.reqObj.categories[index] = changeItem;
      }
    }else{
      const newItem = {...item};
      const newSubItem = {...subItem};
      newItem.subCategories = [newSubItem];
      this.reqObj.categories.push(newItem);
    }
    // if (this.reqObj.categories.length === 0){
    //   this.disableSubmit = true;
    // }
  }
  handleGeneralCatergory(event){
    event.stopPropagation();
    if (event.target.type !== 'checkbox'){
       event.preventDefault();
    }
    this.generalCheckBox = event.currentTarget.checked;
    const item = this.issueType.categories[0];
    if (!this.generalCheckBox){
      this.issueType.categories[0].ischecked = false;
      this.reqObj.categories = this.reqObj.categories.filter((cat) => cat.name !== item.name);
    }else{
      this.issueType.categories[0].ischecked = true;
      item.ischecked = true;
      this.reqObj.categories.push(item);
    }
    this.getCategoriesName();
    // if (this.reqObj.categories.length === 0){
    //   this.disableSubmit = true;
    // }
  }
  onTypeChange(item: string){
    this.errorTextProblem = false;
    this.reqObj.type = item;
    if(this.prevItem !== item){
      if (item === 'idea'){
        this.defaultSuggestionType = 'Idea Type';
        this.helpServices.helpIssueType().then((res) => {
          this.issueType = res.idea;
        });
      }else{
        this.defaultSuggestionType = 'Suggestion Type';
        this.helpServices.helpIssueType().then((res) => {
          this.issueType = res.suggestion;
        });
      }
        this.prevItem=item
        this.reqObj.description = '';
        this.descriptionLength = 0;
        this.reqObj.categories = [];
    }
  }

  mydata = [];
  fileSizeError = false;
  fileTypeAccept = false;

  onimageClick(){
    $('#files')[0].value = null; 
  }

  onSelectFile(event) {
    this.fileSizeError = false;
    this.fileTypeAccept = false;
    const files = event.target.files;

    var ext = $('#files').val().split('.').pop().toLowerCase();
    if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'mp4' && ext !== 'mov' && ext !== 'hevc') {
    this.fileTypeAccept = true;
    this.fileAdded = false;
    return false;
    }

    if (files) {
      for (const file of files) {
        const reader = new FileReader();
        let fileType = (Math.floor(Math.log(file.size) / Math.log(1024)));
        let actualSize = Math.round(file.size / Math.pow(1024, fileType));
        const fileName = file.name.split(' ').join('');
        reader.onload = (e: any) => {
          if (file.type.indexOf('image') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'image',
                name: fileName,
                file: file,
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
            // this.getPreSignedUrl();
          } else if (file.type.indexOf('video') > -1) {
            if (fileType === 2 && actualSize >= 100) {
              this.fileSizeError = true;
              this.fileAdded = false;
              return;
            }
            const index = this.mydata.findIndex((obj) => obj.file.name == file.name)
            if (index == -1) {
              this.fileAdded = false;
              this.mydata.push({
                url: e.target.result,
                type: 'video',
                name: fileName,
                file: file
              });
            } else {
              this.fileAdded = true;
              this.fileSizeError = false;
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  closeThumb(index) {
    this.fileAdded = false;
    this.mydata.splice(index, 1);
  }

  // get permission from s3 to upload image
  getPreSignedUrl(obj): void {
    const apiUrl = 'v4/auth/upload-signed-url?location=ideasAndSuggestions';
    this.httpService.get(apiUrl, true, { key: obj.name }, false).subscribe(
      (res) => {
        if (res && res.code === 200) {
          if (res.data.url && res.data.fields) {
            obj.photoUrlCopy = res.data.url + '/' + res.data.fields.key;
            this.uploadYourFileOnS3(res.data, obj);
          }
        }
      }, (error) => {
        this.currentUploadIndex++;
        obj.status = 'Failed';
        this.uploadFiles();
      });
  }

  // upload image after permision
  uploadYourFileOnS3(data, obj): void {
    const formData: FormData = new FormData();
    formData.append('key', data.fields.key);
    formData.append('bucket', data.fields.bucket);
    formData.append('acl', 'public-read');
    formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
    formData.append('Policy', data.fields['Policy']);
    formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
    formData.append('file', obj.file, obj.name);

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };
    this.http.post(data.url, formData, options).subscribe((res) => {
      this.currentUploadIndex++;
      obj.status = 'Uploaded';
      this.uploadFiles();
    }, () => {
      this.currentUploadIndex++;
      obj.status = 'Failed';
      this.uploadFiles();
    });
  }

  uploadFiles() {
    this.errorAndSuccess = false;
    this.isLoading = true;
    if (this.currentUploadIndex < this.mydata.length) {
      this.getPreSignedUrl(this.mydata[this.currentUploadIndex]);
    } else {
      this.reqObj.attachments = [];
      this.mydata.forEach((item) => {
        this.reqObj.attachments.push({
          type: item.type,
          url: item.photoUrlCopy
        });
      });
      let categories = [];
      let subCategories = [];
      this.issueType.categories.forEach((item)=>{
        if(item.ischecked){
          categories.push(item);
        }
        if(item.subCategories.length){
          item.subCategories.forEach(element => {
            if(element.ischecked){
               subCategories.push(element)
            }
          });
          if(subCategories.length){
            item.subCategories = subCategories;
            categories.push(item);
            subCategories = []
          }
        }
      })
      this.reqObj.categories = categories;
      this.helpServices.postReportAnIssue(this.reqObj).then((res) => {
        if (res.code === 200) {
          // success
          localStorage.setItem('reporteditem','ideasAndSuggestions');
          $('#reportIssueidea').modal('hide');
          $('.modal-backdrop').removeClass('show');
          $('.reportIssueidea').removeClass('discardOpen');
          this.discardChanges = false;
          this.disableSubmit = false;
          this.isLoading = false;
          // hide loader and close popup then show sucsses res.message
          this.store.dispatch(new LoaderAction.SetLoader(false));
          this.alert.showSuccessMessage(res.message);
          this.alert.notificationCountUpdate.next(1);
          this.alert.IdeaStatus.next('All');
          this.homePageService.fetchHomeCardsData().then((res) => {
            if (res){
              if (res.userCards && res.userCards.length) {
                this.dataToPassForUserCards = JSON.parse(JSON.stringify(res.userCards));
                this.dataToPassForUserCards[2].data.forEach(element => {
                  if (element.type === 'ideasAndSuggestions' && this.getParameterByName('type') !== 'idea,suggestion'){
                    this.helpServices.UpdatedDataHelp.next(element);
                  }
                });
              }
              this.uploadingFile = false;
            }

          });
          this.onSubmitSuccess.emit(this.reqObj.type);
          this.reportAnIdeaUpdate.emit();
         } else {
          // error: res.message
          this.alert.showErrorMessage(res.message);
          // hide loader
          this.store.dispatch(new LoaderAction.SetLoader(false));
          this.disableSubmit = false;
          this.uploadingFile = false;


        }
      }, (error) => {
        // error message: Something went wrong, Please try again
        this.alert.showErrorMessage('Something went wrong');
        // hide loader
      });

    }
  }

  currentUploadIndex = 0;
  errorText = false;
  errorTextProblem = false;

  reportAnIssue() {
    // show loader
    // this.disableSubmit = true;
    this.isLoading = false;
    // this.disabledCrossIcon = true;
    this.errorTextProblem = false;
    this.descriptionError = false;
    if (this.reqObj.categories.length === 0){
      this.errorTextProblem = true;
      return;
    }
    this.errorText = false;
    if (this.reqObj.categories.length && (this.descriptionLength < 40)){
      this.descriptionError = true;
      return;
    }
    if (!this.reqObj.description || (this.descriptionLength < 40)) {
      this.errorText = true;
      return;
    }
    if (this.reqObj.categories.length !== 0 && (this.descriptionLength < 40)) {
      this.disabledCrossIcon = false;
      return;
    }
    else{
      this.disabledCrossIcon = true;
    }
   // if(this.reqObj.categories == 'Idea Type' || this.reqObj.categories == 'Suggestion Type')
    this.store.dispatch(new LoaderAction.SetLoader(true));
    this.currentUploadIndex = 0;
    this.uploadingFile = true;
    this.uploadFiles();
  }


  public descriptionLength = 0;
  onKeyUp() {
    const count = this.reqObj.description.replace(/\r?\n|\r/g, "");
    this.descriptionLength = count.replace(/ /g, '').length;
    this.descriptionError = false;
    if (!this.reqObj.description || (this.reqObj.description.replace(/ /g, '').length < 40)) {
      //this.errorText = true;
      this.descriptionError = false;
    } else {
      //this.errorText = false;
      this.disableSubmit = false;
      this.descriptionError = false;
    }
  }

  discardChanges = false;
  cancelClick() {
    this.discardChanges = false;
    $('.reportIssue').removeClass('discardOpen');
  }

  closePopup() {
    if (this.descriptionLength || this.mydata.length) {
      $('.reportIssue').addClass('discardOpen');
      this.discardChanges = true;
    } else {
      this.fileAdded = false;
      this.fileSizeError = false;
      this.fileTypeAccept = false;
      this.onClosePopUp.emit();
      $('#reportIssueidea').modal('hide');
      $('.modal-backdrop').removeClass('show');
    }
  }

  onDiscard() {
    this.onClosePopUp.emit();
    this.discardComplete.emit();
    $('#reportIssueidea').modal('hide');
    $('.modal-backdrop').removeClass('show');
    $('.reportIssue').removeClass('discardOpen');
    this.discardChanges = false;
    this.disabledCrossIcon = false;
    this.fileAdded = false;
    this.fileSizeError = false;
    this.fileTypeAccept = false;
  }

  toggleSelectMenu(){
    this.showSelectMenu = !this.showSelectMenu;
    this.errorTextProblem = false;
    // if (this.reqObj.categories.length === 0){
    //   this.errorTextProblem = true;
    //   return;
    // }
  }

  getCategoriesName(){
    const checkedNames: string[] = [];
    this.issueType.categories.forEach((category: any) => {
      if (category.ischecked) {
        checkedNames.push(category.name);
      }
      if (category.subCategories.length) {
        category.subCategories.forEach((subCategory: any) => {
          if (subCategory.ischecked) {
            checkedNames.push(subCategory.name);
          }
        })
      }
    });
    this.selectedType = checkedNames;
    if (checkedNames.length > 3 && !this.isMobile && !this.isTab) {
      return checkedNames.slice(0, 3).join(', ');
    }
    if (checkedNames.length > 2 && !this.isMobile && this.isTab) {
      return checkedNames.slice(0, 2).join(', ');
    }
    if (checkedNames.length > 1 && this.isMobile && !this.isTab) {
      return checkedNames.slice(0, 1).join(', ');
    }
    return checkedNames.join(', ');
  }

  checkIfDisabled() {
    if (this.descriptionLength >= 40 && this.reqObj.categories.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  onFocusDescription(){
    this.descriptionError = false;
  }

  getParameterByName(name, url = window.location.href): any {
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) {
        return null
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
