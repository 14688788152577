import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-goal-popup',
  templateUrl: './primary-goal-popup.component.html',
  styleUrls: ['./primary-goal-popup.component.scss']
})
export class PrimaryGoalPopupComponent implements OnInit {
  @Input() heading: string = '';
  @Input() primaryGoalData: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
