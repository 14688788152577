import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (environment.name === 'prod') {
    window.console.log =  () => { };
  }
}

if ('serviceWorker' in navigator) {
  removeServiceWorker();
}

function removeServiceWorker(): void {
  /**
   * The following will remove service worker and clear all associated caches
   * Use this for hard reset of website in case of dead-worker
   */

  // test remove service-worker
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
    console.log('Service Worker unregistered successfully');
  }).catch((err) => {
    console.log('Service Worker failed to unregister', err);
  });

  // test clearing cache
  caches.keys().then((names) => {
    for (const name of names) {
      caches.delete(name);
    }
    console.log('Caches cleared successfully');
  }).catch((err) => {
    console.log('Failed to clear caches', err);
  });
}

function addServiceWorker(): void {
  // test add service-worker
  navigator.serviceWorker.register('ngsw-worker.js').then(registration => {
    console.log('SW registered: ', registration);
  }).catch(registrationError => {
    console.log('SW registration failed: ', registrationError);
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => { })
  .catch(err => console.error(err));
