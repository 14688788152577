<div class="reportIssue_modal modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" id="reportIssue">
 <div class="modal-dialog modal-dialog-centered reportIssue" role="document">
    <div class="modal-content"><div class="opacityDiscard"></div>
      <div class="modal-header">
        <div class="col-1"></div>
        <div class="col-10 text-center">
          <h5 class="modal-title">Report an Issue</h5>
        </div>
        <div class="col-1 ms-3">
          <button
            type="button"
            [ngClass]="discardChanges ? 'close closedisable':'close'"
            (click)="closePopup()"
            [disabled]="disabledCloseIcon"
          >
          </button>
        </div>
      </div>
      <div class="modal-body">

          <!-- <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="problemTypeList"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p class="selectcoursetext">{{reqObj.category}}</p>
            </button>
            <div class="dropdown-menu" aria-labelledby="problemTypeList">
              <ul>
                <li *ngFor="let item of issueType" (click)="onChangeProblem(item)">{{item}}</li>
              </ul>
            </div>
          </div> -->
          <!-- Select Module -->
          <div class="form-group">
          <div class="courseDropdown">
            <div class="dropdown">
              <button class="btn btn-goalList dropdown-toggle" type="button" id="goalMenuListButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" [disabled]="isLoading" [ngClass]="{'redborder': errorTextProblem}" (focus) = 'onFocuscategory()'>
                <p class="selectcoursetext">{{reqObj.category}}</p>
              </button>
              <div class="dropdown-menu" aria-labelledby="goalListMenuButton">
                <div class="goalList">
                  <a *ngFor="let item of issueType" (click)="onChangeProblem('category',item)" [class.active]="reqObj.category === item.name" class="dropdown-item"
                   href="javascript:void(0)">
                    <span>{{item.name}}</span></a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="error" *ngIf="errorTextProblem">Please Select Module</div> -->
          <!-- Problem Type -->
          </div>

          <div class="form-group">
            <div class="courseDropdown dsaBTN">
              <div class="dropdown" [ngClass]="{'disabled':subCatergories.length == 0 }">
                <button class="btn btn-goalList dropdown-toggle report_issue_problem_type_field" type="button" id="goalListMenuButton-button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" [disabled]="isLoading" [ngClass]="{'redborder': selectSubCategoryError}" (focus) = "onFocusSubCategory()">
                  <p class="selectcoursetext">{{selectedSubCategory}}</p>
                </button>
                <div class="dropdown-menu" aria-labelledby="goalListMenuButton">
                  <div class="goalList">
                    <a *ngFor="let item of subCatergories" (click)="onChangeProblem('subCatergories',item.name)" [class.active]="selectedSubCategory === item.name" class="dropdown-item"
                     href="javascript:void(0)">
                      <span>{{item.name}}</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="error" *ngIf="showSubCatergoryError">Please Select Problem Type</div>
          </div>
        <div class="form-group">
          <textarea id="#textarea" [ngClass]="{'disabled': selectedSubCategory === 'Problem Type', 'redborder': descriptionError}" [disabled]="selectedSubCategory === 'Problem Type' || isLoading" [(ngModel)]="reqObj.description" (keyup)="onKeyUp()" placeholder="Describe your issue in detail…" rows="6"></textarea>
          <span class="error counter" [ngClass]="descriptionLength >= 40 ? 'textColorChange' : ''"><span id="current" class="coutText">{{descriptionLength}} <span *ngIf="descriptionLength == 1">character</span><span *ngIf="descriptionLength != 1">characters</span></span> (min 40 characters)</span>
        </div>
        <div class="form-group attachMents">
          <label>Attachments<div [ngClass]="fileTypeAccept?'error':'fileTypeUpload'" style="margin-top:0;">only .jpg, .jpeg, .png and .mp4 files are supported</div></label>
          <div class="attachFile">
            <span class="blankData" [ngClass]="{'disabled': isLoading}">
              <label for="helpfiles" class="btn"></label>
              <input type='file' id="helpfiles" accept=".jpg,.jpeg,.png,.mp4" (change)="onSelectFile($event)" (click)="onimageClick()" multiple/>
          </span>
            <span *ngFor="let list of mydata;let index = index">
              <img class="img" *ngIf="list.type == 'image'" [src]="list.url" />
              <!-- <video *ngIf="list.type == 'video'" class="img" [src]="list.url"></video> -->
              <img class="img" *ngIf="list.type == 'video'"
                  src="../../../../../../assets/images/help/bgwith-play.png"
                  alt="Video"
              />
              <button class="closebutton" (click)="closeThumb(index)" href="javascript:void(0);" [disabled]="disabledCrossIcon"
                ><img
                  src="../../../../../../assets/images/help/attachClose.svg"
                  alt="close"
              /></button>
            </span>
            <div class="blankDiv w-100">
          <p class="fileSizeText">Max. File Size: 100 MB</p>
          
        </div>
          </div>
          <p class="fileSizeText error" *ngIf="fileAdded">File is already added!</p>
          <div class="error mtop" *ngIf="fileSizeError">Files greater than 100 MB cannot be added</div>
          <!-- <div class="error" *ngIf="fileTypeAccept">Only .jpg, .jpeg, .png and .mp4 files are supported</div> -->
        </div>
        <div *ngIf="discardChanges" class="discardChanges">
          <div class="txtDiscard">
            <b>Discard Changes?</b>
            <em>Changes you made will be lost</em>
          </div>
          <div class="discardCta">
            <button type="button" (click)="onDiscard()" class="btn textButton">Discard</button>
            <button type="button" (click)="cancelClick()" class="btn btn-primary">Cancel</button>
          </div>
        </div>
        <div class="submitCta">
          <label *ngIf="!this.mydata.length" class="noAttachment">No File Attached</label>
          <label class="noAttachment" *ngIf="this.mydata.length">{{this.mydata.length}} Attachment{{this.mydata.length > 1 ? 's' : ''}} added</label>
          <button type="button" (click)="reportAnIssue()" [disabled]="isLoading" [ngClass]="{'disabledClass': !checkIfDisabled()}" class="btn btn-primary">
            <span [ngClass]="{'spinner': isLoading}"></span>
            Submit
          </button>
        </div>
      </div>

    </div>
  </div>
</div>