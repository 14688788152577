import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HomeService } from '../../services/home.service';
import { AlertService } from '../../../shared/services/alert.service';
import { filter } from 'rxjs/operators';
import _ from 'lodash';

declare var $: any;

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})

export class HomeSearchComponent implements OnInit {
  @Input() clearSearch: any;
  @Output() onExpand = new EventEmitter<any>();
  @ViewChild('globalSearch') searchInputField: ElementRef<HTMLElement>;
  recentSearchData: any;
  searchSuggestions: any;
  showSearchSuggestions: boolean = false;
  showRecentSearch: boolean = false;
  expandInput: boolean = false;
  searchText: string = "";
  recentSearchListFetched = false;
  recentSearchItems: any;
  isSuggestionsFetched: boolean = false;
  elementIds: Array<string> = [
    'recentSearchBar', 'recentSearchText', 'recentList', 'suggestionList', 'searchBar', 'searchIcon'
  ];

  constructor(private homeService: HomeService,
    private router: Router, private alertService: AlertService) {
    const events = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    document.addEventListener('click', this.outSideClick.bind(this));
    events.subscribe((e: NavigationEnd) => {
      if (localStorage.getItem("searchKeywordData")) {
        let searchData = JSON.parse(localStorage.getItem("searchKeywordData"));
        this.searchText = searchData.name
      }
    });
  }

  ngOnInit(): void {
    this.homeService.homeSearchUpdate.subscribe((data) => {
      switch (data.action) {
        case 'autoFocusSearchField':
          this.autoFocusSearchField();
          break;
        case 'clearAllSuggestions':
          this.clearAll();
      }
    });

    if (window.location.pathname.includes("searchResult")) {
      let searchData = JSON.parse(localStorage.getItem("searchKeywordData"));
      this.searchText = searchData.name
    }

    this.alertService.clickedBackHome.subscribe((res) => {
      if (res) {
        this.searchText = '';
        this.alertService.clickedBackHome.next(false);
      }
    });

    // add class in global search 
    $('#searchBar').focus(function () {
      $('.globalSearch').addClass('zIndex99');
    }).blur(function () {
      $('.globalSearch').removeClass('zIndex99');
    });
  }

  ngOnChanges() {
    if (this.clearSearch) {
      this.searchText = "";
    }
  }

  fillSearchItem(itemSelected, event) {
    event.stopPropagation();
    this.searchText = itemSelected.name;
    let id = itemSelected.id;
    localStorage.removeItem("searchKeywordData");
    localStorage.setItem("searchKeywordData", JSON.stringify(itemSelected));
    this.showRecentSearch = false;
    this.showSearchSuggestions = false;
    this.searchSuggestions = [];
    this.fetchSearchSuggestions();
    this.autoFocusSearchField(true);
  }

  showRecentSearchToggle() {
    this.expandInput = true;
    this.onExpand.emit(true);
    if (!this.searchText) {
      this.showRecentSearch = true;
      if (this.recentSearchListFetched) {
        return;
      }

      if (this.showRecentSearch) {
        this.recentSearchData = [];
        this.homeService.fetchRecentSearch().then((res) => {
          if (res) {
            this.recentSearchListFetched = true;
            this.recentSearchData = res;
            if (this.recentSearchData.length > 10) {
              this.recentSearchData = this.recentSearchData.slice(0, 10);
            }
            this.showHighlightedText();
          }
        });
      } else {
        this.recentSearchData = [];
      }
    } else {
      this.showSearchSuggestions = true;
      if (this.isSuggestionsFetched) {
        return;
      }
      if (this.showSearchSuggestions) {
        let types = 'concept,chapter';
        this.appendRecentSearchItem();
        this.homeService.searchSuggestions(types, this.searchText).then((res) => {
          if (res) {
            this.searchSuggestions = res.length ? _.unionBy(this.recentSearchItems, res, 'id') : this.recentSearchItems;
            this.isSuggestionsFetched = true;
            this.showSearchSuggestions = true;
          } else {
            this.showSearchSuggestions = false;
            this.isSuggestionsFetched = false;
          }
          this.showHighlightedText();
        });
      } else {
        this.searchSuggestions = []
      }
    }
  }

  appendRecentSearchItem(): void {
    this.recentSearchItems = this.recentSearchData.filter(item => {
      item.isRecentSearchItem = true;
      return item.name && item.name.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  searchItemSelected(e, itemSelected) {
    e.stopPropagation();
    this.searchText = itemSelected && itemSelected.name;
    let id = itemSelected && itemSelected.id;
    this.closeSearchBoxOnClick();
    localStorage.removeItem("searchKeywordData");

    localStorage.setItem("searchKeywordData", JSON.stringify(itemSelected));
    if (id) {
      this.router.navigate([
        `home/searchResult/${id}`
      ]);
    } else {
      this.router.navigate([
        `home/searchResult/searchForCommunity/${itemSelected.name}`
      ]);
    }
  }

  searchForOnlyCommunity() {
    let itemSelected = {
      name: this.searchText
    }
    localStorage.setItem("searchKeywordData", JSON.stringify(itemSelected));
    this.router.navigate([
      `home/searchResult/searchForCommunity/${itemSelected.name}`
    ]);
    this.resetSearchBar();
  }

  onEnterSearch() {
    if (this.searchText) {
      let searchData = JSON.parse(localStorage.getItem("searchKeywordData"));
      let id = searchData && (searchData._id || searchData.id);
      if (this.searchText.toLowerCase() === searchData && searchData.name.toLowerCase()) {
        this.router.navigate([
          `home/searchResult/${id}`
        ]);
      } else {
        this.searchForOnlyCommunity()
      }
      this.resetSearchBar();
    }
  }

  resetSearchBar() {
    this.showRecentSearch = false;
    this.showSearchSuggestions = false;
    this.recentSearchData = [];
    this.searchSuggestions = [];
    this.expandInput = false;
    this.onExpand.emit(false);
  }

  removesearchText() {
    this.searchText = "";
  }

  removeSearchClass() {
    this.searchText = "";
    let searchEle: any = document.getElementsByClassName('searchHideRes')
    searchEle[0].classList.remove('searchHideRes');
  }

  outSideClick($event) {
    if ($event.target.id === 'recentSearchBar' || $event.target.id === 'recentSearchText') {
      this.autoFocusSearchField();
    } else if (!this.elementIds.includes($event.target.id)) {
      this.searchText = this.showSearchSuggestions || this.showRecentSearch ? '' : this.searchText;
      this.closeSearchBoxOnClick();
    }
  }

  closeSearchBoxOnClick() {
    this.showSearchSuggestions = false;
    this.searchSuggestions = [];
    this.showRecentSearch = false;
    this.expandInput = false;
    this.recentSearchListFetched = false;
    this.isSuggestionsFetched = false;
    this.onExpand.emit(false);
  }

  onChangeSearchValue = _.debounce((event) => {
    this.showRecentSearch = false;
    this.searchText = event;

    if (!(this.searchText || "").trim()) {
      this.showSearchSuggestions = false;
      this.recentSearchListFetched = false;
      this.showRecentSearchToggle();
      this.showHighlightedText();
      return;
    }
    this.fetchSearchSuggestions();
  }, 200);

  fetchSearchSuggestions() {
    const types = 'concept,chapter';

    this.appendRecentSearchItem();
    this.homeService.searchSuggestions(types, this.searchText).then((res) => {
      if (res) {
        this.expandInput = true;
        this.onExpand.emit(true);
        this.isSuggestionsFetched = true;
        this.searchSuggestions = res.length ? _.unionBy(this.recentSearchItems, res, 'id') : this.recentSearchItems;
        this.showSearchSuggestions = true;
        if (this.searchSuggestions.length > 10) {
          this.searchSuggestions = this.searchSuggestions.slice(0, 10);
        }
      } else {
        this.showSearchSuggestions = false;
        this.isSuggestionsFetched = false;
      }
      this.showHighlightedText();
    });
  }

  emptySearchState() {
    this.showSearchSuggestions = false;
    this.searchSuggestions = [];
  }

  clearAll() {
    this.homeService.clearAllSuggestion().then((res: any) => {
      if (res.code === 200) {
        this.recentSearchData = []
      }
    })
    this.showRecentSearch = false;
    this.expandInput = true;
  }

  clearSearchText() {
    this.searchText = '';
    this.autoFocusSearchField();
  }

  autoFocusSearchField(skipClickEvent?) {
    setTimeout(() => {
      if (this.searchInputField) {
        if (!skipClickEvent) {
          this.searchInputField.nativeElement.click();
        }
        this.searchInputField.nativeElement.focus();
      }
    }, 100);
  }

  public showHighlightedText = _.debounce(this.highlightTextUtilityFunction, 200);

  highlightTextUtilityFunction(): void {
    let listElementsName = document.querySelectorAll('.itemName');

    listElementsName.forEach((element) => {
      element.innerHTML = this.showHighlightedTextHelper(element);
    });
  }

  showHighlightedTextHelper(element): string {
    let textContent = element.textContent;
    if (textContent.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) {
      let index = textContent.toLowerCase().indexOf(this.searchText.toLowerCase());
      let textToHiglight = textContent.substring(index, index + this.searchText.length);
      const re = new RegExp(textToHiglight, 'i');
      return element.textContent.replace(re, `<span style="font-weight:bold;font-size:100%;color:#666666;">${textToHiglight}</span>`);
    } else {
      return textContent;
    }
  }
}
