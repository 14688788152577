import { Action } from '@ngrx/store';

export const SET_LOADER = 'SET_LOADER';

export class SetLoader implements Action {
    readonly type = SET_LOADER;
    constructor(public payload: boolean){}
}

export type LoaderActionList = SetLoader;
