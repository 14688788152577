import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { AlertService } from 'src/app/shared/services/alert.service';


declare var $: any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {

  public alertSubscription: Subscription;
  public timeOut: any;
  public alertMessageSuccess = '';
  public alertMessageSuccessSubtitle = '';
  public alertMessageError = '';
  public alertMessageErrorSubtitle = '';
  public alertMessageWarning = '';
  public alertMessageCustom = {
    title: '',
    btn: ''
  };
  public dynamicCustomMessageAlert = '';
  public dynamicMessageClass: string = '';
  public dynamicMessageIcon: string = '';

  showMessage(str): void {
    $(`#${str}-alert`).delay(200).alert();
  }

  hideMessage(): void {
    this.timeOut = setTimeout(() => {
      $('.alert').fadeOut('slow');
      this.alertMessageSuccess = '';
      this.alertMessageSuccessSubtitle = '';
      this.alertMessageWarning = '';
      this.alertMessageError = '';
      this.dynamicCustomMessageAlert = '';
      this.alertMessageCustom = {
        title: '',
        btn: ''
      };
    }, 100);
  }

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.messageChange.subscribe((data) => {
      this.alertMessageErrorSubtitle = '';
      this.alertMessageError = '';
      switch (data.type) {
        case 0:
          if (data?.subtitle) {
            data.subtitle = !(_.isEmpty(data.subtitle)) && data.subtitle.trim();
            data.subtitle = !(_.isEmpty(data.subtitle)) && data.subtitle.charAt(0).toUpperCase() + data.subtitle.slice(1);
            this.alertMessageErrorSubtitle = data.subtitle;
          }
          if (data?.error) {
            data.error = !(_.isEmpty(data.error)) && data.error.trim();
            data.error = !(_.isEmpty(data.error)) && data.error.charAt(0).toUpperCase() + data.error.slice(1);
          }
          this.alertMessageError = data.error;
          this.showMessage('error');
          break;
        case 1:
          if (data.success) {
            data.success = data.success.trim();
            data.success = data.success.charAt(0).toUpperCase() + data.success.slice(1);
          }
          if (data?.subtitle) {
            data.subtitle = !(_.isEmpty(data.subtitle)) && data.subtitle.trim();
            data.subtitle = !(_.isEmpty(data.subtitle)) && data.subtitle.charAt(0).toUpperCase() + data.subtitle.slice(1);
            this.alertMessageSuccessSubtitle = data.subtitle;
          }
          this.alertMessageSuccess = data.success;
          this.showMessage('success');
          break;
        case 2:
          if (data.warning) {
            data.warning = data.warning.trim();
            data.warning = data.warning.charAt(0).toUpperCase() + data.warning.slice(1);
          }
          this.alertMessageWarning = data.warning;
          this.showMessage('warning');
          break;
        case 3:
          if (data.custom) {
            data.custom = !(_.isEmpty(data.custom)) && data.custom.trim();
            data.custom = !(_.isEmpty(data.custom)) && data.custom.charAt(0).toUpperCase() + data.custom.slice(1);
          }
          this.dynamicMessageClass = data.className;
          this.dynamicMessageIcon = data.icon;
          this.dynamicCustomMessageAlert = data.custom;
          this.showMessage('dynamic-custom');
          break;
        case 4:
          if (data.customBtn) {
            data.customBtn = data.customBtn.trim();
            data.customBtn = data.customBtn.charAt(0).toUpperCase() + data.customBtn.slice(1);
          }
          this.alertMessageCustom.title = data.customBtn;
          this.alertMessageCustom.btn = data.btnName;
          this.showMessage('customBtn');
          break;
        case -1:
          this.hideMessage();
      }
    });
  }

  undoRevision(): void {
    this.alertService.undoRevisionQuestion.next(true);
    this.hideMessage();
  }
}
